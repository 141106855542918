import React from 'react';
import PropTypes from 'prop-types';

import './styles/imagePreview.css';

export const ImagePreview = ({ dataUri, isFullscreen, isImageRotate, camPosSel }) => {
  let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';
  let isDisplay = true;
  //let imageStyles = getShowHideStyle(isShowVideo, isImageRotate);
  let imageStyles   = isDisplay
                      ? isImageRotate ? (camPosSel===90) ? {display: 'inline-block', transform: 'rotate(-90deg) rotate3d(0,  1, 0, 20deg) translate(-4.5%, -38.6%) scale(1.15) scaleY(-1)', width: '1920px', height: '1080px'}
                                                         : {display: 'inline-block', transform: 'rotate( 90deg) rotate3d(0, -1, 0, 20deg) translate( 4.5%,  38.6%) scale(1.15) scaleY(-1)', width: '1920px', height: '1080px'}
                                      : {display: 'inline-block', transform: 'none', width: '1920px', height: '1080px'}

                      : isImageRotate ? (camPosSel===90) ? {display: 'none', transform: 'rotate(-90deg) rotate3d(0,  1, 0, 20deg) translate(-4.5%, -38.6%) scale(1.15) scaleY(-1)', width: '1920px', height: '1080px'}
                                                         : {display: 'none', transform: 'rotate( 90deg) rotate3d(0, -1, 0, 20deg) translate( 4.5%,  38.6%) scale(1.15) scaleY(-1)', width: '1920px', height: '1080px'}
                                      : {display: 'none', transform: 'none', width: '1920px', height: '1080px'};




  //console.log("ImagePreview");
  //console.log("dataUri:" + dataUri);
  //console.log("isImageRotate:" + isImageRotate);
  //console.log("isFullscreen:" + isFullscreen);
  //console.log("imageStyles.transform:" + imageStyles.transform);
  //console.log("imageStyles:" + imageStyles);
  
//<div className={'demo-image-preview ' + classNameFullscreen}>
//<Button as={Link} to={`/products/product/:productID`} style={styles.button} size="small" >
//        <Icon name="UsePhoto" /> Use this photo
//</Button>


  return (
    <div className={"{/*ui raised very padded text container segment demo-image-preview*/}" + classNameFullscreen} style={styles.div}>
      
        <img src={dataUri} style={imageStyles}/>        
      
    
      

    </div>
  );
};


ImagePreview.propTypes = {
  dataUri: PropTypes.string,
  isFullscreen: PropTypes.bool
};

const styles = {
  div: {
    marginTop: 40
  },
  button: {
    marginLeft: 5
  }
};

export default ImagePreview;
