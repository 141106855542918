import React, { Component } from "react";
import { CheckoutForm } from "../../components";

class Checkout extends Component {
  render() {
    return <CheckoutForm />;
  }
}

export default Checkout;
