import clickSound from './data/click-sound.base64.json';

export function getShowHideStyle (isDisplay, imageRotate, camPosSel) {
  const displayStyle = isDisplay
                      ? imageRotate ? (camPosSel===90) ? {display: 'inline-block', transform: 'rotate(-90deg) rotate3d(0,  1, 0, 20deg) translate( -6.5%, -38.6%) scale(1.15) scaleY(-1)'}
                                                       : {display: 'inline-block', transform: 'rotate( 90deg) rotate3d(0, -1, 0, 20deg) translate(-50.0%,  38.6%) scale(1.35) scaleY(-1)'}
                                    : {display: 'inline-block', transform: 'none'}

                      : imageRotate ? (camPosSel===90) ? {display: 'none',         transform: 'rotate(-90deg) rotate3d(0,  1, 0, 20deg) translate(-6.5%,  -38.6%) scale(1.15) scaleY(-1)'}
                                                       : {display: 'inline-block', transform: 'rotate( 90deg) rotate3d(0, -1, 0, 20deg) translate(-50.0%,  38.6%) scale(1.35) scaleY(-1)'}
                                    : {display: 'none', transform: 'none'};


  return displayStyle;
}

export function _getRotateCameraStyle (imageRotate, camPosSel) {
  const rotateDisplayStyle = imageRotate ? (camPosSel===90) ? {transform: 'rotate(-90deg)'}
                                                            : {transform: 'rotate( 90deg)'} 
                                         : {transform: 'none'};

  return rotateDisplayStyle;
}

export function _getMirrorCameraStyle (imageMirror) {
  const mirrorDisplayStyle = imageMirror
    ? {transform: 'rotateY(180deg)'}
    : {transform: 'none'};

  return mirrorDisplayStyle;
}

export function getVideoStyles (isDisplay, imageMirror, imageRotate, camPosSel) {
  return {
    ..._getRotateCameraStyle(imageRotate, camPosSel),
    //..._getMirrorCameraStyle(imageMirror),
    ...getShowHideStyle(isDisplay, imageRotate, camPosSel)
  };
}

export function playClickAudio () {
  let audio = new Audio('data:audio/mp3;base64,' + clickSound.base64);
  audio.play();
}

export function printCameraInfo (info) {
  console.info('react-html5-camera-photo info:', info);
}
