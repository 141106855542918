import React, {useState} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./footer.css";


/**
 * @component Form
 * @props - { object } -  config
 */
const Form = (props) => {
  const [mailSent, setmailSent] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [msgData, setMsgData] = useState({});
  const [errInput, setErrInput] = useState({});
 
  /**
  * @function handleFormSubmit
  * @param e { obj } - form event
  * @return void
  */
  const handleFormSubmit = e => {
    e.preventDefault();

    
    //formData.msg = formData.subject + '\n' + formData.msg,
   

    console.log("formData@submit", formData)
    console.log("errInput@submit", errInput)

    let valuesArray = Object.values(errInput);
    var valid = valuesArray.every(err => err===false)

    if(valid){

        axios({
          method: "post",
          url: `https://aichure.com/index.php`,
          headers: { "content-type": "application/json" },
          data: formData
        })
          .then(result => {
            if (result.data.sent) {
              setmailSent(result.data.sent)
              setError(false)
            } else {
              setError(true)
            }
          })
          .catch(error => setError( error.message ));

    }


  };
  /**
    * @function handleChange
    * @param e { obj } - change event
    * @param field { string } - namve of the field
    * @return void
    */
   const handleChange = (e, field, msg) => {
    let value = e.target.value;
    let firstTime = false;
    setFormData({
      ...formData,
      [field]: value,
    });

    if(field==='email'){

      var valid = validEmail(value)

      //initialize
      if(!errInput.email){ 
          setErrInput({
                       ...errInput,
                       [field]: false,
                     });
          firstTime = true;
          
      }


      if(valid){
        //remove label
        removeLabel(field, field+'Label')
        setErrInput({
                      ...errInput,
                      [field]: false,
                    });

      }else{
        //add label

        if(errInput.email===false || firstTime){

            addLabel(field, field+'Label', msg);
            setErrInput({
                          ...errInput,
                          [field]: true,
                        });
        }

      }

    }else if(field==='name'){

      var valid = minMaxLength(value, 2)

      //initialize
      if(!errInput.name){ 
          setErrInput({
                       ...errInput,
                       [field]: false,
                     });
          firstTime = true;
          
      }


      if(valid){
        //remove label
        removeLabel(field, field+'Label')
        setErrInput({
                      ...errInput,
                      [field]: false,
                    });

      }else{
        //add label

        if(errInput.name===false || firstTime){

            addLabel(field, field+'Label', msg);
            setErrInput({
                          ...errInput,
                          [field]: true,
                        });
        }

      }

    }else if(field==='subject'){

      var valid = minMaxLength(value, 4)

      if(!errInput.subject){ 
          setErrInput({
                       ...errInput,
                       [field]: false,
                     });
          firstTime = true;
          
      }


      if(valid){
        //remove label
        removeLabel(field, field+'Label')
        setErrInput({
                      ...errInput,
                      [field]: false,
                    });

      }else{
        //add label

        if(errInput.subject===false || firstTime){

            addLabel(field, field+'Label', msg);
            setErrInput({
                          ...errInput,
                          [field]: true,
                        });
        }

      }

    }else if(field==='msg'){

      var valid = minMaxLength(value, 6)

      if(!errInput.msg){ 
          setErrInput({
                       ...errInput,
                       [field]: false,
                     });
          firstTime = true;
          
      }


      if(valid){
        //remove label
        removeLabel(field, field+'Label')
        setErrInput({
                      ...errInput,
                      [field]: false,
                    });

      }else{
        //add label

        if(errInput.msg===false || firstTime){

            addLabel(field, field+'Label', msg);
            setErrInput({
                          ...errInput,
                          [field]: true,
                        });
        }

      }

    }

    //console.log("formData", formData)

  };

const addLabel = ( ref, id, msg ) => {

  var newlabel = document.createElement("Label");
  newlabel.setAttribute("for", ref);
  newlabel.setAttribute("id", id);
  newlabel.innerHTML = msg;

  document.getElementById(ref).appendChild(newlabel)


}

const removeLabel = (ref, id) => {

  var removeLabel = document.getElementById(id)
  document.getElementById(ref).removeChild(removeLabel);


}

const minMaxLength = (text, minLength, maxLength) => {
    //let result = !text || text.length < minLength;
    let result = text.length >= minLength;
    if(maxLength)
        result = result && text.length < maxLength;
//        result = result || text.length < minLength;
    return result;
}
 
const validEmail = (text) => {
    const regex = RegExp(
        // /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]  + @[a-zA-Z0-9-] + (?:\.[a-zA-Z0-9-]+)*$/
        //   /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-] + @[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
           /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  
      );
     
    return regex.test(text);
}


    const { title,description, successMessage, errorMessage, fieldsConfig } = props.config;
    return (
      <div className="contact-form">
        {/*<div className="contact-form__header">
            <h2>{title}</h2>
            <p>{description}</p>
        </div>*/}
      <div className="contact-form__container">
        <div>
        <fieldset className="fieldsetForm" disabled={mailSent}>
          <form action="#" style={styles.form}>
            

              {fieldsConfig &&
                fieldsConfig.map(field => {
                  return (
                    
                      <React.Fragment key={field.id}>
                      
                        {field.type !== "textarea" ? (
                          <React.Fragment>
                            {/*<label>{field.label}</label>*/}
                            
                              <div id={field.fieldName} className="input-group">

                                <input
                                  type={field.type}
                                  className={field.klassName}
                                  
                                  placeholder={field.placeholder}
                                  value={field.name}
                                  onChange={e => handleChange(e, field.fieldName, field.errMsg)}
                                />
                              </div>
                           
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div id={field.fieldName} className="input-group">
                              {/*<label>{field.label}</label>*/}
                              <textarea className={field.klassName} placeholder={field.placeholder} onChange={e => handleChange(e, field.fieldName, field.errMsg)} value={field.name} />
                            </div>
                          </React.Fragment>
                        )}

                      </React.Fragment>
                    
                  );
                })}
              <input type="submit" onClick={e => handleFormSubmit(e)} value="Submit" style={styles.button}/>
              <div className="sucsess">
                {mailSent && <div >  <h1> Thank you </h1> <h2>{successMessage}</h2>  </div>}
                {error && <div className="error">{errorMessage}</div>}
              </div>
            
          </form>
          </fieldset>
        </div>
        </div>
      </div>
    );
}

export default Form;
//propTypes for the component
Form.propTypes = {
  config: PropTypes.object.isRequired
};

const styles = {
  button: {
    backgroundImage: 'linear-gradient(to right, #70bafe 0%, #998dff 51%, #70bafe 100%)',

    backgroundSize: '200% auto',
    //z-index: 2;
    width: '120px',
    height: '60px',
    //padding: 0px;
    //display: inline-block;
    transition: 'all 400ms ease-out',
    textAlign: 'center',
    //outline: 'none !important',
    //box-shadow: none !important;
    color: '#fff',
    fontSize: '16px',
    lineHeight: '48px',
    border: '6px solid rgba(204, 241, 255, 0.5)',
    borderRadius: '33px',

    padding: 'unset'

  },
  form: {

    flexWrap: 'wrap', 
    display: 'flex',

  },
}