import React, { Component } from 'react';
import { connect } from "react-redux";
import { Input } from 'semantic-ui-react'


import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo';
import Camera from "../../lib";

import { drawArbitraryQuadImage, FILL_METHOD } from 'canvas-arbitrary-quads';

//import * as THREE from "three";

import {
  Button,
  Form
} from "semantic-ui-react";

 
import { 
          //updateParams, 
          updateProject,
          
          updateDataXAR,
          doRTCAR 
       
       } from "../../store";

//import RTCMesh from "../../lib-rtc";
require('react-rtc-real/assets/index.css');

const mapState = state => ({
  
   //baselayout: state.baselayout
   suit : state.suit,
   xar  : state.xar,
   product: state.product,
  
   project: state.project

});

const mapDispatch = dispatch => {
  return {

    //setParams: (params) => dispatch(updateParams(params))
    setProject : (params) => dispatch(updateProject(params)),

    updateXAR: (xar) => dispatch(updateDataXAR(xar)),    
    requestRTCAR: (xar) => dispatch(doRTCAR(xar))

  };
};

class RTCfitting extends Component {
  constructor(props) {
    super(props);

    this.state = {

      request : null,
      hangingGet : null,
      localName : '',
      server : '',
      myId : -1,
      otherPeers : {},
      messageCounter : 0,
      pc : '',    
      pcConfig : {"iceServers": [{"url": "stun:stun.l.google.com:19302"}]},
      pcOptions : {
                  optional: [
                                {DtlsSrtpKeyAgreement: true}
                            ]
                  },
      mediaConstraints : {'mandatory': {
                                'OfferToReceiveAudio': true,
                                'OfferToReceiveVideo': true 
                          }},
     
      RTCPeerConnection : '',
      RTCSessionDescription : '',
      RTCIceCandidate : '',
      //getUserMedia : '',
      //URL : '',
      peer_id: '',



      ARfittigTimeOut: false,


      /*camera*/
      dataUri: '',
      idealFacingMode: FACING_MODES.USER,
      isMaxResolution: false,
      DataUri: '',
      isFullscreen: false,
      imageNumber: 0,
      modifyDataUri:'',
      camPosSel: 0,  
      camBtnPosSel: 0,
      countdownPosSel: 1,
      isImageRotate: 1,//0 horizontal, 1, +90, -1:-90
      isShoot: false,
      isCountDown: false,
      timerCount: 0,
      timerRunning: false,
      sourceType: 'aichureMirror-takephoto',
      isCameraOn: false

      
    };

    this.localStream = null;
    this.localStream_clone = null;
 

    this.remoteVideoCanvas = React.createRef();


    this.cameraPhoto = null;
    this.videoRef = React.createRef();
    this.manipulateCanva = React.createRef();   
    this.videoSnapShot = [];

    this.canvasArray = [];
    this.drawCnt = 0;
    this.sizeCanvasArray = 30;

    for (var i = 0; i < this.sizeCanvasArray; ++i) {

        this.canvasArray[i] = document.createElement('canvas');
        
    }

    this.mergeRef = React.createRef();
    this.mergeCnt = -30;
    this.merge = time => {
      //
      console.log("merge", this.mergeCnt, this.mergeCnt%this.sizeCanvasArray);
      if(this.mergeCnt >= 0){
        const canvas = this.canvasArray[this.mergeCnt%this.sizeCanvasArray];
        const ctx = this.manipulateCanva.current.getContext('2d');
        ctx.globalCompositeOperation = "copy"
        ctx.drawImage(this.remoteVideoCanvas.current, 0, 0, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
        const frame = ctx.getImageData(0, 0, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
        const length = frame.data.length;

        for (let i = 0; i < length; i += 4) {
          const red = frame.data[i + 0];
          const green = frame.data[i + 1];
          const blue = frame.data[i + 2];
          if (green > 175 && red < 100 && blue < 100) {
            frame.data[i + 3] = 0;
          }
        }

        ctx.putImageData(frame, 0, 0);
        ctx.globalCompositeOperation = "destination-over"
        ctx.drawImage(canvas, 0, 0, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
      }

      this.mergeRef.current = requestAnimationFrame(this.merge);
    }

    this.ArbitraryQuadSrc = [];
    this.ArbitraryQuadDst = [];

    

    window.onbeforeunload = this.disconnect();

    //
    const { setParams/*, baselayout*/ } = this.props;
    console.log("disable @baselayout")
    /*
    var params = baselayout;
    params.onGesture = false;
    params.onButton = false;
    params.onHeader = false;
    params.onNavbar = false;
    params.FooterSel = 0;
    setParams(params);
    */


    // create a video texture
    const videoElement = document.getElementById('remoteVideo');
    //this.videoTexture = new THREE.VideoTexture(videoElement);
    //this.videoTexture.format = THREE.RGBAFormat;

  }

  componentWillUnmount() {
    document.getElementsByName('topHeader')[0].style.display = null;
    this.disconnect();
  }

  componentDidMount() {

      //add local stream
    
      const { xar, project, setProject } = this.props;

      var params = project;

      params.onButton = false;
      setProject(params);

      console.log("project@RTCfitting", project)
      
      this.setState({ camPosSel: params.camPosSel });
      this.setState({ camBtnPosSel: params.camBtnPosSel });
      this.setState({ countdownPosSel: params.countdownPosSel });
     
      this.setState({ARfittigTimeOut: false})

      //this.setState({ RTCPeerConnection : ( window.mozRTCPeerConnection || window.webkitRTCPeerConnection) });
      this.setState({ RTCSessionDescription : (window.mozRTCSessionDescription || window.RTCSessionDescription) });
      this.setState({ RTCIceCandidate : (window.mozRTCIceCandidate || window.RTCIceCandidate) });
      //this.setState({ getUserMedia : (navigator.mozGetUserMedia || navigator.webkitGetUserMedia) });
      //this.setState({ URL : (window.webkitURL || window.URL) });
    

      //end of add local stream
  

      this.cameraPhoto = new CameraPhoto(this.videoRef.current);

      this.start(); 


      //for (var i = 0; i < this.sizeCanvasArray; ++i) {


      //}

    //this.connect();

  }


  componentDidUpdate(prevProps, prevState) {

      console.log("Props@RTCfitting componentDidUpdate", this.props.project.ARfittigTimeOut)
      console.log("prevProps@RTCfitting componentDidUpdate", prevProps.project.ARfittigTimeOut)

      if (this.props.project.ARfittigTimeOut===true && this.state.ARfittigTimeOut===false) {

          this.setState({ARfittigTimeOut: this.props.project.ARfittigTimeOut})

          
          var tmp = this.props.project
          tmp.ARfittigTimeOut = false;
          this.props.setProject(tmp);
               

          this.changeClothes();

      }

  }

  XARclothesProducts() {
    console.log("============================= clothlist ===============================")
    
    //if mirror mode, mirror side has no product informoation, need get it from webserver,
    const { xar, suit, product } = this.props;
    console.log("this.props @XARclothesProducts: ", this.props)
    console.log("suit @XARclothesProducts: ", suit)
    console.log("suit.suit @XARclothesProducts: ", suit.suit)


    

    const video_filename = xar.picID + '_' + suit.suit.suitID;
    
    /* originally, find the same suit of other items
    const clothlist = [];
    suit.suit.products.forEach(product => {
        console.log("product.suit.products.internal_name: ", product.internal_name)
        clothlist.push(product.internal_name);        
      });
    */
    const clothlist = suit.suit.suitClothesID;
    const shoes = suit.suit.shoes;

    console.log("clothlist: ", clothlist)

    xar.clothlist = clothlist;
    xar.shoes = shoes;
    xar.suitID = suit.suit.suitID;
    xar.clothesID = "asia2020_f_001_dress_long_red";//product.product.internal_name;
    xar.video_filename = video_filename;
    if(suit.suit.gender === 'male'){
      xar.human.gender = 1;  
    }else
    {
      xar.human.gender = 0;
    }
    

  }



  async createPeerConnection(peer_id) {
        try {
            console.log("@createPeerConnection this: ", this);
            
            //this.test = new this.state.RTCPeerConnection(this.state.pcConfig, this.state.pcOptions);


            this.setState({ pc : (new RTCPeerConnection(this.state.pcConfig, this.state.pcOptions)) }, ()=> {

                                    console.log("createPeerConnection this: ", this);

                                    this.state.pc.onicecandidate = (function(event) {
                                        if (event.candidate) {
                                            var candidate = {
                                                sdpMLineIndex: event.candidate.sdpMLineIndex,
                                                sdpMid: event.candidate.sdpMid,
                                                candidate: event.candidate.candidate
                                            };
                                            this.sendToPeer(JSON.stringify(candidate));
                                        } else {
                                          console.log("End of candidates.");
                                        }
                                    }).bind(this);
                                    this.state.pc.onconnecting = this.onSessionConnecting;
                                    this.state.pc.onopen = this.onSessionOpened;
                                    this.state.pc.onaddstream = this.onRemoteStreamAdded.bind(this);
                                    this.state.pc.onremovestream = this.onRemoteStreamRemoved;
                                    console.log("inside setState of pc: ", this.state.pc)


                                    console.log("Created RTCPeerConnnection with config: " + JSON.stringify(this.state.pcConfig));
                                    this.localStream_clone.getTracks().forEach(track => {
                                                                                            this.state.pc.addTrack(track, this.localStream)
                                                                                            //const [sender] = this.state.pc.getSenders();
                                                                                            //console.log("sender@forEach", sender)
                                                                                            //console.log("params@forEach", sender.getParameters())
                                                                                        });
                                    //this.state.localStream.getTracks().forEach(track => this.state.pc.addTrack(track, this.state.localStream));
                                    //this.state.localStream.getTracks().forEach(track => this.state.pc.addTrack(track.clone(), this.state.localStream));
                                    console.log('Added local stream to pc: ', this.state.pc);
                                    console.log('this: ', this);

                                    //set to downsample
                                   
                                    //const [sender] = this.state.pc.getSenders();
                                    //console.log("sender", sender)
                                    //console.log("params", sender.getParameters())
                                    ////const ratio = sender.track.getSettings().height / height;
                                    //const params = sender.getParameters();
                                    //params.encodings[0].scaleResolutionDownBy = 4;
                                    ////params.encodings[0].scaleResolutionDownBy = Math.max(ratio, 1);
                                    ////params.encodings[0].maxBitrate = rate;
                                    ////await sender.setParameters(params);
                                    //sender.setParameters(params);
                                   
                                    /*
                                    const [sender] = this.state.pc.getSenders();
                                    
                                    const params = sender.getParameters();
                                    if (params.encodings.length == 0) {
                                      params.encodings = [{}]; // Firefox workaround
                                    }
                                    params.encodings[0].scaleResolutionDownBy = 4;
                                    params.encodings[0].maxBitrate = 10000;
                                    sender.setParameters(params);

                                    console.log('sender', sender)
                                    console.log('params', params)
                                    */
                                    // Safari fallback
                                    /*
                                    if (sender.getParameters().encodings[0].scaleResolutionDownBy == 1) {
                                        console.log('Safari fallback')
                                        var height = sender.track.getSettings().height/4;
                                        sender.track.applyConstraints({height});
                                    }*/
                                    
                                    //ios works but the original source is /4  
                                    const [sender] = this.state.pc.getSenders();
                                    var height = sender.track.getSettings().height/4;
                                    sender.track.applyConstraints({height});
                                    //---------------------------------------------
/*                    
                                    this.setState(prevState => ({
                                                  pc: {                   // object that we want to update
                                                      ...this.state.pc,    // keep all other key-value pairs
                                                      onicecandidate: (event)=>{  // update the value of specific key
                                                                                  if(event.candidate) {

                                                                                      var candidate = {
                                                                                          sdpMLineIndex: event.candidate.sdpMLineIndex,
                                                                                          sdpMid: event.candidate.sdpMid,
                                                                                          candidate: event.candidate.candidate
                                                                                      };
                                                                                      this.sendToPeer(peer_id, JSON.stringify(candidate));
                                                                                      console.log("sendToPeer: ", peer_id, " with ", candidate)
                                                                                      console.log("event in setState pc: ", event)
                                                                                  } else {
                                                                                    console.log("End of candidates.");
                                                                                  }
                                                                                 },

                                                      onconnecting: this.onSessionConnecting.bind(this),  
                                                      onopen: this.onSessionOpened.bind(this),
                                                      onaddstream: this.onRemoteStreamAdded.bind(this),
                                                      onremovestream: this.onRemoteStreamRemoved.bind(this)        
                                                      }           
                                                  })
                                                , ()=>{console.log("inside setState of pc: ", this.state.pc)})
                                              
                                    

                                    
                                    console.log("Created RTCPeerConnnection with config: " + JSON.stringify(this.state.pcConfig));
                                    this.state.localStream.getTracks().forEach(track => this.state.pc.addTrack(track, this.state.localStream));
                                    console.log('Added local stream to pc: ', this.state.pc);
                                    console.log('this: ', this);
                                  */
                                  }
            );




        console.log("@createPeerConnection end")
      //add local data
      //console.log("@createPeerConnection localStream: ", this.state.localStream)
      //const videoTracks = this.state.localStream.getVideoTracks();
      //const audioTracks = this.state.localStream.getAudioTracks();
      //if (videoTracks.length > 0) {
      //  console.log(`Using video device: ${videoTracks[0].label}`);
      //}
      //if (audioTracks.length > 0) {
      //  console.log(`Using audio device: ${audioTracks[0].label}`);
      //}
      
      
      
      //end of add local data
        } 
        catch (e) {
            console.log("Failed to create PeerConnection with ",  ", exception: " + e.message);
        }
    }
        


    onRemoteStreamAdded(event) {
      //console.log("Remote stream added:", URL.createObjectURL(event.stream));
      console.log("onRemoteStreamAdded this: ", this)
      //var remoteVideoElement = this.remoteVideoCanvas.current;
      this.remoteVideoCanvas.current.srcObject  = event.stream;//remoteVideoElement.src = URL.createObjectURL(event.stream);
      this.remoteVideoCanvas.current.play();

      //Kabon
      this.mergeCnt = -4
      this.mergeRef.current = requestAnimationFrame(this.merge);
      this.ChromaKey(this.props.project.camPosSel);
    }

    sld_success_cb() {
    }

    sld_failure_cb() {
      console.log("setLocalDescription failed");
    }

    aic_success_cb() {
    }

    aic_failure_cb() {
      console.log("addIceCandidate failed");
    }

    handleSessionDescription(sessionDescription){

      console.log("this: ", this);
      
      console.log("Create answer:", sessionDescription);
      //test for high resolution
      var arr = sessionDescription.sdp.split('\r\n');
      arr.forEach((str, i) => {
          if (/^a=fmtp:\d*/.test(str)) {
            arr[i] = str + ';x-google-max-bitrate=10000;x-google-min-bitrate=10000;x-google-start-bitrate=10000';
          } else if (/^a=mid:(1|video)/.test(str)) {
            arr[i] += '\r\nb=AS:10000';
          }
      });
      //sessionDescription.sdp = arr.join('\r\n')
      
      //-------------
      
      this.state.pc.setLocalDescription(sessionDescription, this.sld_success_cb, this.sld_failure_cb);
      var data = JSON.stringify(sessionDescription);
      this.sendToPeer(data);


      console.log("@handleSessionDescription peer_id: ", this.state.peer_id)

    }
    
    handlePeerMessage(peer_id, data) {

      console.log("@handlePeerMessage this: ", this)
      console.log("@handlePeerMessage peer_id: ", this.state.peer_id)
      this.setState({ messageCounter : (this.state.messageCounter++) });

        
        var str = "Message from '" + this.state.otherPeers[this.state.peer_id] + ":" + data;
        this.trace(str);
        
    

    
    
        var dataJson = JSON.parse(data);
        console.log("@handlePeerMessage received ", dataJson);
        if (data.search("offer") != -1) {  //connect command

            console.log("@handlePeerMessage createPeerConnection: offer pc: ", this.state.pc)
            this.createPeerConnection(this.state.peer_id)//, () => {

            console.log("@handlePeerMessage after  createPeerConnection this: ", this)
                                  
            this.state.pc.setRemoteDescription(new this.state.RTCSessionDescription(dataJson), this.onRemoteSdpSucces, this.onRemoteSdpError)//, ()=>{

            console.log("new session: pc", this.state.pc)

            this.state.pc.createAnswer(this.handleSessionDescription.bind(this),
                                       function(error) { // error
                                                          console.log("Create answer error:", error);
                                                        }, 
                                       this.state.mediaConstraints); // type error  ); //}, null    

                                                                    

                                  //})

                                                                    


                                                                           
            console.log("@handlePeerMessage in createPeerConnection end")
            //window.setTimeout(this.disconnect(), 30);



           // } )
    
        }
        else {
            console.log("Adding ICE candiate ", dataJson);
            var candidate = new this.state.RTCIceCandidate({sdpMLineIndex: dataJson.sdpMLineIndex, candidate: dataJson.candidate});
            this.state.pc.addIceCandidate(candidate, this.aic_success_cb, this.aic_failure_cb);
        }
    }    
    
    trace(txt) {
        var elem = document.getElementById("debug");
        elem.innerHTML += txt + "<br>";
    }
    
    handleServerNotification(data) {
        this.trace("Server notification: " + data);
        var parsed = data.split(',');
        if (parseInt(parsed[2]) != 0)
            this.state.otherPeers[parseInt(parsed[1])] = parsed[0];
    }
    
    parseIntHeader(r, name) {
        var val = r.getResponseHeader(name);
        return val != null && val.length ? parseInt(val) : -1;
    }
    
    hangingGetCallback() {
        try {

          console.log("@hangingGetCallback this.state.hangingGet: ", this.state.hangingGet)

            if (this.state.hangingGet.readyState != 4){
                console.log("this.state.hangingGet.readyState != 4", this.state.hangingGet.readyState)
                return;
            }


            if (this.state.hangingGet.status != 200) {
                console.log("this.state.hangingGet.status != 200")
                this.trace("server error: " + this.state.hangingGet.statusText);
                this.disconnect();

            } else {

                this.setState({ peer_id : this.parseIntHeader(this.state.hangingGet, "Pragma") });
                
                console.log("Message from:", this.state.peer_id, ':', this.state.hangingGet.responseText);
                if (this.state.peer_id == this.state.myId) {
                  this.handleServerNotification(this.state.hangingGet.responseText);
                } else {
                  this.trace("handlePeerMessage: " + this.state.hangingGet.responseText);
                  this.handlePeerMessage(this.state.peer_id, this.state.hangingGet.responseText);
                }
            }

            if (this.state.hangingGet) {
                console.log("if(this.state.hangingGet)");
                this.state.hangingGet.abort();
                this.setState({ hangingGet : null });
                     
              
            }

            if (this.state.myId != -1)
                window.setTimeout(this.startHangingGet(), 0);
      } catch (e) {
          this.trace("Hanging get error: " + e);
      }
    }
    
    startHangingGet() {
        try {
            this.trace("startHangingGet");
            this.setState({ hangingGet : (new XMLHttpRequest()) }, ()=> {
                                    this.state.hangingGet.onreadystatechange = this.hangingGetCallback.bind(this);
                                    this.state.hangingGet.ontimeout = this.onHangingGetTimeout.bind(this);
                                    console.log("startHangingGet myId: ", this.state.server + "/wait?peer_id=" + this.state.myId)
                                    this.state.hangingGet.open("GET", this.state.server + "/wait?peer_id=" + this.state.myId, true);
                                    this.state.hangingGet.send();  

            });
            
           
        } catch (e) {
            this.trace("@startHangingGet error" + e);
        }
    }
    
    onHangingGetTimeout() {

      try {
        this.trace("hanging get timeout. issuing again.");
        this.state.hangingGet.abort();
        this.setState({ hangingGet : null });

        if (this.state.myId != -1)
            window.setTimeout(this.startHangingGet(), 0);

      } catch (e) {
            this.trace("@onHangingGetTimeout error" + e);
      }    
    }
    
    signInCallback() {
        try {
            const { xar, updateXAR, requestRTCAR, project } = this.props;

            console.log("@signInCallback this.state.request: ", this.state.request)
          
            if (this.state.request.readyState == 4) {
                if (this.state.request.status == 200) {


                    

                    var peers = this.state.request.responseText.split("\n");
                    console.log("peers", peers);

                    this.setState({ myId : (parseInt(peers[0].split(',')[1])) }, ()=>{ 
                                                                                         this.XARclothesProducts();
                                                                                         xar.peerID = this.state.myId;
                                                                                         console.log("peerID ", xar.peerID)
                                                                                         updateXAR(xar);
                                                                                     });
                    
                    this.trace("My id: " + this.state.myId);
                    for (var i = 1; i < peers.length; ++i) {
                        if (peers[i].length > 0) {
                            this.trace("Peer " + i + ": " + peers[i]);
                            var parsed = peers[i].split(',');
                            this.state.otherPeers[parseInt(parsed[1])] = parsed[0];
                        }
                    }
                    console.log("@signInCallback startHangingGet request", this.state.request)

                    if(project.onMirror && project.userId===0){
                        //Mirror side, remote side has sent json file. mirror side has no ides which clothes clicked
                        

                        requestRTCAR(xar);  

                    }else{
                        //not ARmorror mode, that is, not remote side or mirror side, either,
                        //such as phone mode, and need send request after rtc connectting
                        requestRTCAR(xar);  
                    }

                    this.startHangingGet();
                    this.setState({ request : null });
                    
                }
            }
        } catch (e) {
            this.trace("signInCallback error: " + e);
            this.trace("signInCallback error: " + e.description);
        }
    }
    
    signIn() {
      try {

          console.log("@signIn")
          

          this.setState({ request : (new XMLHttpRequest()) }, () => {
                          console.log("request@signin", this.state.request);
                          this.state.request.onreadystatechange = this.signInCallback.bind(this);
                          this.state.request.open("GET", this.state.server + "/sign_in?" + this.state.localName, true);
                          this.state.request.send();

          });
       
      } catch (e) {
          this.trace("@signIn error: " + e);
      }
    }

    dummy() {
    }
    
    sendToPeer(data) {
      try {
          console.log("@sendToPeer: ", this.state.peer_id," Send ", data);
          if (this.state.myId == -1) {
              alert("Not connected");
              return;
          }
          if (this.state.peer_id == this.state.myId) {
              alert("Can't send a message to oneself :)");
              return;
          }
          var r = new XMLHttpRequest();
          r.onreadystatechange = this.dummy
          r.open("POST", this.state.server + "/message?peer_id=" + this.state.myId + "&to=" + this.state.peer_id, true);
          r.setRequestHeader("Content-Type", "text/plain");
          r.send(data);
      } catch (e) {
          this.trace("send to peer error: " + e);
      }
    }
    
    connect() {

      //add local data
      //this.start().then( () => {
      //end of add local data  

                          this.setState({ localName : document.getElementById("local").value.toLowerCase(),
                                          server    : document.getElementById("server").value.toLowerCase()
                                        },
                                          () => {

                                                  if (this.state.localName.length == 0) {
                                                    alert("I need a name please.");
                                                    document.getElementById("local").focus();
                                                  } else {
                                                    //document.getElementById("connect").disabled = true;
                                                    //document.getElementById("disconnect").disabled = false;
                                                    console.log("localName, server", this.state.localName, this.state.server);
                                                    this.signIn();
                                                  }

                                                  
                                                }

                                       );


                      // } );
        

      
    }
    
    disconnect() {

        console.log('disconnect');
        console.log("this.props@disconnect: ", this.props);
        console.log("this.state@disconnect: ", this.state);
        

        if (this.state.myId != -1) {
            
            this.setState({ request : (new XMLHttpRequest()) }, ()=>{

                                  this.state.request.open("GET", this.state.server + "/sign_out?peer_id=" + this.state.myId, false);
                                  this.state.request.send();
                                  this.setState({ request : null });
                                  this.setState({ myId : -1 });

                          });
            
             
        }

        if (this.state.request) {
            this.state.request.abort();
            this.setState({ request : null });

        }
        
        if (this.state.hangingGet) {
            this.state.hangingGet.onreadystatechange = null;
            this.state.hangingGet.abort();
            this.setState({ hangingGet : null });

        }
      
      
        //document.getElementById("connect").disabled = false;
        //document.getElementById("disconnect").disabled = true;

        //
        if(this.remoteVideoCanvas.current != null){
          this.remoteVideoCanvas.current.pause();
          cancelAnimationFrame(this.mergeRef.current);
        }


    }
    
    
    
    send() {
        var text = document.getElementById("message").value;
        this.setState({ peer_id : parseInt(document.getElementById("peer_id").value) });
        
        if (!text.length || this.state.peer_id == 0) {
            alert("No text supplied or invalid peer id");
        } else {
            this.sendToPeer(this.state.peer_id, text);
        }
    }
    
    toggleMe(obj) {
        var id = obj.id.replace("toggle", "msg");
        var t = document.getElementById(id);
        if (obj.innerText == "+") {
            obj.innerText = "-";
            t.style.display = "block";
        } else {
            obj.innerText = "+";
            t.style.display = "none";
        }
    }
    
    onSessionConnecting(message) {
        console.log("Session connecting.");
    }
    
    onSessionOpened(message) {
        console.log("Session opened.");
    }
    
    onRemoteStreamRemoved(event) {
        console.log("Remote stream removed.");
    }
    
    onRemoteSdpError(event) {
        console.error('onRemoteSdpError', event.name, event.message);
    }
    
    onRemoteSdpSucces() {
        console.log('onRemoteSdpSucces');
    } 
  
    changeClothes(){

        if(!this.props.project.onMirror){
            const linkAddr = this.props.location.state.linkAddr;
        }
        this.disconnect()
        //this.props.history.push(linkAddr);
        this.props.history.go(-2);
    }
    
//---------------------------------------------

ChromaKey(isRotate) {
    if (this.remoteVideoCanvas.current.paused || this.remoteVideoCanvas.current.ended) {
      console.log("ChromaKey Stop");
      return;
    }

      setTimeout(() => {

        //var canvas = document.createElement('canvas');
        var canvas = this.canvasArray[this.drawCnt];
        var rotateDirection = 0;

          //rotate canvas
          if(isRotate===0 || isRotate===180){

                this.manipulateCanva.current.width = this.videoRef.current.videoWidth;
                this.manipulateCanva.current.height = this.videoRef.current.videoHeight;

          }else{
                this.manipulateCanva.current.width = this.videoRef.current.videoHeight;
                this.manipulateCanva.current.height = this.videoRef.current.videoWidth;
                
                if(isRotate===90){
                    rotateDirection = 1
                }else if(isRotate===270 || isRotate===-90){
                    rotateDirection = -1
                } 
          }
          
          canvas.width = this.manipulateCanva.current.width;
          canvas.height = this.manipulateCanva.current.height;
         
        console.log("this.manipulateCanva.current", this.drawCnt, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
      

        //canvas.getContext('2d').drawImage(this.videoRef.current, 0, 0, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
        const ctx_raw = canvas.getContext('2d');  
        ctx_raw.resetTransform();
        if (isRotate) {
          ctx_raw.translate(this.videoRef.current.videoHeight / 2, this.videoRef.current.videoWidth / 2);
          ctx_raw.rotate(Math.PI* 0.5*rotateDirection);
          ctx_raw.translate(-this.videoRef.current.videoWidth/ 2, -this.videoRef.current.videoHeight/2);
        }
        //ctx_raw.translate(this.videoRef.current.videoWidth, 0);
        //ctx_raw.rotate(Math.PI/2)
        //ctx_raw.translate(-this.videoRef.current.videoHeight, -this.videoRef.current.videoWidth);
        ctx_raw.drawImage(this.videoRef.current, 0, 0, this.videoRef.current.videoWidth, this.videoRef.current.videoHeight);
        
        //-----------------------------------------------------------------------

        if(this.ArbitraryQuadSrc.length == 0){

          const angle = Math.PI*0.1; //18   
          const scale = 1.0

          this.ArbitraryQuadSrc = [
            { x: canvas.width*0.0, y: canvas.height*0.0 },//TL
            { x: canvas.width*0.0, y: canvas.height*1.0 },//BL
            { x: canvas.width*1.0, y: canvas.height*1.0 },//BR
            { x: canvas.width*1.0, y: canvas.height*0.0 } //TR
          ];

          this.ArbitraryQuadDst = [
            { x: (canvas.width - canvas.width/(1+0.5*canvas.height/canvas.width*Math.sin(angle))) / 2, y: canvas.height*(1-Math.cos(angle)) },//TL
            { x: canvas.width*0.0, y: canvas.height*1.0 },//BL
            { x: canvas.width*1.0, y: canvas.height*1.0 },//BR
            { x: canvas.width - (canvas.width - canvas.width/(1+0.5*canvas.height/canvas.width*Math.sin(angle))) / 2, y: canvas.height*(1-Math.cos(angle)) } //TR
          ];

          for(var i = 0;i < 4;i++){
            this.ArbitraryQuadDst[i].x = (this.ArbitraryQuadDst[i].x - canvas.width/2)*scale+canvas.width/2;
            this.ArbitraryQuadDst[i].y = (this.ArbitraryQuadDst[i].y - canvas.height/2)*scale+canvas.height/2;
          }

        }

        console.log("drawArbitraryQuadImage2", this.ArbitraryQuadSrc, this.ArbitraryQuadDst)
        ctx_raw.resetTransform();
        drawArbitraryQuadImage(ctx_raw, canvas, this.ArbitraryQuadSrc, this.ArbitraryQuadDst, FILL_METHOD.BILINEAR);

        /*ctx_raw.resetTransform();
        ctx_raw.setTransform(0.980636935763893 / 0.78451 , -9.332812300755222e-16 / 0.78451, -0.06060668945312318 / 0.78451, 
          0.9463938903808669 / 0.78451 , 10.456 / 0.78451,-122.536 / 0.78451)
        ctx_raw.drawImage(canvas, 0, 0, canvas.width, canvas.height);*/

        //var clip_canvas = document.createElement("canvas");
        //clip_canvas.width = canvas.width;
        //clip_canvas.height = canvas.height-cut_height;
        //clip_canvas.getContext("2d").drawImage(canvas, 0, 0);

        //cb(clip_canvas.toDataURL("image/jpeg", 1))//send clip size
        //cb(canvas.toDataURL("image/jpeg", 1))//send 1920x1080 size
        //-----------------------------------------------------------------------

        
        /*setTimeout(() => {
          const ctx = this.manipulateCanva.current.getContext('2d');
          ctx.globalCompositeOperation = "copy"
          ctx.drawImage(this.remoteVideoCanvas.current, 0, 0, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
          const frame = ctx.getImageData(0, 0, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
          const length = frame.data.length;

          //console.log(length);

          for (let i = 0; i < length; i += 4) {

            const red = frame.data[i + 0];
            const green = frame.data[i + 1];
            const blue = frame.data[i + 2];
            if (green > 175 && red < 100 && blue < 100) {
              frame.data[i + 3] = 0;
            }

          }
          ctx.putImageData(frame, 0, 0);
          ctx.globalCompositeOperation = "destination-over"
          ctx.drawImage(canvas, 0, 0, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
          //for safari creating new canvas
          canvas.width = 0;
          canvas.height = 0; 
          canvas = null;

        }, 730);*/

      }, 0);

    this.drawCnt = (this.drawCnt+1)%this.sizeCanvasArray;
    this.mergeCnt = this.mergeCnt+1;

    //this.ChromaKey(isRotate);
    setTimeout(() => {
        this.ChromaKey(isRotate);
      }, 0);
    
  };

//---------------------------------------------




async start() {
  console.log('@async start');

  document.getElementsByName('topHeader')[0].style.display = "none";
  document.documentElement.scrollTop=0;

  //startButton.disabled = true;
  try {

    let facingMode = FACING_MODES.ENVIRONMENT;
    let idealResolution = { width: 1920, height: 1080 };

    //let videoElement = document.getElementById('localVideo');
    //let cameraPhoto = new CameraPhoto(videoElement);

    // default camera and resolution
    
    this.cameraPhoto.startCamera(facingMode, idealResolution).then((stream)=>{
                                               
                                                //this.setState(prevState => ({
                                                //                              localVideo: {                   // object that we want to update
                                                //                                  ...prevState.localVideo,    // keep all other key-value pairs
                                                //                                  srcObject: stream           // update the value of specific key
                                                //                              }
                                                //                          }), ()=>{console.log("@start set localVideo: ")} )
                                                 
                                                
                                                this.localStream = stream;
                                                console.log("@start set localStream: ", this.localStream)
                                                this.connect();
                                                                                            
                                                 
                                                this.localStream_clone = stream.clone();
                                                console.log("@start set localStream_clone: ", this.localStream_clone)
                                                                                              
                                                                                        
                                               
                                }).catch((error)=>{});

    
    /*                             
    const stream = await navigator.mediaDevices.getUserMedia(this.state.Constraints);
    console.log('@start get local stream: ', stream);

    this.setState(prevState => ({
                                  localVideo: {                   // object that we want to update
                                      ...prevState.localVideo,    // keep all other key-value pairs
                                      srcObject: stream           // update the value of specific key
                                  }
                              }), ()=>{console.log("@start set localVideo: ", localVideo)} )

    
    this.setState({ localStream : stream }, ()=>{console.log("@start set localStream: ", this.state.localStream)});
    */
    //callButton.disabled = false;

  } catch (e) {
    alert(`getUserMedia() error: ${e.name}`);
  }
  
} 

// <RTCMesh URL='wss://localhost:8888' />
  onChangeClientName(e) {
    const ClientName = e.target.value;

    this.setState({
      localName: ClientName
    });
  }

  onChangeServerAddr(e) {
    const ServerAddr = e.target.value;

    this.setState({
      server: ServerAddr
    });
  }
//-------------for camera
  handleTimerStart(startTime) {
    
      this.setState(
        {isCountDown: true,
         timerCount : startTime,
         timerRunning: true
       }
      );
        
      this.timer = setInterval(() => {
        
        const newCount = this.state.timerCount - 1;
        this.setState(
          {timerCount: newCount >= 0 ? newCount : 0}
        );

        if(newCount <= 0){
          this.handleTimerStop();
          this.handleTimerReset();
          this.setState(
            {isCountDown: false}
          );
        };
        console.log("newCount: ", newCount);

        
      }, 1000);
      console.log("this.timer: ", this.timer);
  }

  handleTakePhoto (dataUri, isImageRotate, userID, sourceType) {
    
    // Do stuff with the photo
    console.log('handleTakePhoto');


    this.setState({DataUri: dataUri}, () => {
        console.log('DataUri@handleTakePhoto : ', this.state.DataUri);
      });
    

    
    this.setState({isShoot: true}, () => {
        console.log('isShoot@handleTakePhoto : ', this.state.isShoot);
      });

  }


  handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo
    console.log('handleTakePhotoAnimationDone');
    //upload

  }  

  handleCameraStart (stream) {
    
    console.log('handleCameraStart', this.state.isCameraOn);
    
    //if(!this.state.isCameraOn){

    this.localStream = stream;
    console.log("@start set localStream: ", this.localStream)
    //this.connect();
                                                
     
    this.localStream_clone = stream.clone();
    console.log("@start set localStream_clone: ", this.localStream_clone)
                                               

    //}
      
    
  }

  handleCameraStop () {
    console.log('handleCameraStop');

    
  }

  handleTimerStop() {
  
      if(this.timer) {
        clearInterval(this.timer);
        this.setState(
          {timerRunning:false}
        );
      }

      console.log("handleTimerStop timerRunning: ", this.state.timerRunning);

  }

  handleTimerReset() {
      this.setState(
        {timerCount: 0}
      );
      console.log("handleTimerReset timerCount: ", this.state.timerCount);
  }  

  render(){

    return (

      

      <div id="container" style={styles.container}>


        <video
           ref={this.videoRef}
           autoPlay={true}
           muted="muted"
           playsInline
           style={styles.video}
        /> 

        <canvas id="ManipulatingCanvas" ref={this.manipulateCanva}></canvas>
       

        <video id="remoteVideo" ref={this.remoteVideoCanvas} autoPlay={true} muted="muted" playsInline style={styles.video}></video>         

        {/*
        <Camera
          onButtonClick = { () => {this.handleTimerStart(0);} }
          onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri, this.state.isImageRotate, this.props.project.userId, this.state.sourceType); } }
          onTakePhotoAnimationDone = { (dataUri) => { this.handleTakePhotoAnimationDone(dataUri); } }
          onCameraError = { (error) => { 
                                         console.log("error: ", error)
                                       } 
                          }
          idealFacingMode = {this.state.idealFacingMode}
          idealResolution = {{width: 1920, height: 1080}}
          imageType = {IMAGE_TYPES.JPG}
          imageCompression = {0.97}
          isMaxResolution = {this.state.isMaxResolution}
          isImageMirror = {false}
          isImageRotate = {true}
          camPosSel = {this.state.camPosSel}
          camBtnPosSel = {this.state.camBtnPosSel}
          countdownPosSel = {this.state.countdownPosSel}
          isSilentMode = {false}
          isDisplayStartCameraError = {true}
          isFullscreen = {false}
          sizeFactor = {1}
          onCameraStart = { (stream) => { this.handleCameraStart(stream); } }
          onCameraStop = { () => { this.handleCameraStop(); } }
          
        />
        */}

        <div><input type="text" id="server" defaultValue="https://aichure.com/ARfitting" onChange={this.onChangeServerAddr.bind(this)} /></div>
        <div><input type="text" id="local"  defaultValue="myclient"                    onChange={this.onChangeClientName.bind(this)} /></div>

 
          
          <div>
          {/*
            <Button
              id="camera"
              type="button"
              onClick={this.start.bind(this)}
              style={styles.button}
            >
              Camera
            </Button>
          */}

            <Button
              id="connect"
              type="button"
              onClick={this.connect.bind(this)}
              style={styles.button}
            >
              Connect
            </Button>

            <Button
              id="disconnect"
              type="button"
              onClick={this.disconnect.bind(this)}
              style={styles.button}
            >
              Disconnect
            </Button>
            
            <Button
              id="changeClothes"
              type="button"
              onClick={this.changeClothes.bind(this)}
              style={styles.button}
            >
              change clothes
            </Button>

          </div>  

          
          <div>
            <pre id="debug"></pre>
            
            
          </div>
      </div>


    );
  };


}

const styles = {
  
  container: {
    
    height: 'fit-content'

  },
  
  button: {
    width:'auto',
    //alignContent: 'center',
    //display: 'block ruby'

  },
  icon: {
    
    alignContent: 'center',
    display: 'grid',
    width:'36px',
    height:'36px',
    padding: '21px'

  },
  video:{

    width: '0vw',
    top: '-10%',
    position: 'relative',

  },
  div:{

    marginLeft: 'auto',
    marginRight: 'auto'

  }
};


export default connect(mapState, mapDispatch)(RTCfitting);
