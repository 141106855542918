import React, { Component } from 'react';
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { withRouter } from "react-router";


import { drawArbitraryQuadImage, FILL_METHOD } from 'canvas-arbitrary-quads';
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'


import {
  Button,
  Form,
  Step,
  Icon,
  Grid,
  Header,
  Divider,
  Container,
  Label
} from "semantic-ui-react";

 
import { 
          //updateParams, 
          updateProject,
          uploadPhotoProject,
 
          updateDataXAR,
          doRTCAR,

          updateWebRTC 
       
       } from "../../store";

import * as THREE from 'three'  

import fragmentShaderNoAct from './fragmentShaderNoAct'
import fragmentShader from './fragmentShader'
import RTTfragmentShader from './RTTfragmentShader'
import vertexShader from './vertexShader'

import vdoFragmentShader from './vdoFragmentShader'
import vdoVertexShader from './vdoVertexShader'

import getStats from 'getstats';



const mapState = state => ({
  
   //baselayout: state.baselayout
   suit : state.suit,
   xar  : state.xar,
   product: state.product,
  
   project: state.project,
   webrtc: state.webrtc

});

const mapDispatch = dispatch => {
  return {

    //setParams: (params) => dispatch(updateParams(params))
    setProject : (params) => dispatch(updateProject(params)),
    sendPhotoProject: (params, formData) => dispatch(uploadPhotoProject(params, formData)),
    
    updateXAR: (xar) => dispatch(updateDataXAR(xar)),    
    
    setWebRTC: (webrtc) => dispatch(updateWebRTC(webrtc)),
  };
};

class RTCfittingWebglNoRTC extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };


  constructor(props) {

  	console.log("RTCfittingWebgl =============================================")

    super(props);

    this.state = {

      request : null,
      hangingGet : null,
      localName : '',
      server : '',
      myId : -1,
      otherPeers : {},
      messageCounter : 0,
      pc : '',    
      pcConfig : {"iceServers": [{"urls": "stun:stun.l.google.com:19302"}]},
      pcOptions : {
                  optional: [
                                {DtlsSrtpKeyAgreement: true}
                            ]
                  },
      mediaConstraints : {'mandatory': {
                                'OfferToReceiveAudio': true,
                                'OfferToReceiveVideo': true 
                          }},
     
      RTCPeerConnection : '',
      RTCSessionDescription : '',
      RTCIceCandidate : '',
      //getUserMedia : '',
      //URL : '',
      peer_id: '',

      webrtcReady: false,
      remoteComing: false,

      ARfittigTimeOut: false,
      onHandleTakePhoto: false,
      takePhotoCountDown: false,

      /*camera*/
      dataUri: '',
      idealFacingMode: FACING_MODES.USER,
      isMaxResolution: false,
      DataUri: '',
      isFullscreen: false,
      imageNumber: 0,
      modifyDataUri:'',
      camPosSel: 0,  
      camBtnPosSel: 0,
      countdownPosSel: 1,
      isImageRotate: 1,//0 horizontal, 1, +90, -1:-90
      isShoot: false,
      isCountDown: false,
      timerCount: 0,
      timerCountUse: 0,
      timerRunning: false,
      sourceType: 'aichureMirror-takephoto',
      isCameraOn: false, 

      //rttTrigger: false,
      rttStart: false,
      rttDone: false,
      //scalingFactor: 1.25

      loading: false,

      showSteps: !this.props.project.ClientInfo.isMobile,

      //count down timer for usage, no used due to implemented in route.js
      useExpired: false,

      ARfittingStop: false,


    };

    this.iniSettingDone = false;

    this.takeCnt = 0;
   
    //this.stream = null;
    this.rttCanvas = React.createRef();   
    this.rttCnt = -30;
    this.rttBufSize = 30;
    this.rttBufCnt = 0;

    this.manipulateCanvas = React.createRef();   
    this.videoSnapShot = [];

    this.canvasArray = [];
    this.drawCnt = 0;
    this.sizeCanvasArray = 100; //50;

    this.mergeRef = React.createRef();
    this.mergeCnt = 0;
    this.syncCnt = 0;
    this.localframeTimeStamp = []

    this.accumulateTimeDiff = 0;
    this.accumulateTimeCount = 0;

    this.requestAnimationFrameId = 0;

    this.gl = null
   
    
    this.rttBufferPixel = null;
    this.rttBufPtr = 0;
    this.rttCheckCnt = 0;
    this.timestampToRemote = [];
    this.timestampBackRemote = [];
    this.rttlatency = 0;
    this.rttblocksize = 0;
    this.blockEachLine = 100;
    this.showRawimageCount = 0;
    //this.loader = new THREE.TextureLoader();
    //this.horizonMap = loader.load('https://aichure.com/horizonMap.png');
    //this.verticalMap = loader.load('https://aichure.com/verticalMap_30.png');
    
   
    this.mediaRecorder = null;
    this.recordedChunks = [];
   
    //------------------------ webgl ------------------------------
    //console.log("vertexShader", vertexShader)
    //console.log("fragmentShader", fragmentShader)
    
    //--------------------------------------------------------------

  }
  
  doSomethingBeforeUnload = () => {
      console.log("RTCfittingWebgl close")
      this.props.webrtc.webRTCstart  = false;
      this.props.webrtc.webcamStart  = false;
      this.props.webrtc.webrtcReady  = false;
      this.props.webrtc.remoteComing = false;
      
      this.props.setWebRTC(this.props.webrtc);        
  }
  
  setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.doSomethingBeforeUnload();
        });
  };

  disposeIfExist(element){
    if(element){
      element.dispose();
    }
  }

  componentWillUnmount() {
      console.log("componentWillUnmount@RTCfittingWebgl")
      this.sceneAdded = true

      this.props.webrtc.webRTCstart  = false;
      this.props.webrtc.webcamStart  = false;
      this.props.webrtc.webrtcReady  = false;
      this.props.webrtc.remoteComing = false;

      this.setState({remoteComing: false})

      if(this.webcamDelayVideo){//this.stream

          this.webcamDelayVideo.srcObject.getTracks().forEach(
                                                                track => {
                                                                          if(track){
                                                                            track.enabled = false;
                                                                            track.stop();
                                                                          }
                                                             });

        }

      this.props.setWebRTC(this.props.webrtc);
      document.getElementsByName('topHeader')[0].style.display = null;

      if(this.delayScene){this.delayScene.clear();}
      if(this.scene){     this.scene.clear();     }
      if(this.rttScene){  this.rttScene.clear();  }
      if(this.renderer){
        this.renderer.forceContextLoss();
        this.renderer.content = null;

        //let gl = this.renderer.domElement.getContext("webgl");
        //gl && gl.getExtension("WEBGL_loss_context").loseContext(); //would cause old chrome error

      }
      cancelAnimationFrame(this.requestAnimationFrameId);

      
      //this.disposeIfExist(this.camera);
     
      //this.disposeIfExist(this.delayCamera);
      
      //this.disposeIfExist(this.planeDelayPivot); //not this
      //this.disposeIfExist(this.delayMesh);
      this.disposeIfExist(this.geometryVideo); //not this
      //this.disposeIfExist(this.loader); //not this
      //this.disposeIfExist(this.planePivot); //not this
      //this.disposeIfExist(this.mesh);
      this.disposeIfExist(this.geometryRemote); //not this
      //this.disposeIfExist(this.meshRemote); //not this
      //this.disposeIfExist(this.planePivotRemote); //not this
      


      this.disposeIfExist(this.vdoDelayShaderMaterial);
      this.disposeIfExist(this.vdoShaderMaterial);
      this.disposeIfExist(this.shaderMaterial);
      this.disposeIfExist(this.RTTshaderMaterial);
      this.disposeIfExist(this.localVideoTexture);
      this.disposeIfExist(this.wrapMap);
      this.disposeIfExist(this.remoteTexture)
      this.disposeIfExist(this.geometryDelayVideo);
      this.disposeIfExist(this.rttBufferTexture)
      

      for (var i = 0; i < this.sizeCanvasArray; ++i) {
          this.disposeIfExist(this.canvasArray[i]);
      }

      this.iniSettingDone = false;

      
  }

  componentDidMount() {

      //add local stream
    
      const { xar, project, setProject, webrtc, setWebRTC  } = this.props;

      var params = project;

      params.onButton = false;
      setProject(params);

      console.log("project@RTCfitting componentDidMount", project)
      //initial
      this.setState({ camPosSel: params.camPosSel });
      this.setState({ camBtnPosSel: params.camBtnPosSel });
      this.setState({ countdownPosSel: params.countdownPosSel });
     
      this.setState({ ARfittigTimeOut: false})

      this.setState({ ARfittingStop: false})

      //this.setState({rttTrigger: true})
      if(project.ClientInfo.isMobile===false){
      this.sizeCanvasArray = 100
      }
      //end of add local stream
  

    if(project.onMirror===false && project.mirrorMode==0 && project.userId==0){ // 
      console.log("project.onMirror===false && project.mirrorMode==0 && project.userId==0 @RTCfitting webRTCstart")
      webrtc.webcamStart = true;
      setWebRTC(webrtc);

    }

    this.setState({useExpired: false})

    if(!this.state.showSteps){
      const UsageSteps = document.getElementById('UsageSteps');
      UsageSteps.style.visibility = 'hidden';    

    }else if(project.projectLabel_01 ===''){
      const UsageSteps = document.getElementById('UsageSteps');
      UsageSteps.style.visibility = 'hidden';    
    
    }else if(project.projectLabel_02 ===''){

      const Step02 = document.getElementById('Step02');
      Step02.style.visibility = 'hidden';    
      const Arrow02to03 = document.getElementById('Arrow02to03');
      Arrow02to03.style.visibility = 'hidden';   
      const Step03 = document.getElementById('Step03');
      Step03.style.visibility = 'hidden';    
      const Arrow03to04 = document.getElementById('Arrow03to04');
      Arrow03to04.style.visibility = 'hidden';   
      const Step04 = document.getElementById('Step04');
      Step04.style.visibility = 'hidden';    
    
    }else if(project.projectLabel_03 ===''){

      const Step03 = document.getElementById('Step03');
      Step03.style.visibility = 'hidden';    
      const Arrow03to04 = document.getElementById('Arrow03to04');
      Arrow03to04.style.visibility = 'hidden';   
      const Step04 = document.getElementById('Step04');
      Step04.style.visibility = 'hidden';    

    }else if(project.projectLabel_04 ===''){
      const Arrow03to04 = document.getElementById('Arrow03to04');
      Arrow03to04.style.visibility = 'hidden';   
      const Step04 = document.getElementById('Step04');
      Step04.style.visibility = 'hidden';    
    }
    
    //-------------------------------
	   //this.startWebGL();
  
  }

  handleContextLost(event) {
    console.log("handleContextLost");
    event.preventDefault();
    //cancelAnimationFrame(this.requestAnimationFrameId);
    //this.gl.getExtension('WEBGL_lose_context').restoreContext();
  }

  handleContextRestored(event) {
    console.log("handleContextRestored");
    this.startWebGL();
  }

  resizeByOrientation(orientation){

    let resizeWidth = (orientation == 2) ? this.finalVideoHeight : this.finalVideoWidth;
    let resizeHeight = (orientation == 2) ? this.finalVideoWidth : this.finalVideoHeight;

    let scalingFactor = this.props.project.scalingFactor; 

    for (var i = 0; i < this.sizeCanvasArray; ++i) {
        this.canvasArray[i].setSize(resizeWidth, resizeHeight);
        this.localframeTimeStamp.push(0);
    }

    var positionAttrVideo = this.geometryVideo.attributes.position;
    positionAttrVideo.setXYZ( 0, -resizeWidth/2*scalingFactor,  resizeHeight/2 + resizeHeight * (scalingFactor-1.0), 0 );
    positionAttrVideo.setXYZ( 1, resizeWidth/2*scalingFactor,  resizeHeight/2 + resizeHeight * (scalingFactor-1.0), 0 );
    positionAttrVideo.setXYZ( 2, -resizeWidth/2*scalingFactor, -resizeHeight/2, 0 );
    positionAttrVideo.setXYZ( 3, resizeWidth/2*scalingFactor, -resizeHeight/2, 0 );
    this.planePivot.position.set(resizeWidth/2, resizeHeight/2, 0);
    positionAttrVideo.needsUpdate = true;
    this.planePivot.position.needsUpdate = true;

    var positionAttrDelayVideo = this.geometryDelayVideo.attributes.position;
    positionAttrDelayVideo.setXYZ( 0, -resizeWidth/2,  resizeHeight/2, 0 );
    positionAttrDelayVideo.setXYZ( 1, resizeWidth/2,  resizeHeight/2, 0 );
    positionAttrDelayVideo.setXYZ( 2, -resizeWidth/2, -resizeHeight/2, 0 );
    positionAttrDelayVideo.setXYZ( 3, resizeWidth/2, -resizeHeight/2, 0 );
    this.planeDelayPivot.position.set(resizeWidth/2, resizeHeight/2, 0);
    positionAttrDelayVideo.needsUpdate = true;
    this.planeDelayPivot.position.needsUpdate = true;


    var positionRtt= this.geometryRemoteRtt.attributes.position;
    positionRtt.setXYZ( 0, resizeWidth/2,  resizeHeight/2, 0 );
    positionRtt.setXYZ( 1, -resizeWidth/2,  resizeHeight/2, 0 );
    positionRtt.setXYZ( 2, resizeWidth/2, -resizeHeight/2, 0 );
    positionRtt.setXYZ( 3, -resizeWidth/2, -resizeHeight/2, 0 );
    this.planePivotRemoteRtt.position.set(resizeWidth/2, resizeHeight/2, 0);
    positionRtt.needsUpdate = true;
    this.planePivotRemoteRtt.position.needsUpdate = true;


    var positionAttrRemote = this.geometryRemote.attributes.position;
    positionAttrRemote.setXYZ( 0, resizeWidth/2*scalingFactor,  resizeHeight/2 + resizeHeight * (scalingFactor-1.0), 0 );
    positionAttrRemote.setXYZ( 1, -resizeWidth/2*scalingFactor,  resizeHeight/2 + resizeHeight * (scalingFactor-1.0), 0 );
    positionAttrRemote.setXYZ( 2, resizeWidth/2*scalingFactor, -resizeHeight/2, 0 );
    positionAttrRemote.setXYZ( 3, -resizeWidth/2*scalingFactor, -resizeHeight/2, 0 );
    this.planePivotRemote.position.set(resizeWidth/2, resizeHeight/2, 0);
    positionAttrRemote.needsUpdate = true;
    this.planePivotRemote.position.needsUpdate = true;

    this.delayCamera = new THREE.OrthographicCamera( 0, resizeWidth, resizeHeight, 0, 0, 1 );
    this.camera = new THREE.OrthographicCamera( 0, resizeWidth, resizeHeight, 0, 0, 1 );

    var webcamCanvas = document.getElementById("webcamCanvas")
    webcamCanvas.width = resizeWidth
    webcamCanvas.height = resizeHeight

    this.manipulateCanvas.current.width = webcamCanvas.width;
    this.manipulateCanvas.current.height = webcamCanvas.height;

    this.renderer.setSize( resizeWidth, resizeHeight, false );
    
  }

  async startWebGL() {
  	
    const { project, webrtc } = this.props;


    //------ handle RTT canvas ---------
    const rttWidth  = webrtc.localStream_clone.getTracks()[0].getSettings().width;
    const rttHeight = webrtc.localStream_clone.getTracks()[0].getSettings().height;
    //console.log("this.rttCanvas", this.rttCanvas)
    this.rttCanvas.current.width  = rttWidth;
    this.rttCanvas.current.height = rttHeight;

    this.markVideo = document.createElement('video')
    this.markVideo.setAttribute('playsinline', 'playsinline');
    this.markVideo.srcObject = webrtc.localStream_clone;    
    this.markVideo.onloadedmetadata = function(e) {
      this.play();
    };



    //------ end of handle RTT canvas ----------------------------
    let scalingFactor = project.scalingFactor; 
    const isRotate = project.camPosSel;

    this.finalVideoWidth = this.props.webrtc.canvasResolution[0];
    this.finalVideoHeight = this.props.webrtc.canvasResolution[1];
    

    console.log("sizeCanvasArray @ GL", this.sizeCanvasArray)
    for (var i = 0; i < this.sizeCanvasArray; ++i) {

        this.canvasArray[i] = new THREE.WebGLRenderTarget(this.finalVideoWidth, this.finalVideoHeight);
        
    }


    console.log("finalVideoWidth@startWebGL", this.finalVideoWidth, this.finalVideoHeight)
    
    this.sceneAdded = true;
    /*=============delay==================*/

    this.delayCamera = new THREE.OrthographicCamera( 0, this.finalVideoWidth, this.finalVideoHeight, 0, 0, 1 );
    this.delayCamera.zoom = 1;

    this.delayScene = new THREE.Scene()
    
    this.geometryDelayVideo = new THREE.PlaneGeometry( this.finalVideoWidth, this.finalVideoHeight );
    this.webcamDelayVideo = document.createElement('video')
    this.webcamDelayVideo.muted = true;
    this.webcamDelayVideo.setAttribute('playsinline', 'playsinline');
    

    this.localVideoTexture = new THREE.VideoTexture(this.webcamDelayVideo)
    this.localVideoTexture.minFilter = THREE.LinearFilter
    this.localVideoTexture.maxFilter = THREE.LinearFilter

    this.planeDelayPivot = new THREE.Object3D();//const planeDelayPivot = new THREE.Object3D();
    this.delayScene.add(this.planeDelayPivot);//this.delayScene.add(planeDelayPivot);
    
    this.vdoDelayShaderMaterial = new THREE.ShaderMaterial({
          transparent: true,
          uniforms: {
            map: { value: this.localVideoTexture },
            keyColor: { value: [0.0, 1.0, 0.0] },
            similarity: { value: 0.0 },
            smoothness: { value: 0.0 },
            spill: { value : 0.0 }
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentShaderNoAct
    })
    this.vdoDelayShaderMaterial.side = THREE.DoubleSide;
    this.delayMesh = new THREE.Mesh( this.geometryDelayVideo, this.vdoDelayShaderMaterial );
    this.planeDelayPivot.add(this.delayMesh);;//planeDelayPivot.add(this.delayMesh);
    
    this.delayMesh.position.set(0, 0, 0);
    this.planeDelayPivot.position.set(this.finalVideoWidth/2, this.finalVideoHeight/2, 0);//planeDelayPivot.position.set(1920/2, 1080/2, 0);

    /*=============merge==================*/
    
    var remoteVideoWidth  = (isRotate==90 || isRotate==-90 || isRotate==270) ? this.finalVideoHeight : this.finalVideoWidth;
    var remoteVideoHeight = (isRotate==90 || isRotate==-90 || isRotate==270) ? this.finalVideoWidth : this.finalVideoHeight;

	  this.camera = new THREE.OrthographicCamera( 0, remoteVideoWidth, remoteVideoHeight, 0, 0, 1 );
    this.camera.zoom = 1;
    console.log("Camera @ ", this.camera);
  
    this.scene = new THREE.Scene();
    
    this.geometryVideo = new THREE.PlaneGeometry( this.finalVideoWidth, this.finalVideoHeight );//const geometryVideo = new THREE.PlaneGeometry( 1920, 1080 );
    this.remoteVideo = document.createElement('video')
    this.remoteVideo.muted = true;
    this.remoteVideo.setAttribute('playsinline', 'playsinline');

    this.planePivot = new THREE.Object3D();//const planePivot = new THREE.Object3D();
    this.scene.add(this.planePivot);//this.scene.add(planePivot);
    
        
    var positionAttrVideo = this.geometryVideo.attributes.position;//var positionAttrVideo = geometryVideo.attributes.position;

    if(isRotate===90){

      positionAttrVideo.setXYZ( 0, -remoteVideoWidth/2*scalingFactor,  remoteVideoHeight/2 + remoteVideoHeight * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 1, -remoteVideoWidth/2*scalingFactor, -remoteVideoHeight/2, 0 );
      positionAttrVideo.setXYZ( 2,  remoteVideoWidth/2*scalingFactor,  remoteVideoHeight/2 + remoteVideoHeight * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 3,  remoteVideoWidth/2*scalingFactor, -remoteVideoHeight/2 , 0 );

    }else if(isRotate===270 || isRotate===-90){

      positionAttrVideo.setXYZ( 0,  remoteVideoWidth/2*scalingFactor, -remoteVideoHeight/2 , 0 );
      positionAttrVideo.setXYZ( 1,  remoteVideoWidth/2*scalingFactor,  remoteVideoHeight/2 + remoteVideoHeight * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 2, -remoteVideoWidth/2*scalingFactor, -remoteVideoHeight/2 , 0 );
      positionAttrVideo.setXYZ( 3, -remoteVideoWidth/2*scalingFactor,  remoteVideoHeight/2 + remoteVideoHeight * (scalingFactor-1.0), 0 );

    }else if(isRotate===180){
    
      positionAttrVideo.setXYZ( 0,  remoteVideoWidth/2*scalingFactor, -remoteVideoHeight/2, 0 );
      positionAttrVideo.setXYZ( 1, -remoteVideoWidth/2*scalingFactor, -remoteVideoHeight/2, 0 );
      positionAttrVideo.setXYZ( 2,  remoteVideoWidth/2*scalingFactor,  remoteVideoHeight/2 + remoteVideoHeight * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 3, -remoteVideoWidth/2*scalingFactor,  remoteVideoHeight/2 + remoteVideoHeight * (scalingFactor-1.0), 0 );

    }else{
    
      positionAttrVideo.setXYZ( 0,  remoteVideoWidth/2*scalingFactor,  remoteVideoHeight/2 + remoteVideoHeight * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 1, -remoteVideoWidth/2*scalingFactor,  remoteVideoHeight/2 + remoteVideoHeight * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 2,  remoteVideoWidth/2*scalingFactor, -remoteVideoHeight/2, 0 );
      positionAttrVideo.setXYZ( 3, -remoteVideoWidth/2*scalingFactor, -remoteVideoHeight/2, 0 );
      
    }

    this.loader = new THREE.TextureLoader();//const loader = new THREE.TextureLoader();
    
    this.wrapMap = (isRotate==90 || isRotate==-90 || isRotate==270) ?  this.loader.load(project.href + '/' + project.wrapMap[project.tiltCompensation *2+1])
                                                                    :  this.loader.load(project.href + '/' + project.wrapMap[project.tiltCompensation *2  ]); 
    this.wrapMap.minFilter = THREE.NearestFilter;
    this.wrapMap.maxFilter = THREE.NearestFilter;
    
    console.log("wrapMap isRotate@", this.wrapMap, isRotate);
    console.log("project.href + '/' + project.wrapMap[project.tiltCompensation *2+1] @", project.href + '/' + project.wrapMap[project.tiltCompensation *2+1]);
    console.log("loader.load(project.href + '/' + project.wrapMap[project.tiltCompensation *2  ] @", project.href + '/' + project.wrapMap[project.tiltCompensation *2  ]);
    console.log("this.geometryVideo.attributes.position;", this.geometryVideo.attributes.position, remoteVideoWidth, scalingFactor, remoteVideoWidth/2*scalingFactor)

    




   
    this.vdoShaderMaterial = new THREE.ShaderMaterial({
      transparent: true,
      uniforms: {
        map: { value: this.localVideoTexture },
        warpMap: { value: this.wrapMap },
        tilt: { value: project.tiltCompensation },        
        rotateAngle: { value: isRotate*1.0 }
      },
      vertexShader: vdoVertexShader,
      fragmentShader: vdoFragmentShader
    })


    this.vdoShaderMaterial.side = THREE.DoubleSide;
    this.mesh = new THREE.Mesh( this.geometryVideo, this.vdoShaderMaterial );//this.mesh = new THREE.Mesh( geometryVideo, this.vdoShaderMaterial );
    this.planePivot.add(this.mesh);//planePivot.add(this.mesh);
    
    this.mesh.position.set(0, 0, 0);
    this.planePivot.position.set(remoteVideoWidth/2, remoteVideoHeight/2, 0);//planePivot.position.set(remoteVideoWidth/2, remoteVideoHeight/2, 0);
  


    //----------------------

   this.mergeCnt = 0;//project.RTCdelayTime;


    var webcamCanvas = document.getElementById("webcamCanvas")
    webcamCanvas.width = remoteVideoWidth
    webcamCanvas.height = remoteVideoHeight



    this.renderer = new THREE.WebGLRenderer( { canvas: webcamCanvas, antialias: true } );
  	this.renderer.setSize( remoteVideoWidth, remoteVideoHeight, false );

    console.log("this.props.webrtc@startWebGL", this.props.webrtc)
  	
      /*
      //const constraints = { video: { width: 1920, height: 1080, facingMode: 'user' } };
      if(this.props.webrtc.canvasResolution[0]/this.props.webrtc.canvasResolution[1]>=1.7){

          var constraints = { audio: false, video: { width: 1920, height: 1080,  facingMode: "environment", frameRate: { ideal: 20, max: 22 } }  };
      
      }else{

          var constraints = { audio: false, video: { width: 640, height: 480,  facingMode: "environment", frameRate: { ideal: 20, max: 22 } }  };

      }//this.stream = await navigator.mediaDevices.getUserMedia( constraints );
      

      
      //for firefox, rtc /4 would reduce original resolution
      if(project.ClientInfo.isFirefox){

          var _this = this
          navigator.mediaDevices.getUserMedia(constraints)
              .then(function(mediaStream) {
                console.log("getUserMedia @isFirefox", mediaStream)
                //this.stream = mediaStream;
                //console.log("_this", _this) 
                //console.log("_this.webcamDelayVideo", _this.webcamDelayVideo) 
                _this.webcamDelayVideo.srcObject = mediaStream;
                
                _this.webcamDelayVideo.onloadedmetadata = function(e) {
                                                                      console.log("this, this.webcamDelayVideo", this, this.webcamDelayVideo)
                                                                      this.play();
                                                                     };
                //this.webcamDelayVideo.play();

              })
              .catch(function(err) { console.log(err.name + ": " + err.message); }); // always check for errors at the end.
      }else{

          //testing work on ios safari  
          this.webcamDelayVideo.srcObject = this.props.webrtc.localStream;     
          this.webcamDelayVideo.play();

      }
    */
    this.webcamDelayVideo.srcObject = this.props.webrtc.localStream;     
      this.webcamDelayVideo.play();
    //----------remote session   
      
      

    this.remoteVideo.srcObject = this.props.webrtc.remoteVideoStream;
    this.remoteVideo.play();

    this.remoteTexture = new THREE.VideoTexture(this.remoteVideo)
    this.remoteTexture.minFilter = THREE.LinearFilter
    this.remoteTexture.maxFilter = THREE.LinearFilter

      
    const uvSize = [1/(remoteVideoWidth-1), 1/(remoteVideoHeight-1)]
    console.log("uvSize", uvSize)

        this.shaderMaterial = new THREE.ShaderMaterial({
          transparent: true,
          uniforms: {
            map: { value: this.remoteTexture },
            keyColor: { value: [0.0, 0.0, 0.0] },
            similarity: { value: 0.4 },
            smoothness: { value: 0.1 }, //0.1 => saw edge, 0.2 => key color transparent 
            spill: { value : 0.1 }, //0.3 => clothes color getting gray
            pixel_size: { value: uvSize }
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentShader
        })

        this.RTTshaderMaterial = new THREE.ShaderMaterial({
          transparent: true,
          uniforms: {
            map: { value: this.remoteTexture },
            keyColor: { value: [0.0, 1.0, 0.0] },
            similarity: { value: 0.4 },
            smoothness: { value: 0.1 },
            spill: { value : 0.1 }
          },
          vertexShader: vertexShader,
          fragmentShader: RTTfragmentShader
        })        


    this.geometryRemote = new THREE.PlaneGeometry( remoteVideoWidth*scalingFactor, remoteVideoHeight*scalingFactor );;//const geometryRemote = new THREE.PlaneGeometry( remoteVideoWidth*scalingFactor, remoteVideoHeight*scalingFactor );    
    
    //flip
    var positionAttrRemote = this.geometryRemote.attributes.position;//var positionAttrRemote = geometryRemote.attributes.position;

    positionAttrRemote.setXYZ( 0, -remoteVideoWidth/2*scalingFactor,  remoteVideoHeight/2 + remoteVideoHeight * (scalingFactor-1.0), 0 );
    positionAttrRemote.setXYZ( 1, remoteVideoWidth/2*scalingFactor,  remoteVideoHeight/2 + remoteVideoHeight * (scalingFactor-1.0), 0 );
    positionAttrRemote.setXYZ( 2, -remoteVideoWidth/2*scalingFactor, -remoteVideoHeight/2, 0 );
    positionAttrRemote.setXYZ( 3, remoteVideoWidth/2*scalingFactor, -remoteVideoHeight/2, 0 );

    this.shaderMaterial.side    = THREE.DoubleSide;
    this.meshRemote    = new THREE.Mesh( this.geometryRemote, this.shaderMaterial );//const meshRemote = new THREE.Mesh( geometryRemote, this.shaderMaterial );
    this.meshRemote.position.set(0, 0, 0);//meshRemote.position.set(0, 0, 0);
    

    this.planePivotRemote = new THREE.Object3D();//const planePivotRemote = new THREE.Object3D();
    this.planePivotRemote.position.set(remoteVideoWidth/2, remoteVideoHeight/2, 0);//planePivotRemote.position.set(remoteVideoWidth/2, remoteVideoHeight/2, 0);
    this.planePivotRemote.add(this.meshRemote);//planePivotRemote.add(meshRemote);

    console.log("planePivotRemote Position", remoteVideoWidth/2, remoteVideoHeight/2);
    
    
    //this.scene.add(this.planePivotRemote);//this.scene.add(planePivotRemote);

    this.geometryRemoteRtt = new THREE.PlaneGeometry( remoteVideoWidth, remoteVideoHeight );
    
    this.RTTshaderMaterial.side = THREE.DoubleSide;
    this.meshRemoteRtt = new THREE.Mesh( this.geometryRemoteRtt, this.RTTshaderMaterial );//const meshRemote = new THREE.Mesh( geometryRemote, this.shaderMaterial );
    this.meshRemoteRtt.position.set(0, 0, 0);//meshRemote.position.set(0, 0, 0);

    this.planePivotRemoteRtt = new THREE.Object3D();
    this.planePivotRemoteRtt.position.set(remoteVideoWidth/2, remoteVideoHeight/2, 0);
    this.planePivotRemoteRtt.add(this.meshRemoteRtt);
    this.rttScene = new THREE.Scene();
    this.rttScene.add(this.planePivotRemoteRtt);
    

    //
    this.manipulateCanvas.current.width = webcamCanvas.width;
    this.manipulateCanvas.current.height = webcamCanvas.height;


    this.props.webrtc.videoOrientation = 0;

    //----------  
            this.timestampRTC = new Date().getTime();
            /*this.Rtt = 0;
            var repeatInterval = 4000; // 2000 ms == 2 seconds
            var _this = this;
            getStats(this.props.webrtc.pc, function(getStatsResult) {


                // "getStatsResult" is your "result" object
                console.log("getStatsResult", getStatsResult)
                console.log("getStatsResult.video.latency", getStatsResult.video.latency)
                console.log("getStatsResult.audio.latency", getStatsResult.audio.latency)
                console.log("this.accumulateTimeCount", _this.accumulateTimeCount)

                getStatsResult.results.forEach( data => {
                   if(data.type == "ssrc" && data.googRtt ){
                      _this.Rtt = data.googRtt;
                   }
                });
                    
                if(_this.accumulateTimeCount == 100){
                  getStatsResult.nomore();
                }
            }, repeatInterval);*/



    //-----------        
    
    for(var i = 0;i < 60; i++){
      this.timestampToRemote.push(0);
      this.timestampBackRemote.push(0);
    }
    this.rttblocksize = (1.0*this.rttCanvas.current.width) / this.blockEachLine
    this.showRawimageCount = this.sizeCanvasArray;
    this.drawCnt = 0;

  	this.animate = time => {

        /*var timeDiff = new Date().getTime() - this.timestampRTC;

        if(timeDiff < 50){
          this.requestAnimationFrameId = requestAnimationFrame(this.animate)
          return;
        }

        this.timestampRTC = new Date().getTime();
*/
        //------ handle rtt
        if(this.state.rttDone){

          const rttWidth  = webrtc.localStream_clone.getTracks()[0].getSettings().width;
          const rttHeight = webrtc.localStream_clone.getTracks()[0].getSettings().height;
          //Don't know why, but need to remove if condition, then ios can work on the first time
          if(this.iniSettingDone==false || this.rttCanvas.current.width != rttWidth || this.rttCanvas.current.height != rttHeight){

         
            this.rttCanvas.current.width  = rttWidth;
            this.rttCanvas.current.height = rttHeight;
            this.rttblocksize = (1.0*this.rttCanvas.current.width) / this.blockEachLine

            this.iniSettingDone = true;
          }
          
          //this.trace("rttCanvas " + this.rttCanvas.current.width + ',' + this.rttCanvas.current.height+" , "+this.rttblocksize )
          //this.trace("rtt  " + rttWidth + ',' + rttHeight )
          console.log("Send Img")

          //console.log("this.rttCanvas", this.rttCanvas)
          var ctxRTT = this.rttCanvas.current.getContext("2d");
            ctxRTT.drawImage( this.markVideo, 0, 0)
            ctxRTT.fillStyle = "#000000" 
            ctxRTT.fillRect( 0, 0, this.rttCanvas.current.width, this.rttblocksize+25 )
            ctxRTT.fillStyle = "#FFFFFF" 
            const block = this.drawCnt%this.sizeCanvasArray;
            const encodeA = block%this.blockEachLine;
            //const encodeB = Math.floor(block/this.blockEachLine);
            ctxRTT.fillRect( encodeA*this.rttblocksize , 0, this.rttblocksize, this.rttblocksize+25) //square flag
            //ctxRTT.fillRect( encodeB*this.rttblocksize , this.rttblocksize, this.rttblocksize, this.rttblocksize) //square flag
            this.rttTrack.requestFrame();
            
        }

       //------ end of handle rtt



        if(this.props.webrtc.videoOrientation != 0){
          if(project.ClientInfo.isMobile){
            this.resizeByOrientation(this.props.webrtc.videoOrientation);
          }
          this.props.webrtc.videoOrientation = 0;
          this.props.setWebRTC(this.props.webrtc);
        }

        if(this.props.webrtc.loading){
          this.setState({loading: true}) //20221013
          this.props.webrtc.loading = false
        }

        if(this.state.remoteComing && this.sceneAdded){
          //console.log("sceneAdded");
          this.scene.add(this.planePivotRemote);
          this.sceneAdded = false;

          //
          this.props.parentCallback();//count down in routes
          //
          if(this.props.project.onARrecord){
            console.log("start ARrecord")
            const stream = webcamCanvas.captureStream(30);
            
            this.mediaRecorder = new MediaRecorder(stream, {
                                                                mimeType: 'video/webm;codecs=vp9',
                                                                ignoreMutedMedia: true
                                                            });

            this.mediaRecorder.ondataavailable = e => {
                                                          if(e.data.size > 0){
                                                              this.recordedChunks.push(e.data);
                                                          }
                                                      };
            this.mediaRecorder.start();


          }          


        }


        if(document.getElementById("webcamCanvas") !== null){

          if(this.props.webrtc.groupDelay && this.props.webrtc.groupDelay < 0){
            this.accumulateTimeCount = 0;
            this.accumulateTimeDiff = 0;
            this.timeDiffAvg = 0;
            this.timestampRTC = 0;
            //this.showRawimageCount = 0;

            const d = new Date();
            let time = d.getTime();
            this.setState({loading: false})
            //console.log("DataChannel GroupDelay = ", time , this.props.webrtc.groupDelay/1000, time+this.props.webrtc.groupDelay/1000);

            



          }

          if(this.state.rttDone){

            if(!this.rttBufferTexture){
              this.rttBufferTexture = new THREE.WebGLRenderTarget( remoteVideoWidth, remoteVideoHeight);
              this.rttBufferPixel = new Uint8Array(remoteVideoWidth * remoteVideoHeight * 4);
            }

            this.renderer.setRenderTarget(this.rttBufferTexture);          
            this.renderer.render(this.rttScene, this.camera);
            this.renderer.readRenderTargetPixels(this.rttBufferTexture, 0, 0, remoteVideoWidth, remoteVideoHeight, this.rttBufferPixel);

            let returnBlockSize = remoteVideoWidth/100;

            console.log("returnBlockSize ", returnBlockSize, remoteVideoWidth, this.sizeCanvasArray);

            var tmpCnt = 0;
            var maxColor = 0;
            var need_inverse = false
            
            if(this.rttBufferPixel[(Math.floor(remoteVideoHeight*0.97)*remoteVideoWidth + Math.floor(returnBlockSize/2)) *4+1] < 200){
                need_inverse = true
            }
            /*if(project.ClientInfo.isMobile){ //mobile would flip video automatically, 
              need_inverse = !need_inverse;
            }*/
            //need_inverse = !need_inverse

            for(var i = 0;i < this.sizeCanvasArray; i++){
              var mark_x = Math.floor(i*returnBlockSize + returnBlockSize/2);
              if(need_inverse){
                mark_x = remoteVideoWidth - mark_x;
              }
              let mark_y = Math.floor(remoteVideoHeight*0.97);
              let markColor = this.rttBufferPixel[(mark_y*remoteVideoWidth + mark_x) *4];
              if(markColor > maxColor){
                tmpCnt = i;
                maxColor = markColor;
              }
              if(markColor > 200){
                tmpCnt = i;
                break;
              }
            }
            
            //console.log("tmpCnt", tmpCnt);

            if(this.mergeCnt == tmpCnt){
              this.showRawimageCount += 1;
            }else{
              this.mergeCnt = tmpCnt;
              this.showRawimageCount = 0;
            }

          }
          
          if(this.mergeCnt >= 0 && !this.state.loading){
            /* this.drawCnt <= this.sizeCanvasArray*2 for black screen!*/
            let showIndex = Math.max(0, (this.showRawimageCount >= this.sizeCanvasArray || this.drawCnt <= this.sizeCanvasArray*2) ? this.drawCnt-1 : this.mergeCnt);
            console.log("this.mergeCnt", this.mergeCnt, this.showRawimageCount, showIndex, this.drawCnt);
            this.mesh.material.uniforms.map.value = this.canvasArray[showIndex%this.sizeCanvasArray].texture;
            this.renderer.setRenderTarget(null);
            this.renderer.render( this.scene, this.camera );
          }

          if(this.state.onHandleTakePhoto && this.takeCnt===0){
            this.takeCnt = this.takeCnt + 1;
            this.manipulateCanvas.current.getContext('2d').drawImage(document.getElementById("webcamCanvas"), 0, 0, this.manipulateCanvas.current.width, this.manipulateCanvas.current.height);
            this.handleTakePhoto();
          }          

            this.renderer.setRenderTarget(this.canvasArray[this.drawCnt%this.sizeCanvasArray]);
            this.renderer.render( this.delayScene, this.delayCamera);
            this.localframeTimeStamp[this.drawCnt%this.sizeCanvasArray] = Date.now();
          
          
          this.drawCnt = this.drawCnt+1;          

          this.requestAnimationFrameId = requestAnimationFrame(this.animate)

        }
        
          
    }

    //----



    this.animate()


  }

  resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  /**
   * Creates a plane to display webcam stream
   */
  createWebCamPlane (width = 5, height = 4){
    const geometry = new THREE.PlaneGeometry(width, height)
    return new THREE.Mesh(geometry, this.shaderMaterial)
  }


  /*
  webcamAnimate () {
    if (this.webcamVideo.readyState === this.webcamVideo.HAVE_ENOUGH_DATA) {
      this.canvasCtx.drawImage(this.webcamCanvas, 0, 0, 1920, 1080)
      this.webcamTexture.needsUpdate = true
      
    }
  }
  */
  componentDidUpdate(prevProps, prevState) {
      
    const { project, webrtc } = this.props;


      //console.log("Props@RTCfitting componentDidUpdate", this.props)
      //console.log("prevProps@RTCfitting componentDidUpdate", prevProps)
      //console.log("prevState@componentDidUpdate", prevState)
      //console.log("this.state@componentDidUpdate", this.state)
      
      if(this.props.webrtc.webrtcReady===true && this.state.webrtcReady===false){
      //if(this.props.webrtcReady===true && this.state.webrtcReady===false){
          //console.log("webrtcReady @ componentDidUpdate RTCfittingWebglNoRTC", this.props.webrtcReady, this.props.webrtc.webrtcReady)
          //this.trace("webrtcReady @ componentDidUpdate RTCfittingWebglNoRTC ")

          //if(this.state.rttTrigger===true && prevState.rttTrigger===false){

              //console.log("this.state.rttTrigger===true && prevState.rttTrigger===false")

              this.props.webrtc.pc.getSenders().forEach(sender => {
                                                           console.log("this@getSenders", this)
                                                           this.rttTrack =  this.rttCanvas.current.captureStream(0).getTracks().find(track => track.kind === sender.track.kind)
                                                           if(this.rttTrack){
                                                               //this.trace("into replaceTrack")
                                                               sender.replaceTrack(this.rttTrack).then(()=>{

                                                                                                              //this.setState({rttTrigger:false})
                                                                                                              this.setState({rttDone:true})

                                                                                                              this.props.setWebRTC(this.props.webrtc);
                                                                                                              //this.trace("replaceTrack done")
                                                               })
                                                           }
                                                           
                                                           console.log("sender", sender)
                                                           console.log("rttTrack", this.rttTrack)
                                                       })


          //}


          this.setState({webrtcReady: true})
          this.startWebGL();
      }

      if (this.props.project.ARfittigTimeOut===true && this.state.ARfittigTimeOut===false) {
          console.log("this.props.project.ARfittigTimeOut", this.props.project.ARfittigTimeOut)
          console.log("this.state.ARfittigTimeOut", this.props.project.ARfittigTimeOut)          
          //this.trace("ARfittigTimeOut @ componentDidUpdate RTCfittingWebglNoRTC ")


          this.setState({ARfittigTimeOut: this.props.project.ARfittigTimeOut})

          
          var tmp = this.props.project
          tmp.ARfittigTimeOut = false;
          this.props.setProject(tmp);
               

          this.changeClothes();

      }

      //if (this.props.project.onHandleTakePhoto===true && prevState.onHandleTakePhoto===false && this.state.onHandleTakePhoto===false) {
      if (this.props.project.onHandleTakePhoto===true && prevState.takePhotoCountDown===false && this.state.takePhotoCountDown===false  && prevState.onHandleTakePhoto===false) {
      
          this.trace("onHandleTakePhoto @ componentDidUpdate RTCfittingWebglNoRTC ")

          console.log("this.setState({onHandleTakePhoto: true})")
          this.setState({takePhotoCountDown: true})//onHandleTakePhoto
          this.handleCountDown(5)

      }

      if (this.props.project.onHandleTakePhoto===true && prevState.onHandleTakePhoto===true && this.state.onHandleTakePhoto===false){
         
          console.log("this.props.project.onHandleTakePhoto = false;")
         
          this.props.project.onHandleTakePhoto = false;
          this.props.setProject(this.props.project);
     
          
          this.takeCnt = 0;
      }

      if(this.props.webrtc.remoteComing===true && this.state.remoteComing===false){
          //console.log("remoteComing @ componentDidUpdate RTCfittingWebglNoRTC")
          //this.trace("remoteComing @ componentDidUpdate RTCfittingWebglNoRTC ")


          this.setState({remoteComing: true})
          const UsageSteps = document.getElementById('UsageSteps');
          UsageSteps.style.visibility = 'hidden';  
          

      }


      /*
      if(this.state.rttTrigger===false && prevState.rttTrigger===true){

          console.log("this.state.rttTrigger===false && prevState.rttTrigger===true")
          this.setState({rttStart:true})

      }
      */




      if(this.state.rttStart===true && prevState.rttStart===false){

          //console.log("rttStart === true")
          //this.trace("rttStart @ componentDidUpdate RTCfittingWebglNoRTC ")

                  




          //this.RTTcheck()


      }

      if(this.state.rttDone===true && prevState.rttDone===false){

          //console.log("rttDone === true")
          //this.trace("rttDone @ componentDidUpdate RTCfittingWebglNoRTC ")
          //this.props.webrtc.dataChannel.send("rttDone")
          /*this.props.webrtc.pc.getSenders().forEach(sender => {
                                                                 const newTrack =  this.props.webrtc.localStream_clone.getTracks().find(track => track.kind === sender.track.kind)
                                                                 sender.replaceTrack(newTrack)

                                                                 
                                                                 console.log("sender", sender)
                                                                 console.log("newTrack", newTrack)
                                                             })*/

      }

      /* for count down usage timer */
      /*
      if(this.state.loading === false && prevState.loading === true){

        if(this.props.project.ARtimer !== 0 ){
  
          console.log("start handleCountDownUse", this.props.project.ARtimer)
          handleCountDownUse(this.props.project.ARtimer)

        }
      }

      if(this.state.useExpired === true && prevState.useExpired === false){

        console.log("this.props.history.go(-1); useExpired", this.state.useExpired)
        this.setState({useExpired: false})
        this.props.history.go(-1);

      }
      */

      if(this.props.ARfittingStop===true && this.state.ARfittingStop===false){

          this.trace("ARfittingStop @ componentDidUpdate RTCfittingWebglNoRTC ")
          
          this.setState({ARfittingStop: true})

          //stop recording here
          if(this.props.project.onARrecord){

            
            this.mediaRecorder.stop();
            setTimeout(() => {
                                  const blob = new Blob(this.recordedChunks, {
                                                                                  type: "video/webm"
                                                                              });



                                  const url = URL.createObjectURL(blob);
                                  const a = document.createElement("a");
                                  a.href = url;
                                  a.download = "recording.webm";
                                  a.click();
                                  URL.revokeObjectURL(url);
                              },0);

            

          }else{


            

          }
          this.mediaRecorder = null
          this.recordedChunks = [];
          //this.props.history.push(`/${this.props.project.projectName}/MetaFitting`)
          this.props.history.push(`/${this.props.project.projectName}/${this.props.project.pageName}`)
      }

  }

  XARclothesProducts() {
    console.log("============================= clothlist ===============================")
    
    //if mirror mode, mirror side has no product informoation, need get it from webserver,
    const { xar, suit, product, project } = this.props;
    console.log("this.props @XARclothesProducts: ", this.props)
    console.log("suit @XARclothesProducts: ", suit)
    console.log("suit.suit @XARclothesProducts: ", suit.suit)


    if(project.mirrorMode===1){


        xar.clothlist = [];
        xar.shoes = "empty";
        xar.suitID = "empty";
        xar.clothesID = "asia2020_f_001_dress_long_red";//product.product.internal_name;
        xar.video_filename = xar.picID + '_empty';
        xar.human.gender = 0;
        
    }else{


        xar.clothlist = suit.suit.suitClothesID;
        xar.shoes = suit.suit.shoes;
        xar.suitID = suit.suit.suitID;
        xar.clothesID = "asia2020_f_001_dress_long_red";//product.product.internal_name;
        xar.video_filename = xar.picID + '_' + suit.suit.suitID;
        if(suit.suit.gender === 'male'){
          xar.human.gender = 1;  
        }else
        {
          xar.human.gender = 0;
        }

    }



    
    console.log("xar.clothlist: ", xar.clothlist)

    

  }




    
    changeClothes(){
        console.log("changeClothes()",this.props.location)
        console.log("changeClothes()",this.props.project.mirrorMode)
        console.log("changeClothes()",this.props.history)

        if(this.props.project.mirrorMode===1){
            console.log('this.props.project.mirrorMode===1', this.props.project.mirrorMode)
            const linkAddr = this.props.location.state.linkAddr;
            this.props.history.go(-1);
        
        }else{
          console.log("this.props.project.mirrorMode!==1")
            const linkAddr = this.props.location.state.linkAddr;
            //this.props.history.push(linkAddr);
            this.props.history.go(-2);
        }
        
        //
    }
    


  handleCountDown (countDown) {
      console.log("==================handleCOuntDown", countDown)
      document.getElementById("TimerCount").style.display = 'unset'

      document.getElementById("TimerCount").innerHTML = countDown.toString()
      if(countDown == 0){
        this.setState({onHandleTakePhoto: true})
        this.setState({takePhotoCountDown: false})
        
      }else{
        setTimeout(() => this.handleCountDown(countDown-1), 1000);
      }
  }

  /*
  handleCountDownUse (countDown) {
      console.log("==================handleCountDownUse", countDown)
      document.getElementById("TimerCountUse").style.display = 'unset'

      document.getElementById("TimerCountUse").innerHTML = countDown.toString()
      if(countDown == 0){
        this.setState({useExpired: true})
        
      }else{
        setTimeout(() => this.handleCountDownUse(countDown-1), 1000);
      }
  }
  */
  handleTakePhoto(){

      console.log("handleTakePhoto()")
      var data = this.manipulateCanvas.current.toDataURL();
      
      

      const blob = this.dataURItoBlob(data);
      //const filename = this.getFileName(blob.type,  this.props.project.projectName);
      //const filename_ext = filename[0] + '.' + filename[1];
      
      this.setState( {filename: this.props.project.photoName});

      this.setState( {onHandleTakePhoto: false});

      this.onSubmit(blob, this.props.project.photoName);

      //disable count down string
      document.getElementById("TimerCount").style.display = 'none'


  }
  dataURItoBlob (dataURI) {
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ab], {type: mimeString});
    return blob;
  }  


  onSubmit(blob, filename) {


    const { sendPhotoProject, project } = this.props;

    console.log("======================================================================");


    const formData = new FormData();
    console.log("blob@onSubmit ",  blob);
    console.log("filename@onSubmit ", filename)
    formData.append('file', blob, filename);

    try {

      
      sendPhotoProject(project, formData);

      

    } catch(err) {
      console.log("err in sendPhotoProject")

    }
  }


  trace(txt) {
      
      if(this.props.project.enableDebugLog){
          var elem = document.getElementById("debugShow");
          elem.innerHTML += txt + "<br>";
      }
  }

  render(){
  	
    const {project} = this.props;

    return (

      

      <div id="container" style={styles.container}>

        <canvas hidden id="rttCanvas" ref={this.rttCanvas}></canvas>
       

        <canvas hidden id="ManipulatingCanvas" ref={this.manipulateCanvas}></canvas>

        <canvas id="webcamCanvas" style={styles.canvas}></canvas>
       

        {
          (this.state.loading) ? (<Loader active size='massive'>Loading</Loader>) : (<Loader size='massive'>Loading</Loader>)
        }
        
        
        <div id="UsageSteps" style={styles.stepBlock} >

          <Grid columns='two' >
          
            
            <Grid.Row id="Step01">
              
              <Grid.Column style={styles.columnA}>
                  <Label circular  style={styles.label} > 1 </Label>
              </Grid.Column>

              <Grid.Column style={styles.columnB}>
                  <Container style={styles.textA}> {project.projectLabel_01}        </Container>  
                  <Container style={styles.textB}> {project.projectDescription_01}  </Container>  
              </Grid.Column>
              
            </Grid.Row>

            <Icon id="Arrow01to02" name="arrow down" style={styles.arrow}/>

            <Grid.Row id="Step02">
             
              <Grid.Column style={styles.columnA}>
                  <Label circular  style={styles.label} > 2 </Label>
              </Grid.Column>

              <Grid.Column style={styles.columnB}> 
                  <Container style={styles.textA}> {project.projectLabel_02}        </Container>  
                  <Container style={styles.textB}> {project.projectDescription_02}  </Container>  
              </Grid.Column>
        
            </Grid.Row>
        
            <Icon id="Arrow02to03" name="arrow down" style={styles.arrow}/>
            
            <Grid.Row id="Step03">
          
              <Grid.Column style={styles.columnA}>
                 <Label circular  style={styles.label} > 3 </Label>
              </Grid.Column>

              <Grid.Column style={styles.columnB}>
                  <Container style={styles.textA}> {project.projectLabel_03}        </Container>  
                  <Container style={styles.textB}> {project.projectDescription_03}  </Container>  
              </Grid.Column>
              
            </Grid.Row>

            <Icon id="Arrow03to04" name="arrow down" style={styles.arrow}/>
                        
            <Grid.Row id="Step04">
          
              <Grid.Column style={styles.columnA}>
                 <Label circular  style={styles.label} > 4 </Label>
              </Grid.Column>

              <Grid.Column style={styles.columnB}>
                  <Container style={styles.textA}> {project.projectLabel_04}        </Container>  
                  <Container style={styles.textB}> {project.projectDescription_04}  </Container>  
              </Grid.Column>
              
            </Grid.Row>          
          </Grid>

        </div>  

        <div id="TimerCount" style={ styles.countdown }></div>  

        {
            this.props.project.enableDebugLog ? (
                                                  <div>
                                                      <div> <pre id="debugShow"></pre> </div>
                                                  </div>    
                                            ) : (<div></div>)
          }   

        {/*<div id="TimerCountUse" style={ styles.countdownUse }></div>*/}

   
      </div>


    );
  };


}

const styles = {
  
  container: {
    
    height: 'fit-content'

  },
  label:{

    fontSize: '3rem',

  },
  arrow:{

    margin: 'auto',

  },
  columnA:{

    width: '20%',
    textAlign: 'center',
    
  },
  columnB:{

    width: '70%',    
  },
  textA:{

    textAlign: 'center',
    color: '#5e5e5e', //#e8e8e8',
    
  },
  textB:{

    textAlign: 'left',
    fontSize: '3rem',
    
  },
  button: {
    width:'auto',
    //alignContent: 'center',
    //display: 'block ruby'

  },
  stepBlock: {
    
      top : '200px',
      position: 'absolute',
      display: 'block',
      fontSize: '4rem',
      lineHeight: 'normal',
      backgroundColor: 'rgba(255, 130, 175, 0.8)',
      width: '43%',
      marginLeft: '15px',
      paddingLeft: '15px',
      paddingTop: '15px',
      paddingBottom: '15px',
  },
  icon: {
    
    alignContent: 'center',
    display: 'grid',
    width:'36px',
    height:'36px',
    padding: '21px'

  },
  video:{

    width: '100vw',
    top: '-10%',
    position: 'relative',

  },
  usage:{
      top : '-1920px',
      position: 'relative'
      //width: '100%',
      //height: '100%',
      //display: 'block'
      //clipPath: 'inset(0px 500px 0px 0px)',
      //width: '200%'
  },
  canvas:{
    position: 'relative',
    //top: '-160px',  //for baselayout header height
    

    width: '100%'
  },
  div:{

    marginLeft: 'auto',
    marginRight: 'auto'

  },
  countdown: {

    position: 'fixed',
    left: '45%',
    top: '25%',
    'z-index': 999,
    'font-size': '300px',
    color: 'rgb(255, 76, 143)',
    width: '50%'
   

  },
  /*
  countdownUse: {

    position: 'fixed',
    left: '45%',
    top: '25%',
    'z-index': 999,
    'font-size': '300px',
    color: 'rgb(255, 76, 143)',
    width: '50%'
     
  },*/
};


export default connect(mapState, mapDispatch)(withRouter(RTCfittingWebglNoRTC));
