import axios from 'axios'
import store from '../../store';
/**
 * ACTION TYPES
 */


const UPDATE_PAYMENT = 'UPDATE_PAYMENT'

/**
 * INITIAL STATE
 */

const initialState = [
  {
    id: 0,
    status: ''
  }
]

/**
 * ACTION CREATORS
 */

const updatePayment = payment => ({ type: UPDATE_PAYMENT, payment })

/**
 * THUNK CREATORS
 */

export const  recievePayment = payment => {
  return async dispatch => {

    const body = {
                  databaseName: store.getState().project.projectName,
                  orderId: payment.orderId,
                  paymentId: payment.paymentId

                 }


    const {data} = await axios.post(`/api/payment/addPayment`)
    dispatch(updatePayment(data))
  }
}


/**
 * REDUCER
 */

export default function(state = initialState, action) {
  switch (action.type) {

    case UPDATE_PAYMENT:
      return action.payment
   
    default:
      return state
  }
}
