import React, { Component } from "react";

import { connect } from "react-redux";
import { Container, Header, Button, Card, Image, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { fetchProduct, fetchDemo, findSuits, chooseSuit, updateDataXAR, doXAR, fetchXAR} from "../../store";

import { ProductCard, EmptyProduct } from "../../components";

//import { loadScript } from "@paypal/paypal-js";
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


import './XARdemoShow.css';


const mapState = state => ({

  project: state.project,
  xar: state.xar, 
  demo: state.demo, 
  suits: state.suits,
  suit: state.suit,
  webrtc: state.webrtc

});

const mapDispatch = dispatch => {
  return {
      getProduct: id => dispatch(fetchProduct(id)),
      getDemo:   (projectName) => dispatch(fetchDemo(projectName)),
      searchSuits:   (suitIDs) => dispatch(findSuits(suitIDs)),

      selectSuit:   (suit) => dispatch(chooseSuit(suit)),

   
      
      requestXAR: (xar) => dispatch(doXAR(xar)),
      updateXAR: (xar) => dispatch(updateDataXAR(xar)),    
      getXAR: (xar) => dispatch(fetchXAR(xar)),

      

  };
};






class XARdemoShow extends Component {


  constructor(props) {
    super(props);

    console.log("xar @constructor XARdemoShow", this.props.xar)

    this.handleScroll = this.handleScroll.bind(this);

    this.Ref_XAR_show1 = React.createRef();
    this.Ref_wipe_caption = React.createRef();


    this.Ref_parallax_content = React.createRef(); 
    this.Ref_parallax_video_block = React.createRef(); 
    this.Ref_parallax_video = React.createRef();

    this.Ref_flex_female = React.createRef();
    this.Ref_flex_male = React.createRef();

    this.previous_scroll = 0;


    this.menu_height = 67;//-120
    this.white_range = 0;//


    this.parallax_video_top_update = -0.5*window.innerHeight; 
    this.parallax_video_bottom_update = 0.5*window.innerHeight;

    this.state = {

        projectName: this.props.project.projectName,
        wipe_imgshow_width: window.innerWidth,
        wipe_imgshow_height: window.innerHeight,
        
        /*image init.*/
        wipe_img_original_width : this.props.xar.uploadImgSize[0], //385, //=> need to wait the photo loaded completly, but windows.onload too many elements. should be specified in the div not by img.  
        wipe_img_original_height : this.props.xar.uploadImgSize[1], //969,


       
        wipe_img_margin_top : (window.innerHeight - 969)/2,
        
        XAR_show2_top : (window.innerHeight<969) ? -this.white_range*window.innerHeight/969 /*+ this.menu_height*/ //120 for reduce the blank area of image
                                                 : -this.white_range /*+ this.menu_height*/,
        XAR_show2_height: '100vh',                                                 
        XAR_show2_bottom : '',      
        XAR_show2_position : 'fixed',   
        XAR_show2_marginTop : 0,//because scroll not trigger at beginning, but original design scroll will be triggerred when loading js
        

        wipe_top_display: "none",
        wipe_top_height: 1*window.innerHeight,
        wipe_bottom_display: '',
        wipe_bottom_height: 1*window.innerHeight,

        set_wipe_top : true,
        set_wipe_bottom : false,

        wipe_left_display: "",
        wipe_right_display: "",
        wipe_left_width: 969,
        wipe_wrap_width: (window.innerHeight<969) ? window.innerHeight/969*385 
                                                  : 969,
        wipe_wrap_left_pos: 0,
        wipe_wrap_marginLeft: 0,
        wipe_fixed : false,

        
        downArrow_bounce_wipe_display : '',
        note_bounce_wipe_display : '',

        downArrow_bounce_video_display : 'none',
        note_bounce_video_display : 'none',



        wipe_caption_top : '',
        wipe_caption_left : '',
        wipe_caption_textAlign: '',

        wipe1_height : window.innerHeight,
                                                                               
        wipe2_scroll_up_height : 0,/*image full of screen size = wipe_imgshow_height*/
        wipe2_scroll_down_height : window.innerHeight,/*image full of screen size = */


        //----------------
  
        //----------------
        clothing_1_maxHeight : window.innerHeight,
        clothing_2_maxHeight : window.innerHeight,
        clothing_3_maxHeight : window.innerHeight,
        clothing_4_maxHeight : window.innerHeight,
        clothing_5_maxHeight : window.innerHeight,
        clothing_6_maxHeight : window.innerHeight,



      
        //--------------------------------------------------------------------
        parallax_wrap2_top: "",

        //--------------------------------------------------------------------
        parallax_content_background_position: "center center",
        parallax_video_top: -0.5*window.innerHeight,
        parallax_video_height: "",
        parallax_video_marginleft: "-20%",


        parallax_speed: 0.4,
        scroll_from_top: 1,
        scroll_from_bottom: 0,

        parallax_video_trigger: false,

        //--------------------------------------------------------------
        renderObjs: null,
        XARdone: false,
        isWaiting: false,
        sendXARreq: false,

        jumpFromAIfitting: false,
       
    };
    window.scrollTo(0, 0)         

    console.log("constructor @XARdemoShow")
  


  }



  componentDidUpdate(prevProps, prevState) {

    const { getXAR, xar, suit, updateXAR, project } = this.props;


    //console.log("**********************************************");
    //console.log("componentDidUpdate@XARdemoShow", this);
    //console.log("this.props.project.:", this.props.project);
    //console.log("prevProps.project.:", prevProps.project);
    //console.log("this.state.userStatus:", this.state.userStatus);
    //console.log("prevState.userStatus:", prevState.userStatus);
    
    if(this.state.renderObjs && !prevState.renderObjs){

        /*========================================================*/
        var flex_male = this.Ref_flex_male.current
        var flex_female = this.Ref_flex_female.current

        console.log("this.Ref_flex_male", this.Ref_flex_male)
        console.log("this.Ref_flex_female", this.Ref_flex_female)
        console.log("this.props.demo.length", this.props.demo.length)
        flex_male.scrollLeft   = flex_male.scrollWidth *  150/2/(150*this.props.demo.length/2) 
        flex_female.scrollLeft = flex_female.scrollWidth *  150/2/(150*this.props.demo.length/2) 

    }

    //after send photo by fileUpload, return back XARdemoShow and send json file
    if(!this.state.isWaiting && !prevState.isWaiting){

      if (!this.state.sendXARreq && !this.state.XARdone) {

        //console.log("!this.state.sendXARreq && !this.state.XARdone")
        if( xar.isShoot && xar.isRequest ){
          console.log("xar.isShoot && xar.isRequest")      
          this.XARsubmit();        
        }

      }

    }


    if ( (this.props.xar.isUpload !== prevProps.xar.isUpload) || (this.state.jumpFromAIfitting && !prevState.jumpFromAIfitting) )  {
   
      console.log("xar photo & clothlist changes @componentDidUpdate");
      console.log("xar.isUpload @componentDidUpdate:", xar.isUpload);
      console.log("XARdone @componentDidUpdate:", this.state.XARdone)
      
      //get XAR result       
      if(xar.isUpload.photoUpload && xar.isUpload.clothlistUpload && !this.state.XARdone){
          console.log("!!!!!!!!!!!!!! xar photo & clothlist both upload!!!!!!!!!!!!!!!!!!!!!!");
          console.log("Go get the photo: start getXAR(xar) in componentDidUpdate");
          getXAR(xar).then(()=>{
              this.setState({ XARdone: true }); 
              this.setState({ isWaiting: false});             
              console.log("set XARdone done! ", this.state.XARdone);
             
              //xar.isUpload.photoUpload = false;
              //updateXAR(xar);

         });
       


         

         
      }else{
        console.log("Not go get the photo: maybe due to XARdone  in componentDidUpdate", this.state.XARdone);
         

      }

    }
 



  }

  componentWillUnmount() {
   
    console.log("this@componentWillUnMount XARdemoShow", this)
    
    window.removeEventListener('scroll', this.handleScroll);
     
   } 

  componentDidMount() {
    
    console.log("this@componentDidMount XARdemoShow", this)
    

    window.addEventListener('scroll', this.handleScroll);

    window.addEventListener('scroll', function() {
      //console.log("here", window.pageYOffset + 'px');
    });

    //console.log(this.props.location.state.linkAddr)
    //console.log(`${this.state.projectName}/AI-virtual-fitting`)
    //console.log(this.props.location.state.linkAddr ===`${this.state.projectName}/AI-virtual-fitting`)
    if(this.props.location.state.linkAddr === `${this.state.projectName}/AI-virtual-fitting`){
      console.log("this.props.location.state.linkAddr", `${this.state.projectName}/AI-virtual-fitting`)
      this.setState({jumpFromAIfitting : true})

    }

    /*=======================================================*/

    var wipe_img_wipe_resize_width  = this.state.wipe_img_original_width;
    var wipe_img_wipe_resize_height = this.state.wipe_img_original_height;
    var wipe_num = 1;



    if(this.state.wipe_imgshow_height > this.state.wipe_img_original_height){

      wipe_img_wipe_resize_height = this.state.wipe_img_original_height
      
      if(this.state.wipe_imgshow_width > this.state.wipe_img_original_width){

          wipe_img_wipe_resize_width = this.state.wipe_img_original_width
          
          this.setState({XAR_show2_top: -this.white_range /*+ this.menu_height*/ })

          console.log("here 1", wipe_img_wipe_resize_height, wipe_img_wipe_resize_width)
      }else{

          wipe_img_wipe_resize_width  = this.state.wipe_imgshow_width;
          wipe_img_wipe_resize_height = this.state.wipe_imgshow_width/this.state.wipe_img_original_width *this.state.wipe_img_original_height
          this.setState({XAR_show2_top: -this.white_range*this.state.wipe_imgshow_height/this.state.wipe_img_original_height /*+ this.menu_height*/ })
      
          console.log("here 2", wipe_img_wipe_resize_height, wipe_img_wipe_resize_width)

      }


    

    }else{

      wipe_img_wipe_resize_height = this.state.wipe_imgshow_height 

      wipe_img_wipe_resize_width = this.state.wipe_imgshow_height/this.state.wipe_img_original_height*this.state.wipe_img_original_width

      if(this.state.wipe_imgshow_width >= wipe_img_wipe_resize_width  ){

        console.log("here 3", wipe_img_wipe_resize_height, wipe_img_wipe_resize_width)


      }else{

        wipe_img_wipe_resize_width  = this.state.wipe_imgshow_width;
        wipe_img_wipe_resize_height = this.state.wipe_imgshow_width/this.state.wipe_img_original_width*this.state.wipe_img_original_height


        console.log("here 4", wipe_img_wipe_resize_height, wipe_img_wipe_resize_width)


      }

      this.setState({XAR_show2_top: -this.white_range*this.state.wipe_imgshow_height/this.state.wipe_img_original_height /*+ this.menu_height*/ })
      
      this.setState({wipe_img_margin_top: (this.state.wipe_imgshow_height - wipe_img_wipe_resize_height)/2})//+this.menu_height
          
          
      
    }

    /*=======================================================*/
    console.log("wipe_img_wipe_resize_width", wipe_img_wipe_resize_width)
    console.log("wipe_img_wipe_resize_height", wipe_img_wipe_resize_height)
    
    if(this.state.wipe_imgshow_width < 1*wipe_img_wipe_resize_width){

      this.setState({wipe_left_display: "none"})
      this.setState({wipe_right_display: "none"})
      
      wipe_num=1;
      this.setState({wipe_wrap_width: "100%"})

    }else if(this.state.wipe_imgshow_width < 2*wipe_img_wipe_resize_width){
      
      this.setState({wipe_left_display: "none"})
      this.setState({wipe_right_display: "none"})
      
      wipe_num=1;
      this.setState({wipe_wrap_width: wipe_img_wipe_resize_width})

      
    }else if(this.state.wipe_imgshow_width < 3*wipe_img_wipe_resize_width){
      
      this.setState({wipe_left_display: "none"})
      this.setState({wipe_right_display: "none"})
      //this.setState({wipe_right_display: ""})
      this.setState({wipe_left_width: 0})
      
      wipe_num=1;
      //wipe_num=2;
      this.setState({wipe_wrap_width: wipe_img_wipe_resize_width})

    }else{
      
      //this.setState({wipe_left_display: ""})
      //this.setState({wipe_right_display: ""})
      this.setState({wipe_left_display: "none"})
      this.setState({wipe_right_display: "none"})
      //wipe_num=3;
      wipe_num=1;
      this.setState({wipe_wrap_width: wipe_img_wipe_resize_width})

    }

    

    //console.log("wipe_wrap_left_pos", (this.state.wipe_imgshow_width - wipe_num*wipe_img_wipe_resize_width)/2)
    this.setState({wipe_wrap_left_pos: (this.state.wipe_imgshow_width - wipe_num*wipe_img_wipe_resize_width)/2 })
    this.setState({parallax_video_marginleft: -(this.state.wipe_imgshow_width - wipe_num*wipe_img_wipe_resize_width)/2})


    if(this.state.wipe_wrap_left_pos<0){
  
      this.setState({wipe_wrap_marginLeft: 0})
      this.setState({wipe_caption_left: 0})

    }else{
     
    }

    var wipe_caption = this.Ref_wipe_caption.current;
    //console.log("this.Ref_wipe_caption", this.Ref_wipe_caption)
    //console.log("wipe_caption", wipe_caption)
    var wipe_caption_fix_pos_left = this.state.wipe_imgshow_width/2 - wipe_caption.offsetWidth/2;
    //var wipe_caption_fix_pos_left_for_fix = this.state.wipe_imgshow_width/2 - this.state.wipe_wrap_left_pos;// relayout
    //var wipe_caption_fix_pos_left_for_unfix = wipe_caption_fix_pos_left_for_fix + this.state.wipe_wrap_left_pos - wipe_caption.offsetWidth/2;// relayout
    
    //console.log("wipe_caption_fix_pos_left", wipe_caption_fix_pos_left)
    this.setState({wipe_caption_left: wipe_caption_fix_pos_left})
    
    var wipe_caption_pos_for_fix = this.state.wipe_imgshow_height + this.state.XAR_show2_top;//this.state.wipe_imgshow_height - wipe_caption.offsetHeight - 115;//this.state.XAR_show2_top;//relayout
    var wipe_caption_pos_for_unfix = wipe_caption_pos_for_fix + this.state.XAR_show2_top;//relayout
    
    this.setState({wipe_caption_top: wipe_caption_pos_for_fix})// relayout  
    this.setState({wipe_caption_textAlign: "center"})// relayout  


    //------------------------------
    this.setState({video_wrap_width: this.state.wipe_imgshow_width})

    /*========================================================================*/

    var video_background_fix_width = 1200;
    var scale_size = 1;

    if(this.state.wipe_imgshow_height>826 ){
      
      scale_size = 1;
      this.setState({video_background_fix_height : 826})

      video_background_fix_width = 1200;
      
      this.setState({video_background_fix_top : (this.state.wipe_imgshow_height - 826 * scale_size)/2})
      
    }else{

      scale_size = 0.8;
      this.setState({video_background_fix_height : this.state.wipe_imgshow_height * scale_size})
      
      video_background_fix_width = this.state.wipe_imgshow_height*scale_size/826 * 1200;
      
      //console.log("video_background_fix_width " + video_background_fix_width);
      
      this.setState({video_background_fix_top : (this.state.wipe_imgshow_height - this.state.wipe_imgshow_height * scale_size)/2})
      
    }

      
    //

    //=================================================================
    /* parallax video*/
    //console.log("this.Ref_parallax_video_block.current 1", this.Ref_parallax_video_block.current)
    //console.log("this.Ref_parallax_video.current 1", this.Ref_parallax_video.current)
    this.Ref_parallax_video_block.current.playbackRate = 0.5;

    this.setState({parallax_video_height : this.Ref_parallax_content.current.offsetHeight})

    if(window.innerWidth>480){
      this.Ref_parallax_video.current.setAttribute('src', '/vismile/AI-virtual-fitting/Vismile-clothes_large.mp4');
    
    }else{
      this.Ref_parallax_video.current.setAttribute('src', '/vismile/AI-virtual-fitting/Vismile-clothes_small.mp4');
      
    }

    this.Ref_parallax_video.current.loop = true;
    this.Ref_parallax_video.current.muted = true;
    this.Ref_parallax_video.current.playsInLine = true;
    this.Ref_parallax_video.current.autoPlay = true;
    

    this.Ref_parallax_video_block.current.appendChild(this.Ref_parallax_video.current);
    
    if(window.innerWidth>1920){
      
      this.Ref_parallax_video.current.width = window.innerWidth;
      
    }
    //console.log("this.Ref_parallax_video_block.current 2", this.Ref_parallax_video_block.current)
    //console.log("this.Ref_parallax_video.current 2", this.Ref_parallax_video.current)
    
  /*=================================================================*/

    const { getDemo, getProduct, demo, searchSuits, project, setWebRTC  } = this.props;

    this.setState({ ARfittigTimeOut : project.ARfittigTimeOut });

    //console.log("this.props.myTurnNow, this.state.myTurnNow@DemoSite componentDidMount", this.props.myTurnNow, this.state.myTurnNow)


    getDemo(this.state.projectName).then( () => {
                                                  let suitIDs = [];
                                                  console.log("demo", this.props.demo)
                                                  this.props.demo.map(item => {
                                                                                 suitIDs.push(item.suitID) 
                                                                              }
                                                                                     
                                                                     )
                                                  console.log("suitIDs", suitIDs)
                                                  
                                                  searchSuits(suitIDs).then( () => {
                                                                                    //console.log("this", this)
                                                                                    this.setState({renderObjs: this.props.suits}) 
                                                                                    

                                                                                    //console.log("this.state.renderObjs", this.state.renderObjs)

                                                                           })

                                                }
                                                  


                                        )




  }


  handleScroll(event) {


  


    if(this.state.XARdone){

      
      //console.log("event", event)
      //console.log(window)
      //console.log("window.scrollY", window.scrollY)
      let scroll_top = window.scrollY;//event.srcElement.body.scrollTop;
      let scroll_bottom=scroll_top+window.innerHeight
      
      var scroll_this_time = scroll_top - this.previous_scroll;
      this.previous_scroll = scroll_top;

      //---------------------------------------------------------


      var wipe_start_pos = 0;//this.state.XAR_show2_top, 0 => scroll from the beginning

      //for wipe_wrap
      if( scroll_top < wipe_start_pos){  //such as menu div

        //console.log("scroll_top < wipe_start_pos", scroll_top, wipe_start_pos)
        if(this.state.set_wipe_top||this.state.set_wipe_bottom){
          //console.log("this.state.set_wipe_top||this.state.set_wipe_bottom", this.state.set_wipe_top, this.state.set_wipe_bottom)
          
          this.setState({wipe_top_display: 'none'})   
          this.setState({wipe_bottom_display: ''})   
                
          this.setState({set_wipe_top: false})         
          this.setState({set_wipe_bottom: false})         
          
         
          
          //below is correct for bottom up ???
          //this.setState({XAR_show2_top : 0})
          this.setState({XAR_show2_bottom : ''})
          

          this.setState({downArrow_bounce_wipe_display : 'none'})
          this.setState({note_bounce_wipe_display : 'none'})
          
        }


        
      }else if(scroll_top > wipe_start_pos && scroll_top < wipe_start_pos + this.state.wipe_imgshow_height){



      }else if( scroll_top > wipe_start_pos + this.state.wipe_imgshow_height){
        
        //console.log("scroll_top > wipe_start_pos + this.state.wipe_imgshow_height", scroll_top, wipe_start_pos, this.state.wipe_imgshow_height)
        
        if(this.state.set_wipe_top||this.state.set_wipe_bottom){

          //console.log("this.state.set_wipe_top||this.state.set_wipe_bottom", this.state.set_wipe_top, this.state.set_wipe_bottom)
          this.setState({wipe_top_display: ''})  
          this.setState({wipe_bottom_display: 'none'})  

          this.setState({set_wipe_top: false})         
          this.setState({set_wipe_bottom: false})         
          
          //this.setState({XAR_show2_top : ''})
          this.setState({XAR_show2_bottom : 0})

          this.setState({downArrow_bounce_wipe_display : 'none'})
          this.setState({note_bounce_wipe_display : 'none'})
          

          
        }
      }

      var wipe_caption = this.Ref_wipe_caption.current;
      //console.log("wipe_caption", wipe_caption)
      //console.log("wipe_caption.width", wipe_caption.width)

      //var wipe_caption_fix_pos_left = this.state.wipe_imgshow_width/2 - wipe_caption.offsetWidth/2;
      //var wipe_caption_fix_pos_left_for_fix = this.state.wipe_imgshow_width/2 - this.state.wipe_wrap_left_pos;// relayout
      //var wipe_caption_fix_pos_left_for_unfix = wipe_caption_fix_pos_left_for_fix + this.state.wipe_wrap_left_pos - wipe_caption.offsetWidth/2;// relayout
      var wipe_caption_pos_for_fix = this.state.wipe_imgshow_height + this.state.XAR_show2_top;//this.state.wipe_imgshow_height - wipe_caption.offsetHeight - 115;//this.state.XAR_show2_top;//relayout
      var wipe_caption_pos_for_unfix = wipe_caption_pos_for_fix + this.state.XAR_show2_top;//relayout
      
      
      //console.log("wipe_caption_fix_pos_left", wipe_caption_fix_pos_left)
      //console.log("wipe_caption_fix_pos_left_for_unfix", wipe_caption_fix_pos_left_for_unfix)
      //console.log("scroll_top", scroll_top)
      //console.log("wipe_start_pos", wipe_start_pos)
      //console.log("this.state.wipe_imgshow_height", this.state.wipe_imgshow_height)

      //console.log("this.state.wipe_fixed", this.state.wipe_fixed)

      if( scroll_top >= wipe_start_pos && scroll_top < wipe_start_pos + this.state.wipe_imgshow_height) {
        //console.log("in between")

        this.setState({set_wipe_top: true})  
        this.setState({set_wipe_bottom: true})  
        
        
        
        if(!this.state.wipe_fixed){
          
          this.setState({XAR_show2_position : 'fixed'}) 
          this.setState({XAR_show2_bottom : 0}) 

          this.setState({XAR_show2_marginTop : 0}) 

          //below is correct for bottom up 
          //this.setState({XAR_show2_top : 0})
          
          
          this.setState({wipe_fixed: true})

          //this.setState({wipe_caption_left: wipe_caption_fix_pos_left})
          
          this.setState({wipe_top_height: 2*this.state.wipe_imgshow_height})
          this.setState({wipe_bottom_height:  2*this.state.wipe_imgshow_height})
          
          this.setState({wipe_caption_top: wipe_caption_pos_for_fix})
          
          this.setState({downArrow_bounce_wipe_display:  'unset'})
          this.setState({note_bounce_wipe_display:  'unset'})
         
          
        }
        
        this.setState({wipe2_scroll_down_height : this.state.wipe_imgshow_height - (scroll_top-wipe_start_pos)})//try for bottom up: this one correct for all devices
        this.setState({wipe2_scroll_up_height : (scroll_top-wipe_start_pos) })//try for top down: this one correct for all devices
        
      
          
      }else{ 
        //console.log("not in between")

        if(this.state.wipe_fixed){

          this.setState({XAR_show2_position : ''}) 
          this.setState({XAR_show2_bottom : ''}) 

          this.setState({wipe_fixed: false})

          this.setState({XAR_show2_marginTop : this.state.XAR_show2_top - this.menu_height}) // menu_height due to paddingTop of div in route 

          //this.setState({wipe_caption_left: wipe_caption_fix_pos_left})
          
          
          this.setState({wipe_top_height: 1*this.state.wipe_imgshow_height})
          this.setState({wipe_bottom_height:  1*this.state.wipe_imgshow_height})
          
          this.setState({parallax_wrap2_top: 5*this.state.wipe_imgshow_height })//compensate to the display none of wipe_bottom, only correct when wipe_imgshow_height is screen size
          
          this.setState({wipe_caption_top: wipe_caption_pos_for_unfix + 1*this.state.wipe_imgshow_height})//2 times for inset, but only 2x when in the fixed resgion
          
          this.setState({downArrow_bounce_wipe_display:  'none'})
          this.setState({note_bounce_wipe_display:  'none'})

          
        }
      }

      /*========================================================================*/
   
     
      //console.log("this.Ref_parallax_content", this.Ref_parallax_content)
      if(   scroll_top > this.Ref_parallax_content.current.offsetTop /*- this.state.wipe_imgshow_height*/
         && scroll_top < this.Ref_parallax_content.current.offsetTop /*- this.state.wipe_imgshow_height*/ + this.Ref_parallax_content.current.offsetHeight + this.state.wipe_imgshow_height) {//!isElementOutViewport(services)
        
        
        
        
          
          //console.log("in scroll block");
          
          if(this.state.scroll_from_top){//st_delta>=0
            
            this.parallax_video_top_update = this.parallax_video_top_update - scroll_this_time * this.state.parallax_speed;
            this.setState({parallax_video_top: this.parallax_video_top_update})
            this.setState({parallax_video_bottom: ""})

          }else if(this.state.scroll_from_bottom){//st_delta<0
            this.parallax_video_bottom_update = this.parallax_video_bottom_update + scroll_this_time * this.state.parallax_speed;
            this.setState({parallax_video_top: ""})
            this.setState({parallax_video_bottom: this.parallax_video_bottom_update})

          }
          this.setState({parallax_video_trigger:true})
          
          
      }else if ( this.state.parallax_video_trigger ) { /*isElementOutViewport(services) && (parallax_video_trigger==1) */
        
        this.parallax_video_top_update = -0.5*window.innerHeight;
        this.parallax_video_bottom_update = 0.5*window.innerHeight;
        
        //console.log("here", scroll_this_time)

        if(scroll_this_time>=0){//scroll_from_top


          this.setState({parallax_video_top: ""})
          this.setState({parallax_video_bottom: this.parallax_video_bottom_update})

          this.setState({scroll_from_top:0})
          this.setState({scroll_from_bottom:1})
           
          
        }else if(scroll_this_time<0){//scroll_from_bottom

          this.setState({parallax_video_top: this.parallax_video_top_update})
          this.setState({parallax_video_bottom: ""})

          this.setState({scroll_from_top:1})
          this.setState({scroll_from_bottom:0})
          
        }
        
        //console.log("~isElementInViewport");
        this.setState({parallax_video_trigger:false})
      } 
      
      //console.log("parallax_video_top_update ", this.parallax_video_top_update);
      //console.log("parallax_video_bottom_update ", this.parallax_video_bottom_update);
      
  }else{

    this.setState({XAR_show2_position : ''}) 


    this.setState({XAR_show2_height:  ''})
    this.setState({wipe_bottom_height:  ''})

  }

}



/********************************************************/
//  XAR fitting related start
/********************************************************/


  onClickSuit(suit){

    const { xar, updateXAR, selectSuit } = this.props;
    
    //must remove before jump page
    window.removeEventListener('scroll', this.handleScroll);
    

    this.DemoClothesProducts(suit);
    xar.isRequest = true;
    xar.isUpdate = false;
    updateXAR(xar);

    var tempSuit = {};
    tempSuit.suit = suit
    selectSuit(tempSuit)

    this.setState({demoSuit : suit})
    this.setState({onClickDemo : true})
   

    this.setState({ sendXARreq: true});
    

    

    console.log("go to fileupload page @onClickSuit ");
    const linkAddr       = `${this.state.projectName}/XARdemoShow`
    
    
    this.props.history.push({ pathname: '/fileupload',
                              state: { linkAddr: linkAddr }
                           });


        

    // pop-up choose photo or use sample photo
    //this.AIfittingSubmit();

  }

  XARsubmit() {

      const { xar, requestXAR, } = this.props;


      //if no photo, do take photo
      //if already has photo, do xar json request
      
      //


      if(xar.isShoot){
        //send XAR json file

        requestXAR(xar);      
        console.log("  with old pic");
        this.setState({ isWaiting: true});

      }

  }  

  DemoClothesProducts(suit) {
    console.log("============================= clothlist ===============================")
    
    const { xar, product } = this.props;
    console.log("this.props @XARclothesProducts: ", this.props)
    console.log("suit @XARclothesProducts: ", suit)


    

    const video_filename = xar.picID + '_' + suit.suitID;
    
    /* originally, find the same suit of other items
    const clothlist = [];
    suit.suit.products.forEach(product => {
        console.log("product.suit.products.internal_name: ", product.internal_name)
        clothlist.push(product.internal_name);        
      });
    */
    const clothlist = suit.suitClothesID;
    const shoes = suit.shoes;

    console.log("clothlist: ", clothlist)

    xar.clothlist = clothlist;
    xar.shoes = shoes;
    xar.suitID = suit.suitID;
    xar.clothesID = suit.suitID;//product.product.internal_name;
    xar.video_filename = video_filename;
    if(suit.gender === 'male'){
      xar.human.gender = 1;  
    }else
    {
      xar.human.gender = 0;
    }
    

  }

/********************************************************/
//  AR fitting related end
/********************************************************/




  render() {
    
    const {  } = this.props;

    
    
    return (
      <div style={{ backgroundColor : "unset"}} >

              { 
                this.state.XARdone ? '' : (

                                            <svg viewBox='0 0 36 36' class='circular-chart'>
                                              <path class='circle1'
                                                
                                                d='M18 2.0845
                                                   a 15.9155 15.9155 0 0 1 0 31.831 
                                                   a 15.9155 15.9155 0 0 1 0 -31.831'
                                              />
                                               <path class='circle2'
                                                
                                                d='M18 5.2676
                                                   a 12.7324 12.7324 0 0 1 0 25.4648
                                                   a 12.7324 12.7324 0 0 1 0 -25.4648'
                                              />
                                                 <path class='circle3'
                                                
                                                d='M18 8.4507
                                                   a 9.5493 9.5493 0 0 1 0 19.0986
                                                   a 9.5493 9.5493 0 0 1 0 -19.0986'
                                              />
                                              
                                            </svg>

                                           )
              }

              <div class="wipe-top" style={{ display : this.state.wipe_top_display,
                                             height  : this.state.wipe_top_height,
                                             visibility: this.state.XARdone ? '' : 'hidden' 
                                          }} >
              </div>


              <div class="XAR-show2" ref={this.Ref_parallax_content} style={{ height      : this.state.XARdone ? this.state.XAR_show2_height : '',
                                                                              top         : this.state.XAR_show2_top,
                                                                              paddingLeft : this.state.wipe_wrap_left_pos,
                                                                              marginTop   : this.state.XAR_show2_marginTop,
                                                                              position    : this.state.XAR_show2_position,
                                                                              visibility: this.state.XARdone ? '' : 'hidden' 
                                                                           }} >
                  <div class="container" style={{position:'relative', 
                                                 backgroundColor: "rgba(0,0,0,0)!important"
                                               }}>
                      <div class=" parallax-video" id="parallax-video-container" ref={this.Ref_parallax_video_block} style={{ position:'absolute',
                                                                                                                              top: this.state.parallax_video_top,
                                                                                                                              height: this.state.parallax_video_height,    
                                                                                                                              bottom: this.state.parallax_video_bottom,
                                                                                                                              marginLeft: this.state.parallax_video_marginleft,
                                                                                                                           }}> {/*video-background-fix*/}
                          <video id="parallax-video-0" preload="auto"   ref={this.Ref_parallax_video} muted autoPlay playsInline loop> 
                          </video>  
                      </div>                
                  </div>                
                  
                  
                  <div class='wipe-wrap' id='wipe-left' style={{ width            : this.state.wipe_wrap_width,
                                                                 display          : this.state.wipe_left_display,                                                            
                                                              }} >
                    
                    <div class='wipe1' style={{ height : this.state.wipe1_height
                                             }} ><img class='wipe-img' id='clothing-1' 
                                                                                           style={{ marginTop: this.state.wipe_img_margin_top,
                                                                                                    maxHeight : this.state.clothing_1_maxHeight
                                                                                                 }} src='/vismile/AI-virtual-fitting/Marilyn-Monroe.jpg'  alt='Marilyn Monroe photo'/></div>
                                
                    <div class='wipe2-scroll-up' style={{height : this.state.wipe2_scroll_up_height
                                                       }} ><img class='wipe-img' id='clothing-2' style={{ marginTop: this.state.wipe_img_margin_top,
                                                                                                          maxHeight : this.state.clothing_2_maxHeight 
                                                                                                       }} src='/vismile/AI-virtual-fitting/Marilyn-Monroe-tryon.jpg'  alt='Marilyn Monroe virtual fitting photo' /></div>
                                                                        
                    
                  </div>
                    
                
                  
                  <div class='wipe-wrap' id='wipe-center' style={{ width            : this.state.wipe_wrap_width,
                                                                }} >
                    
                    <div class='wipe1'  style={{ height : this.state.wipe1_height,
                                                 width  : 'inherit',
                                                 visibility: this.state.XARdone ? '' : 'hidden' 
                                              }} ><img class='wipe-img' id='clothing-3' 
                                                                                        style={{ marginTop: this.state.wipe_img_margin_top,
                                                                                                 maxHeight     : this.state.clothing_3_maxHeight,
                                                                                                 width         : '100%',
                                                                                              }} src={'/'+this.props.project.projectName + '/' + this.props.xar.picID + '.' + this.props.xar.picExt}  alt='The V-J Day Kiss in times square virtual fitting photo' /></div>
                              
                    <div class='wipe2-scroll-down' style={{ height : this.state.wipe2_scroll_down_height,
                                                            width  : 'inherit',
                                                            visibility: this.state.XARdone ? '' : 'hidden' 
                                                         }} ><img class='wipe-img' id='clothing-4'  style={{ marginTop: this.state.wipe_img_margin_top,
                                                                                                             maxHeight     : this.state.clothing_4_maxHeight, 
                                                                                                             width         : '100%'
                                                                                                          }} src={"/"+this.props.project.projectName+this.props.xar.XARimg[0]["img"]}  alt="The V-J Day Kiss in times square photo"/></div>
                                                                        
                    
                  </div>
                  
                  
                  
                  
                  <div class="wipe-wrap" id="wipe-right" style={{ width            : this.state.wipe_wrap_width,
                                                                  display          : this.state.wipe_right_display,                                                            
                                                               }} >
                    
                    <div class="wipe1" style={{ height : this.state.wipe1_height
                                             }} ><img class="wipe-img" id="clothing-5" style={{ marginTop: this.state.wipe_img_margin_top,
                                                                                                maxHeight     : this.state.clothing_5_maxHeight,
                                                                                                width         : "100%" 
                                                                                             }} src="/vismile/AI-virtual-fitting/Audrey-Hepburn.jpg"  alt="Audrey Hepburn photo"/></div>
                              
                    <div class="wipe2-scroll-up" style={{ height : this.state.wipe2_scroll_up_height
                                                       }}  ><img class="wipe-img"  id="clothing-6" style={{ marginTop: this.state.wipe_img_margin_top,
                                                                                                            maxHeight  : this.state.clothing_6_maxHeight 
                                                                                                         }} src="/vismile/AI-virtual-fitting/Audrey-Hepburn-tryon.jpg"  alt="Audrey Hepburn virtual fitting photo"/></div>
                                                                        
                    
                  </div>
                    
                
                  <div class="wipe-caption" ref={this.Ref_wipe_caption} style={{ top       : this.state.wipe_caption_top,
                                                                                 left      : this.state.wipe_caption_left,
                                                                                 textAlign : "center",
                                                                                 display   : "none"
                                                                              }}>

                    <h3>Dress with imagination</h3>
                    
                    
                    <h3>Show in reality</h3>   
                  </div>
                
                  <div class="downArrow bounce" >
                    <a id="note-bounce-wipe" style={{ display : this.state.note_bounce_wipe_display,
                                                      color   : "inherit",
                                                      textDecoration: "none"
                                                   }} >Scrolling</a>
                    <img id="downArrow-bounce-wipe" style={{ display : this.state.downArrow_bounce_wipe_display,
                                                             verticalAlign: "middle",
                                                          }} width="30" height="30" alt="scroll down to virtual try on" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSLQodC70L7QuV8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yNC4yODUsMTEuMjg0TDE2LDE5LjU3MWwtOC4yODUtOC4yODhjLTAuMzk1LTAuMzk1LTEuMDM0LTAuMzk1LTEuNDI5LDAgIGMtMC4zOTQsMC4zOTUtMC4zOTQsMS4wMzUsMCwxLjQzbDguOTk5LDkuMDAybDAsMGwwLDBjMC4zOTQsMC4zOTUsMS4wMzQsMC4zOTUsMS40MjgsMGw4Ljk5OS05LjAwMiAgYzAuMzk0LTAuMzk1LDAuMzk0LTEuMDM2LDAtMS40MzFDMjUuMzE5LDEwLjg4OSwyNC42NzksMTAuODg5LDI0LjI4NSwxMS4yODR6IiBmaWxsPSIjMTIxMzEzIiBpZD0iRXhwYW5kX01vcmUiLz48Zy8+PGcvPjxnLz48Zy8+PGcvPjxnLz48L3N2Zz4=" />
                  </div>

              </div>

              <div class="wipe-bottom" style={{ display : this.state.wipe_bottom_display,
                                                height  : this.state.XARdone ? this.state.wipe_bottom_height : "",
                                                visibility: this.state.XARdone ? '' : 'hidden' 
                                             }} ></div>


              



        {/*================Feature Area =================*/}
        <section class="feature_area" style={{paddingBottom: "200px"
                                            }}>
            <div class="container" style={{backgroundColor: "rgba(0,0,0,0)!important"
                                         }}>
                
              <div class="c_title">
          
                <h6 class="strong-heading">Try it now </h6>
                <h2>Try it now</h2>
              </div>
          
        
              {
                this.state.renderObjs ? (
                                          <>
                                            <div className="flexContainer" ref={this.Ref_flex_female}>
                                                 {this.state.renderObjs.map(suit => (     
                                                                                       (suit.gender==='female') ? (  
                                                                                                 
                                                                                           <Card  onClick={() =>  this.onClickSuit(suit) /*this.ARfittingSubmit(suit)*/ }  style={styles.cardImage2}>
                                                                                            
                                                                                            <Image src={suit.imageUrl} style={styles.cardImage}/>
         
                                                                                           </Card>
                                                                                       ) : null
                                                                                    
                                                                                    ))}
                                            </div>
                                            <div className="flexContainer" ref={this.Ref_flex_male}>
                                                 {this.state.renderObjs.map(suit => (     
                                                                                       (suit.gender==='male') ? (  
                                                                                                 
                                                                                           <Card onClick={() =>  this.onClickSuit(suit) /*this.ARfittingSubmit(suit)*/ }  style={styles.cardImage2}>
                                                                                            
                                                                                            <Image src={suit.imageUrl} style={styles.cardImage}/>
         
                                                                                           </Card>
                                                                                       ) : null
                                                                                    
                                                                                    ))}
                                            </div>    
                                          </>
                                        ) : null

        
              }
        
        
        
        
            </div>
      
      

    
      
      
        </section>

        {/*================End Feature Area ================= */}
    
    



      </div>
    );

  }
}

const styles = {

  container: {
    /*
    marginRight: 30,
    marginBottom: 30,
    marginLeft: 30,
    minHeight: 'unset'
    */
  },
  showXAR: {
    marginTop: 10,
    //width:'96vw'
  },
 maincard: {

    height: 'unset',
    width: 'unset',
    maxHeight: '75vh'
   

  },
  xarImg: {
    transform: 'scale(1.0)'
  },
  cardImage2: {

    flexShrink: '0',
    width: '150px',
    height: '100%',
    borderRadius: '10px',
    marginLeft: '10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',

    margin: '1em 0',
  },  
};

export default connect(mapState, mapDispatch)(XARdemoShow);



  