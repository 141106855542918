import React from "react";
import { Card } from "semantic-ui-react";
import { ProductCard, EmptyProduct } from "../../components";
//import './index.css';

//<Card.Group itemsPerRow={6}>
const ProductGrid = ({ products, category }) => {
  if (products && products.length < 1) {
    return <EmptyProduct />;
  } else {
    return (
      <Card.Group itemsPerRow={2} centered className="products-wrapper" style={styles.productGrid}>
        {products.map(product => (
          <ProductCard key={product.id} product={product} category={category}/>
        ))}
      </Card.Group>
    );
  }
};

const styles = {
  productGrid: {
    marginLeft: '-0.5em',
    marginRight: '-0.5em',
     
  }
 
};

export default ProductGrid;
