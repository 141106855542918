import axios from 'axios'
import store from '../../store';
/**
 * ACTION TYPES
 */
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const GET_GENDER_CATEGORIES = 'GET_GENDER_CATEGORIES'
/**
 * INITIAL STATE
 */
const initialState = [{id: 0, name: ''}]


/**
 * ACTION CREATORS
 */

const getCategories = categories => ({
  type: GET_ALL_CATEGORIES,
  categories
})

const getCategoriesGender = categories => ({
  type: GET_GENDER_CATEGORIES,
  categories
})

/**
 * THUNK CREATORS
 */
export const fetchCategories = () => {
  return async dispatch => {

    const body = {
                  databaseName: store.getState().project.projectName,
                  userId: store.getState().project.userId
                  }

    const {data} = await axios.post(`/api/categories`, body)
    dispatch(getCategories(data))



  }
}

export const fetchCategoriesGender = () => {
  return async dispatch => {

    const body = {
                  databaseName: store.getState().project.projectName,
                  }

    const {data} = await axios.post(`/api/categories/gender`, body)
    dispatch(getCategoriesGender(data))
  }
}
/**
 * REDUCER
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_GENDER_CATEGORIES:
      return action.categories
    case GET_ALL_CATEGORIES:
      return action.categories
    default:
      return state
  }
}
