import Camera, { FACING_MODES, IMAGE_TYPES } from './components';
import { getVideoStyles, getShowHideStyle } from './components';

export {
  Camera,
  FACING_MODES,
  IMAGE_TYPES, 

  getVideoStyles, 
  getShowHideStyle
};

export default Camera;
