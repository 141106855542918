import React, { useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CategoryList, Search } from "../../components";
//import { CategoryList } from "components";
//import { Search } from "../index.js";

import { Menu, Button, Icon, Dropdown } from "semantic-ui-react";
import { logout } from "../../store";

import "./navbar.css";


const mapState = state => {
  return {
    isLoggedIn: !!state.user.id,
    isAdmin: state.user.isAdmin,

    project: state.project,
    projectName: state.project.projectName,

    
  };
};


const mapDispatch = dispatch => {
  return {
    handleClick: () => {
      dispatch(logout());
    },
    
    

  };
};


export const Navbar = ({ handleClick, isLoggedIn, isAdmin, project, projectName, windowWidth}) => {

  const [activeItem, setActiveItem] = useState("MetaFitting");

  const handleItemClick = (e, { name }) => setActiveItem(name);

  
  console.log("activeItem", activeItem)


  return (
  <Menu secondary>
      <Link to={`/`+ projectName + `/MetaFitting`} >
          <img
            src="/images/vismile.gif"
            alt="Vismile logo"
            
            style={ project.onDemoSite ? styles.logoDemo : styles.logo}
          />
      </Link>
      <div style={styles.list}>
          {project.onList ? <CategoryList /> : null}
      </div>  


     
     
      <Menu.Menu position="right" style={ project.onDemoSite ? (windowWidth>992) ? styles.demoSiteMenuLarge 
                                                                                 : styles.demoSiteMenuSmall 
                                                             : styles.rightMenu}>
        
          {project.onSearch ? <Search style={styles.search}/> : null}
        
          {project.onDemoSite ? 
                              (windowWidth>992) ? ( <>
                                                      <Menu.Item href='//vismile.com/aichure/MetaFitting'           name='MetaFitting' active={activeItem === 'MetaFitting'} onClick={handleItemClick} > META FITTING </Menu.Item>
                                                      <Menu.Item href='//vismile.com.tw/en/AI-virtual-fitting.html' name='AIfitting'   active={activeItem === 'AIfitting'  } onClick={handleItemClick} > AI VIRTUAL FITTING </Menu.Item>
                                                      <Menu.Item href='//vismile.com.tw/en/ARmirror.html'           name='ARmirror'    active={activeItem === 'ARmirror'   } onClick={handleItemClick} > AR MIRROR </Menu.Item>
                                                      <Menu.Item href='//vismile.com.tw/en/Vismile.html'            name='Vismile'     active={activeItem === 'Vismile'    } onClick={handleItemClick} > VISMILE </Menu.Item>
                                                  </>)

                                                : (
                                                    <Dropdown item icon='bars'  className={"right"} style={styles.demoSiteDropdownSmall}>
                                                        <Dropdown.Menu position="right" className={"rightMenu"} style={styles.demoSiteDropdownMenuSmall}> 
                                                                <Dropdown.Item style={styles.demoSiteItem} href='//vismile.com/aichure/MetaFitting'           name='MetaFitting' active={activeItem === 'MetaFitting'} onClick={handleItemClick} > META FITTING </Dropdown.Item>
                                                                <Dropdown.Item style={styles.demoSiteItem} href='//vismile.com.tw/en/AI-virtual-fitting.html' name='AIfitting'   active={activeItem === 'AIfitting'  } onClick={handleItemClick} > AI VIRTUAL FITTING </Dropdown.Item>
                                                                <Dropdown.Item style={styles.demoSiteItem} href='//vismile.com.tw/en/ARmirror.html'           name='ARmirror'    active={activeItem === 'ARmirror'   } onClick={handleItemClick} > AR MIRROR </Dropdown.Item>
                                                                <Dropdown.Item style={styles.demoSiteItem} href='//vismile.com.tw/en/Vismile.html'            name='Vismile'     active={activeItem === 'Vismile'    } onClick={handleItemClick} > VISMILE </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                  )      
                              : null }

          {project.onAccount ? 
                                  isLoggedIn ? (
                                                  <Menu.Item>
                                                      {  isAdmin ? (
                                                                      <Dropdown button text="Account">
                                                                        <Dropdown.Menu>
                                                                          <Dropdown.Item as={Link} to="/account/info">
                                                                            Account Info
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item as={Link} to="/account/orderhistory">
                                                                            Order History
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item as={Link} to="/admin/account/manage">
                                                                            Manage Accounts
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item as={Link} to="/admin/orders/manage">
                                                                            Manage Orders
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    ) 
                                                                  : (
                                                                      <Dropdown button text="Account">
                                                                        <Dropdown.Menu>
                                                                          <Dropdown.Item as={Link} to={projectName+"/account/info"}>
                                                                            Account Info
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item as={Link} to={projectName+"/account/orderhistory"}>
                                                                            Order History
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    )
                                                        }

                                                        <Button onClick={handleClick}>Logout</Button>

                                                  </Menu.Item>
                                                ) 
                                              : (
                                                    <Menu.Item> 
                                                        <Button disabled as={Link} to={projectName+"/signup"} primary> {/*temporary disabled for testing*/}
                                                            Sign Up          
                                                        </Button>
                                                        <Button as={Link} to={projectName+"/login"}>
                                                            Login
                                                        </Button>
                                                     
                                                      {/*
                                                        <Button animated="vertical" as={Link} to={projectName+"/cart"} style={styles.cart}>
                                                          <Button.Content hidden>Cart</Button.Content>
                                                          <Button visible>
                                                            <Icon name="shopping cart" />
                                                          </Button>
                                                        </Button>
                                                     */}
                                                    </Menu.Item>
                                                )
                                


                           : null
          }


        
      </Menu.Menu>
    
  </Menu>
  
);
}

const styles = {
  logo: {
    marginTop: "0px",
    marginLeft: "3vw", 
    width: "25vw",
    maxWidth: "1240px",
    
  },
  logoDemo: {
    marginTop: "0px",
    marginLeft: "1rem", 
    width: "25vw",
    maxWidth: "130px",
    paddingBottom: ".3125rem"
  },  
  list: {
    alignSelf: 'baseline',
    paddingTop: '15px',

  },
  rightMenu: {
    flexWrap: 'wrap',
    width: '35%',
    justifyContent: 'flex-end',
  },
  demoSiteMenuLarge: {

    margin: 'auto',
                         
  },
  demoSiteMenuSmall: {

    margin: 'auto',
    width: '100vw',                            
  },
  demoSiteDropdownSmall:{
    background: 'rgba(0,0,0,0)',
    color: 'rgba(11,16,51,1)',
    lineHeight: '1.3',
    verticalAlign: 'middle',
    fontSize: '1.5em',
  },
  demoSiteDropdownMenuSmall:{



  },
  demoSiteItem: {

    textAlign: "end",
    width: "100vw", 

  },
  
  cart: {
    padding: '0px',

  },
  search: {
    width: '40%'
  }
};

export default connect(mapState, mapDispatch)(Navbar);

Navbar.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};
