//Dependencies
import React from 'react';
import {NavLink} from 'react-router-dom';
import { connect } from "react-redux";


//Internals
import './header.css';

const mapState = state => ({
  headers: state.headers,
  project: state.project
});

 

const Header = ({ headers, project }) => {

	console.log("headers.length", headers.length)
	console.log("headers", headers)

  return (
	  <div className="baselayout-header">
	    <h1 id="header-title">Shop Now</h1>
	    <div className="links-header">
	      
	      {headers.length > 1 ? (
	        headers.map(header => (
	          <p><NavLink activeClassName="selected" className="nav-link-header" to={`${project.projectName}/catalog/${header.category.id}/gender/${-header.category.genderID}/products/`}>{header.description}</NavLink></p>
	        ))
	      ) : (
	        <div>No Categories</div>
	      )}	


	    </div>
	  </div>
  );
};

/*
      <p><NavLink activeClassName="selected" className="nav-link-header" to={`/catalog/${id}/products/`}>Women</NavLink></p>
      <p><NavLink activeClassName="selected" className="nav-link-header" to="/men">Men</NavLink></p>
      <p><NavLink activeClassName="selected" className="nav-link-header" to="/clothes">Clothes</NavLink></p>
      <p><NavLink activeClassName="selected" className="nav-link-header" to="/accessories">Accessories</NavLink></p>
*/
export default connect(mapState)(Header);
