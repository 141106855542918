import React from "react";
import { Link } from "react-router-dom";
import { Card, Image, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";

import './categories.css';

const mapState = state => ({
  user: state.user,
  project: state.project
});

const CategoryCard = ({ id, name, description, imageUrl, user, categoryGenderMode, HierachyCategoryCatalog, gender, genderID, project}) => {

  const projectName = project.projectName
  //href for item click redirect
  var href =""

  if( HierachyCategoryCatalog===0 && categoryGenderMode==0 ){
      //no gender category and redirect to product in category
      href = `/${projectName}/catalog/${id}/gender/${-genderID}/products`;

  }else if( HierachyCategoryCatalog===0 && categoryGenderMode===1 ){
      //show gender and redirect to category in gender     
      href = `/${projectName}/gender/${genderID}/categories`;

  }else if( HierachyCategoryCatalog===0 && categoryGenderMode===2 ){
      //show gender and redirect to category in gender     
      href = `/${projectName}/catalog/${id}/gender/${genderID}/products`;




  }else if( HierachyCategoryCatalog===1 && categoryGenderMode===1 ){
      //show gender and redirect to category in gender     
      href = `/${projectName}/catalog/${id}/gender/${genderID}/products`;

  }else if( HierachyCategoryCatalog===1 && categoryGenderMode===2 ){
      //show category in gender and redirect to product in category
      href = `/${projectName}/catalog/${id}/gender/${genderID}/products`;

  }


  //let href = categoryGenderMode ?  `/${projectName}/catalog/${genderID}/${id}/products/` : `/${projectName}/gender/${genderID}/categories`
  //console.log("projectName@CategoryCard", projectName)
  //console.log("categoryGenderMode@CategoryCard", categoryGenderMode)
  //console.log("gender@CategoryCard", gender)
  //console.log("href@CategoryCard",id, href)

  return (
    /* <Card as={Link}  to={`/catalog/${id}/products/`} style={styles.card}> */

    <Card as={Link}  to={href} style={styles.card}>
      <Image src={imageUrl} className="product-img" style={styles.image} />
      {/*<Card.Content className="items-title">*/}
      
        <Card.Header className="items-title">
          {/*<Link to={`/catalog/${id}/products/`}>{name}</Link>*/}
          <Link to={href} style={styles.itemDescription} >{description}</Link>
          {user.isAdmin && id !== 0 ? (
            <Button
              as={Link}
              to={`/admin/category/edit/${id}`}
              icon
              style={styles.button}
            >
              <Icon name="edit" />
            </Button>
          ) : (
            ""
          )}
        </Card.Header>
      
    {/*</Card.Content>*/}
    </Card>
  );
};

const styles = {
  button: {
    marginLeft: 10
  },
  card: {
    height: '100%',/*unset*/
    width: 'calc(50% - 4em)',
    //minWidth: '290px'
  },
  image: {
    objectFit: 'contain',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%'
  },  
  itemDescription:{
    
    color: '#545454'
  }  
};

export default connect(mapState)(CategoryCard);
