import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from "semantic-ui-react";
import './styles/circleButton.css';

export const CamButton = ({ onClick, isClicked, isCountdown, camBtnPosSel }) => {
  const innerCircleClasses = isClicked ? 'is-clicked' : '';
  console.log("isClicked: ", isClicked);
  return (
    
    <div id="container-circles"  style =  { camBtnPosSel ?  isCountdown ? styles.container_unshown_right : styles.container_show_right
                                                           :  isCountdown ? styles.container_unshown_left  : styles.container_show_left  }  >
      <Button
        className={innerCircleClasses}
        onClick={(e) => {
                          if (!isClicked) {
                            onClick();
                          }
                        }
                }
        style={ styles.button}
        size="huge" 
        circular
      >
        <Icon name=" camera " 
              style={
                     { color: 'rgb(255, 112, 112)',
                       margin: '0 0 0 0'}
                    }  
              size="huge"/> 
      </Button>
    </div>

  );
};

const styles = {

  button: {
    width:'150px',
    height:'150px'


  },
  container_show_right: {
    display: 'unset',   
    right: '45%',
    top: '30%'    
  },

  container_show_left: {
    display: 'unset',   
    right: '89.5%',
    top: '15%'    
  },
  container_unshown_right: {
    display: 'none',
    right: '45%',
    top: '30%'        
  },
  container_unshown_left: {
    display: 'none',
    right: '89.5%',
    top: '15%'        
  }

};

CamButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isClicked: PropTypes.bool.isRequired,
  isCountdown: PropTypes.bool.isRequired,
  camBtnPosSel: PropTypes.bool.isRequired
};

export default CamButton;
