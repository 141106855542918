import React, { Component } from "react";
import ReactAvatarEditor from "./ReactAvatarEditor";
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'

import { connect } from "react-redux";
import { updateMaterials } from "../../store";
import {
  Grid,
  Form
} from "semantic-ui-react";



const mapState = state => ({

  packs: state.packs

});

const mapDispatch = dispatch => {
  return {

    updateImage: (pack) => dispatch(updateMaterials(pack))

  };
};



class SewingUploadApp extends Component {
  state = {
    image: null,
    maskImage: null,
    allowZoomOut: true,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 0,
    border:0,
    preview: null,
    width: 2048,
    height: 2048,
    flip: false,

    isUpdate: false
  }

  static propTypes = {
    patternList: PropTypes.arrayOf(
      PropTypes.shape({
        maskImage: PropTypes.string,
        id: PropTypes.number
      })
    ),
    imgList: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
    render_id: PropTypes.number,
    onClickMask: PropTypes.func,
    onLoadImg: PropTypes.func,
    isReset: PropTypes.bool,
    onSetImg: PropTypes.func

  }

  static defaultProps = {
    patternList: [{
        maskImage: "",
        id: 0
      }],
    imgList: [],
    id: 0,
    render_id: 0,
    onClickMask() {},
    onLoadImg() {},
    isReset: false,
    onSetImg() {}
  }
 
  componentDidUpdate(prevProps) {
    console.log('@componentDidUpdate prevProps:', prevProps)
    console.log('@componentDidUpdate Props:', this.props)


    
      const { packs, updateImage } = this.props;
    
      if(packs.isReady && !packs.isUpload && !this.state.isUpdate){
      

        console.log("packs.isReady: ", packs.isReady);
        console.log("this.props.id: ", this.props.id);
        console.log("packs.ImgReady[this.props.id]: ", packs.ImgReady[this.props.id]);
      
        const img = this.editor.getImageScaledToCanvas().toDataURL("image/jpeg", 1);
        packs.imglist[this.props.id] = img;
        packs.ImgReady[this.props.id] = true;
        updateImage(packs);


        this.setState({ isUpdate: true })

      }

   




  }

/*
  onSaveImg = (isReady) => {
      const { packs, updateImage } = this.props;
    
      console.log("packs.isReady: ", packs.isReady);
      console.log("this.props.id: ", this.props.id);
      console.log("packs.ImgReady[this.props.id]: ", packs.ImgReady[this.props.id]);
    
      const img = this.editor.getImageScaledToCanvas().toDataURL("image/jpeg", 1);
      packs.imglist[this.props.id] = img;
      packs.ImgReady[this.props.id] = true;
      updateImage(packs);

     
    
    
  }
*/
  handleNewImage = e => {
    const { onLoadImg } = this.props
    this.setState({ image: this.state.image }) //re-render

    const fileType = e.target.files[0]['type'];
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (validImageTypes.includes(fileType)) {
      onLoadImg(window.URL.createObjectURL(e.target.files[0]))
    }
  }

  LoadExistedImg = (url) => {
    if(this.state.image == null){
      this.props.onSetImg();
    }
    this.setState({ image: url })
  }
  
  handleNewMaskImage = e => {
    this.setState({ maskImage: e.target.files[0] })
  }

  handleSave = data => {
    const img = this.editor.getImageScaledToCanvas().toDataURL("image/jpeg", 1)
    const rect = this.editor.getCroppingRect()

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius,
      },
    })
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut })
  }
  
  handleFlip = ({ target: { checked: flip } }) => {
    this.setState({ flip })
  }

  rotateLeft = e => {
    e.preventDefault()

    this.setState({
      rotate: this.state.rotate - 5,
    })
  }

  rotateRight = e => {
    e.preventDefault()
    this.setState({
      rotate: this.state.rotate + 5,
    })
  }

  handleBorderRadius = e => {
    const borderRadius = parseInt(e.target.value)
    this.setState({ borderRadius })
  }

  handleXPosition = e => {
    const x = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, x } })
  }

  handleYPosition = e => {
    const y = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, y } })
  }
  
  handleRotate = e => {
    const angle = parseFloat(e.target.value)
    this.setState({
      rotate: angle,
    })
  }

  handleWidth = e => {
    const width = parseInt(e.target.value)
    this.setState({ width })
  }

  handleHeight = e => {
    const height = parseInt(e.target.value)
    this.setState({ height })
  }

  logCallback(e) {
    // eslint-disable-next-line
    console.log('callback', e)
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor
  }

  handlePositionChange = position => {
    this.setState({ position })
  }

  handleDrop = acceptedFiles => {
    this.setState({ image: acceptedFiles[0] })
  }

  render() {

    console.log('render @', this.props.id, this.props.isReset )
      
    if(this.props.isReset){
      /*
      this.state.image = null;
      this.state.position = { x: 0.5, y: 0.5 };
      this.state.scale = 1;
      this.state.rotate = 0;
      this.state.flip = false;
      this.state.isUpdate = false;
      */
      this.setState({ image: null })
      this.setState({ position: { x: 0.5, y: 0.5 } })
      this.setState({ scale: 1 })
      this.setState({ rotate: 0 })
      this.setState({ flip: false })
      this.setState({ isUpdate: false })
    }

    //console.log("patternList@SewingUploadApp: ", this.props.patternList);
    if(this.props.id !== this.props.render_id)
      return(null);


/*
    const imageClick = (value) => {
          console.log('Click', value);
        } 
*/
    const MaskPreviewElements = this.props.patternList.map((pattern_data) => (
        <input type="image" src={pattern_data.maskImage}  onClick={() => this.props.onClickMask(pattern_data.id)} width="200" height="200" />
      ));                        

    const ImgPreviewElements = this.props.imgList.map((img_Url) => (
        <input type="image" src={img_Url} onClick={() => this.LoadExistedImg(img_Url)} width="200" height="200" />
      ));

    return (
      <div>
        <Grid columns={2} relaxed='very' stackable>
          <Grid.Column>
            <Dropzone
              onDrop={this.handleDrop}
              disableClick
              multiple={false}
              style={{ width: '100%', height: '100%', marginBottom:'35px' }}
            >
            {dropzoneProps => {
              return (
                <div>
                  <ReactAvatarEditor
                    ref={this.setEditorRef}
                    scale={parseFloat(this.state.scale)}
                    width={this.state.width}
                    height={this.state.height}
                    position={this.state.position}
                    onPositionChange={this.handlePositionChange}
                    rotate={parseFloat(this.state.rotate)}
                    borderRadius={this.state.width / (100 / this.state.borderRadius)}
                    border={this.state.border}
                    onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
                    onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
                    onImageReady={this.logCallback.bind(this, 'onImageReady')}
                    image={this.state.image}
                    maskImage={this.props.patternList[this.props.id].maskImage}
                    className="editor-canvas"
                    flip={this.state.flip}
                    style={{width:'100%', height:'100%'}}
                  />
                </div>
              );
            }}
            </Dropzone>
          </Grid.Column>  
          <Grid.Column >
            <Form.Field>
              Open new picture:
            </Form.Field>
            <Form.Field>  
              <input name="newImage" type="file" onChange={this.handleNewImage} />
            </Form.Field>            
            <Form.Field>
              Clothes pictures:
            </Form.Field>
            {ImgPreviewElements}

            <Form.Field>
              Clothes packs:
            </Form.Field>
            {MaskPreviewElements}


            {/*<br />
            New Mask File:
            <input name="newMaskImage" type="file" onChange={this.handleNewMaskImage} />*/}
            
            <Form.Group inline>
              <label>Flip: </label>
                 
              <input
                name="isFlip"
                type="checkbox"
                onChange={this.handleFlip}
                checked={this.state.flip}
              />
            </Form.Group>
            <Form.Field>  
              Zoom:
            </Form.Field>
            <Form.Field>     
              <input
                name="scale"
                type="range"
                onChange={this.handleScale}
                min={this.state.allowZoomOut ? '0.1' : '1'}
                max="2"
                step="0.01"
                defaultValue="1"
              />
            </Form.Field>
            <Form.Field>   
              X Position:
            </Form.Field>
            <Form.Field>   
              <input
                name="scale"
                type="range"
                onChange={this.handleXPosition}
                min="0"
                max="1"
                step="0.01"
                value={this.state.position.x}
              />
            </Form.Field>
            <Form.Field> 
              Y Position:
            </Form.Field>
            <Form.Field>   
              <input
                name="scale"
                type="range"
                onChange={this.handleYPosition}
                min="0"
                max="1"
                step="0.01"
                value={this.state.position.y}
              />
            </Form.Field>
            <Form.Field> 
              Rotate:
            </Form.Field>
            <Form.Field>   
              <input
                name="rotate"
                type="range"
                onChange={this.handleRotate} //NO use
                min="-180"
                max="180"
                step="1"
                value={this.state.rotate}
              />
            </Form.Field>  


            
          </Grid.Column>
        </Grid>  
  
      
        
        

          {/*
          <input type="button" onClick={this.handleSave} value="Preview" />
          <br />
          {!!this.state.preview && (
            <img
              src={this.state.preview.img}
              style={{
                borderRadius: `${(Math.min(
                  this.state.preview.height,
                  this.state.preview.width
                ) +
                  10) *
                  (this.state.preview.borderRadius / 2 / 100)}px`,
              }}
            />
          )}
        */}
      </div>
    );
  }
}

// Used to display the cropping rect
export default connect(mapState, mapDispatch)(SewingUploadApp);

//ReactDOM.render(<App />, document.getElementById('app'))
