import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  ProductForm,
  CategoryForm,
  Home,
  UserOrderHistory,
  AccountPage,
  AccountManagement,
  EditAccount,
  OrderManagement
} from "../components";

const AdminRoutes = () => {
  return (

    <Switch>
      
      {console.log("AdminRoutes")}

      {/* Routes only admins have access to*/}
      <Route exact path="/:projectName/admin/products/add"      component={ProductForm} />
      <Route exact path="/:projectName/admin/category/add"      component={CategoryForm} />
      <Route exact path="/:projectName/admin/account/manage"    component={AccountManagement} />
      <Route exact path="/:projectName/admin/orders/manage"     component={OrderManagement} />
      <Route exact path="/:projectName/admin/products/edit/:id" render={routeProps => <ProductForm id={routeProps.match.params.id} />} />
      <Route exact path="/:projectName/admin/product/edit/:id"  render={routeProps => <ProductForm id={routeProps.match.params.id} />} />
      <Route exact path="/:projectName/admin/category/edit/:id" render={routeProps => <CategoryForm id={routeProps.match.params.id} />} />
    
      <Route exact path="/:projectName/account/:id/info/edit" component={EditAccount} />
      <Route exact path="/:projectName/account/info" component={AccountPage} />
      <Route exact path="/:projectName/account/orderhistory" component={UserOrderHistory} />
      <Route exact path="/:projectName" component={Home} />

    </Switch>
  );
};

export default AdminRoutes;
