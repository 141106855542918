import React, { Component } from "react";
import { connect } from "react-redux";
import { findPacks, fetchPacks } from "../../store";
import PropTypes from 'prop-types'

import {
  
  Button,
  Form
} from "semantic-ui-react";


const mapState = state => ({
  packs: state.packs,
  
  //recommended: state.recommended,

  user: state.user,
  cart: state.cart
});


const mapDispatch = dispatch => ({

  FetchPacks: () => dispatch(fetchPacks()),
  FindPacks: (packs_props) => dispatch(findPacks(packs_props))
  
});


class Tshirt_Item extends Component {
  state = {
    sleeve_type : "Standard",
    sleeve_length : "Short",
    shape : "U_neck",
    gender:0,
    collar : false,
    hem : false,
    slit_side : false,
    send: false
  }


  static propTypes = {
    handle_Submit: PropTypes.func
  }

  static defaultProps = {
    handle_Submit() {}
  }

  componentDidMount() {
   

  }


  handle_Submit = e => {
    const { FindPacks } = this.props;
    let packs_props = {

      sleeve_type : this.state.sleeve_type,
      sleeve_length : this.state.sleeve_length,
      shape : this.state.shape,
      gender: this.state.gender,
      collar : this.state.collar,
      hem : this.state.hem,
      slit_side : this.state.slit_side
     
    }

    
    console.log("packs_props: " + packs_props);
    console.log("sleeve_type: " + packs_props.sleeve_type);
    console.log("sleeve_length: " + packs_props.sleeve_length);
    console.log("shape: " + packs_props.shape);
    console.log("gender: " + packs_props.gender);
    console.log("collar: " + packs_props.collar);
    console.log("hem: " + packs_props.hem);
    console.log("slit_side: " + packs_props.slit_side);
    FindPacks(packs_props);

    this.setState({ send: true });

    this.props.handle_Submit();

    console.log("handle_Submit");
  }

  handle_Gender = e => {
    if(e.target.value === "Male"){
        this.setState({ gender:1 })
    }else if(e.target.value === "Female"){
        this.setState({ gender:0 })
    }
  }
  
  handle_Sleeve_type = e => {
    this.setState({ sleeve_type:e.target.value })
    if (e.target.value === "Drop" && this.state.sleeve_length === "Long")
        this.setState({ sleeve_length:"Short" })
  }
  
  handle_Sleeve_length = e => {
    if(this.state.sleeve_type === "Drop" && e.target.value === "Long")
        return;
    this.setState({ sleeve_length:e.target.value })
  }
  
  handle_Shape = e => {
    this.setState({ shape:e.target.value })
  }
  
  handle_Collar = ({ target: { checked: collar } }) => {
    this.setState({ collar })
  }
  
  handle_Hem = ({ target: { checked: hem } }) => {
    this.setState({ hem })
    if(this.state.slit_side){
        this.setState({ hem : false })
    }
  }
  
  handle_Slit_side = ({ target: { checked: slit_side } }) => {
    this.setState({ slit_side })
    if(slit_side){
        this.setState({ hem : false })
    }
  }

  render() {
    console.log("this: ", this)

    return (
      <div className="show-product ui raised very padded text segment">


      <Form style={styles.formField}>           
        <Form.Field>
          Gender: 
        </Form.Field>
        <Form.Field>
          <input className="ui"
            name="gender"
            type="radio"
            onChange={this.handle_Gender}
            value="Male"
            checked={this.state.gender === 1}
          />Male
        </Form.Field>
        <Form.Field>
          <input
            name="gender"
            type="radio"
            onChange={this.handle_Gender}
            value="Female"
            checked={this.state.gender === 0}
          />Female
       </Form.Field>
      </Form>


      <Form style={styles.formField}>   
        
        <Form.Field>
          Sleeve Type: 
        </Form.Field>
        <Form.Field>  
          <input
            name="sleeve_type"
            type="radio"
            onChange={this.handle_Sleeve_type}
            value="Standard"
            checked={this.state.sleeve_type === "Standard"}
          />Standard
        </Form.Field>  
        <Form.Field>  
          <input
            name="sleeve_type"
            type="radio"
            onChange={this.handle_Sleeve_type}
            value="Lackland"
            checked={this.state.sleeve_type === "Lackland"}
          />Lackland
        </Form.Field>  
        <Form.Field>  
          <input
            name="sleeve_type"
            type="radio"
            onChange={this.handle_Sleeve_type}
            value="Drop"
            checked={this.state.sleeve_type === "Drop"}
          />Drop
        </Form.Field>  
      </Form>  


      <Form style={styles.formField}>   
        <Form.Field>
          Sleeve Length: 
        </Form.Field>
        <Form.Field>
          <input
            name="sleeve_length"
            type="radio"
            onChange={this.handle_Sleeve_length}
            value="Short"
            checked={this.state.sleeve_length === "Short"}
          />Short
        </Form.Field>
        <Form.Field>
          <input
            name="sleeve_length"
            type="radio"
            onChange={this.handle_Sleeve_length}
            value="Medium"
            checked={this.state.sleeve_length === "Medium"}
          />Medium
        </Form.Field>
        <Form.Field>
          <input
            name="sleeve_length"
            type="radio"
            onChange={this.handle_Sleeve_length}
            value="Long"
            checked={this.state.sleeve_length === "Long"}
          />Long
        </Form.Field>
      </Form>  

      <Form style={styles.formField}>   
        <Form.Field>
          Shape: 
        </Form.Field>
        <Form.Field>
          <input
            name="shape"
            type="radio"
            onChange={this.handle_Shape}
            value="U_neck"
            checked={this.state.shape === "U_neck"}
          />U-neck
        </Form.Field>
        <Form.Field>
          <input
            name="shape"
            type="radio"
            onChange={this.handle_Shape}
            value="U_neck-slit"
            checked={this.state.shape === "U_neck-slit"}
          />U-neck slit
        </Form.Field>
        <Form.Field>
          <input
            name="shape"
            type="radio"
            onChange={this.handle_Shape}
            value="V_neck"
            checked={this.state.shape === "V_neck"}
          />V-neck
        </Form.Field>
        <Form.Field>
          <input
            name="shape"
            type="radio"
            onChange={this.handle_Shape}
            value="High_U_neck"
            checked={this.state.shape === "High_U_neck"}
          />High U-neck
        </Form.Field>
      </Form>   


      <Form style={styles.formField}>  
        <Form.Field>
          Options: 
        </Form.Field>
        <Form.Field>
          <input
            name="isCollar"
            type="checkbox"
            onChange={this.handle_Collar}
            checked={this.state.collar}
          />Collar
        </Form.Field>
        <Form.Field>
          <input
            name="isHem"
            type="checkbox"
            onChange={this.handle_Hem}
            checked={this.state.hem}
          />Hem
        </Form.Field>
        <Form.Field>
          <input
            name="isSlit_side"
            type="checkbox"
            onChange={this.handle_Slit_side}
            checked={this.state.slit_side}
          />Slit side
        </Form.Field>
      </Form>    

{/*
        
          <Form style={styles.formField}>          
            <Form.Field>
                Gender: 
            </Form.Field>
            <Form.Field >
              <Checkbox
                radio
                label='Male'
                name='gender'
                value='Male'
                checked={this.state.gender === 1}
                onChange={this.handle_Gender}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                label='Female'
                name='gender'
                value='Female'
                checked={this.state.gender === 0}
                onChange={this.handle_Gender}
              />
            </Form.Field>
          </Form>


          <Form style={styles.formField}>
            <Form.Field>
                Sleeve Type: 
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                label='Standard'
                name='sleeve_type'
                value='Standard'
                checked={this.state.sleeve_type === "Standard"}
                onChange={this.handle_Sleeve_type}
              />
            </Form.Field>          
            <Form.Field>
              <Checkbox
                radio
                label='Lackland'
                name='sleeve_type'
                value='Lackland'
                checked={this.state.sleeve_type === "Lackland"}
                onChange={this.handle_Sleeve_type}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                label='Drop'
                name='sleeve_type'
                value='Drop'
                checked={this.state.sleeve_type === "Drop"}
                onChange={this.handle_Sleeve_type}
              />
            </Form.Field>
          </Form>


          <Form style={styles.formField}>
            <Form.Field>
                Sleeve Length: 
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                label='Short'
                name='sleeve_length'
                value='Short'
                checked={this.state.sleeve_length === "Short"}
                onChange={this.handle_Sleeve_length}
              />
            </Form.Field>          
            <Form.Field>
              <Checkbox
                radio
                label='Medium'
                name='sleeve_length'
                value='Medium'
                checked={this.state.sleeve_length === "Medium"}
                onChange={this.handle_Sleeve_length}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                label='Long'
                name='sleeve_length'
                value='Long'
                checked={this.state.sleeve_length === "Long"}
                onChange={this.handle_Sleeve_length}
              />
            </Form.Field>
          </Form>



          <Form style={styles.formField}>
            <Form.Field>
                Shape: 
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                label='U-neck'
                name='Shape'
                value='U_neck'
                checked={this.state.sleeve_length === "U_neck"}
                onChange={this.handle_Shape}
              />
            </Form.Field>    
            <Form.Field>
              <Checkbox
                radio
                label='U-neck slit'
                name='Shape'
                value='U_neck-slit'
                checked={this.state.sleeve_length === "U_neck-slit"}
                onChange={this.handle_Shape}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                label='V-neck'
                name='Shape'
                value='V_neck'
                checked={this.state.sleeve_length === "V_neck"}
                onChange={this.handle_Shape}
              />
            </Form.Field>                
            <Form.Field>
              <Checkbox
                radio
                label='High U-neck'
                name='Shape'
                value='High_U_neck'
                checked={this.state.sleeve_length === "High_U_neck"}
                onChange={this.handle_Shape}
              />
            </Form.Field>
          </Form>


          <Form style={styles.formField}>
            <Form.Field>
                Options: 
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                name='isCollar'
                value='isCollar'
                label='Collar'
                checked={this.state.collar}
                onChange={this.handle_Collar}
              />
            </Form.Field>          
            <Form.Field>
              <Checkbox
                radio
                name='isHem'
                value='isHem'
                label='Hem'
                checked={this.handle_Hem}
                onChange={this.handle_Hem}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                radio
                name='isSlit_side'
                value='isSlit_side'
                label='Slit side'
                checked={this.state.slit_side}
                onChange={this.handle_Slit_side}
              />
            </Form.Field>           
          </Form>

   */}


        <Button type="submit"  onClick={this.handle_Submit} >
           Submit
        </Button>



{/*
        <br />
        <input type="button" onClick={this.handle_Submit} value="Submit" />
        <br />
*/}


      </div>





    )
  }
}

const styles = {

  formField: {
    width: '8%',
    height: '13rem'
  },
  downloadButton: {
    
  }

};

export default connect(mapState, mapDispatch)(Tshirt_Item);
