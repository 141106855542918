import Camera, { FACING_MODES, IMAGE_TYPES } from './Camera';
import { getVideoStyles, getShowHideStyle } from './Camera/helpers';

export {
  Camera,
  FACING_MODES,
  IMAGE_TYPES,

  getVideoStyles, 
  getShowHideStyle
};

export default Camera;
