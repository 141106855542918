import axios from "axios";
import produce from 'immer';
import store from '../../store';

/**
 * ACTION TYPES
 */
export const GET_MIRROR = 'GET_MIRROR'


/**
 * INITIAL STATE
 */
const defaultMirror = {}

/**
 * ACTION CREATORS
 */

const getMirror = MirrorData => ({type: GET_MIRROR, MirrorData});

/**
 * THUNK CREATORS
 */
export const fetchMirror = (mirror) => {
  return async dispatch => {
    
    //console.log("project@before fetchProject", project)

    const body = {
                  databaseName:  mirror.projectName,
                  userId: mirror.userId
                  
                 }

    const {data} = await axios.post(`/api/mirror`, body)

    dispatch(getMirror(data))
     
  }
}

/**
 * REDUCER
 */
export default function(state = defaultMirror, action) {
  switch (action.type) {
    case GET_MIRROR:
      return action.MirrorData
 
    default:
      return state
  }
}