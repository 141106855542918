import React, { Component } from "react";
import { ProductGrid } from "../../components";
import { connect } from "react-redux";
import { fetchProducts, fetchCategory, updateProject } from "../../store";
import {
  Header,
  Container,
  Button,
  Pagination,
  Divider
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import './products.css';

const mapState = state => ({

  project: state.project,
  products: state.products,
  category: state.category,
  user: state.user
});

const mapDispatch = dispatch => ({

  setProject: (params) => dispatch(updateProject(params)),

  getProducts: () => dispatch(fetchProducts()),
  getCategory: id => dispatch(fetchCategory(id))
});

class ProductCatalog extends Component {


  constructor(props) {
    super(props);

    const projectName = this.props.match.params.projectName.toLowerCase();
    const { project, setProject } = this.props;
    var params = project;   
    params.projectName = projectName


    this.state = {
      
      projectName: projectName,

      readyRender: false,

      gender: '',  

      renderProducts: null    

    };
    console.log("params@ProductCatalog: ", params)
    setProject(params);
    

    
  }




  componentDidMount() {
    const { getProducts, getCategory } = this.props;
    const categoryId = Number(this.props.match.params.categoryId);
    const genderId = Number(this.props.match.params.genderId);
    

    if(genderId==1 || genderId==-1){

        this.setState({gender: "male"})

    }else if(genderId==2 || genderId==-2){

        this.setState({gender: "female"})
        
    }

    getProducts();
    getCategory(categoryId);
    console.log("getProducts, getCategory@componentDidMount@ProductCatalog", categoryId)
  }

  componentDidUpdate(prevProps, prevState) {
    const categoryId = Number(this.props.match.params.categoryId);
    const { getProducts, getCategory, category } = this.props;
    

    if (this.props.category && (this.props.category !== prevProps.category) ) {

      console.log("this.props.category", this.props.category)
      console.log("prevProps.category", prevProps.category)
      
      //if (category && categoryId !== 0) {
        //console.log("categoryId: ", categoryId)
        //console.log("category.name: ", category.name)
        
        if(category.name==='Men' || category.name==='Women'){
          this.setState({renderProducts: category.suits})
          //renderProducts = category.suits; //render suits for category as Men/Women
          //renderProducts = category.products;
        }else{
          this.setState({renderProducts: category.products.filter(o => o.gender === this.state.gender)})
          //renderProducts = category.products.filter(o => o.gender === gender);
        }

        this.setState({readyRender: true})

      //}


    }
    /*
    if(category){
        if (categoryId !== category.id) {
          getCategory(categoryId);
        }
        if (categoryId === 0 && category.id !== 0) {
          getProducts();
        }
    }
    */
  }

  render() {
    const { products, user, category } = this.props;
    
    




      return (
        <Container style={styles.container}>
          <Header as="h1" className="catalog-header">
            {this.state.readyRender && category.name ? category.name : "All Products"}
            {user.isAdmin ? (
              <Button
                as={Link}
                to="/admin/products/add"
                basic
                color="green"
                style={styles.button}
              >
                Add A Product
              </Button>
            ) : (
              ""
            )}
          </Header>

          {this.state.readyRender ? <ProductGrid products={this.state.renderProducts} category={category} /> : ''}
          
          <Divider />
          {/*
          <Container textAlign="center">
            <Pagination defaultActivePage={1} totalPages={3} />
          </Container>
        */}
        </Container>
      );
    
  }
}

const styles = {
  container: {
    margin: 30,
    width: '65%'
  },
  button: {
    marginLeft: 20
  }
};

export default connect(mapState, mapDispatch)(ProductCatalog);
