import React, { Component } from "react";
import { postCart, putCart, postGuestCart, putGuestCart, fetchReviewsOfProduct } from "../../store";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Image, Rating, Button, Icon, Grid } from "semantic-ui-react";

const mapState = state => ({
  project: state.projec,
  user: state.user,
  cart: state.cart,
  reviews: state.reviews
});

const mapDispatch = dispatch => ({
  addToCart: input => dispatch(postCart(input)),
  editProductQuantity: data => dispatch(putCart(data)),
  addToGuestCart: product => dispatch(postGuestCart(product)),
  editGuestQuantity: productId => dispatch(putGuestCart(productId)),
  getProductReviews: id => dispatch(fetchReviewsOfProduct(id))
});

class RecommendCard extends Component {
  constructor() {
    super();
    this.state = {
      inCart: false,
      inventoryReq: 0,
      isGuest: true
    };
  }

  componentDidMount() {
    const { user, cart, product, getProductReviews } = this.props;
    const { id } = product;

    getProductReviews(id);
    // if user is logged in, set isGuest to false
    if (user.id) {
      this.setState({ isGuest: false });
    }

    cart.forEach(elem => {
      if (elem.productId === id) {
        this.setState({ inCart: true, inventoryReq: elem.inventoryReq });
      }
    });
  }

  addToCartSubmit(productId, userId) {
    const {
      addToCart,
      editProductQuantity,
      addToGuestCart,
      editGuestQuantity,
      product
    } = this.props;
    let { inCart, inventoryReq, isGuest } = this.state;
    const quantity = inventoryReq + 1;

    // if the product isn't in the cart && it's a guest
    if (!inCart && isGuest) {
      addToGuestCart(product);
      this.setState({ inCart: true, inventoryReq: 1 });
    }

    // if product is inCart and the user is a guest
    if (inCart && isGuest) {
      editGuestQuantity({ productId, inventoryReq: quantity });
      this.setState({ inventoryReq: quantity });
    }

    // if the product isn't in the cart && user is logged in
    if (!inCart && !isGuest) {
      addToCart({ productId, userId: userId });
      this.setState({ inCart: true, inventoryReq: 1 });
    }

    // if the product is in the cart && user is logged in
    if (inCart && !isGuest) {
      editProductQuantity({ quantity, productId, userId });
      this.setState({ inventoryReq: quantity });
    }
  }

  renderAddProductButton() {
    const { product, user } = this.props;
    const { inventory } = product;
    const { inventoryReq } = this.state;

    if (inventory === inventoryReq) {
      return (
        <Button
          icon
          compact
          circular
          negative
          disabled
          floated="right"
          size="small"
          style={styles.button}
        >
          <Icon name="x" />
        </Button>
      );
    } else {
      return (
        <Button
          icon
          compact
          circular
          floated="right"
          style={styles.button}
          onClick={() => this.addToCartSubmit(product.id, user.id)}
        >
          <Icon name="add to cart" />
        </Button>
      );
    }
  }

  render() {
    const { project, product, reviews } = this.props;
    const { id, imageUrl, name, price, description } = product;
    if (reviews.length > 0) {
      const average =
        reviews.reduce((accum, currentValue) => accum + currentValue) /
        reviews.length;
    }
    return (
      <Card style={styles.card} as={Link} /* to={`/products/product/${id}`} */>
        <Image src={imageUrl} />
        <Card.Content style={styles.cardContent}>

          <Grid style={styles_grid}>
            <Grid.Row style={styles_row}>
              <Grid.Column width={5}>
                <Card.Header className="product-header">
                    {/*<Link to={`/products/product/${id}`}>{name}</Link>*/}
                    
                </Card.Header>
              </Grid.Column>

              {/*
              <Grid.Column width={11}>
                <Card.Meta className="product-meta">Price: ${price}</Card.Meta> 
                
              </Grid.Column>
            */}
              

            </Grid.Row>

            <Grid.Row>
              {/*
              <Grid.Column width={0}>
                <Card.Header className="product-header">
                 
                      {product ? this.renderAddProductButton() : ""}
                      
                </Card.Header>
                
              </Grid.Column>
              */} {/*
              <Grid.Column width={16}>
                <Card.Description>{description.slice(0, 40)}.</Card.Description>  
                */}{/*<Rating icon="star" defaultRating={4} maxRating={5} disabled />*/}{/*
              </Grid.Column>
              */}
            </Grid.Row>
          </Grid>

        </Card.Content>
      </Card>
    );
  }
}

const styles = {
  button: {
    marginRight: 0,
    float: 'none'
  },
  card: {
    height: 'unset',
    minWidth: '40%'
  },
  cardContent: {
    minHeight: 'unset',
  }
};


const styles_row = {
  paddingBottom: '0', /* Remove padding */
  paddingLeft: '0', /* Remove padding */
  paddingRight: '0', /* Remove padding */
};
const styles_grid = {
  paddingTop: '0', /* Remove padding */
  paddingBottom: '0', /* Remove padding */
};

export default connect(mapState, mapDispatch)(RecommendCard);
