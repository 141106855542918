//Dependencies
import React from 'react';
//Internals
import './footer.css';
import { Image, Grid, Header, List } from "semantic-ui-react";
import Form from "./Form";

const Footer = (project) => (

//api: `http://aichure.com:80/../backendcontact/index.php`,

	
	<div className="footer" style={ project.footerColorSel ? styles.footerColor_01 : styles.footerColor_02} >
	    





	    <Grid divided="vertically" className="ui  container  " id="footerGrid" style={styles.footerGrid}>

	    		<Grid.Row columns={2} style={project.onDemoSite ? styles.footerContact
	    																									  : styles.footerNoContact
	    																}>

							{

								project.onDemoSite ? (

																			
																			<>
																				   

																					      <Grid.Column mobile={16} tablet={16} computer={6} >

																					        	<div class="touch_details">
																												
																												<div class="l_title">
																							      		
																							      				<Header className="strong-heading" as='h1'>SAY HELLO</Header>
																							      				<Header as='h3' style={styles.touch_detailsP1}>We appreciate your comments.</Header>
																							      		    
																							      		    <p style={styles.touch_detailsP2}>If you have any comments and suggestions, please contact us, and we will response to you immediately.</p>
																							              {/*
																							              <Header as='h4' style={styles.touch_detailsH4} >Tel</Header>
																							      		    <Header as='h5' style={styles.touch_detailsH5} >+886 2 2555 5578</Header>
																							      		    */}
																							      		    <Header as='h4' style={styles.touch_detailsH4}>Email</Header>
																							      		    <Header as='h5' style={styles.touch_detailsH5}>service@vismile.com.tw</Header>
																							      		                
																														
																							      		</div>

																							      </div>	


																					      </Grid.Column>

																					      <Grid.Column mobile={16} tablet={16} computer={6} >
																					        	<Form config={
																					        									{
																																		  api: 'https://aichure.com/index.php',
																																		  title: 'Contact Me',
																																		  description: 'Write us about your request, We\'ll get back to you within 24 hours.',
																																		  successMessage: 'Your message is successfully sent.',
																																		  errorMessage: 'Please fill the complete form',
																																		  fields:{
																																		    name: '',//firstName: '',
																																		    //lastName: '',
																																		    email: '',
																																		    subject: '',
																																		    msg: ''
																																	  	},
																																		  fieldsConfig:  [
																																		   //{ id: 1, label: 'First Name', fieldName: 'firstName', type: 'text',placeholder:'Your First Name', isRequired: true , klassName:'first-name-field'},
																																		   //{ id: 2, label: 'Last Name', fieldName: 'lastName', type: 'text', placeholder: 'Your Last Name', isRequired: true , klassName:'last-name-field'},
																																		   { id: 1, label: 'Name', fieldName: 'name', type: 'text',placeholder:'Name', isRequired: true , klassName:'name-field', errMsg: "your name must consist of at least 2 characters."},
																																		   { id: 2, label: 'Email', fieldName: 'email', type: 'email', placeholder: ' Email', isRequired: true , klassName:'email-field', errMsg: "Please enter a valid email address."},
																																		   { id: 3, label: 'Subject', fieldName: 'subject', type: 'subject', placeholder: ' Subject*', isRequired: true , klassName:'subject-field', errMsg: "your subject must consist of at least 4 characters."},
																																		   { id: 4, label: 'Message', fieldName: 'msg', type: 'textarea',placeholder:'Message', isRequired: true , klassName:'message-field', errMsg: "Say something more to us."}
																																		  ]  
																																		}  
																					        								} />
																					      </Grid.Column>
																					     
																			</>		    

																			

																 ) : null


							}
	    		</Grid.Row>

			    <Grid.Row columns={2}  style={ project.onDemoSite ? styles.footerBK
			    																									: styles.footerNoBK
			    														 } >

				    	<Grid.Column  style={styles.footerImg} >
				              
									{

										project.onDemoSite ? (

											                      <aside class="f_about_widget">
											                          <div style={ project.onDemoSite ? styles.f_titleLeft : styles.f_titleCenter }>
											                              <Image src={project.firstFooterImg} />
											                          </div>

											                          <p>Vismile also provides customized services with our AR/VR+AI technologies, such as ARmirror, virtual makeup, 3D body scanner, RFID and CRM/ERP integratin, etc.</p>
											                      
											                      </aside>
										                  ) : (
														              	<>
														              		  <div style={ project.onDemoSite ? styles.f_titleLeft : styles.f_titleCenter }>
											                              <Image src={project.firstFooterImg} />
											                          </div>
														              	</>
										                  )

									}	                  
				    	</Grid.Column>
				    	<Grid.Column  style={styles.footerImg} >

									{

										project.onDemoSite ? (
				              
											                      <aside class="f_widget f_insta_widget">
											                          <div style={ project.onDemoSite ? styles.f_titleLeft : styles.f_titleCenter }>
											                              <h1>Applications</h1>
											                          </div>

											                        <Grid columns='four' >
																						    <Grid.Row mobile={16} tablet={6} computer={6} >
																						      <Grid.Column style={styles.cellImg}>
																						        	<Image src='/images/vismile/applications/vismile-3Dcloth.jpg' />
																						      </Grid.Column>
																						      <Grid.Column style={styles.cellImg}>
																						        	<Image src='/images/vismile/applications/vismile-VR-fitting.jpg' />
																						      </Grid.Column>
																						      <Grid.Column style={styles.cellImg}>
																						        	<Image src='/images/vismile/applications/vismile-virtual-makeup.jpg' />
																						      </Grid.Column>
																						      <Grid.Column style={styles.cellImg}>
																						        	<Image src='/images/vismile/applications/vismile-virtual-accessory.jpg' />
																						      </Grid.Column>
																						    </Grid.Row>

																						    <Grid.Row mobile={16} tablet={6} computer={6}>
																						      <Grid.Column style={styles.cellImg}>
																						        	<Image src='/images/vismile/applications/vismile-in-mall.jpg' />
																						      </Grid.Column>
																						      <Grid.Column style={styles.cellImg}>
																						        	<Image src='/images/vismile/applications/vismile-3D-body-reconstruct.jpg' />
																						      </Grid.Column>
																						      <Grid.Column style={styles.cellImg}>
																						        	<Image src='/images/vismile/applications/vismile-3D-face-reconstruct.jpg' />
																						      </Grid.Column>
																						      <Grid.Column style={styles.cellImg}>
																						        	<Image src='/images/vismile/applications/vismile-jill-stuart.jpg' />
																						      </Grid.Column>
																						      
																						    </Grid.Row>
																						  </Grid>


											                      </aside>
											               ) : (
														              	<>
														              			<div style={ project.onDemoSite ? styles.f_titleLeft : styles.f_titleCenter }>
											                              <Image src={project.secondFooterImg} />
											                          </div>					                      
														              	</>
											               )
									}		               
				    	</Grid.Column>

			    </Grid.Row>


			    <Grid.Row style={styles.footerBottom}>
				    	<p>WE MIRROR THE WORLD </p>
			    </Grid.Row>


	  	</Grid>
	   	
	</div>
)  
 
const styles = {
  footerGrid: {
  	//width: '100vw !important' , not work
    justifyContent: 'space-evenly',
  	margin: '3px',
  	backgroundColor: 'rgba(255,255,255,1)',
  	
  },
  footerContact: {

  	paddingTop: '40px',
    paddingBottom: '40px',

  },
  footerNoContact: {
  	paddingTop: '0px',
    paddingBottom: '0px',

  },  
  footerBK: {
    background: 'url(/images/vismile/slider-bg-1.png)',
  	backgroundPosition: 'center',
  	backgroundSize: 'cover',
  	backgroundRepeat: 'no-repeat',

  	//backgroundColor: 'rgba(255,255,255,1.0)',

  	paddingLeft: '5vw',
    paddingRight: '5vw',

  },  
  footerNoBK: {

  	paddingLeft: '5vw',
    paddingRight: '5vw',
    backgroundColor: 'rgba(0,0,0,0.12)'

  },
  footerBottom: {

  	backgroundColor: '#11173b',
		fontSize: '16px',
    color: '#EEEEEE'
  },  
  footerImg: {
    
    width: '50% !important',
    display: 'grid',

  	//justifyContent: 'center',
  	//alignContent: 'center',
 
  },
  footerColor_01: {

    backgroundColor: '#000000',
    color: '#FFF'
  },
  footerColor_02: {
    
    backgroundColor: '#FFFFFF',
    color: '#000'
  },
 
  f_titleLeft: {

  	//paddingBottom: '2rem',
  	

  },
  f_titleCenter: {

  	//paddingBottom: '2rem',
  	margin: 'auto'

  },
  touch_detailsH4: {
		
		fontSize: '16px',
    color: '#97ccfe',
    fontWeight: '600',
    padding: '50px 0px 20px 0px'

  },
  touch_detailsH5: {
		
		fontSize: '24px',
    color: '#555',
    fontWeight: '600',
    margin: 'unset',

  },
	touch_detailsP1: {
		fontSize: '24px',
    color: '#555',
    fontWeight: '300',
    lineHeight: '1.5',
    paddingBottom: '55px'
	},

	touch_detailsP2: {
		fontSize: '16px',
    lineHeight: '1.5',
    color: '#555'
	},
	cellImg: {

		padding: 'unset',

	},

};

export default Footer;
