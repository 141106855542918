import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { auth } from "../../store";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment
} from "semantic-ui-react";


const styles = {
  loginForm: {
    width:'100vw' 
  }


};


const AuthForm = props => {
  const { name, /*forward,*/ displayName, handleSubmit, error, cart, redirect } = props;
  

  return (

    <div className="  ui raised very padded text segment" style={styles.loginForm}>
      <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
        width: 100vw;
      }
    `}</style>
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="blue" textAlign="center">
            {displayName}
          </Header>
          <Form size="large" onSubmit={handleSubmit(cart, redirect)} name={name} >
            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                name="email"
                type="text"
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                name="password"
              />

              <Button color="blue" fluid size="large">
                {displayName}
              </Button>
              {error && error.response && <div> {error.response.data} </div>}
            </Segment>
          </Form>
          <Message>
            
            {/*<a href={forward}> Go {forward} </a>*/}

          </Message>
        </Grid.Column>
      </Grid>
    </div>
  );
};
//<!--a href="/auth/google"> Go {forward} </a-->

const mapLogin = state => {
  return {
    name: "login",
    forward: "signup",
    displayName: "Login",
    //redirect: "/sewing",
    error: state.user.error
  };
};

const mapSignup = state => {
  return {
    name: "signup",
    forward: "login",
    displayName: "Sign Up",
    //redirect: "/sewing",
    error: state.user.error,
    cart: state.cart
  };
};

const mapDispatch = dispatch => ({

  handleSubmit: (cart, Redirect) => evt => {
    evt.preventDefault();
    const formName = evt.target.name;
    const email = evt.target.email.value;
    const password = evt.target.password.value;
    const redirect = Redirect;
    console.log("name: " + evt.target.name)
    console.log("Redirect: " + Redirect)
    console.log("redirect: " + redirect)
    
   
    dispatch(auth(email, password, formName, cart, redirect));
  }
});

export const Login = connect(mapLogin, mapDispatch)(AuthForm);
export const Signup = connect(mapSignup, mapDispatch)(AuthForm);


AuthForm.propTypes = {
  name: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.object
};
