/*

UPDATE_XAR => clothlist/gender

UPDATE_XAR => isShoot

UPLOAD_XAR

REQ_XAR

UPDATE_XAR => isUpload.photoUpload

FETCH_XAR

===============================
button click => XARsubmit
render  if (!this.state.sendXARreq && !this.state.XARdone) {
    
             if( xar.isShoot && xar.isRequest ){

        => XARsubmit

*/

import React, { Component } from "react";

import {
  
  updateProject,

  fetchProduct,
  fetchSuit,
  fetchCategory, 

  postCart,
  putCart,
  postGuestCart,
  putGuestCart,


  doXAR,
  updateDataXAR,
  fetchXAR, 

  doRTCAR,

  updateWebRTC

} from "../../store";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Image,
  Button,
  Grid,
  Header,
  Label,
  Icon,
  Message, 
  Card,
  Container
} from "semantic-ui-react";
import { RecommendPage } from "../../components";

import { SimilarGrid } from "../../components";


import './products.css';




const mapState = state => ({
  
  project: state.project,
  product: state.product,
  suit: state.suit,
  category: state.category,

  user: state.user,
  cart: state.cart,



  xar: state.xar,
  webrtc: state.webrtc

});

const mapDispatch = dispatch => {
  return {
    
    setProject: (params) => dispatch(updateProject(params)),

    getProduct: id => dispatch(fetchProduct(id)),
    getSuit: id => dispatch(fetchSuit(id)),
    getCategory: id => dispatch(fetchCategory(id)),
    
    addToCart: input => dispatch(postCart(input)),
    editProductQuantity: data => dispatch(putCart(data)),
    addToGuestCart: product => dispatch(postGuestCart(product)),
    editGuestQuantity: productId => dispatch(putGuestCart(productId)),

    requestXAR: (xar) => dispatch(doXAR(xar)),
    updateXAR: (xar) => dispatch(updateDataXAR(xar)),    
    getXAR: (xar) => dispatch(fetchXAR(xar)),

    requestRTCAR: (xar) => dispatch(doRTCAR(xar)),
    setWebRTC: (webrtc) => dispatch(updateWebRTC(webrtc)),

  };
};

class SingleSuitPage extends Component {
  constructor(props) {
    super(props);
    
    const projectName = this.props.match.params.projectName.toLowerCase();
    const { project, setProject } = this.props;
    var params = project;   
    params.projectName = projectName


    setProject(params);
    

    this.state = {

      projectName: projectName,

      other_suits_category: '',

      divBarPosRight: 0, 
      /*camera*/
      dataUri: '',
      idealFacingMode: '',
      isMaxResolution: false,
      DataUri: '',
      isFullscreen: false,

      message: "",
      inCart: false,
      inventoryReq: 0,
      isGuest: true, 

      sendXARreq: false, 
      XARdone: false,
      isWaiting: false,
     
      returnLinkAddr: '',

      rimProps : {
                    enlargedImagePortalId: 'portal',
                    enlargedImageContainerDimensions: 
                    {
                      width: '200%',
                      height: '200%'
                    }
                  },

      renderObj: null,            

      ARfittigTimeOut:false,            

      jumpPage: project.onMirror,
     
      queryDone: 0,

    };
  }

  doSomethingBeforeUnload = () => {
      console.log("SingleProductPage close")
      this.props.webrtc.webRTCstart = false;
      this.props.webrtc.webcamStart = false;
      this.props.setWebRTC(this.props.webrtc);
  }
  
  setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.doSomethingBeforeUnload();
        });
  };

  componentWillUnmount() {
      console.log("componentWillUnmount@SingleProductPage")
      if(this.props.project.onMirror==false){
          this.props.webrtc.webRTCstart = false;
          this.props.webrtc.webcamStart = false;
          this.props.setWebRTC(this.props.webrtc);
      }
      this.setState({renderObj: null})
      this.setState({queryDone: 0})
  }
  componentDidMount() {
    const { getProduct, getSuit, getCategory, user, cart, category, suit, project, webrtc, setWebRTC } = this.props;//getProducts,  
    const productId = +this.props.match.params.id;
    const isSuit = this.props.match.params.isSuit;

    this.setState({ divBarPosRight : project.divBarPosSel });
    this.setState({ ARfittigTimeOut : project.ARfittigTimeOut });

    console.log("componentDidMount");
    console.log('isSuit @componentDidMount', isSuit);

    if(isSuit==1){


        

        getSuit(productId).then(()=>{
           

            console.log('aftet getSuit @componentDidMount', this.props);
            //below is waste due to suits in product already, no need to fetch suit

            console.log('this.props.suit. @componentDidMount', this.props.suit);
            console.log('this.props.suit.suit @componentDidMount', this.props.suit.suit);
            console.log('this.props.suit.suit.products @componentDidMount', this.props.suit.suit.products);
            console.log('this.props.suit.suit.products[0].id @componentDidMount', this.props.suit.suit.products[0].id);
            console.log('this.props.suit.suit.products[0].suitID @componentDidMount', this.props.suit.suit.products[0].suitID);

            this.setState({renderObj: this.props.suit.suit}) 

            getProduct(this.props.suit.suit.products[0].id).then(()=>{
                
                console.log('after getProduct @componentDidMount xar', this.props.xar);
                console.log('after getProduct @componentDidMount suit', this.props.product);
                
                
                if (category.id===0) {

                    console.log("this.props.product.product", this.props.product.product);  
                    console.log("this.props.suit.suit.categories[0]", this.props.suit.suit.categories[0]);  
                  
                    getCategory(this.props.suit.suit.categories[0].id).then( () => {

                        console.log("this.props.category", this.props.category);  
                                      
                        this.setState({ other_suits_category: this.props.category.suits.filter(data => data.id !== this.props.suit.suit.categories[0].id) });

                          

                    })
                  

                  
                }

             });
            



        });

    }else{

        getProduct(productId).then(()=>{
           

            console.log('aftet getProduct @componentDidMount', this.props);
            //below is waste due to suits in product already, no need to fetch suit

            console.log('this.props.product. @componentDidMount', this.props.product);
            console.log('this.props.product.product @componentDidMount', this.props.product.product);
            console.log('this.props.product.product.suits @componentDidMount', this.props.product.product.suits);
            console.log('this.props.product.product.suits[0].id @componentDidMount', this.props.product.product.suits[0].id);
            console.log('this.props.product.product.suits[0].suitID @componentDidMount', this.props.product.product.suits[0].suitID);

            this.setState({renderObj: this.props.product.product}) 

            getSuit(this.props.product.product.suits[0].id).then(()=>{
                
                console.log('after getSuit @componentDidMount xar', this.props.xar);
                console.log('after getSuit @componentDidMount suit', this.props.suit);
                console.log('after getSuit @componentDidMount suit', suit);//not update
                
                console.log("category", category);  

                if (category.id===0) {

                    console.log("this.props.suit.suit", this.props.suit.suit);  
                    console.log("this.props.suit.suit.categories[0]", this.props.suit.suit.categories[0]);  
                  
                    getCategory(this.props.suit.suit.categories[0].id).then( () => {

                        console.log("this.props.category", this.props.category);  
                                      
                        this.setState({ other_suits_category: this.props.category.suits.filter(data => data.id !== this.props.suit.suit.categories[0].id) });

                          

                    })
                  

                  
                    }

             });




        });

    }




    // if user is logged in, set isGuest to false
    if (user.id) {
      this.setState({ isGuest: false });
    }

    if (cart) {
      cart.forEach(elem => {
        if (elem.productId === productId) {
          this.setState({ inCart: true, inventoryReq: elem.inventoryReq });
        }
      });
    }

    console.log("project.onMirror@SingleProductPage", project.onMirror, project.userId)
      
    if(project.onMirror && project.mirrorMode===0 && project.userId==0){ // 
      console.log("project.onMirror@SingleProductPage")
      webrtc.webcamStart = true;
      setWebRTC(webrtc);


    }

  }


  componentDidUpdate(prevProps, prevState) {
    // 常見用法（別忘了比較 prop）：
    console.log("**********************************************");
    console.log("componentDidUpdate@SingleProductPage");
    console.log("this.props:", this.props);
    console.log("prevProps:", prevProps);
    console.log("prevState:", prevState);
    console.log("this.state:", this.state);

    const { getXAR, xar, updateXAR, project } = this.props;
    
    let id = Number(this.props.match.params.id);

   

    if(this.props.suit !== prevProps.suit){

        if( this.props.match.params.isSuit ==1 ){
            
            this.setState({renderObj: this.props.suit.suit}) 
            
        }
        this.setState({queryDone: this.state.queryDone+1}) 
    }

    if(this.props.product !== prevProps.product){

        if( this.props.match.params.isSuit ==0 ){
            
            this.setState({renderObj: this.props.product.product}) 
            
        }
        this.setState({queryDone: this.state.queryDone+1}) 
    }


    if (!this.state.sendXARreq && !this.state.XARdone) {
    
      if( xar.isShoot && xar.isRequest ){
      
        var userId = 0;
        if(this.props.user){
          userId = this.props.user.id
        }

        var renderObj = null;
        if( this.props.match.params.isSuit ==1 ){      
            renderObj = this.props.suit.suit;                          
        }else{
            renderObj = this.props.product.product;                  
        }
        this.setState({renderObj: renderObj}) 
        console.log("this.state.renderObj", this.state.renderObj, renderObj)
        this.XARsubmit( project.projectName, renderObj.id, userId, false);        
      }

    }
    


    if (this.props.xar.isUpload !== prevProps.xar.isUpload) {
   
      console.log("xar photo & clothlist changes @componentDidUpdate");
      console.log("xar.isUpload @componentDidUpdate:", xar.isUpload);
      console.log("XARdone @componentDidUpdate:", this.state.XARdone)
             
      if(xar.isUpload.photoUpload && xar.isUpload.clothlistUpload && !this.state.XARdone){
          console.log("!!!!!!!!!!!!!! xar photo & clothlist both upload!!!!!!!!!!!!!!!!!!!!!!");
          console.log("Go get the photo: start getXAR(xar) in componentDidUpdate");
          getXAR(xar).then(()=>{
              this.setState({ XARdone: true }); 
              this.setState({ isWaiting: false});             
              console.log("set XARdone done! ", this.state.XARdone);
             
              //xar.isUpload.photoUpload = false;
              //updateXAR(xar);

         });
       


         

         
      }else{
        console.log("Not go get the photo: maybe due to XARdone  in componentDidUpdate", this.state.XARdone);
         

      }

    }


    if(this.state.XARdone !== prevState.XARdone){
      //check whether get photo or timeout error
      if(!xar.isUpdate && this.state.XARdone){ //something connection error, such as http time-out
        console.log("!!!!!!!!!!!!!! getXAR got error and redo!!!!!!!!!!!!!!!!!!!!!!");
        this.setState({ XARdone: false }); 
        this.setState({ isWaiting: true});
        getXAR(xar).then(()=>{
             this.setState({ XARdone: true }); 
             this.setState({ isWaiting: false});
             console.log("set XARdone done! ", this.state.XARdone);
             
             //xar.isUpload.photoUpload = false;
             //updateXAR(xar);

         });

      }

    }


    if(this.props.project.ARfittigTimeOut===true && this.state.ARfittigTimeOut===false){

      this.setState({ARfittigTimeOut:true})
      this.setState({ isWaiting: false});
      this.props.history.go(-1);
    }


    //jumpPage to remotePhone
    if(this.props.project.onMirror && this.state.renderObj && (this.state.queryDone>1) ){

      this.XARsubmit(this.props.project.projectName, this.state.renderObj.id, this.props.user.id, true)

    }

  }


  addToCartSubmit(productId, userId) {
    const {
      addToCart,
      editProductQuantity,
      suit,
      product,
      addToGuestCart,
      editGuestQuantity
    } = this.props;

    let { inCart, inventoryReq, isGuest } = this.state;
    const quantity = inventoryReq + 1;

    let suitId = suit.suitId;
    // if the product inventory and the required inventory are the same
    if (product.inventory === inventoryReq) {
      this.setState({ message: "out-of-stock" });
    }

    // if the product isn't in the cart && it's a guest
    if (!inCart && isGuest) {
      addToGuestCart(suit);
      this.setState({ inCart: true, message: "updated", inventoryReq: 1 });
    }

    // if product is inCart and the user is a guest
    if (inCart && isGuest) {
      editGuestQuantity({ suitId, inventoryReq: quantity });
      this.setState({ message: "updated", inventoryReq: quantity });
    }

    // if the product isn't in the cart && user is logged in
    if (!inCart && !isGuest) {
      addToCart({ suitId, userId: userId });
      this.setState({ inCart: true, message: "updated", inventoryReq: 1 });
    }

    // if the product is in the cart && user is logged in
    if (inCart && !isGuest) {
      editProductQuantity({ quantity, suitId, userId });
      this.setState({ message: "updated", inventoryReq: quantity });
    }
  }

  XARclothesProducts() {
    console.log("============================= clothlist ===============================")
    
    const { xar, suit, product } = this.props;
    console.log("this.props @XARclothesProducts: ", this.props)
    console.log("suit @XARclothesProducts: ", suit)
    console.log("suit.suit @XARclothesProducts: ", suit.suit)


    

    const video_filename = xar.picID + '_' + suit.suit.suitID;
    
    /* originally, find the same suit of other items
    const clothlist = [];
    suit.suit.products.forEach(product => {
        console.log("product.suit.products.internal_name: ", product.internal_name)
        clothlist.push(product.internal_name);        
      });
    */
    const clothlist = suit.suit.suitClothesID;
    const shoes = suit.suit.shoes;

    console.log("clothlist: ", clothlist)

    xar.clothlist = clothlist;
    xar.shoes = shoes;
    xar.suitID = suit.suit.suitID;
    xar.clothesID = product.product.internal_name;
    xar.video_filename = video_filename;
    if(suit.suit.gender === 'male'){
      xar.human.gender = 1;  
    }else
    {
      xar.human.gender = 0;
    }
    

  }


  XARsubmit(projectName, productId, userId, isRTC) {
    const {
      
      project,
      xar,
      webrtc,
      updateXAR,
      requestXAR, 

      requestRTCAR,

      setProject

    } = this.props;

    this.setState({ sendXARreq: true}, ()=>{
                                            console.log("sendXARreq@XARsubmit: " + this.state.sendXARreq);
                                            });
    
    this.XARclothesProducts();

    xar.isRequest = true;
    xar.isUpdate = false;
    xar.isRTC = isRTC;
    xar.peerID = webrtc.myId;
    
    if(project.onMirror){
        xar.camOrientation = project.camPosSel; //only mirror mode
    }
    updateXAR(xar);
    
    //if no photo, do take photo
    //if already has photo, do xar json request
    
    //

    const linkAddr       = `${projectName}/suits/suit/${productId}/${this.props.match.params.isSuit}`
    const returnLinkAddr = `/${projectName}`
                            
    console.log("linkAddr@XARsubmit: " + linkAddr);

    this.setState({ returnLinkAddr: returnLinkAddr});

    console.log("project@XARsubmit: " + project);

    if(!xar.isRTC){

        if(xar.isShoot){

          requestXAR(xar);      
          console.log("  with old pic");
          this.setState({ isWaiting: true});

        }else if(project.onCamera){

          console.log("don't send and go to camera page @XARsubmit ");
          this.props.history.push({ pathname: '/camera',
                                    state: { linkAddr: linkAddr }
                                  });
        }else{

          console.log("don't send and go to fileupload page @XARsubmit ");
          this.props.history.push({ pathname: '/fileupload',
                                    state: { linkAddr: linkAddr }
                                  });


        }
    }else{

          
          console.log("don't send and go to RTC camera page @XARsubmit ");
          console.log("@XARsubmit onMirror userId", project.onMirror, project.userId )
          if(project.onMirror && project.userId!==0){
            
            if(project.mirrorMode===1){
                //requestRTCAR(xar);
            }
            //button disable
            this.setState({ isWaiting: true});
            
            var tmp = project;
            tmp.ARfittingRemoteStart = true;
            console.log("setProject@XARsubmit")
            setProject(tmp);
            
            //-----------------------------------------------
            console.log("Mirror project && Mirror client")
            this.props.history.push({ pathname: `/${projectName}/RemotePhone`,
                                      state: { linkAddr: linkAddr }
                                    });
            //-------------------------------------------------

          }else if(project.onMirror && project.mirrorMode===0 && project.userId===0){
            //Mirror project && Mirror client

            xar.remoteUserId = project.remoteUserId;
            xar.mirrorId = project.mirrorId;
            requestRTCAR(xar);

          }else{
            //no Mirror project 
            //requestRTCAR(xar);
            this.props.history.push({ pathname: `/${projectName}/ARfitting`,
                                      state: { linkAddr: linkAddr }
                                    });

          }



    }

  }


  
  renderAddToCart() {
    const { suit, user } = this.props;
    const { inventory, name } = suit;
    const { message, inventoryReq } = this.state;

    // if product is out of stock, render out of stock message
    if (inventory === 0) {
      return (
        <Message negative>
          Sorry, this product is currently out of stock.
        </Message>
      );
    } else if (
      message === "out-of-stock" ||
      inventoryReq === suit.inventory
    ) {
      return (
        <Message negative>
          You have all {name}s currently available in your cart.
        </Message>
      );
      // if product is in stock, render add to cart button
    } else if (inventory > 0 && message !== "out-of-stock") {
      return (
        <div>
          <Button as="div" labelPosition="right">
            <Button
              color="red"
              onClick={() => this.addToCartSubmit(suit.id, user.id)}
            >
              <Icon name="shop" />Add to Cart
            </Button>
            <Label as="a" basic color="red" pointing="left">
              Only {inventory} left!
            </Label>
          </Button>
          {message === "updated" ? (
            <Message compact positive>
              There are {inventoryReq} {name}s in your cart!
            </Message>
          ) : (
            ""
          )}
        </div>
      );
    }
  }
  


  handleTakePhoto (dataUri) {
    // Do stuff with the photo
    console.log('handleTakePhoto');

    
    console.log('dataUri:' + dataUri);
    console.log('DataUri:' + this.state.DataUri);

    this.setState({ DataUri: dataUri }, () => {
      console.log(this.state.DataUri, 'DataUri');
    });
    


  }
  handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo
    console.log('handleTakePhotoAnimationDone');

  }

  handleCameraError (error) {
    console.log('handleCameraError', error);
  }

  handleCameraStart (stream) {
    console.log('handleCameraStart');
  }

  handleCameraStop () {
    console.log('handleCameraStop');
  }

  showTakePhoto () {
    console.log("showTakePhoto");    
  }

  render() {
    const { suit, user, xar, category, product, project } = this.props;
    const { projectName } = project;






        
   

    if ( this.state.queryDone > 1) {
      

      //
      if(!project.jumpPage ){

      return (
        
        
        <div className="show-suit">
           
          <div id="portal" className="portal" style={styles.portal}/>
                          
          
               

            { this.state.XARdone ? (
                            
                              <div className="product-wrapper ui raised very padded text segment" style={styles.showXAR}>
                              {/* remove temporary about QRcode/preview*/}
                                  <div className="  " style={ this.state.divBarPosRight ? styles.divBar_right : styles.divBar_left } > 
                                        <SimilarGrid className="ui raised very padded text container segment " 
                                                 xar={xar} 
                                                 products={this.state.renderObj} 
                                                 category={this.state.renderObj.categories} 
                                                 rimProps={this.state.rimProps}
                                                 style={styles.magnifyBar}
                                        /> 
                              </div> 
                              
                              <Card as={Link}  to={this.state.returnLinkAddr} centered style={styles.maincard}>
                                
                               
                                  <Card.Header className="product-header">
                                      <Link to={this.state.returnLinkAddr}>{this.state.renderObj.name}</Link>
                                  
                                  </Card.Header>

                                  <Image src={xar.XARimg[0]["img"]} style={styles.xarImg} />
                                
                              
                             
                                  <Card.Description style={{ color: "black" }}>
                                     
                                  </Card.Description>
                                   
                                
                                  <Card.Content extra>
                                      {/*
                                      <Icon name="money bill alternate outline" />
                                          {this.state.renderObj.price}
                                      <Button color="green" inverted floated="right">
                                          Submit
                                      </Button>
                                    */}
                                  </Card.Content>

                              </Card>
                           
                                

                            </div> 
                            

                          )
                          :(
                            <div className="suit-wrapper">

                          
                              

                                <div className="ui raised very padded text segment " style={styles.div} >

                                  <Grid divided="vertically">



                                    <Grid.Row mobile={1} tablet={2} computer={2}>

                                      <Grid.Column  mobile={16} tablet={10} computer={10} style={styles.grid}>
                                        
                                        <Image src={this.state.renderObj.imageUrl} style={styles.imageGrid} />
                                      </Grid.Column>
                                      
                                      <Grid.Column mobile={16} tablet={6} computer={6} >

                                        <Grid divided="vertically">
                                          <Grid.Row >

                                            <Grid.Column width={12}>
                                              {/*<Header as="h2" className="textStyle2">{renderObj.name}</Header>*/}
                                              <Header as="h2" className="textStyle2">{this.state.renderObj.name}</Header>
                                              
                                              <Label.Group>
                                                <text as="h4"  className="textStyle4">
                                                 
                                                  { "$" + this.state.renderObj.price }
                                                </text>
                                                
                                                
                                                
                                                {user.isAdmin ? (
                                                  <Button
                                                    as={Link}
                                                    to={`/admin/suits/edit/${this.state.renderObj.id}`}
                                                    style={styles.button}
                                                    size="small"
                                                  >
                                                    <Icon name="edit" /> Edit
                                                  </Button>
                                                ) : (
                                                  ""
                                                )}
                                              </Label.Group>
                                              

                                            </Grid.Column>  
                                            
                                            <Grid.Column width={4}>
                                              {this.renderAddToCart()}   
                                              
                                            </Grid.Column>    
                                            

                                          </Grid.Row>    

                                          <Grid.Row >
                                            <Grid.Column>
                                              <text as="h3" className="textStyle3">
                                               
                                                {this.state.renderObj.color}
                                              </text>
                                            </Grid.Column>      
                                          </Grid.Row>    
                                          <Grid.Row >
                                            <Grid.Column>
                                              <text as="h3"  className="textStyle3">
                                                
                                                {this.state.renderObj.description}
                                              </text>  
                                            </Grid.Column>        
                                          </Grid.Row>    
                                          <Grid.Row >
                                            <Grid.Column>
                                              {
                                                (this.state.isWaiting) ? (
                                                                          <div>
                                                                            <Image src='/3fitting.gif' size="large" />  
                                                                            
                                                                            <Message negative>
                                                                              Vismile virtaul fitting room utilizes the AI/AR&VR technologies and it will show you the acurate and realistic fitting results soon.
                                                                            </Message> 
                                                                            
                                                                          </div>
                                                                         )
                                                                       : (
                                                                          
                                                                          (project.fittingMode===1 || project.fittingMode===3) ? (
                                                                                <Button  onClick={() => this.XARsubmit(projectName, this.state.renderObj.id, user.id, false)} style={styles.tryonButton} size="large" circular>
                                                                                   <Image src='/try-fitting-room.gif' size="large" />                                                                                
                                                                                </Button>
                                                                          ):''

                                                                      )
                                              }
                                            </Grid.Column>       
                                          </Grid.Row>    


                                          <Grid.Row >
                                            <Grid.Column>
                                              {
                                                  (project.fittingMode===2 || project.fittingMode===3) ? (
                                                      <Button onClick={() => this.XARsubmit(projectName, this.state.renderObj.id, user.id, true)} style={styles.tryonButton} size="large" circular>
                                                          <Image src='/try-fitting-room.gif' size="large" />                                                                                
                                                      </Button>
                                                  ):''
                                              }
                                            </Grid.Column>       
                                          </Grid.Row>    


                                        </Grid>    


                                      </Grid.Column>

                                      


                                    </Grid.Row>
                                  </Grid>
                                </div>

                               

                            </div>     
                          )
            }



 
            
          

      


        </div>
        
      );}else{return (<div>Jumping</div>);}

    }else{ return (<div>loading</div>);}

  }
}



const styles = {
  div: {
    marginTop: 40,
    width: '72vw',
    zIndex: 3,
    minWidth: '380px'
  },
  grid: {
    display: 'table',
    justifyContent: 'center',
    minWidth: '55%'
  },

      
  divBar_right: {
    marginTop: '10%', /*20% for 70em*/
    width: '23vw',
    right: '4em',/*'4em'*/
    position: 'absolute',
    zIndex: 2
  },
  divBar_left: {
    marginTop: '10%', /*20% for 70em*/
    width: '23vw',
    left: '4em',/*'4em'*/
    position: 'absolute',
    zIndex: 2
  },

  portal: {
    
    width: '46vw',
    height: '46vw',
    top: 'calc(25vmin + 0vw + 0em + 0% + 20vmax)',
    left: '28vw',
    position: 'absolute',
    zIndex: 2
  },


  xar: {

    color: 'rgb(255, 112, 112)',
    margin: '0 0 0 0'

  },
 maincard: {

    height: 'unset',
    width: 'unset',
    maxHeight: '75vh'
   

  }, 
  imageGrid: {

    //height: '100%',
    width: '100%'
   

  }, 
  showXAR: {
    marginTop: 10,
    //width:'96vw'
  },

  xarImg: {
    transform: 'scale(1.0)'
  },
  
  magnifyBar: {

    //pointerEvents: 'none', not allowed to enlarge
    
    //mark for havas QRcode, height: 'unset',
    width: '23vw',
   
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',

    margin: '0.5em'

  }, 

  tryonButton: {

    width: 'unset',
    height: 'unset'

  }

};

export default connect(mapState, mapDispatch)(SingleSuitPage);
