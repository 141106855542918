import React, { Component } from 'react';

class Tracking extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {scrollTop: 0}
  }

  componentDidMount() {
    
    const height = document.getElementById('root').scrollHeight;
    console.log("root@Tracking DidMount", height)
  
  }

  onScroll(){
    console.log("test")
    const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
    const scrollTop = this.myRef.current.scrollTop
    console.log(`onScroll, window.scrollY: ${scrollY} myRef.scrollTop: ${scrollTop}`)
    this.setState({
      scrollTop: scrollTop
    })
  }

  render() {
    const {
      scrollTop
    } = this.state
    
    const height = document.getElementById('root').scrollHeight;
    console.log("root@Tracking render", height)
   

    return (
      <div>
          <div 
              ref={this.myRef} 
              onScroll={this.onScroll}
              style={{

                      zIndex: '-100',
                      position: 'absolute',
                      width: '100%',
                      height: height,
                      overflow: 'scroll',
                    }}
              >
              <p>This demonstrates how to get the scrollTop position within a scrollable react component.</p>
              <p>ScrollTop is {scrollTop}</p>
              <p>This demonstrates how to get the scrollTop position within a scrollable react component.</p>
              <p>ScrollTop is {scrollTop}</p>
              <p>This demonstrates how to get the scrollTop position within a scrollable react component.</p>
              <p>ScrollTop is {scrollTop}</p>
              <p>This demonstrates how to get the scrollTop position within a scrollable react component.</p>
              <p>ScrollTop is {scrollTop}</p>
              <p>This demonstrates how to get the scrollTop position within a scrollable react component.</p>
              <p>ScrollTop is {scrollTop}</p>
              <p>This demonstrates how to get the scrollTop position within a scrollable react component.</p>
              <p>ScrollTop is {scrollTop}</p>
          </div>
      </div>
    )
  }
}

export default Tracking;
