import axios from "axios";
import produce from 'immer';
import store from '../../store';

/**
 * ACTION TYPES
 */
export const UPDATE_WEBRTC = 'UPDATE_WEBRTC'
export const REG_WEBRTC = 'REG_WEBRTC'
export const GET_WEBRTC = 'GET_WEBRTC'

/**
 * INITIAL STATE
 */
const initialState = {
                        webRTCstart: false,
                        pc : null,    
                        pcConfig : {"iceServers": [{"urls": "stun:stun.l.google.com:19302"}]},
                        pcOptions : {
                                    optional: [
                                                  {DtlsSrtpKeyAgreement: true}
                                              ]
                                    },
                        stream: null,   
                        localStream_clone: null,
                        localStream: null,
                        rttStream: null,
                        dataChannel: null,
                        remoteComing: false,
                        remoteVideoStream: null,
                        peer_id: null,
                        otherPeers: null,
                        otherPeers : {},
                        RTCSessionDescription: null,

                        mediaConstraints : {
                                              'mandatory': {
                                                            'OfferToReceiveAudio': true,
                                                            'OfferToReceiveVideo': true 
                                                           }
                                           },
                        
                        RTCIceCandidate: null,
                        hangingGet: null,
                        myId: -1,
                        server: null,
                        request: null,
                        localName: null,
                        stream: null,
                        webcamDelayVideo: null,

                        webcamStart: false,

                        webrtcReady: false,


                        canvasResolution: null,


                        rttStart: false,
                        rttDone: false,
                         
                        dataChannelDone: false,

                        booking: false,
                        ticket: {
                                  id: -1,
                                  timeStamp: -1
                                },
                        timer: null,        
                         
                      }

/**
 * ACTION CREATORS
 */

const setWebRTC = WebrtcData => ({type: UPDATE_WEBRTC, WebrtcData});
const regWebRTC = WebrtcData => ({type: REG_WEBRTC, WebrtcData});
const getWebRTC = WebrtcData => ({type: GET_WEBRTC, WebrtcData});

/**
 * THUNK CREATORS
 */

export const updateWebRTC = (webrtc) => {
  return async dispatch => {
    
    //console.log("updateWebRTC", webrtc)
    dispatch(setWebRTC(webrtc))
  }
}

export const registerWebrtc = (webrtc) => {
  return async dispatch => {

    try{
   
        const body = {
                      databaseName: store.getState().project.projectName,
                      registerServer : webrtc.dataChannelDone
                     }

       
       

        const {data} = await axios.post('/api/webrtc/registerWebrtc', body );

        console.log("registerWebrtc data", data)


        if(data === "server is already busy"){

          //console.log("registerWebrtc data", data)

        }else if(data.done = true){        

          dispatch(regWebRTC(webrtc));
          //console.log("regWebRTC done ");
        }

        

    }catch (err) {
      console.error(err)
    }
  }
}

export const getTicketWebrtc = (webrtc) => {
  return async dispatch => {

    console.log('store@webrtc.js 1 ', store)
    try{
        console.log('store@webrtc.js 2 ', store)
        const body = {
                      databaseName: store.getState().project.projectName,
                      booking : webrtc.booking
                     }

       
       

        const {data} = await axios.post('/api/webrtc/bookingWebrtc', body );

        //console.log("data", data)
        if(data.id > -1){        
          webrtc.ticket.id        = data.id;
          webrtc.ticket.timeStamp = data.timeStamp;
          
          dispatch(getWebRTC(webrtc));
          console.log("getTicketWebrtc done ");
        }

        

    }catch (err) {
      console.log('store@webrtc.js 3 ', store)
      console.error(err)
    }
  }
}

export const updateTicketWebrtc = (webrtc) => {
  return async dispatch => {

    try{
   
        //console.log("webrtc", webrtc)

        const body = {
                      databaseName: store.getState().project.projectName,
                      ticket   : webrtc.ticket.id,
                      timeStamp: webrtc.ticket.timeStamp
                     }

       
       

        const {data} = await axios.post('/api/webrtc/ticketWebrtc', body );

        //console.log("data", data)

        if(data.id > -1){        
          webrtc.ticket.id        = data.id;
          webrtc.ticket.timeStamp = data.timeStamp;
          dispatch(getWebRTC(webrtc));
          //console.log("ticketWebrtc done ");
        }

        

    }catch (err) {
      console.error(err)
    }
  }
}

/**
 * REDUCER
 */
export default function(state = initialState, action) {
  switch (action.type) {

  case UPDATE_WEBRTC:
      //console.log("action.WebrtcData", action.WebrtcData)
      return action.WebrtcData

  case REG_WEBRTC:
      return produce(state, data => {
                                      data.dataChannelDone = true;
                                    }
                    );

  case GET_WEBRTC:
      return produce(state, data => {
                                      data.ticket.id = action.WebrtcData.ticket.id;
                                      data.ticket.timeStamp = action.WebrtcData.ticket.timeStamp;
                                    }
                    );
  
    default:
      return state
  }
}
