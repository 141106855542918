import React from "react";
import { Card } from "semantic-ui-react";
import { SimilarCard } from "../../components";
//import './index.css';
/*
{products.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
*/
//<Card.Group itemsPerRow={6}>
const SimilarGrid = ({ xar, products, category, rimProps, style }) => {
  console.log("products@SimiliarGrid: ",  products)
  //if (products && products.length < 1) {
  console.log('xar.XARimg.length: ', xar.XARimg.length)  
  console.log('xar: ', xar)  
  if (xar && xar.XARimg.length < 1) {
    /*return <EmptyProduct />;*/
    return null;
  } else {

    var XARimg = [];
    xar.XARimg.forEach(elem => {
                            if (elem.img === "" || elem.img === "/") {
                              
                            }else{
                              XARimg.push(elem)

                            }
                });
    console.log("XARimg@SimilarGrid", XARimg)

    return (
      <Card.Group itemsPerRow={1} className="similar-products" style={style} >       
        {XARimg.map(elem => (          
          <SimilarCard key={elem.id} renderObj={elem} product={products} category={category} rimProps={rimProps} style={style}/>
        ))}
      </Card.Group>
    );
  }
};

/*{products.map(product => (
<SimilarCard key={product.id} product={product} category={category}/>*/

/*

*/

export default SimilarGrid;
