
/**
 * ACTION TYPES
 */
export const UPDATE_PARAMS = 'UPDATE_PARAMS'
/**
 * INITIAL STATE
 */
const initialState = {
                      
                        isMobile:           false, 
                        isMobileOnly:       false, 
                        isTablet:           false,
                        isBrowser:          false,
                        isConsole:          false,
                        isAndroid:          false,
                        isWinPhone:         false,
                        isIOS:              false,
                        isChrome:           false,
                        isFirefox:          false,
                        isSafari:           false,
                        isOpera:            false,
                        isIE:               false,
                        isEdge:             false,
                        isYandex:           false,
                        isChromium:         false,
                        isMobileSafari:     false,
                        osVersion:          '',
                        osName:             '',
                        fullBrowserVersion: '',
                        browserName:        '',
                        mobileVendor:       '',
                        mobileModel:        '',
                        engineName:         '',
                        engineVersion:      '',
                        getUA:              '',
                        deviceType:         '',

                        disableCardLink:    false,
                        

                     }

/**
 * ACTION CREATORS
 */

const setParams = params => ({
  type: UPDATE_PARAMS,
  params
})

/**
 * THUNK CREATORS
 */
export const updateParams = (params) => {
  return async dispatch => {
    
    dispatch(setParams(params))
  }
}

/**
 * REDUCER
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PARAMS:
      return action.params
    default:
      return state
  }
}
