import axios from "axios";
import produce from 'immer';
import store from '../../store';

/**
 * ACTION TYPES
 */
export const GET_DEMO = 'GET_DEMO'


/**
 * INITIAL STATE
 */
const defaultDemo = {}

/**
 * ACTION CREATORS
 */

const getDemo = DemoData => ({type: GET_DEMO, DemoData});

/**
 * THUNK CREATORS
 */
export const fetchDemo = (projectName) => {
  return async dispatch => {
    
    console.log("projectName@fetchDemo", projectName)
    
    const body = {
                  databaseName:  projectName,
                  
                 }
    console.log("body@fetchDemo", body)             
    const {data} = await axios.post(`/api/demo`, body)

    dispatch(getDemo(data))
     
  }
}

/**
 * REDUCER
 */
export default function(state = defaultDemo, action) {
  switch (action.type) {
    case GET_DEMO:
      console.log("action.DemoData ", action.DemoData)
      return action.DemoData
 
    default:
      return state
  }
}