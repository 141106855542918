// Dependencies
import React, { Component } from 'react';
import { connect } from "react-redux";
// Externals
//import NavBar from './components/NavBar';
import { Navbar/*, HandToCursorPage*/ } from '../../components';
import Header from './components/Header';
import Footer from './components/Footer';
import ButtonBar from './components/ButtonBar';


import { /*fetchProject,*/ updateProject, fetchHeaders, fetchXARsetting} from "../../store";
import { 
  
  deviceDetect,

  isMobile,   
  isMobileOnly,      
  isTablet,       
  isBrowser,          
  isConsole,         
  isAndroid,          
  isWinPhone,         
  isIOS,              
  isChrome,           
  isFirefox,          
  isSafari,          
  isOpera,           
  isIE,            
  isEdge,             
  isYandex,           
  isChromium,         
  isMobileSafari,     
  osVersion,     
  osName,          
  fullBrowserVersion,
  browserName, 
  mobileVendor,       
  mobileModel,       
  engineName,        
  engineVersion,
  getUA,      
  deviceType,


} from 'react-device-detect';
   

import './BaseLayout.css';



const mapState = state => ({
  project: state.project,
  headers: state.headers, 
  categories: state.categories, 
  xar: state.xar
  //baselayout: state.baselayout
});
const mapDispatch = dispatch => ({
  //getProject: (params) => dispatch(fetchProject(params)),  
  setProject: (params) => dispatch(updateProject(params)),  
  getHeaders: () => dispatch(fetchHeaders()),
  getXARsetting: (params) => dispatch(fetchXARsetting(params)),
  
  //setParams: (params) => dispatch(updateParams(params))
});


const classNames = [
  "firstHeader",
  "secondHeader",
  "thirdHeader",
  "fourthHeader"
];


class BaseLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,

      isImageRotate: true,
      camPosSel: 0,

      windowWidth: window.innerWidth, 

      largeHeaderMode: 2,

    };
    this.timer = 0;

  }

  incrementIndex = () => {
    const newIndex = this.state.index + 1;
    console.log("newIndex", newIndex)
    this.setState({ index: newIndex })
  }

  handleResize = (e) => {
  
      this.setState({ windowWidth: window.innerWidth });
  
  }

  componentWillUnmount() {
   
      window.removeEventListener("resize", this.handleResize);
      if(this.timer!=0){clearInterval(this.timer)}
   
  } 

  componentDidMount = () => {

    window.addEventListener("resize", this.handleResize);
    console.log("this.props.project.secondHeaderImg", this.props.project.secondHeaderImg, this.props.project.secondHeaderImg!='')
    if(this.props.project.secondHeaderImg != ''){
      this.timer = setInterval(this.incrementIndex, 3000);    
    }
    
    this.setState({largeHeaderMode: this.props.project.largeHeaderMode})

    const { /*getProject,*/ getHeaders, getXARsetting, setProject, xar, project, headers } = this.props;
    //console.log("project@baselayout: ", project);
    //console.log("headers@baselayout: ", headers);

    //getProject(project);
    getHeaders();
    getXARsetting(xar);
    //const test = deviceDetect();
    const clientInfo = {  
                          isMobile,   
                          isMobileOnly,      
                          isTablet,       
                          isBrowser,          
                          isConsole,         
                          isAndroid,          
                          isWinPhone,         
                          isIOS,              
                          isChrome,           
                          isFirefox,          
                          isSafari,          
                          isOpera,           
                          isIE,            
                          isEdge,             
                          isYandex,           
                          isChromium,         
                          isMobileSafari,     
                          osVersion,     
                          osName,          
                          fullBrowserVersion,
                          browserName, 
                          mobileVendor,       
                          mobileModel,       
                          engineName,        
                          engineVersion,
                          getUA,      
                          deviceType,
                        }

    console.log("clientInfo: ", clientInfo);

    var params = project;
    params.ClientInfo = clientInfo;
    setProject(params);
/*
    if(isMobile)

    var params = baselayout.ClientInfo;
    params.onGesture = false;
    params.onButton = false;
    setParams(params);

*/
    
  }

  componentDidUpdate(prevProps, prevState) {

    console.log("**********************************************");
    console.log("componentDidUpdate@baselayout");
    console.log("this.props.project.largeHeaderMode", this.props.project.largeHeaderMode)
    console.log("this.state.largeHeaderMode", this.state.largeHeaderMode)
        
    if (this.props.project.largeHeaderMode !== prevProps.project.largeHeaderMode) {
        console.log("this.props.project.largeHeaderMode !== prevProps.project.largeHeaderMode@baselayout")
        this.setState({largeHeaderMode: this.props.project.largeHeaderMode})
        
    }    
    if (this.props.project.largeHeaderMode !== this.state.largeHeaderMode) {
        console.log("this.props.project.largeHeaderMode !== this.state.largeHeaderMode@baselayout")
        this.setState({largeHeaderMode: this.props.project.largeHeaderMode})
        
    }   
  }

  CameraErrorHandler = (errorMsg) =>{
    const { setProject, project } = this.props;
    console.log("Catch CameraError @baselayout", errorMsg)
    var params = project;
    params.onGesture = true;
    params.onButton = false;
    setProject(params);
  }


  render() {
    const { project } = this.props;
    const index = this.state.index % classNames.length;
    const className = classNames[index];
    const HeaderImg = [project.firstHeaderImg, project.secondHeaderImg, project.thirdHeaderImg, project.fourthHeaderImg]
    //console.log("this.state.index", this.state.index)
    //console.log("classNames", classNames.length, classNames)
    //console.log("props: ", this.props)
    //console.log("LogoImg: ", this.props.baselayout.FooterImg[this.props.baselayout.FooterSel].LogoImg)
    //console.log("QRcodeImg: ", this.props.baselayout.FooterImg[this.props.baselayout.FooterSel].QRcodeImg)

    //console.log("baselayout.HeaderImg[baselayout.HeaderSel].className", baselayout.HeaderImg[baselayout.HeaderSel][className])

    console.log("this.state.largeHeaderMode@render baselayout", this.state.largeHeaderMode)

    const style_backgroung_url = (this.state.largeHeaderMode===2 && project.userId<0 ) ? {
                                                                                         background: "url(" + HeaderImg[index] + ")",
                                                                                         height: "38vh",
                                                                                         width: "100%",
                                                                                         position: "fixed",
                                                                                         zIndex: "10",
                                                                                         backgroundSize: 'contain',
                                                                                         backgroundPosition: 'center',
                                                                                         backgroundRepeat: 'no-repeat',
                                                                                         backgroundColor: "#00000000",
                                                                                         
                                                                                      }
                               : (this.state.largeHeaderMode===1 && project.userId<0 ) ? {

                                                                                         background: "url(" + HeaderImg[index] + ")",
                                                                                         height: "180px",
                                                                                         width: "100%",
                                                                                         position: "fixed",
                                                                                         zIndex: "10",
                                                                                         backgroundColor: "#00000000",
                                                                                      }
                               : (this.state.largeHeaderMode===0 && project.userId<0 ) ? {
                                                                                         background: "url(" + HeaderImg[index] + ")",
                                                                                         height: "8vh",
                                                                                         width: "100%",
                                                                                         position: "fixed",
                                                                                         zIndex: "10",
                                                                                         backgroundSize: 'contain',
                                                                                         backgroundPosition: 'center',
                                                                                         backgroundRepeat: 'no-repeat',
                                                                                         backgroundColor: "#00000000",
                                                                                         
                                                                                      }      
                               : (project.userId>=0 && project.onDemoSite==false ) ? {                                                                                    
                                                           background: "url(" + HeaderImg[index] + ")",
                                                           height: "8vh",
                                                           width: "100%",
                                                           backgroundSize: 'contain',
                                                           backgroundPosition: 'center',
                                                           backgroundRepeat: 'no-repeat',
                                                           backgroundColor: "#00000000",
                                                           
                                                        }
                                                               : {
                                                                    height: "unset",
                                                                    width: "100%",
                                                                    position: "fixed",
                                                                    zIndex: "10",
                                                                    backgroundColor: "#00000000",
                                                                  }   
    return(
      <div>
        <div className={className} style= {style_backgroung_url} name='topHeader' >
        
          
          { (project.onNavbar) ? <Navbar projectName={project.projectName} windowWidth={this.state.windowWidth} /> : null }
          { (project.onHeader) ? <Header /> : null }
          
          { (project.onButton && ((project.onMirror && project.userId>0)|| (!project.onMirror && project.userId<=0)) ) 
                               ? <ButtonBar {...this.props} /> : null }
          
          {/* (project.onGesture) ? 
                                    <HandToCursorPage
                                     containerID= {"HandToCursorContainer"}
                                     style={{ width: "0", height: "0", 'line-height': '0em', 'font-size': '0px' }}
                                     mapping_width={document.documentElement.clientWidth}
                                     mapping_height={document.documentElement.clientHeight}
                                     isImageRotate={this.state.isImageRotate}
                                     camPosSel={project.camPosSel}
                                     onError={this.CameraErrorHandler}
                                     />
                                   : null
                                   
          */ }                                  

         
        </div>
        
          {this.props.children}
        
        { (project.onFooter) ? <Footer {...project} /> : null }

      </div>
    )
  }
}

export default connect(mapState, mapDispatch)(BaseLayout);