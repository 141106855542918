import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import Message from './Message';

import {
  Card, 
  Grid, 
  Segment, 
  Divider, 
  Header, 
  Button,
  Icon,
  Menu,
  Form,
  Image
} from "semantic-ui-react";

import {
  
  
  updateProject,
  updateDataXAR,
  doRTCAR,
  fetchPhotoProject,

} from "../../store";

import { IMAGE_TYPES } from 'jslib-html5-camera-photo';


const mapState = state => ({
  
  suit : state.suit,
  xar  : state.xar,
  project: state.project

});

const mapDispatch = dispatch => {
  return {

    
    getPhotoProject : (params) => dispatch(fetchPhotoProject(params)),
    updateXAR       : (xar) => dispatch(updateDataXAR(xar)),    
    requestRTCAR    : (xar) => dispatch(doRTCAR(xar)),
    
    setProject      : (params) => dispatch(updateProject(params))
  };
};

class RemotePhone_SendJson extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {

      
                    onTakePhoto: false,
                    onShowQRcode: false,
                    QRcodeRef: '',
                    filename: '',
                    photoDone: false,

                    buttonDisabled: false,
                    photoName: ''
                  }

    const { xar, updateXAR, requestRTCAR, project } = this.props;
    this.XARclothesProducts();
    xar.peerID = project.userId;
    console.log("peerID ", xar.peerID)
    updateXAR(xar);
    requestRTCAR(xar);

    console.log("RemotePhone constructor")               
  }

  componentDidUpdate(prevProps, prevState) {
    
    console.log("this.props.project@componentDidUpdate RemotePhone", this.props.project)
    console.log("this.state@componentDidUpdate RemotePhone", this.state)
    console.log("prevProps@componentDidUpdate RemotePhone", prevProps)
    console.log("prevState@componentDidUpdate RemotePhone", prevState)
    
    console.log("this.props.project.photoDone@componentDidUpdate RemotePhone", this.props.project.photoDone)
    console.log("prevProps.project.photoDone@componentDidUpdate RemotePhone", prevProps.project.photoDone)
    console.log("this.state.photoDone@componentDidUpdate RemotePhone", this.state.photoDone)
    console.log("prevState.photoDone@componentDidUpdate RemotePhone", prevState.photoDone)
   
    if (this.props.project.photoDone===true && prevProps.project.photoDone===false) {
      console.log("this.props.project.photoDone===true && prevState.photoDone===false @componentDidUpdate")
      this.setState({photoDone: true}) //no set false, user can always see the photo once photo created
      this.setState({buttonDisabled: false})
      this.setState({photoName: this.props.project.photoName})

    }



  }  

 

  onTakePhoto() {

      this.setState({buttonDisabled: true})

      var tmp = this.props.project
      tmp.onTakePhotoStart = true;


      this.props.setProject(tmp);

      this.handleCountDown(5);


  }

  handleCountDown (countDown) {
      console.log("==================handleCOuntDown", countDown)
      document.getElementById("TimerCount").style.display = 'unset'

      document.getElementById("TimerCount").innerHTML = countDown.toString()
      if(countDown == 0){
        this.handleTakePhoto()
        
      }else{
        setTimeout(() => this.handleCountDown(countDown-1), 1000);
      }
  }



  handleTakePhoto(){

      var tmp = this.props.project
      tmp.onTakePhotoStart = false;
      tmp.onTakePhoto = true;
      tmp.photoDone = false;

      const filename = this.getFileName("png",  this.props.project.projectName);
      const filename_ext = filename[0] + '.' + filename[1];
      
      tmp.photoName = filename_ext;
      
      console.log("setProject@handleTakePhoto RemotePhone")
      this.props.setProject(tmp);

      //query photo
      this.props.getPhotoProject(tmp)

      //disable count down string
      document.getElementById("TimerCount").style.display = 'none'

      console.log("this.props.parentCallback@handleTakePhoto RemotePhone", this.props.parentCallback)
      this.props.parentCallback(tmp.onTakePhoto)
  }


  getFileName (fileType, projectName) {

    console.log('@getFileName')
    const prefix = projectName+'-takephoto';


    var options = {
      day: '2-digit',    //(e.g., 1)
      month: '2-digit',    //(e.g., Oct)
      year: '2-digit',   //(e.g., 2019)
      hour: '2-digit',   //(e.g., 02)
      minute: '2-digit', //(e.g., 02)          
      second: '2-digit',
      hour12:false// 
    };
  
    var now = new Date().toLocaleString('en-us', options,  {timeZone: 'Asia/Taipei', hour12:false}); 

    var photoStamp = now.replace(/\//g,'');
    photoStamp = photoStamp.replace(/:/g, '');
    photoStamp = photoStamp.replace(/,\s/g, '-');
    
    const extention = this.getFileExtention(fileType);

    return [ prefix+"-"+photoStamp, extention];
    
  }
  getFileExtention (blobType) {
    // by default the extention is .png
    let extention = IMAGE_TYPES.PNG;

    if (blobType === 'image/jpeg') {
      extention = IMAGE_TYPES.JPG;
    }
    return extention;
  }



  XARclothesProducts() {
    console.log("============================= clothlist ===============================")
    
    //if mirror mode, mirror side has no product informoation, need get it from webserver,
    const { xar, suit, product } = this.props;
    console.log("this.props @XARclothesProducts: ", this.props)
    console.log("suit @XARclothesProducts: ", suit)
    console.log("suit.suit @XARclothesProducts: ", suit.suit)


    

    const video_filename = xar.picID + '_' + suit.suit.suitID;
    

    const clothlist = suit.suit.suitClothesID;
    const shoes = suit.suit.shoes;

    console.log("clothlist: ", clothlist)

    xar.clothlist = clothlist;
    xar.shoes = shoes;
    xar.suitID = suit.suit.suitID;
    xar.clothesID = "asia2020_f_001_dress_long_red";//product.product.internal_name;
    xar.video_filename = video_filename;
    if(suit.suit.gender === 'male'){
      xar.human.gender = 1;  
    }else
    {
      xar.human.gender = 0;
    }
    

  }

  changeClothes(){
      this.setState({buttonDisabled: false})
      this.setState({photoDone: false})
      console.log("@changeClothes this.props.history", this.props.history)
      this.props.history.go(-2);
  }

  render(){

    const { suit } = this.props;

  
    return (

      <div className="  ui raised very padded text segment container" style={styles.div}>
          
       
                                    <Segment placeholder>
                                      <Grid columns={2}  /*stackable textAlign='center'*/> 
                                       
                                          <Grid.Row verticalAlign='middle' columns={1} >
                                              <Grid.Column>
                                                  <Header>                 
                                                    {/*You can check your virtual fitting looks on the ARmirror now.*/}
                                                    你已進入虛擬試衣間，請觀看魔鏡展示
                                                  </Header>              
                                              </Grid.Column>            
                                          </Grid.Row>  

                                          <Divider horizontal>拍照</Divider>
                                        
                                          <Grid.Row verticalAlign='middle'>
                                              <Grid.Column>
                                                  <Header>
                                                   
                                                      {/*Clik and take a photo now.*/}
                                                      點擊拍照鍵，可收藏虛擬試衣照片
                                                    
                                                  </Header>
                                                 
                                                  

                                              </Grid.Column>

                                              <Grid.Column>
                                                 
                                                  
                                                      
                                                      <Button
                                                          id="TakePhoto"
                                                          disabled={this.state.buttonDisabled}
                                                          style={styles.button}
                                                          icon="photo"
                                                          onClick={() => this.onTakePhoto()}
                                                          size="large" 
                                                          circular
                                                          color='black'
                                                      />    
                                                              

                                                         
                                                  
                                              </Grid.Column>
                                              
                                          </Grid.Row>

                                          <Divider horizontal>換衣</Divider>
                                        
                                          <Grid.Row verticalAlign='middle'>
                                              <Grid.Column>
                                                  <Header>
                                                   
                                                      點擊按鍵可重新選擇衣服
                                                    
                                                  </Header>
                                                 
                                                  

                                              </Grid.Column>

                                              <Grid.Column>
                                                 
                                                  <Button
                                                      id="ChangeClothes"
                                                      disabled={this.state.buttonDisabled}
                                                      style={styles.button}
                                                      icon="undo alternate"
                                                      onClick={() => this.changeClothes()}
                                                      size="large" 
                                                      circular
                                                      color='black'
                                                  />    

                                              </Grid.Column>
                                              
                                          </Grid.Row>
  


                                      </Grid>

                                    </Segment>
                                  
              {this.state.photoDone ? (
                                   
                                    
                                    <Card  disabled centered style={styles.maincard} >
                                        <Card.Header disabled> 你的虛擬試穿照 </Card.Header>
                                        <Image  src={ "/" + this.state.photoName} style={styles.sampleImg} />
                                    </Card>

                                   
                                   )
                                 :(<div></div>)                      
              }                   

              <div id="TimerCount" style={ styles.countdown }></div>


      


      </div>
    );
  };


}

const styles = {
  container: {
    margin: 30
  },
  maincard: {
    margin: 'auto',
    height: '100%',
    width: '100%',
    maxHeight: '75vh'
   

  }, 
  button: {
    
    width: 'unset',
    height: 'unset'

    

  },
  icon: {
    
    alignContent: 'center',
    display: 'grid',
    width:'36px',
    height:'36px',
    padding: '21px'

  },
  sampleCard: {
    height: 'unset',
    color: '#e44299'
  },   
  loginForm:{

    justifyContent: 'center',
    display: 'flex'

  },
  div:{

    marginLeft: 'auto',
    marginRight: 'auto',

    padding: '1.5em',
    borderTop: '4px solid black',
    borderTopWidth: '4px',
    borderTopStyle: 'solid',
    borderTopColor: 'black'    

  },
  sampleImg: {
    /*transform: 'scale(1.0)'*/
    width: '100%',
    height: '100%'
  },
  
  photoDescription:{
    marginLeft: '5%',
    color: '#e44299'
  },
  countdown: {

    position: 'fixed',
    left: '25.5%',
    top: '25%',
    'z-index': 999,
    'font-size': '300px',
    color: 'rgb(255, 76, 143)',
    width: '50%'
   

  },
};


export default connect(mapState, mapDispatch)(withRouter(RemotePhone_SendJson));
