import axios from 'axios'
import produce from 'immer';
import store from '../../store';


/**
 * ACTION TYPES
 */
export const SET_XAR = 'SET_XAR'
export const REQ_XAR = 'REQ_XAR'
export const GET_XAR = 'GET_XAR'
export const FETCH_XAR = 'FETCH_XAR'
export const UPLOAD_XAR = 'UPLOAD_XAR'
export const UPDATE_XAR = 'UPDATE_XAR'
export const SAMPLE_XAR = 'SAMPLE_XAR'

export const SINGLE_XAR = 'SINGLE_XAR'
export const MULTI_XAR  = 'MULTI_XAR'

/**
 * INITIAL STATE
 */
const initialXARdata = {
    id: 0,
    name: '',
    url: '',
    time: '',
    
    photoURI: '',

    human:{
            gender:0
          },

    picID: '',
    picExt: 'jpg',
    
    clothesID: '',
    
    suitID: '',

    clothlist: [
              'vsdemo_female_style43'
              ],
    shoes : "vsdemo_male_leathershoe001",    
     //wholeshoes: ["vsdemo_female_sneaker001", "vsdemo_female_highheels001", "vsdemo_male_leathershoe001"],      
    
    isRequest: false,                
    isUpdate: false,
    isUpload: {
                photoUpload: false,
                clothlistUpload: false  
              },
    isShoot: false,

    isRTC: false,
    peerID: 0,
    
    
    XARimg: [
        {
          id: 1,
          img: '/'
        },
        {
          id: 2,
          img: '/'
          //for Havas project, img: '/images/SKYLINES_logo_120x120.jpg'
        },
        {
          id: 3,
          img: '/'          
        },
        {
          id: 4,
          img: '/'
        }
      ], 

    enable_log: false,


    //------ photo setting start----//
     
/*
    resetAfterfetch: true,        
    
    isPrerender: false,
    
    photoBackgroundName: 'vismile_background.png',
    photoFrameName: 'vismile_frame.png',
    
    photoBodyScale: 1.0,
    fixedBackground : true,
    photoHeadLeftUpPosition: [ 0, 0 ], 
    photoHeadHeight: 0,
    CameraScale : 1.65,
    photoBodyRightBottomConstraint : [842, 1505],

    photoBodyPos: [ 0.0,  0.0 ],
    renderVideo: 'none',
    videoQuality: 'HD',
    renderNearScale: 1.0,
    image_size : [1080, 1920], //for ARmirror webrtc streaming size
    cameraFrames : [
                       { 
                         Position : [-0.0181445, 0.9, -1.561946], 
                         Target : [-0.0183055, 0.9, -1.0580287] 
                       }
                   ],
    extraOutputFileFormat : "jpg", //jpg mov
    extraOutputFileHead : "asia-takephoto-100720-161857_Vismile_male_set_008",

    lightList : [
                    {
                        Geometry : "Sphere",
                        KindOfLight : "DiffuseLight",
                        Position : [1.065000, 4.379000, 4.142000],
                        Radius : 2.840000,
                        Diffuse : [26.923000, 26.627001, 23.372999],
                        FixToCamera : false
                    },
                    {
                        Geometry : "Sphere",
                        KindOfLight : "DiffuseLight",
                        Position : [-3.203410, -8.166000, 0.592000],
                        Radius : 6.391000,
                        Diffuse : [11.243000, 10.355000, 10.651000],
                        FixToCamera : false
                    },
                    {
                        Geometry : "Sphere",
                        KindOfLight : "DiffuseLight",
                        Position : [3.203410, 2.959000, 0.592000],
                        Radius : 2.249000,
                        Diffuse : [4.438000, 3.254000, 2.959000],
                        FixToCamera : false
                    },
                    {
                        Geometry : "Background",
                        Diffuse : 0.880000
                    }
                ],


    CameraAngleCorrection : [0, 0, 0],
    PoseCorrectMethod : ["BentLegsToStraightLegs"],
    HeadAngleCorrection : [0, 5, 0],
    HeadReconstructionMaskScaleFactor : 0.94868,

    camOrientation: 0, //0: landscape, 1: 90deg 2: 180deg 3: 270deg

*/
    //AI-fitting
    uploadImgSize: [0, 0]

    //------ photo setting end ----//




}

const data_download = {
    id: 0,
    name: '',    
    photoURI: '',
    human:{
            gender:0
          },
    clothesID: '',    
    suitID: '',
    clothlist: [
              'vsdemo_female_style43'
              ]    
}

const options = {

    day: '2-digit',    //(e.g., 1)
    month: '2-digit',    //(e.g., Oct)
    year: '2-digit',   //(e.g., 2019)
    hour: '2-digit',   //(e.g., 02)
    minute: '2-digit', //(e.g., 02)          
    second: '2-digit',
    hour12:false// 
};

/**
 * ACTION CREATORS
 */

const getXARsetting = XARdata => ({type: SET_XAR, XARdata});
const reqXAR = XARdata => ({type: REQ_XAR, XARdata});
const getXAR = XARdata => ({type: FETCH_XAR, XARdata});
const uploadXAR = XARdata => ({type: UPLOAD_XAR, XARdata});
const updateXAR = XARdata => ({type: UPDATE_XAR, XARdata});
const sampleXAR = XARdata => ({type: SAMPLE_XAR, XARdata});

const GoAIfittingSingle   = XARdata => ({type: SINGLE_XAR, XARdata});
const GoAIfittingMultiple = XARdata => ({type: MULTI_XAR, XARdata});


/**
 * THUNK CREATORS
 */

var encode = function( s ) {
  var out = [];
  for ( var i = 0; i < s.length; i++ ) {
    out[i] = s.charCodeAt(i);
  }
  return new Uint8Array( out );
}



export const fetchXARsetting = (xar_setting) => {
  return async dispatch => {
    
    //console.log("xar@xar.js", xar_setting)
    console.log('store@fetchXARsetting', store)
    const body = {
                  databaseName: store.getState().project.projectName,
                 }

    const {data} = await axios.post(`/api/xar/setting`, body)
   
    
    dispatch(getXARsetting(data))
     
  }
}

export const AIfittingSingle = (xar_necessary) => {
  return async dispatch => {

    /*
    xar_necessary = {

        photoURI: '',
        clothesID: '' 


    }
    */
    const {data} = await axios.post(`/api/aifitting/tryonSingle`, xar_necessary);
    dispatch(reqXAR(data))   
    console.log("XAR doXAR data: ", data);
    console.log("XAR doXAR done, can go getXAR");
    
  }
}

export const AIfittingMultiple = (xar_necessary) => {
  return async dispatch => {

    /*
    xar_necessary = {

        photoURI: '',
        clothlist: [
                    '',
                    ''
                   ],
        shoes : "",   


    }
    */
    let xar_removeURI = xar_necessary;
    xar_removeURI.photoURI = '';
    console.log("XAR doXAR reqFormat: ", xar_removeURI);
    
    const {data} = await axios.post(`/api/aifitting/tryonMulti`, xar_removeURI);
    data.photoURI = xar_necessary.photoURI;
    dispatch(reqXAR(data))   //only isUpload.clothlistUpload set true by server
    console.log("XAR doXAR data: ", data);
    console.log("XAR doXAR done, can go getXAR");
    
  }
}



export const doXAR = (xar) => {
  return async dispatch => {

    console.log('store@doXAR', store)
    
    //const clothlist = ['vsdemo_female_style43'];
    //const gender = 0;
    //const picID = '0_Website_vismileshaping_2020-0327-191536';
    const ClientInfo   = store.getState().project.ClientInfo;
    const databaseName = store.getState().project.projectName;
              
            
    console.log("store.getState().project: ", store.getState().project)
    console.log("ClientInfo: ", ClientInfo)
    console.log("databaseName: ", databaseName)

    let xar_removeURI = xar;
    xar_removeURI.photoURI = '';
    console.log("XAR doXAR reqFormat: ", xar_removeURI);
    var test = {};
    test = {...xar_removeURI,  databaseName, ...ClientInfo};
    console.log("test: ", test)
    const {data} = await axios.post(`/api/xar/tryon`, {...xar_removeURI,  databaseName, ...ClientInfo} );
    data.photoURI = xar.photoURI;
    dispatch(reqXAR(data))   //only isUpload.clothlistUpload set true by server
    console.log("XAR doXAR data: ", data);
    console.log("XAR doXAR done, can go getXAR");
    
  }
}

export const fetchXAR = (xar) => {
  return async dispatch => {
    try {  
      //const clothlist = ['vsdemo_female_style43'];
      //const gender = 0;
      //const picID = 'aichure_cloth1_0_Website_vismileshaping_2020-0327-191536.jpg';

      let xar_removeURI = xar;
      xar_removeURI.photoURI = '';

      console.log("XAR getPhoto @fetch: ", xar_removeURI);
      const {data} = await axios.post(`/api/xar/getPhoto`, xar_removeURI);
      console.log("XAR fetchXAR return: ", data);
      console.log("XAR fetchXAR return data.picID: ", data.picID);
      
      console.log('store@fetchXAR', store)
      if(xar.resetAfterfetch){
      //reset clothlist
          data.isShoot = false;
          data.isUpload.clothlistUpload = false;
          data.isUpload.photoUpload = false;
          data.isRequest = false;
      }
      //data.photoURI = xar.photoURI;

      console.log("end of fetchXAR");
      //dispatch(getXAR(initialXARdata));
      dispatch(getXAR(data));

      data_download.id = data.id;
      data_download.name = data.name;
      data_download.photoURI = data.photoURI;
      data_download.human = data.human;
      data_download.clothesID = data.clothesID;
      data_download.suitID = data.suitID;
      data_download.clothlist = data.clothlist;
      data_download.shoes = data.shoes;

      if(store.getState().project.enable_log){
          //export log 
          var now = new Date().toLocaleString('en-us', options,  {timeZone: 'Asia/Taipei', hour12:false}); 
          var log_filename = now.replace(/\//g,'');
          log_filename = log_filename.replace(/:/g, '');
          log_filename = log_filename.replace(/,\s/g, '-');
          log_filename = log_filename + '.json'
          
          var str = JSON.stringify(data_download);
          var str_bin = encode( str );

          var blob = new Blob( [ str_bin ], {
              type: 'application/octet-stream'
          });

          window.URL = window.webkitURL || window.URL;

          let anchor = document.createElement('a');
          anchor.download = log_filename;
          anchor.href = window.URL.createObjectURL(blob);      

          let mouseEvent = document.createEvent('MouseEvents');
          mouseEvent.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
          anchor.dispatchEvent(mouseEvent);

          //--end of export log
      }

      console.log("XAR fetchXAR return isUpdate: ", data.isUpdate);
      console.log("XAR fetchXAR return XARimg[0]: ", data.XARimg[0].img);
      console.log("XAR fetchXAR return XARimg[1]: ", data.XARimg[1].img);
      console.log("XAR fetchXAR return XARimg[2]: ", data.XARimg[2].img);
      
    }catch (err) {
      console.error(err)
    }
  }
}

export const uploadPhotoXAR = (xar, formData) => {
  return async dispatch => {

    //reqFormat.isUpload = true;

    //console.log("formData@uploadPhotoXAR : ", formData);
    const body = {
                  databaseName: store.getState().project.projectName,
                  xar: xar
                 }

    //formData.set('xar', JSON.stringify(xar));
    formData.set('onDemoSite', store.getState().project.onDemoSite);
    formData.set('databaseName', store.getState().project.projectName);

    const res =  await axios.post('/api/xar/uploadPhoto', formData, 
                                   {
                                      headers: { 'Content-Type': 'multipart/form-data' }
                                   }
                 );


    //const {data} = await axios.post('/api/xar/uploadPhoto', xar) //temporaty upload by takephoto.js server.js
    
    console.log("XAR uploadPhotoXAR return: ", res);
    console.log("data.isUpload : ", res.data.isUpload);
    
    xar.isUpload.photoUpload = res.data.isUpload;  
    
    dispatch(uploadXAR(xar));
    console.log("XAR uploadPhotoXAR xar.isUpload: ", xar.isUpload.photoUpload);
    

    //history.push(`/products/product/${productID}`)
  }
};


export const updateDataXAR = (xar)  => {
  return async dispatch => {

    //console.log("xar in updateDataXAR: ", xar);
    dispatch(updateXAR(xar));
    //console.log("XAR updateXAR");
  }
};


export const selectSampleXAR = (xar) => {
  return async dispatch => {

    //reqFormat.isUpload = true;
    const body = {
                  databaseName: store.getState().project.projectName,
                  xar: xar
                 }

   
   

    console.log("filename@samplePhotoXAR : ", xar.picID);
    //const ClientInfo   = store.getState().project.ClientInfo;
    //const databaseName = store.getState().project.projectName;    
    //const res =  await axios.post('/api/xar/useSample', {...xar,  databaseName, ...ClientInfo} );
    const res =  await axios.post('/api/xar/useSample', body );


   
    
    xar.isUpload.photoUpload = res.data.isUpload;  
    
    dispatch(sampleXAR(xar));
    console.log("XAR uploadPhotoXAR xar.isUpload: ", xar.isUpload.photoUpload);
    

    //history.push(`/products/product/${productID}`)
  }
};

export const doRTCAR = (xar) => {
  return async dispatch => {

    //const clothlist = ['vsdemo_female_style43'];
    //const gender = 0;
    //const picID = '0_Website_vismileshaping_2020-0327-191536';
    const ClientInfo   = store.getState().project.ClientInfo;
    const databaseName = store.getState().project.projectName;
    const remoteUserId = store.getState().project.remoteUserId;      
    const rtcServerMode = store.getState().project.rtcServerMode;    
    const rtcServerId = store.getState().project.rtcServerId;    
    const rtcDetectFootZone = store.getState().project.rtcDetectFootZone;    

    //const canvasResolution = store.getState().webrtc.canvasResolution;         

    console.log("store.getState().project: ", store.getState().project)
    console.log("store.getState().webrtc: ", store.getState().webrtc.canvasResolution)
    console.log("ClientInfo: ", ClientInfo)
    console.log("databaseName: ", databaseName)

    let xar_removeURI = xar;
    xar_removeURI.photoURI = '';
    console.log("XAR doRTCAR reqFormat: ", xar_removeURI);
    //var test = {};
    //test = {...xar_removeURI,  databaseName, ...ClientInfo};
    //console.log("test: ", test)
    //const {data} = await axios.post(`/api/xar/rtcfitting`, {...xar_removeURI,  databaseName, remoteUserId, canvasResolution, ...ClientInfo} );
    const {data} = await axios.post(`/api/xar/rtcfitting`, {...xar_removeURI,  databaseName, remoteUserId, rtcServerMode, rtcServerId, rtcDetectFootZone, ...ClientInfo} );
    dispatch(reqXAR(data))   //only isUpload.clothlistUpload set true by server
    console.log("XAR doRTCAR data: ", data);
    console.log("XAR doRTCAR done, can go peerconnection");
    
  }
}


/**
 * REDUCER
 */
export default function(state = initialXARdata, action) {
  switch (action.type) {
    case SET_XAR:
      return produce(state, data => {
                                      data.resetAfterfetch                   = action.XARdata.resetAfterfetch;
                                      data.isPrerender                       = action.XARdata.isPrerender;
                                      data.photoBackgroundName               = action.XARdata.photoBackgroundName;
                                      data.photoFrameName                    = action.XARdata.photoFrameName;
                                      data.photoBodyScale                    = action.XARdata.photoBodyScale;
                                      data.fixedBackground                   = action.XARdata.fixedBackground;
                                      data.photoHeadLeftUpPosition           = action.XARdata.photoHeadLeftUpPosition;
                                      data.photoHeadHeight                   = action.XARdata.photoHeadHeight;
                                      data.CameraScale                       = action.XARdata.CameraScale;
                                      data.photoBodyRightBottomConstraint    = action.XARdata.photoBodyRightBottomConstraint;
                                      data.photoBodyPos                      = action.XARdata.photoBodyPos;
                                      data.renderVideo                       = action.XARdata.renderVideo;
                                      data.videoQuality                      = action.XARdata.videoQuality;
                                      data.renderNearScale                   = action.XARdata.renderNearScale;
                                      data.image_size                        = action.XARdata.image_size;
                                      data.cameraFrames                      = action.XARdata.cameraFrames;
                                      data.extraOutputFileFormat             = action.XARdata.extraOutputFileFormat;
                                      data.extraOutputFileHead               = action.XARdata.extraOutputFileHead;
                                      data.lightList                         = action.XARdata.lightList;
                                      data.CameraAngleCorrection             = action.XARdata.CameraAngleCorrection;
                                      data.PoseCorrectMethod                 = action.XARdata.PoseCorrectMethod;
                                      data.HeadAngleCorrection               = action.XARdata.HeadAngleCorrection;
                                      data.HeadReconstructionMaskScaleFactor = action.XARdata.HeadReconstructionMaskScaleFactor;
                                      data.modelPose                         = action.XARdata.modelPose;
                                      data.camOrientation                    = action.XARdata.camOrientation;
                                      

                                    }
                    );


   
    case REQ_XAR:
      return produce(state, data => {
                                      data.isUpload.clothlistUpload = true;
                                    }
                    );

   
    case FETCH_XAR:
      return action.XARdata
      
    case UPLOAD_XAR:
      return produce(state, data => {
                                      data.isUpload.photoUpload = true;
                                    }
                    );
    case SAMPLE_XAR:
      return produce(state, data => {
                                      data.isShoot = true;
                                      data.isUpload.photoUpload = true;
                                    }
                    );      
    case UPDATE_XAR:
      /*
      return {
            ...state,
            data: state.map(data => action.find(el => el === data) || data )

        };
    */
      return action.XARdata

    case SINGLE_XAR:
      return produce(state, data => {
                                      data.XARimg                   = action.XARdata.XARimg;
                                      
                                    }
                    );

    case MULTI_XAR:
      return produce(state, data => {
                                      data.XARimg                   = action.XARdata.XARimg;
                                      
                                    }
                    );



    default:
      return state
  }
};
