export default `

    uniform vec3 keyColor;
    uniform float similarity;
    uniform float smoothness;
    varying vec2 vUv;
    uniform sampler2D map;
    uniform float spill;
    uniform vec2 pixel_size;

// From https://github.com/libretro/glsl-shaders/blob/master/nnedi3/shaders/rgb-to-yuv.glsl
vec2 RGBtoUV(vec3 rgb) {
  return vec2(
    rgb.r * -0.169 + rgb.g * -0.331 + rgb.b *  0.5    + 0.5,
    rgb.r *  0.5   + rgb.g * -0.419 + rgb.b * -0.081  + 0.5
  );
}

vec3 KeyColorCorr(vec3 rgb){
  rgb.r = (rgb.r > 0.5) ? 1.0 : 0.0;
  rgb.g = (rgb.g > 0.5) ? 1.0 : 0.0;
  rgb.b = (rgb.b > 0.5) ? 1.0 : 0.0;
  return rgb;
}

/*void make_kernel(inout vec4 n[9])
{
  float w = pixel_size.x;
  float h = pixel_size.y;

  n[0] = texture2D(map, vUv + vec2( -w, -h));
  n[1] = texture2D(map, vUv + vec2(0.0, -h));
  n[2] = texture2D(map, vUv + vec2(  w, -h));
  n[3] = texture2D(map, vUv + vec2( -w, 0.0));
  n[4] = texture2D(map, vUv);
  n[5] = texture2D(map, vUv + vec2(  w, 0.0));
  n[6] = texture2D(map, vUv + vec2( -w, h));
  n[7] = texture2D(map, vUv + vec2(0.0, h));
  n[8] = texture2D(map, vUv + vec2(  w, h));
}*/

vec4 GetBoxFilteredChromaDist(vec4 rgba, /*inout vec3 avgRGB,*/ vec3 sample_KeyColor)
{
  vec2 h_pixel_size = pixel_size / 2.0;
  
  vec2 point_hor = vec2(pixel_size.x,    h_pixel_size.y);
  vec2 point_ver = vec2(h_pixel_size.x, -pixel_size.y);

  vec4 rgbaLeft  = texture2D(map, vUv - point_hor);
  vec4 rgbaRight = texture2D(map, vUv + point_hor);
  vec4 rgbaUp    = texture2D(map, vUv - point_ver);
  vec4 rgbaDown  = texture2D(map, vUv + point_ver);

  float distN[5];

  distN[0]      = distance(RGBtoUV(rgba.rgb),      RGBtoUV(sample_KeyColor.rgb)); //GetChromaDist(SampleTexture(texCoord-point_0).rgb);
  distN[1]      = distance(RGBtoUV(rgbaLeft.rgb),  RGBtoUV(sample_KeyColor.rgb)); //GetChromaDist(SampleTexture(texCoord-point_0).rgb);
  distN[2]      = distance(RGBtoUV(rgbaRight.rgb), RGBtoUV(sample_KeyColor.rgb)); //GetChromaDist(SampleTexture(texCoord-point_0).rgb);
  distN[3]      = distance(RGBtoUV(rgbaUp.rgb),    RGBtoUV(sample_KeyColor.rgb)); //GetChromaDist(SampleTexture(texCoord-point_0).rgb);
  distN[4]      = distance(RGBtoUV(rgbaDown.rgb),  RGBtoUV(sample_KeyColor.rgb)); //GetChromaDist(SampleTexture(texCoord-point_0).rgb);
  
  float distVal = distN[1] + distN[2] + distN[3] + distN[4];
  distVal      *= 2.0;

  distVal      += distN[0];

  //---
  float alphaN[5];
  alphaN[0] = pow(clamp( (distN[0]-similarity)  / smoothness, 0., 1.), 1.5);
  alphaN[1] = pow(clamp( (distN[1]-similarity)  / smoothness, 0., 1.), 1.5);
  alphaN[2] = pow(clamp( (distN[2]-similarity)  / smoothness, 0., 1.), 1.5);
  alphaN[3] = pow(clamp( (distN[3]-similarity)  / smoothness, 0., 1.), 1.5);
  alphaN[4] = pow(clamp( (distN[4]-similarity)  / smoothness, 0., 1.), 1.5);


  float cntAvg = 0.0;
  vec3 similarRGB[4];
  vec3 avgRGB;

  if(distN[0] < similarity){//self is background, then sample un-similar others

    if(abs(distN[0] - distN[1]) > similarity){
      cntAvg = cntAvg+1.0; 
      similarRGB[0] = rgbaLeft.rgb;
    }
    if(abs(distN[0] - distN[2]) > similarity){
      cntAvg = cntAvg+1.0; 
      similarRGB[1] = rgbaRight.rgb; 
    }
    if(abs(distN[0] - distN[3]) > similarity){ 
      cntAvg = cntAvg+1.0; 
      similarRGB[2] = rgbaUp.rgb; 
    }
    if(abs(distN[0] - distN[4]) > similarity){ 
      cntAvg = cntAvg+1.0; 
      similarRGB[3] = rgbaDown.rgb;  
    }

    avgRGB = similarRGB[0] + similarRGB[1] + similarRGB[2] + similarRGB[3];
    if(cntAvg==0.0){
        avgRGB = vec3(0,1,0);//rgba.rgb;
    }else{
        avgRGB = avgRGB/cntAvg;
    }

  }else{//self is clothes, then sample similar others

    if(abs(distN[0] - distN[1]) < similarity){
      cntAvg = cntAvg+1.0; 
      similarRGB[0] = rgbaLeft.rgb;
    }
    if(abs(distN[0] - distN[2]) < similarity){
      cntAvg = cntAvg+1.0; 
      similarRGB[1] = rgbaRight.rgb; 
    }
    if(abs(distN[0] - distN[3]) < similarity){ 
      cntAvg = cntAvg+1.0; 
      similarRGB[2] = rgbaUp.rgb; 
    }
    if(abs(distN[0] - distN[4]) < similarity){ 
      cntAvg = cntAvg+1.0; 
      similarRGB[3] = rgbaDown.rgb;  
    }

    avgRGB = similarRGB[0] + similarRGB[1] + similarRGB[2] + similarRGB[3] + rgba.rgb;
    if(cntAvg==0.0){
        avgRGB = vec3(1,0,0);//rgba.rgb;
    }else{
        avgRGB = avgRGB/(cntAvg+1.0);
    }


  }  

//---
/*
    if(alphaN[1] > 0.8){
      cntAvg = cntAvg+1.0; 
      similarRGB[0] = rgbaLeft.rgb;
    }
    if(alphaN[2]) > 0.8){
      cntAvg = cntAvg+1.0; 
      similarRGB[1] = rgbaRight.rgb; 
    }
    if(alphaN[3]) > 0.8){ 
      cntAvg = cntAvg+1.0; 
      similarRGB[2] = rgbaUp.rgb; 
    }
    if(alphaN[4]) > 0.8){ 
      cntAvg = cntAvg+1.0; 
      similarRGB[3] = rgbaDown.rgb;  
    }

    if(alphaN[0] < 0.2){//self is background, then sample un-similar others
     
      avgRGB = similarRGB[0] + similarRGB[1] + similarRGB[2] + similarRGB[3];
      if(cntAvg==0.0){
          avgRGB = vec3(0,1,0);//rgba.rgb;
      }else{
          avgRGB = avgRGB/cntAvg;
      }

    }else{


      avgRGB = similarRGB[0] + similarRGB[1] + similarRGB[2] + similarRGB[3] + rgba.rgb;
      if(cntAvg==0.0){
          avgRGB = vec3(1,0,0);//rgba.rgb;
      }else{
          avgRGB = avgRGB/(cntAvg+1.0);
      }

    }
*/
    avgRGB = rgba.rgb * alphaN[0] + rgbaLeft.rgb * alphaN[1] + rgbaRight.rgb * alphaN[2] + rgbaUp.rgb * alphaN[3] + rgbaDown.rgb * alphaN[4];
    avgRGB = avgRGB/5.0;


//---    

   

  

  //---
  vec4 result;
  result.a = distVal / 9.0;
  result.xyz = avgRGB;
  //return distVal;
  return result;
}

vec4 ProcessChromaKey() {

  vec3 avgRGB;
  vec4 rgba = texture2D(map, vUv);
  vec4 chromeResult;

  vec4 sample_Texture = texture2D(map, vec2(0, 1));

  vec3 sample_KeyColor = KeyColorCorr(sample_Texture.rgb);  
  
  if( all( equal( sample_Texture.rgb, vec3(0,0,0) ) ) ){
    sample_KeyColor = keyColor;
  }
  
  float chromaDist = 0.0;
  //if(vUv.x<0.5){
  //  chromaDist = distance(RGBtoUV(rgba.rgb), RGBtoUV(sample_KeyColor.rgb));
  //}else{
    chromeResult = GetBoxFilteredChromaDist(rgba, /*avgRGB,*/ sample_KeyColor);
    chromaDist = chromeResult.a;
    avgRGB = chromeResult.xyz;

  //}
  
  float baseMask = chromaDist - similarity;
  float fullMask = pow(clamp(baseMask / smoothness, 0., 1.), 1.5);
  float spillVal = pow(clamp(baseMask / spill, 0., 1.), 1.5);

  float sawIndex = pow(clamp(baseMask / smoothness, 0., 1.), 1.5);

  rgba.a = min(fullMask, rgba.a);

  float desat = clamp(rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722, 0., 1.);
  
  //
  //    x x x o 
  //    x x o o 
  //    x o o o
  //    o o o o 
  //

  //if(vUv.x>=0.5 ){

    
    //rgba.a = 1.0;//(1.0 - rgba.a)/1.0;
    
    //if( sawIndex>0.001 && sawIndex<0.999 ){

      //rgba.rgb = avgRGB;
      //rgba.rgb = vec3(1, 1, 1);
  
    //}
    //
    
  //}    




    rgba.rgb = clamp( mix(vec3(desat, desat, desat), rgba.rgb, spillVal), 0., 1.); //desat*(1-spillVal)+rgba.rgb*spillVal


  //ink
  if(vUv.x == pixel_size.x*540.0 ){
    rgba.a = 1.0;
    rgba.rgb = vec3(1, 1, 1);

  }
  if(vUv.x == pixel_size.x*541.0 ){
    rgba.a = 1.0;
    rgba.rgb = vec3(1, 0, 0);

  }
  //end of ine
  
  /*if(vUv.y >0.9){
      rgba = texture2D(map, vUv);
      rgba.a = 1.0;
  }*/

  if(vUv.y >= 0.96){
      return vec4(0, 0, 0, 0);
  }

  //return texture2D(map, vUv);


  return rgba;
}

void main() {

  //vec4 n[9];
  //make_kernel( n );
  //vec4 sobel_edge_h = n[2] + (2.0*n[5]) + n[8] - (n[0] + (2.0*n[3]) + n[6]);
  //vec4 sobel_edge_v = n[0] + (2.0*n[1]) + n[2] - (n[6] + (2.0*n[7]) + n[8]);

  //vec4 sobel = sqrt((sobel_edge_h * sobel_edge_h) + (sobel_edge_v * sobel_edge_v));

  gl_FragColor = ProcessChromaKey();
}

/*void main( )  {
    
        vec4 videoColor = texture2D(map, vUv);

        float Y1 = 0.299 * keyColor.r + 0.587 * keyColor.g + 0.114 * keyColor.b;
        float Cr1 = keyColor.r - Y1;
        float Cb1 = keyColor.b - Y1;
        
        float Y2 = 0.299 * videoColor.r + 0.587 * videoColor.g + 0.114 * videoColor.b;
        float Cr2 = videoColor.r - Y2; 
        float Cb2 = videoColor.b - Y2; 
        
        float blend = smoothstep(similarity, similarity + smoothness, distance(vec2(Cr2, Cb2), vec2(Cr1, Cb1)));
        gl_FragColor = vec4(videoColor.rgb, videoColor.a * blend); 
        
}*/
`
