import React, { Component } from "react";
import { postCart, putCart, postGuestCart, putGuestCart, fetchReviewsOfProduct } from "../../store";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Image, Rating, Button, Icon, Grid } from "semantic-ui-react";

import './products.css'

const mapState = state => ({
  project: state.project,
  user: state.user,
  cart: state.cart,
  reviews: state.reviews
});

const mapDispatch = dispatch => ({
  addToCart: input => dispatch(postCart(input)),
  editProductQuantity: data => dispatch(putCart(data)),
  addToGuestCart: product => dispatch(postGuestCart(product)),
  editGuestQuantity: productId => dispatch(putGuestCart(productId)),
  getProductReviews: id => dispatch(fetchReviewsOfProduct(id))
});

class ProductCard extends Component {
  constructor() {
    super();
    this.state = {
      inCart: false,
      inventoryReq: 0,
      isGuest: true
    };
  }

  componentDidMount() {
    const { project, user, cart, product, getProductReviews } = this.props;
    const { id } = product;

    if(project.showReviews){
        getProductReviews(id);
    }
    // if user is logged in, set isGuest to false
    if (user.id) {
      this.setState({ isGuest: false });
    }

    console.log("cart@productCard didmount", cart)
    if(cart){
      cart.forEach(elem => {
        console.log("elem@forEach", elem)
        if (elem.productId === id) {
          this.setState({ inCart: true, inventoryReq: elem.inventoryReq });
        }
      });
    }
  }

  addToCartSubmit(productId, userId) {
    const {
      addToCart,
      editProductQuantity,
      addToGuestCart,
      editGuestQuantity,
      product
    } = this.props;
    let { inCart, inventoryReq, isGuest } = this.state;
    const quantity = inventoryReq + 1;

    // if the product isn't in the cart && it's a guest
    if (!inCart && isGuest) {
      addToGuestCart(product);
      this.setState({ inCart: true, inventoryReq: 1 });
    }

    // if product is inCart and the user is a guest
    if (inCart && isGuest) {
      editGuestQuantity({ productId, inventoryReq: quantity });
      this.setState({ inventoryReq: quantity });
    }

    // if the product isn't in the cart && user is logged in
    if (!inCart && !isGuest) {
      addToCart({ productId, userId: userId });
      this.setState({ inCart: true, inventoryReq: 1 });
    }

    // if the product is in the cart && user is logged in
    if (inCart && !isGuest) {
      editProductQuantity({ quantity, productId, userId });
      this.setState({ inventoryReq: quantity });
    }
  }

  renderAddProductButton() {
    const { product, user } = this.props;
    const { inventory } = product;
    const { inventoryReq } = this.state;

    if (inventory === inventoryReq) {
      return (
        <Button
          icon
          compact
          circular
          negative
          disabled
          floated="right"
          size="small"
          style={styles.button}
        >
          <Icon name="x" />
        </Button>
      );
    } else {
      return (
        <Button
          icon
          compact
          circular
          floated="right"
          style={styles.button}
          onClick={() => this.addToCartSubmit(product.id, user.id)}
        >
          <Icon name="add to cart" />
        </Button>
      );
    }
  }

  render() {
    const { project, key, product, reviews, category } = this.props;
    const { id, suitID, imageUrl, name, price, description } = product;
    const { projectName } = project;



    //console.log("this.props@ProductCard: ", this.props)
    //console.log("product@ProductCard: ", product)
    //console.log("id@ProductCard: ", id)
    //console.log("key@ProductCard: ", key)
    //console.log("reviews@ProductCard: ", reviews)
    //console.log("category@ProductCard: ", category)
    

    if (reviews.length > 0) {
      const average =
        reviews.reduce((accum, currentValue) => accum + currentValue) /
        reviews.length;
    }
    let productLink = "";
    if(category.name==='Men' || category.name==='Women'){
        const isSuit = 1;
        productLink = `/${projectName}/suits/suit/${id}/${isSuit}`; //render suits for category as Men/Women
        //renderProducts = category.products;
      }else{
        const isSuit = 0;
        productLink = `/${projectName}/suits/suit/${id}/${isSuit}`;
        //productLink = "/products/product/" + product.id;
    }
    //console.log("productLink@ProductCard: ", productLink)
    
    return (
      <Card as={Link}  to={productLink} style={styles.card}>
        <Image src={imageUrl} style={styles.cardImage}/>
        <Card.Content style={styles.cardContent}>

          <Grid style={styles_grid}>
            <Grid.Row style={styles_row}>
              {/*<Grid.Column width={5}>*/}
              <Grid.Row style={styles_content}>
                <Card.Header className="product-header">
                    <Link to={productLink} style={styles.itemDescription}>{name}</Link>
                    
                </Card.Header>
             {/* </Grid.Column>*/}
              </Grid.Row>

              <Grid.Row style={styles_content}>
              {/*<Grid.Column width={11}>*/}
             
                {/*<Card.Description>{description.slice(0, 80)}.</Card.Description>  for Havas project*/}
                <Card.Description style={styles.itemDescription}>{description.slice(0, 40)}.</Card.Description>  
              
              {/*</Grid.Column>*/}
              </Grid.Row>
              

            </Grid.Row>
            
            
            <Grid.Row>
              <Grid.Column width={5}>
                <Card.Header className="product-header">
                 
                      {product ? this.renderAddProductButton() : ""}
                      
                </Card.Header>
              </Grid.Column>

              <Grid.Column width={11}>
                {/*<Card.Meta className="product-meta">Price: ${price}</Card.Meta>*/} 
                <Card.Meta className="product-meta"></Card.Meta>  {/* price remove for Career project */}
                <Rating icon="star" defaultRating={4} maxRating={5} disabled />
              </Grid.Column>
              
            </Grid.Row>
          

          </Grid>

        </Card.Content>
      </Card>
    );
  }
}

const styles = {
  button: {
    marginRight: 0,
    float: 'none',
    width: 'auto',
    height: 'auto'
    
  },
  card: {
    /*height: 'unset', removed for small size to easiler gesture control */
    /*minWidth: '290px'*/
    height: '100%'
  },
  cardContent: {
    minHeight: 'unset',
  },
  cardImage: {
    width: '100%',
  },
  itemDescription:{
    
    color: '#545454'
  }    
};


const styles_row = {
  paddingBottom: '0', /* Remove padding */
  paddingLeft: '0', /* Remove padding */
  paddingRight: '0', /* Remove padding */
};
const styles_grid = {
  paddingTop: '0', /* Remove padding */
  paddingBottom: '0', /* Remove padding */
};

const styles_content = {
  paddingLeft: '1rem',   
  
};

export default connect(mapState, mapDispatch)(ProductCard);
