import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import { RecommendGrid } from "../../components";
import { updateProject } from "../../store";

const mapState = state => ({
  project: state.project
  
});

const mapDispatch = dispatch => ({
  setProject: (params) => dispatch(updateProject(params))

});

class RecommendPage extends Component {

/*
  constructor(props) {
    super(props);

    const projectName = this.props.match.params.projectName.toLowerCase();
    const { project, setProject } = this.props;
    var params = project;   
    params.projectName = projectName


    this.state = {
      
      projectName: projectName      

    };
    setProject(params);
    

    
  }
*/
  componentDidMount() {
    
  }

  render() {
    const { recommends, title } = this.props;
    console.log('recommends: ', recommends)
    return (
      <div
        className=""
        style={styles.div}
      >
        <Header as="h2">{title}</Header>
        {recommends.length > 0 ? (
          <RecommendGrid recommends={recommends} />
        ) : (
          <Segment>
            Sorry, there's currently no recommendations for this product.
          </Segment>
        )}
        
      </div>
    );
  }
}

const styles = {
  div: {
    marginTop: 0
  }
};

export default connect(mapState, mapDispatch)(RecommendPage);
