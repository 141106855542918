import React from "react";
import { Card } from "semantic-ui-react";
import { RecommendCard } from "../../components";
//import './index.css';
/*
{products.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
*/
//<Card.Group itemsPerRow={6}>
const RecommendGrid = ({ recommends }) => {
  if (recommends && recommends.length < 1) {
   
    return null;
  } else {
    return (
      <Card.Group itemsPerRow={3} className="recommend-products">
        {recommends.map(product => (
          <RecommendCard key={product.id} product={product} />
        ))}
      </Card.Group>
    );
  }
};


export default RecommendGrid;
