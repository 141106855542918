import React, { Component } from "react";

import { connect } from "react-redux";
import { Container, Header, Button, Card, Image, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { fetchProduct, fetchDemo, findSuits, chooseSuit, updateDataXAR, doXAR, fetchXAR} from "../../store";

import { ProductCard, EmptyProduct } from "../../components";

//import { loadScript } from "@paypal/paypal-js";
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


import './VismileSite.css';


const mapState = state => ({

  project: state.project,
  xar: state.xar, 
  demo: state.demo, 
  suits: state.suits,
  suit: state.suit,
  webrtc: state.webrtc

});

const mapDispatch = dispatch => {
  return {
      getProduct: id => dispatch(fetchProduct(id)),
      getDemo:   (projectName) => dispatch(fetchDemo(projectName)),
      searchSuits:   (suitIDs) => dispatch(findSuits(suitIDs)),

      selectSuit:   (suit) => dispatch(chooseSuit(suit)),

   
      
      requestXAR: (xar) => dispatch(doXAR(xar)),
      updateXAR: (xar) => dispatch(updateDataXAR(xar)),    
      getXAR: (xar) => dispatch(fetchXAR(xar)),

      

  };
};






class VismileSite extends Component {


  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);

    this.Ref_XAR_show1 = React.createRef();
    this.Ref_wipe_caption = React.createRef();

    this.Ref_parallax_wrap1 = React.createRef();

    this.Ref_video_block = React.createRef();
    this.Ref_video_caption = React.createRef();

    this.Ref_parallax_content = React.createRef(); 
    this.Ref_parallax_video_block = React.createRef(); 
    this.Ref_parallax_video = React.createRef();

    this.Ref_flex_female = React.createRef();
    this.Ref_flex_male = React.createRef();

    this.previous_scroll = 0;

    this.scroll_dir_from_top     = true;
    this.scroll_dir_from_bottom = false;

    this.menu_height = 67;//-120

    this.timer = 0;
    this.scroll_video_step =600;/*400*/
    
    this.video_scrollpos = window.pageYOffset/this.scroll_video_step;
    this.video_scrollpos_previous = 0;
    this.targetscrollpos = this.video_scrollpos;
    this.update_video_time = false;


    this.parallax_video_top_update = -0.5*window.innerHeight; 
    this.parallax_video_bottom_update = 0.5*window.innerHeight;

    this.state = {

        projectName: this.props.project.projectName,
        wipe_imgshow_width: window.innerWidth,
        wipe_imgshow_height: window.innerHeight,
        
        /*image init.*/
        wipe_img_original_width : 385,//wipe1.width();clothing_1.width();////; //=> need to wait the photo loaded completly, but windows.onload too many elements. should be specified in the div not by img.  
        wipe_img_original_height : 969,


        wipe_img_wipe_resize_width : (window.innerHeight<969) ? window.innerHeight/969*385 
                                                              : 969,

        wipe_img_padding_top : (window.innerHeight - 969)/2,
        XAR_show2_top : (window.innerHeight<969) ? -120*window.innerHeight/969 /*+ this.menu_height*/ //120 for reduce the blank area of image
                                                 : -120 /*+ this.menu_height*/,
        XAR_show2_bottom : '',      
        XAR_show2_position : 'fixed',   
        XAR_show2_marginTop : 0,//because scroll not trigger at beginning, but original design scroll will be triggerred when loading js
        

        wipe_top_display: "none",
        wipe_top_height: 1*window.innerHeight,
        wipe_bottom_display: '',
        wipe_bottom_height: 1*window.innerHeight,

        set_wipe_top : true,
        set_wipe_bottom : false,

        wipe_left_display: "",
        wipe_right_display: "",
        wipe_left_width: 969,
        wipe_wrap_width: (window.innerHeight<969) ? window.innerHeight/969*385 
                                                  : 969,
        wipe_wrap_left_pos: 0,
        wipe_wrap_marginLeft: 0,
        wipe_fixed : false,

        
        downArrow_bounce_wipe_display : '',
        note_bounce_wipe_display : '',

        downArrow_bounce_video_display : 'none',
        note_bounce_video_display : 'none',



        wipe_caption_top : '',
        wipe_caption_left : '',
        wipe_caption_textAlign: '',

        wipe1_height : window.innerHeight,
                                                                               
        wipe2_scroll_up_height : 0,/*image full of screen size = wipe_imgshow_height*/
        wipe2_scroll_down_height : window.innerHeight,/*image full of screen size = */


        //----------------
  
        //----------------
        clothing_1_maxHeight : window.innerHeight,
        clothing_2_maxHeight : window.innerHeight,
        clothing_3_maxHeight : window.innerHeight,
        clothing_4_maxHeight : window.innerHeight,
        clothing_5_maxHeight : window.innerHeight,
        clothing_6_maxHeight : window.innerHeight,



        //---------------------------------------------------------------------------
        parallax_wrap1_top : "",
        parallax_wrap1_height : "",
        //---------------------------------------------------------------------------
        
        video_wrap_width: window.innerWidth,

        video_background_fix_height : 826,
        video_background_fix_top    : (window.innerHeight - 826 * 1)/2,
        video_background_fix_left   : (window.innerWidth - 1200)/2,
        video_background_fix_display: "none",

        video_length : 2500,
        video_start_pos: 0,
        video_stop_pos: 0,
        set_video_top : false,
        set_video_bottom : false,
        video_fixed : false,
        video_play : false,
        
        video_block_top: 0,
        video_block_bottom: "",
        video_block_left: "",
        video_block_height: "",
        video_block_position: "absolute",

        video_caption_top: 0,
        video_caption_bottom: "",
        video_caption_position: "absolute",

        accelamount : 0.2, //How fast the video will try to catch up with the target position. 1 = instantaneous, 0 = do nothing.

        //--------------------------------------------------------------------
        parallax_wrap2_top: "",

        //--------------------------------------------------------------------
        parallax_content_background_position: "center center",
        parallax_video_top: -0.5*window.innerHeight,
        parallax_video_height: "",

        parallax_speed: 0.4,
        scroll_from_top: 1,
        scroll_from_bottom: 0,

        parallax_video_trigger: false,

        //--------------------------------------------------------------
        renderObjs: null,
        XARdone: false,
        isWaiting: false,
        sendXARreq: false,
    };
    
  console.log("constructor @VismileSite")
  


  }



  componentDidUpdate(prevProps, prevState) {

    const { getXAR, xar, suit, updateXAR, project } = this.props;


    //console.log("**********************************************");
    //console.log("componentDidUpdate@VismileSite", this);
    //console.log("this.props.project.:", this.props.project);
    //console.log("prevProps.project.:", prevProps.project);
    //console.log("this.state.userStatus:", this.state.userStatus);
    //console.log("prevState.userStatus:", prevState.userStatus);

    if(this.state.renderObjs && !prevState.renderObjs){

        /*========================================================*/
        var flex_male = this.Ref_flex_male.current
        var flex_female = this.Ref_flex_female.current

        console.log("this.Ref_flex_male", this.Ref_flex_male)
        console.log("this.Ref_flex_female", this.Ref_flex_female)
        console.log("this.props.demo.length", this.props.demo.length)
        flex_male.scrollLeft   = flex_male.scrollWidth *  150/2/(150*this.props.demo.length/2) 
        flex_female.scrollLeft = flex_female.scrollWidth *  150/2/(150*this.props.demo.length/2) 

    }

    //after send photo by fileUpload, return back VismileSite and send json file
    if(!this.state.isWaiting && !prevState.isWaiting){ //bug but works => always in the condition

      if (!this.state.sendXARreq && !this.state.XARdone) {

        //console.log("!this.state.sendXARreq && !this.state.XARdone")
        if( xar.isShoot && xar.isRequest ){
          console.log("xar.isShoot && xar.isRequest")      
          this.XARsubmit();        
        }

      }

    }


    if (this.props.xar.isUpload !== prevProps.xar.isUpload) {
   
      console.log("xar photo & clothlist changes @componentDidUpdate");
      console.log("xar.isUpload @componentDidUpdate:", xar.isUpload);
      console.log("XARdone @componentDidUpdate:", this.state.XARdone)
      
      //get XAR result       
      if(xar.isUpload.photoUpload && xar.isUpload.clothlistUpload && !this.state.XARdone){
          console.log("!!!!!!!!!!!!!! xar photo & clothlist both upload!!!!!!!!!!!!!!!!!!!!!!");
          console.log("Go get the photo: start getXAR(xar) in componentDidUpdate");
          /*
          getXAR(xar).then(()=>{
              this.setState({ XARdone: true }); 
              this.setState({ isWaiting: false});             
              console.log("set XARdone done! ", this.state.XARdone);
              
              window.removeEventListener('scroll', this.handleScroll);

              this.props.history.push({ pathname: '/aichure/XARdemoShow',
                                        state: { linkAddr: `${this.state.projectName}/AI-virtual-fitting`}
                                     });

              //xar.isUpload.photoUpload = false;
              //updateXAR(xar);

         });
         */
         window.removeEventListener('scroll', this.handleScroll);
         this.props.history.push({ pathname: '/aichure/XARdemoShow',
                                   state: { linkAddr: `${this.state.projectName}/AI-virtual-fitting`}
                                });


         

         
      }else{
        console.log("Not go get the photo: maybe due to XARdone  in componentDidUpdate", this.state.XARdone);
         

      }

    }
 



  }

  componentWillUnmount() {
   
    console.log("this@componentWillUnMount VismileSite", this)
    
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.timer);
      
   } 

  componentDidMount() {
    
    console.log("this@componentDidMount VismileSite", this)
    

    window.addEventListener('scroll', this.handleScroll);

    window.addEventListener('scroll', function() {
      //console.log("here", window.pageYOffset + 'px');
    });

    /*=======================================================*/

    var wipe_img_wipe_resize_width = this.state.wipe_img_original_height;
    var wipe_num = 3;


    if(this.state.wipe_imgshow_height > this.state.wipe_img_original_height){
    
      wipe_img_wipe_resize_width = this.state.wipe_img_original_width
      this.setState({wipe_img_padding_top: (this.state.wipe_imgshow_height - this.state.wipe_img_original_height)/2})
      
      this.setState({XAR_show2_top: -120 /*+ this.menu_height*/ })
    }else{
      wipe_img_wipe_resize_width = this.state.wipe_imgshow_height/this.state.wipe_img_original_height*this.state.wipe_img_original_width;//667=> the max-height from css 100% 
      
      this.setState({XAR_show2_top: -120*this.state.wipe_imgshow_height/this.state.wipe_img_original_height /*+ this.menu_height*/ })
      
    }

    /*=======================================================*/
    var XAR_show1 = this.Ref_XAR_show1.current;
    //console.log("this.refs", this.refs)
    //console.log("XAR_show1", XAR_show1)
    //console.log("XAR_show1.top", XAR_show1.offsetTop)
    //console.log("video_start_pos", XAR_show1.offsetTop /*+ this.state.wipe_imgshow_height*/  + this.state.XAR_show2_top - this.menu_height - 57.53)
    //console.log("video_stop_pos", XAR_show1.offsetTop + this.state.video_length - this.state.wipe_imgshow_height/*+ this.state.XAR_show2_top*/ - 57.53)
    
    this.setState({video_start_pos: XAR_show1.offsetTop + this.state.wipe_imgshow_height + this.state.XAR_show2_top /*- this.menu_height*/ - 57.53})//57.53 due to white part below girl's hand
    this.setState({video_stop_pos: XAR_show1.offsetTop + this.state.video_length /*- this.state.wipe_imgshow_height*/+ this.state.XAR_show2_top - 57.53})//var video_stop_pos = video_wrap.height() - window.pageYOffset;

    //console.log("this.state.wipe_imgshow_width wipe_img_wipe_resize_width", this.state.wipe_imgshow_width, wipe_img_wipe_resize_width)
    
    if(this.state.wipe_imgshow_width < 1*wipe_img_wipe_resize_width){

      this.setState({wipe_left_display: "none"})
      this.setState({wipe_right_display: "none"})
      
      wipe_num=1;
      this.setState({wipe_wrap_width: "100%"})

    }else if(this.state.wipe_imgshow_width < 2*wipe_img_wipe_resize_width){
      
      this.setState({wipe_left_display: "none"})
      this.setState({wipe_right_display: "none"})
      
      wipe_num=1;
      this.setState({wipe_wrap_width: wipe_img_wipe_resize_width})

      
    }else if(this.state.wipe_imgshow_width < 3*wipe_img_wipe_resize_width){
      
      this.setState({wipe_left_display: "none"})
      this.setState({wipe_right_display: ""})
      this.setState({wipe_left_width: 0})
      
      wipe_num=2;
      this.setState({wipe_wrap_width: wipe_img_wipe_resize_width})

    }else{
      
      this.setState({wipe_left_display: ""})
      this.setState({wipe_right_display: ""})
      wipe_num=3;
      this.setState({wipe_wrap_width: wipe_img_wipe_resize_width})

    }

    //console.log("wipe_wrap_left_pos", (this.state.wipe_imgshow_width - wipe_num*wipe_img_wipe_resize_width)/2)
    this.setState({wipe_wrap_left_pos: (this.state.wipe_imgshow_width - wipe_num*wipe_img_wipe_resize_width)/2 })
     
    if(this.state.wipe_wrap_left_pos<0){
  
      this.setState({wipe_wrap_marginLeft: 0})
      this.setState({wipe_caption_left: 0})

    }else{
     
    }

    var wipe_caption = this.Ref_wipe_caption.current;
    //console.log("this.Ref_wipe_caption", this.Ref_wipe_caption)
    //console.log("wipe_caption", wipe_caption)
    var wipe_caption_fix_pos_left = this.state.wipe_imgshow_width/2 - wipe_caption.offsetWidth/2;
    //var wipe_caption_fix_pos_left_for_fix = this.state.wipe_imgshow_width/2 - this.state.wipe_wrap_left_pos;// relayout
    //var wipe_caption_fix_pos_left_for_unfix = wipe_caption_fix_pos_left_for_fix + this.state.wipe_wrap_left_pos - wipe_caption.offsetWidth/2;// relayout
    
    //console.log("wipe_caption_fix_pos_left", wipe_caption_fix_pos_left)
    this.setState({wipe_caption_left: wipe_caption_fix_pos_left})
    
    var wipe_caption_pos_for_fix = this.state.wipe_imgshow_height + this.state.XAR_show2_top;//this.state.wipe_imgshow_height - wipe_caption.offsetHeight - 115;//this.state.XAR_show2_top;//relayout
    var wipe_caption_pos_for_unfix = wipe_caption_pos_for_fix + this.state.XAR_show2_top;//relayout
    
    this.setState({wipe_caption_top: wipe_caption_pos_for_fix})// relayout  
    this.setState({wipe_caption_textAlign: "center"})// relayout  


    //------------------------------
    this.setState({video_wrap_width: this.state.wipe_imgshow_width})

    /*========================================================================*/

    var video_background_fix_width = 1200;
    var scale_size = 1;

    if(this.state.wipe_imgshow_height>826 ){
      
      scale_size = 1;
      this.setState({video_background_fix_height : 826})

      video_background_fix_width = 1200;
      
      this.setState({video_background_fix_top : (this.state.wipe_imgshow_height - 826 * scale_size)/2})
      
    }else{

      scale_size = 0.8;
      this.setState({video_background_fix_height : this.state.wipe_imgshow_height * scale_size})
      
      video_background_fix_width = this.state.wipe_imgshow_height*scale_size/826 * 1200;
      
      //console.log("video_background_fix_width " + video_background_fix_width);
      
      this.setState({video_background_fix_top : (this.state.wipe_imgshow_height - this.state.wipe_imgshow_height * scale_size)/2})
      
    }

      
    //
    if(this.state.wipe_imgshow_width > video_background_fix_width ){
      
      this.setState({video_background_fix_left : (this.state.wipe_imgshow_width-video_background_fix_width)/2 })
      
    }else{
      
      //console.log("video_background_fix_width " + video_background_fix_width);
      var view_start = (video_background_fix_width - this.state.wipe_imgshow_width)/2;
      //console.log("view_start " + view_start);
      this.setState({video_background_fix_left : -view_start })
      
    }   
    //=================================================================
    /*   video */
    this.Ref_video_block.current.pause();
    this.setState({video_caption_top: 0.14*this.state.wipe_imgshow_height /*+ this.menu_height*/ + 57.53});//57.53 for matching the video white part 
    this.setState({video_block_left: (this.state.wipe_imgshow_width- (1*this.state.wipe_imgshow_height/828*1792 - 70))/2 })
    this.setState({video_block_height: 1*this.state.wipe_imgshow_height})
  

    /*---------------------------------------------------------------*/
    var parallax_wrap1 = this.Ref_parallax_wrap1.current;
    //console.log("this.Ref_parallax_wrap1", this.Ref_parallax_wrap1)
    //console.log("parallax_wrap1.offsetTop", parallax_wrap1.offsetTop)
    //console.log("parallax_wrap1.offsetHeight", parallax_wrap1.offsetHeight)

    this.setState({parallax_wrap1_top: this.state.wipe_imgshow_height})//parallax_wrap1.offsetTop
    this.setState({parallax_wrap1_height: parallax_wrap1.offsetHeight})

    //=================================================================
    /* parallax video*/
    //console.log("this.Ref_parallax_video_block.current 1", this.Ref_parallax_video_block.current)
    //console.log("this.Ref_parallax_video.current 1", this.Ref_parallax_video.current)
    this.Ref_parallax_video_block.current.playbackRate = 0.5;

    this.setState({parallax_video_height : this.Ref_parallax_content.current.offsetHeight})

    if(window.innerWidth>480){
      this.Ref_parallax_video.current.setAttribute('src', '/vismile/AI-virtual-fitting/Vismile-clothes_large.mp4');
    
    }else{
      this.Ref_parallax_video.current.setAttribute('src', '/vismile/AI-virtual-fitting/Vismile-clothes_small.mp4');
      
    }

    this.Ref_parallax_video.current.loop = true;
    this.Ref_parallax_video.current.muted = true;
    this.Ref_parallax_video.current.playsInLine = true;
    this.Ref_parallax_video.current.autoPlay = true;
    

    this.Ref_parallax_video_block.current.appendChild(this.Ref_parallax_video.current);
    
    if(window.innerWidth>1920){
      
      this.Ref_parallax_video.current.width = window.innerWidth;
      
    }
    //console.log("this.Ref_parallax_video_block.current 2", this.Ref_parallax_video_block.current)
    //console.log("this.Ref_parallax_video.current 2", this.Ref_parallax_video.current)
    
  /*=================================================================*/

    const { getDemo, getProduct, demo, searchSuits, project, setWebRTC  } = this.props;

    this.setState({ ARfittigTimeOut : project.ARfittigTimeOut });

    //console.log("this.props.myTurnNow, this.state.myTurnNow@DemoSite componentDidMount", this.props.myTurnNow, this.state.myTurnNow)


    getDemo(this.state.projectName).then( () => {
                                                  let suitIDs = [];
                                                  console.log("demo", this.props.demo)
                                                  this.props.demo.map(item => {
                                                                                 suitIDs.push(item.suitID) 
                                                                              }
                                                                                     
                                                                     )
                                                  console.log("suitIDs", suitIDs)
                                                  
                                                  searchSuits(suitIDs).then( () => {
                                                                                    //console.log("this", this)
                                                                                    this.setState({renderObjs: this.props.suits}) 
                                                                                    

                                                                                    //console.log("this.state.renderObjs", this.state.renderObjs)

                                                                           })

                                                }


                                        )




  }


  handleScroll(event) {


  


    //---------------------------------------------------------
    // for video play control
    var XAR_show1 = this.Ref_XAR_show1.current;    
    //console.log("this.Ref_XAR_show1@handleScroll", this.Ref_XAR_show1)
    this.targetscrollpos = (window.scrollY - XAR_show1.offsetTop)/this.scroll_video_step;
    //---------------------------------------------------------

    //console.log("event", event)
    //console.log(window)
    //console.log("window.scrollY", window.scrollY)
    let scroll_top = window.scrollY;//event.srcElement.body.scrollTop;
    let scroll_bottom=scroll_top+window.innerHeight
    
    var scroll_this_time = scroll_top - this.previous_scroll;
    this.previous_scroll = scroll_top;

    //---------------------------------------------------------


    var wipe_start_pos = 0;//this.state.XAR_show2_top, 0 => scroll from the beginning
    //console.log("this.state.XAR_show2_top", this.state.XAR_show2_top)
    //console.log("scroll_top, wipe_start_pos", scroll_top, wipe_start_pos)//initial 1,67, then 2,0
    //console.log("wipe_start_pos", wipe_start_pos)
    //console.log("wipe_start_pos + this.state.wipe_imgshow_height", wipe_start_pos + this.state.wipe_imgshow_height)
    //console.log("this.state.parallax_wrap1_top", this.state.parallax_wrap1_top)
    //console.log("this.state.parallax_wrap1_top + this.state.parallax_wrap1_height + this.state.wipe_imgshow_height", this.state.parallax_wrap1_top + this.state.parallax_wrap1_height + this.state.wipe_imgshow_height)
    //console.log("video_start_pos", this.state.video_start_pos)
    //console.log("video_stop_pos", this.state.video_stop_pos)

    //console.log("scroll_top", scroll_top)//initial 1,67, then 2,0
   
    //for wipe_wrap
    if( scroll_top < wipe_start_pos){  //such as menu div

      //console.log("scroll_top < wipe_start_pos", scroll_top, wipe_start_pos)
      if(this.state.set_wipe_top||this.state.set_wipe_bottom){
        //console.log("this.state.set_wipe_top||this.state.set_wipe_bottom", this.state.set_wipe_top, this.state.set_wipe_bottom)
        
        this.setState({wipe_top_display: 'none'})   
        this.setState({wipe_bottom_display: ''})   
              
        this.setState({set_wipe_top: false})         
        this.setState({set_wipe_bottom: false})         
        
       
        
        //below is correct for bottom up ???
        //this.setState({XAR_show2_top : 0})
        this.setState({XAR_show2_bottom : ''})
        

        this.setState({downArrow_bounce_wipe_display : 'none'})
        this.setState({note_bounce_wipe_display : 'none'})
        
      }


      
    }else if(scroll_top > wipe_start_pos && scroll_top < wipe_start_pos + this.state.wipe_imgshow_height){



    }else if( scroll_top > wipe_start_pos + this.state.wipe_imgshow_height){
      
      //console.log("scroll_top > wipe_start_pos + this.state.wipe_imgshow_height", scroll_top, wipe_start_pos, this.state.wipe_imgshow_height)
      
      if(this.state.set_wipe_top||this.state.set_wipe_bottom){

        //console.log("this.state.set_wipe_top||this.state.set_wipe_bottom", this.state.set_wipe_top, this.state.set_wipe_bottom)
        this.setState({wipe_top_display: ''})  
        this.setState({wipe_bottom_display: 'none'})  

        this.setState({set_wipe_top: false})         
        this.setState({set_wipe_bottom: false})         
        
        //this.setState({XAR_show2_top : ''})
        this.setState({XAR_show2_bottom : 0})

        this.setState({downArrow_bounce_wipe_display : 'none'})
        this.setState({note_bounce_wipe_display : 'none'})
        

        
      }
    }

    var wipe_caption = this.Ref_wipe_caption.current;
    //console.log("wipe_caption", wipe_caption)
    //console.log("wipe_caption.width", wipe_caption.width)

    //var wipe_caption_fix_pos_left = this.state.wipe_imgshow_width/2 - wipe_caption.offsetWidth/2;
    //var wipe_caption_fix_pos_left_for_fix = this.state.wipe_imgshow_width/2 - this.state.wipe_wrap_left_pos;// relayout
    //var wipe_caption_fix_pos_left_for_unfix = wipe_caption_fix_pos_left_for_fix + this.state.wipe_wrap_left_pos - wipe_caption.offsetWidth/2;// relayout
    var wipe_caption_pos_for_fix = this.state.wipe_imgshow_height + this.state.XAR_show2_top;//this.state.wipe_imgshow_height - wipe_caption.offsetHeight - 115;//this.state.XAR_show2_top;//relayout
    var wipe_caption_pos_for_unfix = wipe_caption_pos_for_fix + this.state.XAR_show2_top;//relayout
    
    
    //console.log("wipe_caption_fix_pos_left", wipe_caption_fix_pos_left)
    //console.log("wipe_caption_fix_pos_left_for_unfix", wipe_caption_fix_pos_left_for_unfix)
    //console.log("scroll_top", scroll_top)
    //console.log("wipe_start_pos", wipe_start_pos)
    //console.log("this.state.wipe_imgshow_height", this.state.wipe_imgshow_height)

    //console.log("this.state.wipe_fixed", this.state.wipe_fixed)

    if( scroll_top >= wipe_start_pos && scroll_top < wipe_start_pos + this.state.wipe_imgshow_height) {
      //console.log("in between")

      this.setState({set_wipe_top: true})  
      this.setState({set_wipe_bottom: true})  
      
      
      
      if(!this.state.wipe_fixed){
        
        this.setState({XAR_show2_position : 'fixed'}) 
        this.setState({XAR_show2_bottom : 0}) 

        this.setState({XAR_show2_marginTop : 0}) 

        //below is correct for bottom up 
        //this.setState({XAR_show2_top : 0})
        
        
        this.setState({wipe_fixed: true})

        //this.setState({wipe_caption_left: wipe_caption_fix_pos_left})
        
        this.setState({wipe_top_height: 2*this.state.wipe_imgshow_height})
        this.setState({wipe_bottom_height:  2*this.state.wipe_imgshow_height})
        
        this.setState({wipe_caption_top: wipe_caption_pos_for_fix})
        
        this.setState({downArrow_bounce_wipe_display:  'unset'})
        this.setState({note_bounce_wipe_display:  'unset'})
       
        
      }
      
      this.setState({wipe2_scroll_down_height : this.state.wipe_imgshow_height - (scroll_top-wipe_start_pos)})//try for bottom up: this one correct for all devices
      this.setState({wipe2_scroll_up_height : (scroll_top-wipe_start_pos) })//try for top down: this one correct for all devices
      
    
        
    }else{ 
      //console.log("not in between")

      if(this.state.wipe_fixed){

        this.setState({XAR_show2_position : ''}) 
        this.setState({XAR_show2_bottom : ''}) 

        this.setState({wipe_fixed: false})

        this.setState({XAR_show2_marginTop : this.state.XAR_show2_top - this.menu_height}) // menu_height due to paddingTop of div in route 

        //this.setState({wipe_caption_left: wipe_caption_fix_pos_left})
        
        
        this.setState({wipe_top_height: 1*this.state.wipe_imgshow_height})
        this.setState({wipe_bottom_height:  1*this.state.wipe_imgshow_height})
        
        this.setState({parallax_wrap2_top: 5*this.state.wipe_imgshow_height })//compensate to the display none of wipe_bottom, only correct when wipe_imgshow_height is screen size
        
        this.setState({wipe_caption_top: wipe_caption_pos_for_unfix + 1*this.state.wipe_imgshow_height})//2 times for inset, but only 2x when in the fixed resgion
        
        this.setState({downArrow_bounce_wipe_display:  'none'})
        this.setState({note_bounce_wipe_display:  'none'})

        
      }
    }
    /*========================================================================*/  
    
    if( scroll_top >= this.state.parallax_wrap1_top  && scroll_top < this.state.parallax_wrap1_top + this.state.parallax_wrap1_height + this.state.wipe_imgshow_height){

      this.setState({video_background_fix_display: "block"})

    }else{

      this.setState({video_background_fix_display: "none"})

    }

    /*========================================================================*/
    var video_block = this.Ref_video_block.current;
    var video_caption = this.Ref_video_caption.current;
    
    //console.log("video_block", this.Ref_video_block)
    //console.log("video_block", video_block)
    //console.log("video_caption", video_caption)
    var video_top_onscroll_top = video_block.offsetTop;//element position on screen 
      
    var video_fix_pos = this.menu_height;  
    var video_caption_fix_pos = this.state.video_caption_top;/* + this.menu_height + 57.53*///57.53 for matching the video white part 
    var video_caption_current_pos = video_caption_fix_pos;
    
    //console.log("video_start_pos", this.state.video_start_pos)
    //console.log("video_stop_pos", this.state.video_stop_pos)

    if( scroll_top < this.state.video_start_pos){
        
        
        
        
        if(this.state.set_video_top||this.state.set_video_bottom){
          this.setState({set_video_top: false})
          this.setState({set_video_bottom: false})
          this.setState({video_fixed: false})
          this.setState({video_play: false})

          this.setState({video_block_top: video_fix_pos})
          this.setState({video_block_bottom: ""})
          this.setState({video_block_position: "absolute"})

          this.setState({video_caption_top: video_caption_fix_pos})
          //this.setState({video_caption_bottom: ""})
          this.setState({video_caption_position: "absolute"})

          
          this.setState({downArrow_bounce_video_display: "none"})
          this.setState({note_bounce_video_display: "none"})

        
          this.scroll_dir_from_top     = true;
          this.scroll_dir_from_bottom = false;

          video_block.currentTime = 0;
          
        }


    }else if(scroll_top >= this.state.video_start_pos && scroll_top < this.state.video_stop_pos){
        
        //console.log("scroll_top " + scroll_top);
        //console.log("this.state.video_start_pos " + this.state.video_start_pos);
        
        if(!this.state.video_fixed){
          
          //--
          this.setState({set_video_top: true})
          this.setState({set_video_bottom: true})
          this.setState({video_play: true})

       
          //--
          
          this.setState({video_block_position: "fixed"})
          this.setState({video_caption_position: "fixed"})

          this.setState({downArrow_bounce_video_display: ""})
          this.setState({note_bounce_video_display: ""})

   
          //this.setState({video_caption_top: video_caption_fix_pos})/*video fix to below main_menu*/

          if(this.scroll_dir_from_top){
            
            video_fix_pos = this.menu_height;//main_menu_area_height;
            this.setState({video_block_top: video_fix_pos})/*video fix to below main_menu*/
            
            //this.setState({video_caption_top: video_caption_fix_pos})/*video fix to below main_menu*/
            
            
          }else if(this.scroll_dir_from_bottom){
            

            this.setState({video_block_top: ""})
            this.setState({video_block_bottom: 0})
           
            //this.setState({video_caption_top: ""})
            //this.setState({video_caption_bottom: this.state.wipe_imgshow_height-video_caption_current_pos - video_caption.offsetHeight})

            
          }
          
          
          
          this.setState({video_fixed: true})
          
          this.setVideoPlayTimer()

        }
        
        
    }else if( scroll_top > this.state.video_stop_pos  ){
        
        
        if(this.state.set_video_top||this.state.set_video_bottom){
          
          this.setState({set_video_top: false})
          this.setState({set_video_bottom: false})
          
          this.setState({video_fixed: false})
          this.setState({video_play: false})
          
          this.setState({video_block_top: ""})
          this.setState({video_block_bottom: 0})
          this.setState({video_block_position: ""})

          this.setState({video_caption_top: 0.14*this.state.wipe_imgshow_height})
          //this.setState({video_caption_bottom: this.state.wipe_imgshow_height-video_caption_current_pos - video_caption.height})
          this.setState({video_caption_position: "absolute"})


          this.setState({downArrow_bounce_video_display: "none"})
          this.setState({note_bounce_video_display: "none"})

    
          
          this.scroll_dir_from_top     = false;
          this.scroll_dir_from_bottom  = true;

          //video_block.currentTime = this.state.video_length;
        }
        
        
        
    }
    //---------------------------------------------------------------------
    
    //this.setState({parallax_content_background_position: 'center calc(50% + '+(scroll_top*.5)+'px)'}) no used, video not background
    
    //console.log("this.Ref_parallax_content", this.Ref_parallax_content)
    if(   scroll_top > this.Ref_parallax_content.current.offsetTop - this.state.wipe_imgshow_height
       && scroll_top < this.Ref_parallax_content.current.offsetTop - this.state.wipe_imgshow_height + this.Ref_parallax_content.current.offsetHeight + this.state.wipe_imgshow_height) {//!isElementOutViewport(services)
      
      
      
      
        
        //console.log("in service block");
        
        if(this.state.scroll_from_top){//st_delta>=0
          
          this.parallax_video_top_update = this.parallax_video_top_update - scroll_this_time * this.state.parallax_speed;
          this.setState({parallax_video_top: this.parallax_video_top_update})
          this.setState({parallax_video_bottom: ""})

        }else if(this.state.scroll_from_bottom){//st_delta<0
          this.parallax_video_bottom_update = this.parallax_video_bottom_update + scroll_this_time * this.state.parallax_speed;
          this.setState({parallax_video_top: ""})
          this.setState({parallax_video_bottom: this.parallax_video_bottom_update})

        }
        this.setState({parallax_video_trigger:true})
        
        
    }else if ( this.state.parallax_video_trigger ) { /*isElementOutViewport(services) && (parallax_video_trigger==1) */
      
      this.parallax_video_top_update = -0.5*window.innerHeight;
      this.parallax_video_bottom_update = 0.5*window.innerHeight;
      
      //console.log("here", scroll_this_time)

      if(scroll_this_time>=0){//scroll_from_top


        this.setState({parallax_video_top: ""})
        this.setState({parallax_video_bottom: this.parallax_video_bottom_update})

        this.setState({scroll_from_top:0})
        this.setState({scroll_from_bottom:1})
         
        
      }else if(scroll_this_time<0){//scroll_from_bottom

        this.setState({parallax_video_top: this.parallax_video_top_update})
        this.setState({parallax_video_bottom: ""})

        this.setState({scroll_from_top:1})
        this.setState({scroll_from_bottom:0})
        
      }
      
      //console.log("~isElementInViewport");
      this.setState({parallax_video_trigger:false})
    } 
    
    //console.log("parallax_video_top_update ", this.parallax_video_top_update);
    //console.log("parallax_video_bottom_update ", this.parallax_video_bottom_update);
    


}

setVideoPlayTimer(){

    
    //var _this = this
    setInterval( this.videoControl.bind(this), 100);

}

videoControl(){

        //console.log("this@setVideoPlayTimer", this)
        //console.log("vid@setVideoPlayTimer", vid)

        //Accelerate towards the target:
        this.video_scrollpos += (this.targetscrollpos - this.video_scrollpos)*this.state.accelamount;
        


        //move the blue dot to a position across the side of the screen
        //that indicates where the current video scroll pos is.  
        
        //console.log("_this.update_video_time, _this.state.video_play", this.update_video_time, this.state.video_play)
        //update video playback
        if(Math.abs(this.video_scrollpos_previous-this.video_scrollpos)>0.05){
      
            this.update_video_time = true;
            this.video_scrollpos_previous = this.video_scrollpos;
        }
  
        if(this.update_video_time && this.state.video_play){
            //console.log("this.video_scrollpos", this.video_scrollpos)
            //console.log("this.Ref_video_block.current.duration", this.Ref_video_block.current.duration)
            /*this.Ref_video_block.current.currentTime = this.video_scrollpos > this.Ref_video_block.current.duration ? this.Ref_video_block.current.duration 
                                                                                                                    : this.video_scrollpos;
            */
            this.Ref_video_block.current.currentTime = this.video_scrollpos;
            this.Ref_video_block.current.pause();
            this.update_video_time = false;
        }

}

/********************************************************/
//  XAR fitting related start
/********************************************************/


  onClickSuit(suit){

    const { xar, updateXAR, selectSuit } = this.props;

    this.DemoClothesProducts(suit);
    xar.isRequest = true;
    xar.isUpdate = false;
    updateXAR(xar);

    var tempSuit = {};
    tempSuit.suit = suit
    selectSuit(tempSuit)

    this.setState({demoSuit : suit})
    this.setState({onClickDemo : true})
   

    this.setState({ sendXARreq: true});
    

    

    console.log("go to fileupload page @onClickSuit ");
    const linkAddr       = `${this.state.projectName}/AI-virtual-fitting`
    
    //must remove before jump page
    window.removeEventListener('scroll', this.handleScroll);
    
    this.props.history.push({ pathname: '/fileupload',
                              state: { linkAddr: linkAddr }
                           });


        

    // pop-up choose photo or use sample photo
    //this.AIfittingSubmit();

  }
  XARsubmit() {

    const {
      
      xar,
      requestXAR,
     
    } = this.props;


    //if no photo, do take photo
    //if already has photo, do xar json request
    
    //


    if(xar.isShoot){
      //send XAR json file

      requestXAR(xar);      
      console.log("  with old pic");
      this.setState({ isWaiting: true});

    }
    


    

  }  

  DemoClothesProducts(suit) {
    console.log("============================= clothlist ===============================")
    
    const { xar, product } = this.props;
    console.log("this.props @XARclothesProducts: ", this.props)
    console.log("suit @XARclothesProducts: ", suit)


    

    const video_filename = xar.picID + '_' + suit.suitID;
    
    /* originally, find the same suit of other items
    const clothlist = [];
    suit.suit.products.forEach(product => {
        console.log("product.suit.products.internal_name: ", product.internal_name)
        clothlist.push(product.internal_name);        
      });
    */
    const clothlist = suit.suitClothesID;
    const shoes = suit.shoes;

    console.log("clothlist: ", clothlist)

    xar.clothlist = clothlist;
    xar.shoes = shoes;
    xar.suitID = suit.suitID;
    xar.clothesID = suit.suitID;//product.product.internal_name;
    xar.video_filename = video_filename;
    if(suit.gender === 'male'){
      xar.human.gender = 1;  
    }else
    {
      xar.human.gender = 0;
    }
    

  }

/********************************************************/
//  AR fitting related end
/********************************************************/




  render() {
    
    const {  } = this.props;

    
    
    return (
      <div style={{ backgroundColor : "unset"}} >



        {/* ================Begin wipe =================*/}

        <div class="wipe-top" style={{ display : this.state.wipe_top_display,
                                       height  : this.state.wipe_top_height
                                    }} >
        </div>


        <div class="XAR-show2" style={{ height      : "100vh",
                                      top         : this.state.XAR_show2_top,
                                      paddingLeft : this.state.wipe_wrap_left_pos,
                                      marginTop   : this.state.XAR_show2_marginTop,
                                      position    : this.state.XAR_show2_position,
                                   }} >
          
            
          
            <div class="wipe-wrap" id="wipe-left" style={{ width            : this.state.wipe_wrap_width,
                                                           display          : this.state.wipe_left_display,                                                            
                                                        }} >
              
              <div class="wipe1" style={{ height : this.state.wipe1_height
                                       }} ><img class="wipe-img" id="clothing-1" style={{ paddingTop: this.state.wipe_img_padding_top,
                                                                                          maxHeight : this.state.clothing_1_maxHeight
                                                                                       }}  src="/vismile/AI-virtual-fitting/Marilyn-Monroe.jpg"  alt="Marilyn Monroe photo"/></div>
                          
              <div class="wipe2-scroll-up" style={{height : this.state.wipe2_scroll_up_height
                                                 }} ><img class="wipe-img" id="clothing-2" style={{ paddingTop: this.state.wipe_img_padding_top,
                                                                                                    maxHeight : this.state.clothing_2_maxHeight 
                                                                                                 }} src="/vismile/AI-virtual-fitting/Marilyn-Monroe-tryon.jpg"  alt="Marilyn Monroe virtual fitting photo"/></div>
                                                                  
              
            </div>
              
          
            
            <div class="wipe-wrap" id="wipe-center" style={{ width            : this.state.wipe_wrap_width,
                                                          }} >
              
              <div class="wipe1" style={{ height : this.state.wipe1_height
                                       }} ><img class="wipe-img" id="clothing-3" style={{ paddingTop: this.state.wipe_img_padding_top,
                                                                                          maxHeight : this.state.clothing_3_maxHeight
                                                                                       }} src="/vismile/AI-virtual-fitting/V-J-Day-Times-Square-Kiss-tryon.jpg"  alt="The V-J Day Kiss in times square virtual fitting photo"/></div>
                        
              <div class="wipe2-scroll-down" style={{height : this.state.wipe2_scroll_down_height                                                     
                                                  }} ><img class="wipe-img" id="clothing-4"  style={{ paddingTop: this.state.wipe_img_padding_top,
                                                                                                      maxHeight : this.state.clothing_4_maxHeight 
                                                                                                   }} src="/vismile/AI-virtual-fitting/V-J-Day-Times-Square-Kiss.jpg"  alt="The V-J Day Kiss in times square photo"/></div>
                                                                  
              
            </div>
            
            
            
            
            <div class="wipe-wrap" id="wipe-right" style={{ width            : this.state.wipe_wrap_width,
                                                            display          : this.state.wipe_right_display,                                                            
                                                         }} >
              
              <div class="wipe1" style={{ height : this.state.wipe1_height
                                       }} ><img class="wipe-img" id="clothing-5" style={{ paddingTop : this.state.wipe_img_padding_top,
                                                                                          maxHeight  : this.state.clothing_5_maxHeight 
                                                                                       }} src="/vismile/AI-virtual-fitting/Audrey-Hepburn.jpg"  alt="Audrey Hepburn photo"/></div>
                        
              <div class="wipe2-scroll-up" style={{ height : this.state.wipe2_scroll_up_height
                                                 }}  ><img class="wipe-img"  id="clothing-6" style={{ paddingTop : this.state.wipe_img_padding_top,
                                                                                                      maxHeight  : this.state.clothing_6_maxHeight 
                                                                                                   }} src="/vismile/AI-virtual-fitting/Audrey-Hepburn-tryon.jpg"  alt="Audrey Hepburn virtual fitting photo"/></div>
                                                                  
              
            </div>
              
          
            <div class="wipe-caption" ref={this.Ref_wipe_caption} style={{ top       : this.state.wipe_caption_top,
                                                                           left      : this.state.wipe_caption_left,
                                                                           textAlign : "center"
                                                                        }}>

              <h3>Dress with imagination</h3>
              
              
              <h3>Show in reality</h3>   
            </div>
          
            <div class="downArrow bounce" >
              <a id="note-bounce-wipe" style={{ display : this.state.note_bounce_wipe_display,
                                                color   : "inherit",
                                                textDecoration: "none"
                                             }} >Scrolling</a>
              <img id="downArrow-bounce-wipe" style={{ display : this.state.downArrow_bounce_wipe_display,
                                                       verticalAlign: "middle",
                                                    }} width="30" height="30" alt="scroll down to virtual try on" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSLQodC70L7QuV8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yNC4yODUsMTEuMjg0TDE2LDE5LjU3MWwtOC4yODUtOC4yODhjLTAuMzk1LTAuMzk1LTEuMDM0LTAuMzk1LTEuNDI5LDAgIGMtMC4zOTQsMC4zOTUtMC4zOTQsMS4wMzUsMCwxLjQzbDguOTk5LDkuMDAybDAsMGwwLDBjMC4zOTQsMC4zOTUsMS4wMzQsMC4zOTUsMS40MjgsMGw4Ljk5OS05LjAwMiAgYzAuMzk0LTAuMzk1LDAuMzk0LTEuMDM2LDAtMS40MzFDMjUuMzE5LDEwLjg4OSwyNC42NzksMTAuODg5LDI0LjI4NSwxMS4yODR6IiBmaWxsPSIjMTIxMzEzIiBpZD0iRXhwYW5kX01vcmUiLz48Zy8+PGcvPjxnLz48Zy8+PGcvPjxnLz48L3N2Zz4=" />
            </div>

        </div>

        <div class="wipe-bottom" style={{ display : this.state.wipe_bottom_display,
                                          height  : this.state.wipe_bottom_height
                                       }} ></div>


        {/* ================End Banner Area ================= */}
        
        {/* ================Begin parallax1 ================= */}
        <div class="parallax-wrap1" ref={this.Ref_parallax_wrap1}>
        
        
            <div class="service-content baner-content" id="background_gif"> 
              
                <video class="video-background-fix"   preload="auto"  loop muted autoPlay playsInline 
                                                      style={{ height : this.state.video_background_fix_height,
                                                               top: this.state.video_background_fix_top,
                                                               left: this.state.video_background_fix_left,
                                                               display: this.state.video_background_fix_display
                                                            }}> 
                    <source src="/vismile/AI-virtual-fitting/phone-photo-fitting.mp4" type="video/mp4" alt="virtual fitting via your phone" />  
                </video>              
                
                <div class="container" style={{ backgroundColor : "unset"}} >
                
                    <div class="first-content">
                      <h1>Your own <br/> 3D fitting solution</h1>
                      <span><em>Convenient & Accurate</em> </span>
                      
                    </div>
                    <div class="second-content">
                      <h1>Fast changing <br/> in seconds</h1> {/* 秒換裝 */}
                      <span><em>Try on thousands of styles <br/>with a click</em> </span><br/>{/*  手指輕點，輕鬆換穿風格服飾 */}
                      
                      <h3 id="operation-script">Send you photos to Vismile, and try any fashion styles. You can check your total looks in various kinds of background scenes.</h3>{/* 傳送照片到智鏡網站，挑選你想嘗試的任意造型，展現試穿效果，還可不斷變換場景搭配 */}
                      
                    </div>

                </div>
            
            </div>

        </div>
        {/* ================End parallax1 ================= */}

        <div class="video-top"></div>
        
        <div class="XAR-show1" ref={this.Ref_XAR_show1} >
        
          
          <div class="video-wrap" > {/* equivalent one-page */}
            
            <video class="video_block" ref={this.Ref_video_block} id="v0" style={{ top: this.state.video_block_top,
                                                                                   bottom: this.state.video_block_bottom,
                                                                                   left: this.state.video_block_left,
                                                                                   height: this.state.video_block_height,
                                                                                   position: this.state.video_block_position
                                                                                }} tabindex="0"  preload="auto"  muted autoPlay playsInline > 
              <source src="/vismile/AI-virtual-fitting/3D-virtual-dressing-video.mp4" type="video/mp4" alt="3D virtual dressing video"/>  
            </video>
        
            <div class="video-caption" ref={this.Ref_video_caption} style={{ top: this.state.video_caption_top,
                                                                             position: this.state.video_caption_position
                                                                          }} > 
              
              <h2>3D fitting in details</h2>
              <p>Check the detailed look in 3D simulation</p>
              
                 
            </div>
            
          </div>
          
          
          
          <div class="downArrow bounce" >
            <a id="note-bounce-video" style={{ display : this.state.note_bounce_video_display,
                                               color   : "inherit",
                                               textDecoration: "none"
                                            }} >Scrolling</a>
            <img id="downArrow-bounce-video" style={{ display : this.state.downArrow_bounce_video_display,
                                                      verticalAlign: "middle",
                                                   }}  width="30" height="30" alt="" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSLQodC70L7QuV8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yNC4yODUsMTEuMjg0TDE2LDE5LjU3MWwtOC4yODUtOC4yODhjLTAuMzk1LTAuMzk1LTEuMDM0LTAuMzk1LTEuNDI5LDAgIGMtMC4zOTQsMC4zOTUtMC4zOTQsMS4wMzUsMCwxLjQzbDguOTk5LDkuMDAybDAsMGwwLDBjMC4zOTQsMC4zOTUsMS4wMzQsMC4zOTUsMS40MjgsMGw4Ljk5OS05LjAwMiAgYzAuMzk0LTAuMzk1LDAuMzk0LTEuMDM2LDAtMS40MzFDMjUuMzE5LDEwLjg4OSwyNC42NzksMTAuODg5LDI0LjI4NSwxMS4yODR6IiBmaWxsPSIjMTIxMzEzIiBpZD0iRXhwYW5kX01vcmUiLz48Zy8+PGcvPjxnLz48Zy8+PGcvPjxnLz48L3N2Zz4=" />
          </div>
              
              
          
        </div>  
        <div class="video-bottom"></div>
          
        {/* end of video scroll */}

        {/*================Feature Area =================*/}
        <section class="feature_area" style={{paddingBottom: "200px"
                                            }}>
            <div class="container" style={{backgroundColor: "rgba(0,0,0,0)!important"
                                         }}>
                
              <div class="c_title">
          
                <h6 class="strong-heading">Try it now </h6>
                <h2>Try it now</h2>
              </div>
          
        
              {
                this.state.renderObjs ? (
                                          <>
                                            <div className="flexContainer" ref={this.Ref_flex_female}>
                                                 {this.state.renderObjs.map(suit => (     
                                                                                       (suit.gender==='female') ? (  
                                                                                                 
                                                                                           <Card  onClick={() =>  this.onClickSuit(suit) /*this.ARfittingSubmit(suit)*/ }  style={styles.cardImage2}>
                                                                                            
                                                                                            <Image src={suit.imageUrl} style={styles.cardImage}/>
         
                                                                                           </Card>
                                                                                       ) : null
                                                                                    
                                                                                    ))}
                                            </div>
                                            <div className="flexContainer" ref={this.Ref_flex_male}>
                                                 {this.state.renderObjs.map(suit => (     
                                                                                       (suit.gender==='male') ? (  
                                                                                                 
                                                                                           <Card onClick={() =>  this.onClickSuit(suit) /*this.ARfittingSubmit(suit)*/ }  style={styles.cardImage2}>
                                                                                            
                                                                                            <Image src={suit.imageUrl} style={styles.cardImage}/>
         
                                                                                           </Card>
                                                                                       ) : null
                                                                                    
                                                                                    ))}
                                            </div>    
                                          </>
                                        ) : null

        
              }
        
        
        
        
            </div>
      
      

    
      
      
        </section>

        {/*================End Feature Area ================= */}
    
    
        <div class="parallax-content service-content" id="services" ref={this.Ref_parallax_content} style={{ height: "100vh",    
                                                                                                             /*backgroundPosition: this.state.parallax_content_background_position,*/
                                                                                                          }}> {/* service-content */}
            
            
          
          <div class="container" style={{position:'relative', 
                                         backgroundColor: "rgba(0,0,0,0)!important"
                                       }}>

              <div class=" parallax-video" id="parallax-video-container" ref={this.Ref_parallax_video_block} style={{ position:'absolute',
                                                                                                                      top: this.state.parallax_video_top,
                                                                                                                      height: this.state.parallax_video_height,    
                                                                                                                      bottom: this.state.parallax_video_bottom
                                                                                                                   }}> {/*video-background-fix*/}
                  <video id="parallax-video-0" preload="auto"   ref={this.Ref_parallax_video} muted autoPlay playsInline loop> 
                  </video>  
              </div>
              
              { this.state.XARdone ? (
                              
                                <div className="product-wrapper ui raised very padded text segment" style={styles.showXAR}>
                                
                                  <Card centered style={styles.maincard}>
                                    <Image src={"/"+this.props.project.projectName+this.props.xar.XARimg[0]["img"]} style={styles.xarImg} />                                
                                  </Card>                                                          

                                </div> 
                              

                            )
                            :(

                                <div class="row" style={ {position:'relative'} }>
                                
                                    <div class="col-lg-6 col-md-4">
                                      <div class="left-text">
                                        <h4>3D Virtual fitting, Anytime and Anywhere</h4>
                                        <p> <a rel="virtual fitting website" href="https://fitting.vismile.com.tw"></a> </p>
                                        <ul>
                                          <li>- Enjoy Vismile virtual fittng in stores, malls, you phones, and your home.  </li> 
                                          <li>- Make personal fashion magazines with the virtual fitting photos from you, your friends, and family.</li>
                                        </ul>                  
                                      </div>
                                    </div>

                                    <div class="col-lg-6 col-md-8">
                                      <div class="row row-flex">
                                        
                                          <div class="col-md-6">
                                            <div class="service-item">
                                              <h4>Easy</h4>
                                              <div class="line-dec"></div>
                                              <p>Upload your photo with your connected devices.</p>                      
                                            </div>
                                          </div>

                                          <div class="col-md-6">
                                            <div class="service-item">
                                              <h4>Accurate</h4> 
                                              <div class="line-dec"></div>
                                              <p>Vismile 3D fitting technology will precisely simulate the 3D virtual fitting results.</p>                      
                                            </div>
                                          </div>

                                          <div class="col-md-6">
                                            <div class="service-item">
                                              <h4>Fast</h4>
                                              <div class="line-dec"></div>
                                              <p>It takes only seconds to get your own 3D fitting results.</p>                      
                                            </div>
                                          </div>

                                          <div class="col-md-6">
                                            <div class="service-item">
                                              <h4>Fun</h4>
                                              <div class="line-dec"></div>
                                              <p>Various styles to try on and for social sharing.  </p>                      
                                            </div>
                                          </div>

                                      </div>
                                    </div> 


                                </div>



                               
                            )
              }
           </div>
        </div>


      </div>
    );

  }
}

const styles = {

  container: {
    /*
    marginRight: 30,
    marginBottom: 30,
    marginLeft: 30,
    minHeight: 'unset'
    */
  },
  showXAR: {
    marginTop: 10,
    //width:'96vw'
  },
 maincard: {

    height: 'unset',
    width: 'unset',
    maxHeight: '75vh'
   

  },
  xarImg: {
    transform: 'scale(1.0)'
  },
  cardImage2: {

    flexShrink: '0',
    width: '150px',
    height: '100%',
    borderRadius: '10px',
    marginLeft: '10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',

    margin: '1em 0',
  },  
};

export default connect(mapState, mapDispatch)(VismileSite);



  