import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducer as formReducer } from "redux-form";

// Reducer imports
import project from "./project-reducers/project";
import user from "./user-reducers/user";
import users from "./user-reducers/users";
import product from "./product-reducers/product";
import products from "./product-reducers/products";
import suits from "./suit-reducers/suits";
import suit from "./suit-reducers/suit";
import search from "./search";
import categories from "./category-reducers/categories";
import category from "./category-reducers/category";
import reviews from "./reviews-reducers/reviews";
import cart from "./cart-reducers/cart";
import userorderhistory from "./order-reducers/user-order-history";
import orderhistory from "./order-reducers/order-history";
import headers from "./header-reducers/headers";
import xar from "./xar-reducers/xar";
import home from "./home-reducers/home";
import webrtc from "./webrtc-reducers/webrtc";
import mirror from "./mirror-reducers/mirror";
import demo from "./demo-reducers/demo";
import packs from "./sewing-reducers/packs";
import payment from "./payment-reducers/payment";

// Persist configuration
const rootPersistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["cart"]
};

// Combine reducers
const rootReducer = combineReducers({
  project, 
  user,
  users,
  product,
  products,
  suit,
  suits,
  form: formReducer,
  search,
  categories,
  category,
  reviews,
  cart,
  userorderhistory,
  orderhistory, 
  headers,
  xar,
  home,
  webrtc,
  mirror,
  demo,
  packs, 
  payment,
});

// Create middleware array
const middlewareArray = [thunkMiddleware];

// Only add logger in development
if (process.env.NODE_ENV === 'development') {
  // Import logger only if needed
  const { createLogger } = require('redux-logger');
  middlewareArray.push(createLogger({ collapsed: true }));
}

// Configure middleware
const middleware = composeWithDevTools(
  applyMiddleware(...middlewareArray)
);

// Create the persisted reducer
const pReducer = persistReducer(rootPersistConfig, rootReducer);

// Create store
const store = createStore(pReducer, middleware);
export const persistor = persistStore(store);
export default store;

// Export actions and selectors from reducers
export * from "./project-reducers/project";
export * from "./cart-reducers/cart";
export * from "./category-reducers/categories";
export * from "./category-reducers/category";
export * from "./order-reducers/order-history";
export * from "./order-reducers/user-order-history";
export * from "./product-reducers/product";
export * from "./product-reducers/products";
export * from "./suit-reducers/suit";
export * from "./suit-reducers/suits";
export * from "./reviews-reducers/reviews";
export * from "./user-reducers/user";
export * from "./user-reducers/users";
export * from "./search";
export * from "./header-reducers/headers";
export * from "./xar-reducers/xar";
export * from "./home-reducers/home";
export * from "./webrtc-reducers/webrtc";
export * from "./mirror-reducers/mirror";
export * from "./demo-reducers/demo";
export * from "./sewing-reducers/packs";
export * from "./payment-reducers/payment";