import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { fetchCategories, fetchCategoriesGender } from "../../store";

const mapState = state => ({
  project: state.project,
  categories: state.categories
});

const mapDispatch = dispatch => ({
  getCategoriesGender: () => dispatch(fetchCategoriesGender()),
  getCategories: () => dispatch(fetchCategories())

});

class CategoryList extends React.Component {
  componentDidMount() {
    //this.props.getCategories();
    //this.props.getCategoriesGender();
    console.log("getCategoriesGender@CategoryList@Navbar")
  }

  render() {
    const { project, categories } = this.props;
    return (
      <Dropdown item icon="th list" simple>
        <Dropdown.Menu>
          {categories.length > 0 ? (
            categories.map(category => {
              return (
                <Dropdown.Item
                  key={category.id}
                  as={Link}
                  to={"/${project.projectName}/catalog/${category.id}/gender/${-category.genderID}/products"}
                >
                  {category.name}
                </Dropdown.Item>
              );
            })
          ) : (
            <Dropdown.Item>No Categories</Dropdown.Item>
          )}
          <Dropdown.Item as={Link} to={"/${project.projectName}/catalog/0/gender/${-category.genderID}/products"}>
            All Products
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default connect(mapState, mapDispatch)(CategoryList);
