export default `

    uniform vec3 keyColor;
    uniform float similarity;
    uniform float smoothness;
    varying vec2 vUv;
    uniform sampler2D map;
    uniform float spill;

// From https://github.com/libretro/glsl-shaders/blob/master/nnedi3/shaders/rgb-to-yuv.glsl
vec2 RGBtoUV(vec3 rgb) {
  return vec2(
    rgb.r * -0.169 + rgb.g * -0.331 + rgb.b *  0.5    + 0.5,
    rgb.r *  0.5   + rgb.g * -0.419 + rgb.b * -0.081  + 0.5
  );
}

vec4 ProcessChromaKey() {
  vec4 rgba = texture2D(map, vUv);

  //vec4 sample_Texture = texture2D(map, vec2(0, 0));
  //
  //vec3 sample_KeyColor = sample_Texture.rgb;  
  //
  //if( all( equal( sample_Texture.rgb, vec3(0,0,0) ) ) ){
  //  sample_KeyColor = keyColor;
  //}
  //
  //float chromaDist = distance(RGBtoUV(rgba.rgb), RGBtoUV(sample_KeyColor.rgb));
  //
  //float baseMask = chromaDist - similarity;
  //float fullMask = pow(clamp(baseMask / smoothness, 0., 1.), 1.5);
  //rgba.a = min(fullMask, rgba.a);
  //
  //if(vUv.x < 0.2 && vUv.y < 0.2){
  //    rgba.a = 1.0;
  //}
  //
  //float spillVal = pow(clamp(baseMask / spill, 0., 1.), 1.5);
  //float desat = clamp(rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722, 0., 1.);
  //rgba.rgb = mix(vec3(desat, desat, desat), rgba.rgb, spillVal);

  return rgba;
}

void main() {
  gl_FragColor = ProcessChromaKey();
}

`
