import React, { Component } from "react";

import { connect } from "react-redux";
import { Container, Header, Button, Card, Image, Grid, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { fetchProduct, fetchDemo, findSuits, updateDataXAR, getTicketWebrtc, updateTicketWebrtc, updateWebRTC } from "../../store";

import { ProductCard, EmptyProduct } from "../../components";

//import { AnimatedGesture } from './AnimatedGesture';
//import { withHooksHOC } from './AnimatedGesture';
//import { animated } from "react-spring";

import './CrossBrowser.css';



const mapState = state => ({

  project: state.project,
  xar: state.xar, 
  demo: state.demo, 
  suits: state.suits,
  suit: state.suit,
  webrtc: state.webrtc

});

const mapDispatch = dispatch => {
  return {
      getProduct: id => dispatch(fetchProduct(id)),
      getDemo:   (projectName) => dispatch(fetchDemo(projectName)),
      searchSuits:   (suitIDs) => dispatch(findSuits(suitIDs)),

      updateXAR: (xar) => dispatch(updateDataXAR(xar)),    
    
      bookingWebrtc: (webrtc) => dispatch(getTicketWebrtc(webrtc)),    
      checkTicketWebrtc: (webrtc) => dispatch(updateTicketWebrtc(webrtc)),    
      setWebRTC: (webrtc) => dispatch(updateWebRTC(webrtc)),    


  };
};






class CrossBrowser extends Component {


  constructor(props) {
    super(props);



    //const { } = this.props;

    this.state = {

        projectName: this.props.project.projectName,
        windowWidth: window.innerWidth,
        //timer: null,
        renderObjs: null,    
        
        
        demoSuit: null,

        onClickDemo: false,  

        orientation: [0, 0, 0],
        color: "red"
    };
    
  

  }



  componentDidUpdate(prevProps, prevState) {

    const { project, webrtc} = this.props;

    console.log("**********************************************");
    console.log("componentDidUpdate@DemoSite");
    //console.log("this.props.project.:", this.props.project);
    //console.log("prevProps.project.:", prevProps.project);
    //console.log("this.state.userStatus:", this.state.userStatus);
    //console.log("prevState.userStatus:", prevState.userStatus);





  }

  componentWillUnmount() {
   

    window.removeEventListener('deviceorientation');
      
  } 

  componentDidMount() {
    
    console.log("this@componentDidMount", this)


    //window.addEventListener("resize", this.handleResize);
    const { getDemo, getProduct, demo, searchSuits, project, setWebRTC  } = this.props;

    this.setState({ ARfittigTimeOut : project.ARfittigTimeOut });

    console.log("this.props.myTurnNow, this.state.myTurnNow@DemoSite componentDidMount", this.props.myTurnNow, this.state.myTurnNow)


    getDemo(this.state.projectName).then( () => {
                                                  let suitIDs = [];
                                                  console.log("demo", this.props.demo)
                                                  this.props.demo.map(item => {
                                                                                 suitIDs.push(item.suitID) 
                                                                              }
                                                                                     
                                                                     )
                                                  console.log("suitIDs", suitIDs)
                                                  
                                                  searchSuits(suitIDs).then( () => {
                                                                                    //console.log("this", this)
                                                                                    this.setState({renderObjs: this.props.suits}) 
                                                                                    

                                                                                    //console.log("this.state.renderObjs", this.state.renderObjs)

                                                                           })

                                                }


                                        )



  }

/********************************************************/
//  AR fitting related start
/********************************************************/



  AIfittingSubmit(suit) {
    const {
      
      project,
      xar,
      webrtc,
      updateXAR,
      requestXAR, 

      requestRTCAR,

      setProject

    } = this.props;

    
    this.DemoClothesProducts(suit);

    xar.isRequest = true;
    xar.isUpdate = false;
    xar.isRTC = true;
    xar.peerID = webrtc.myId;
    

    updateXAR(xar);
    
    //if no photo, do take photo
    //if already has photo, do xar json request
    
    //

    const linkAddr       = `${project.projectName}/metafitting`
    const returnLinkAddr = `/${project.projectName}`
                            
    console.log("linkAddr@XARsubmit: " + linkAddr);

    this.setState({ returnLinkAddr: returnLinkAddr});

    console.log("project@XARsubmit: " + project);
 
 
    console.log("don't send and go to RTC camera page @XARsubmit ", this);
    this.props.history.push({ pathname: `/${project.projectName}/ARfitting`,
                              state: { linkAddr: linkAddr }
                           });
     


 

  }

  DemoClothesProducts(suit) {
    console.log("============================= clothlist ===============================")
    
    const { xar, product } = this.props;
    console.log("this.props @XARclothesProducts: ", this.props)
    console.log("suit @XARclothesProducts: ", suit)


    

    const video_filename = xar.picID + '_' + suit.suitID;
    
    /* originally, find the same suit of other items
    const clothlist = [];
    suit.suit.products.forEach(product => {
        console.log("product.suit.products.internal_name: ", product.internal_name)
        clothlist.push(product.internal_name);        
      });
    */
    const clothlist = suit.suitClothesID;
    const shoes = suit.shoes;

    console.log("clothlist: ", clothlist)

    xar.clothlist = clothlist;
    xar.shoes = shoes;
    xar.suitID = suit.suitID;
    xar.clothesID = suit.suitID;//product.product.internal_name;
    xar.video_filename = video_filename;
    if(suit.gender === 'male'){
      xar.human.gender = 1;  
    }else
    {
      xar.human.gender = 0;
    }
    

  }

/********************************************************/
//  AR fitting related end
/********************************************************/


/********************************************************/
//  animation slide start
/********************************************************/


/********************************************************/
//  animation slide end
/********************************************************/



/********************************************************/
//  paypal sandbox end
/********************************************************/

/********************************************************/
//  device orientation start
/********************************************************/
handleClick(){

    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      DeviceOrientationEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            this.setState({color: "blue"})
            //window.addEventListener('deviceorientation', this.handleOrientation);
            window.addEventListener('deviceorientation', (event) => {
                                    this.setState({color: "black"})
                                    this.setState({orientation: [event.alpha, event.beta, event.gamma] })
                                   })
          }
        })
        .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices
      window.addEventListener('deviceorientation', (event) => {
                                    this.setState({color: "black"})
                                    this.setState({orientation: [event.alpha, event.beta, event.gamma] })
                             })
    }

}


/********************************************************/
//  device orientation end
/********************************************************/

  render() {
    
    const {  } = this.props;

    


    const { windowWidth } = this.state; 

    

    console.log("this.state.renderObjs", this.state.renderObjs)
    console.log("this.state.initialOptions", this.state.initialOptions)

    
    return (
      <Container className="containerCrossBrowser" style={ styles.container} >
        <Header as="h1">
          
        </Header>
        <div>


            <div style={styles.overlayA}>
                
                <Header as="h1" style={styles.headerH1}>
                  MetaFitting, changes clothes and go! 
                </Header>

                <Button
                  color={this.state.color}
                  onClick={() => this.handleClick()}
                >
                  <Icon name="Orientation" />Orientation
                </Button>

                <Header>{this.state.orientation[0]}</Header>
                <Header>{this.state.orientation[1]}</Header>
                <Header>{this.state.orientation[2]}</Header>
                                              
            </div>    
            
            {/*<div style={styles.overlayB}>
            
                <Header as="h2" style={styles.headerH2}>
                  You can change clothes of anybody, including yourself, with your phone.
                </Header>

            </div>*/}

            <video loop muted autoPlay playsInline style={styles.video}>
              <source
                src="/videos/ARfitting.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>




        </div>
        
        <div style={styles.demoMsgBox}>
        
          <Header as="h1" style={styles.demoDescription}>
             Choose an item and click to try on.
          </Header>


        </div>
              

        {
          this.state.renderObjs ? (
                                      <Grid.Row className="hs full" style={styles.rowList}>

                                        
                                          <li className="itemCrossBrowser" style={styles.columnList}>
                                               {this.state.renderObjs.map(suit => (     
                                                                                     (suit.gender==='female') ? (  
                                                                                               
                                                                                         <Card as={Link}  onClick={() =>  this.onClickSuit(suit) /*this.ARfittingSubmit(suit)*/ }  style={styles.cardImage2}>
                                                                                          
                                                                                          <Image src={suit.imageUrl} style={styles.cardImage}/>
       
                                                                                         </Card>
                                                                                     ) : null
                                                                                  
                                                                                  ))}
                                          </li>
                                          <li className="itemCrossBrowser" style={styles.columnList}>
                                               {this.state.renderObjs.map(suit => (     
                                                                                     (suit.gender==='male') ? (  
                                                                                               
                                                                                         <Card as={Link}  onClick={() =>  this.onClickSuit(suit) /*this.ARfittingSubmit(suit)*/ }  style={styles.cardImage2}>
                                                                                          
                                                                                          <Image src={suit.imageUrl} style={styles.cardImage}/>
       
                                                                                         </Card>
                                                                                     ) : null
                                                                                  
                                                                                  ))}
                                          </li>                                                                          
                                          <li className="itemCrossBrowser" style={styles.columnList}>
                                               {this.state.renderObjs.map(suit => (     
                                                                                     (suit.gender==='female') ? (  
                                                                                               
                                                                                         <Card as={Link}  onClick={() =>  this.onClickSuit(suit) /*this.ARfittingSubmit(suit)*/ }  style={styles.cardImage2}>
                                                                                          
                                                                                          <Image src={suit.imageUrl} style={styles.cardImage}/>
       
                                                                                         </Card>
                                                                                     ) : null
                                                                                  
                                                                                  ))}
                                          </li>
                                          <li className="itemCrossBrowser" style={styles.columnList}>
                                               {this.state.renderObjs.map(suit => (     
                                                                                     (suit.gender==='male') ? (  
                                                                                               
                                                                                         <Card as={Link}  onClick={() =>  this.onClickSuit(suit) /*this.ARfittingSubmit(suit)*/ }  style={styles.cardImage2}>
                                                                                          
                                                                                          <Image src={suit.imageUrl} style={styles.cardImage}/>
       
                                                                                         </Card>
                                                                                     ) : null
                                                                                  
                                                                                  ))}
                                          </li>  
                                      </Grid.Row> 
                                  ) : null



       }








      </Container>
    );

  }
}

const styles = {
  rowList: {
    /*
    display: 'grid',
    gridGap: 'calc(var(--gutter) / 2)',
    gridTemplateColumns: '10px',
    gridTemplateRows: 'minmax(150px, 1fr)',
    gridAutoFlow: 'column',
    gridAutoColumns: 'calc(50% - var(--gutter) * 2)',

    overflowX: 'scroll',
    scrollSnapType: 'x proximity',
    paddingBottom: 'calc(.75 * var(--gutter))',
    marginBottom: 'calc(-.25 * var(--gutter))',
    */
    //flex: 1,
    //flexDirection: 'row',
    //alignItems: 'flex-start', // if you want to fill rows left to right
    //height: '50vh',
    //display: '-webkit-box',
    //overflowX: 'scroll',
    
   
  },
  columnList: {
      //float: "left",
      //overflowY: 'scroll',
      //width: "40%",
      //justifyContent: 'space-evenly',
      //maxHeight: '-webkit-fill-available',
  },

  container: {
    marginRight: 30,
    marginBottom: 30,
    marginLeft: 30,
    minHeight: 'unset'
  },
  containerMirror: {
    marginRight: 30,
    marginBottom: 30,
    marginLeft: 30,
    minHeight: '51vh',
    paddingTop: '30px',
  },
  button: {
    marginLeft: 20
  },
  mainDiv: {
    width: '60%',
    marginLeft: '20%'
  },
  instructions: {
    fontSize: '5.5rem',
    textAlign: 'center',
  },
  qrcode: {
    width: '100%',
    height: '100%'
  }, 
  imageGrid: {

    margin: "auto"
  },
  productGrid: {
    marginLeft: '-0.5em',
    marginRight: '-0.5em',
     
  },
  card: {
    /*height: 'unset', removed for small size to easiler gesture control */
    /*minWidth: '290px'*/
    height: '100%'
  },
  cardContent: {
    minHeight: 'unset',
  },
  cardImage: {
    width: '100%',
  },
  video: {
    width: '100%',
    
    position: 'relative',
    '& video': {
      objectFit: 'cover',
    },
  },
  overlayA: {
    position: 'absolute',
    zIndex: '1',
    
    width: '32vw',
    margin: '3vw',
    marginTop: '3vh',
    

  },
  overlayB: {
    position: 'absolute',
    zIndex: '1',
    
    width: '32vw',
    margin: '22vw 42vw 0vw',
    
    

  },
  headerH1: {

    color: '#ff82af',
    fontSize: '4vw',
    padding: '1.5vw',
    backgroundColor: 'rgba(255,255,255,0.62)',

  },
  headerH2: {
    paddingTop: '3.5rem',
    /*color: '#ff82af',*/
    fontSize: '3vw',
    /*backgroundColor: 'rgba(255,255,255,0.56)',*/
    padding: '1.5vw',
    paddingTop: '1.5vw',
    paddingBottom: '1.5vw',
  },
  demoMsgBox: {

    margin: '2rem',
    textAlign: 'center',

  },
  demoDescription: {
    fontSize: '3vw',

  },
  cardImage2: {

    flexShrink: '0',
    width: '150px',
    height: '30%',
    borderRadius: '10px',
    marginLeft: '10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',

    margin: '1em 0',
  },

  donateContainer:{




  },

};

export default connect(mapState, mapDispatch)(CrossBrowser);



  