export default `
    varying vec2 vUv;

    uniform int tilt;    
    uniform float rotateAngle;    
    uniform sampler2D map;
    uniform sampler2D warpMap;
/*
vec2 transformUV(vec2 uv){
    if(rotateAngle == 0.0){
        return vec2(1.0) - uv;
    }
    else if(rotateAngle == 90.0){
        return vec2(1.0) - vec2(uv.y, uv.x);
    }
    else if(rotateAngle == -90.0 || rotateAngle == 270.0){
        return vec2(uv.y, uv.x);
    }
    return uv;
}
*/
vec2 wrapUVtoVideoUV(vec2 uv){
    
    if(rotateAngle == 0.0){
        return uv;
    }
    else if(rotateAngle == 90.0){
        return vec2(1.0) - vec2(uv.y, uv.x);
    }
    else if(rotateAngle == -90.0 || rotateAngle == 270.0){
        return vec2(uv.y, uv.x);
    }
    return vec2(1.0) - uv;
}

void main( )  {

        if(tilt == 0){
            if(rotateAngle == 0.0){
                gl_FragColor = texture2D(map, vUv);
            }
            else if(rotateAngle == 90.0){
                gl_FragColor = texture2D(map, vec2(1.0) - vec2(vUv.y, vUv.x));
            }
            
        }else{
            vec4 warpUV = texture2D(warpMap, wrapUVtoVideoUV(vUv)); 

            if(length(warpUV) == 0.0){
                gl_FragColor = vec4(1.0); 
            }else{

                vec2 srcUV = vec2((warpUV.r*255.0+warpUV.g)/255.0, (warpUV.b*255.0+warpUV.a)/255.0);
                gl_FragColor = texture2D(map, wrapUVtoVideoUV(srcUV)); 
            }
        }



}
`
