import axios from 'axios'
import store from '../../store';

/**
 * ACTION TYPES
 */
export const GET_ALL_HEADERS = 'GET_ALL_HEADERS'
/**
 * INITIAL STATE
 */
const initialState = [
                       {
                           id: 0, 
                           name: '', 
                           description: ''

                       }
                     ]

/**
 * ACTION CREATORS
 */

const getHeaders = headers => ({
  type: GET_ALL_HEADERS,
  headers
})

/**
 * THUNK CREATORS
 */
export const fetchHeaders = () => {
  return async dispatch => {

    //console.log("store", store)

    const body = {
                  databaseName: store.getState().project.projectName,
                  }

    const {data} = await axios.post(`/api/header`, body)
    dispatch(getHeaders(data))
  }
}

/**
 * REDUCER
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_HEADERS:
      return action.headers
    default:
      return state
  }
}
