// Dependencies
import React, { useState, useEffect, useRef } from "react";

//Internals

import './App.css';

import Routes from "./routes";



const App = () => {
  /*
  const prevScrollY = useRef(0);

  const [goingUp, setGoingUp] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
      console.log(goingUp, currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);
*/
  return (
    <div id="app" >
        
       
        
        <div className="content">
          <Routes />
        </div>
    </div>
  );
};

export default App;
