import React from 'react';
import ReactDOM from 'react-dom';

//import './index.css';


import App from './App';
import BaseLayout from './components/BaseLayout';
import registerServiceWorker from './registerServiceWorker';



import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import history from "./history";
import { PersistGate } from "redux-persist/lib/integration/react";
import store, { persistor } from "./store";

import 'semantic-ui-css/semantic.min.css'; //has ;; duplicate typo, need to fix manually in node_modules

import Tracking from './components/Tracking';

//-------------------------
//BrowserRouter => use Router, history can work

//--------------------------

// replace console.* for disable log on production
/*
if (process.env.NODE_ENV === 'production') {	
	console.log("process.env.NODE_ENV", process.env.NODE_ENV)
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}
*/
ReactDOM.render(
	
	   <Provider store={store}>
		<PersistGate persistor={persistor}>	
		  <Router history={history}>
		  		
		  		<BaseLayout>
		     		<App />
		     	</BaseLayout >  
		    	
		  </Router>
		 </PersistGate>
		</Provider>  

, document.getElementById('root'));
//registerServiceWorker();
