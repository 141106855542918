import React, { Component }  from "react";
import { Tshirt_Item, SewingUploadApp } from "../../components";
import { Login } from "../../components";
import { connect } from "react-redux";
import { uploadMaterials, uploadTexList, readyToUpload, fetchModel, updateParams} from "../../store";
import { IMAGE_TYPES } from 'jslib-html5-camera-photo';

import { Button } from "semantic-ui-react";
//import './stylesheets/pygment_trac.css';
//import './stylesheets/styles.css';

const mapState = state => ({
  user: state.user,
  isLoggedIn: !!state.user.id,

  packs: state.packs, 

  baselayout: state.baselayout
  
});

const mapDispatch = dispatch => {
  return {

  	uploadImages: (formData, packs) => dispatch(uploadMaterials(formData, packs)),
  	uploadList: (packs, folder) => dispatch(uploadTexList(packs, folder)),
  	isReady: (packs) => dispatch(readyToUpload(packs)),

  	downloadModel: (packs, folder) => dispatch(fetchModel(packs, folder)),

  	setParams: (params) => dispatch(updateParams(params))

  };
};



class SewingUploadPage extends Component{
	constructor() {
	    super();
	    
	    
	    this.state = {

	    	
			

		    render_id: 0,
			ImgList: [],
			ReadyToUploadCount: 0,
			isReadyToUpload: false,
			isReset: false,
			toSaveImg: false,

			folder: '',

			isLoading: false, 
			isFinished: false
		    };
	}
	


	componentDidMount() {

	    const { baselayout, setParams } = this.props;

	    var params = baselayout;

	    params.onButton = false;
	    params.onGesture = false;//set img src need to be remove
	    
	    setParams(params);
	    
	    

	}  

	componentDidUpdate(prevProps, prevState) {
		console.log('componentDidUpdate');
	    console.log('prevProps', prevProps);
	    console.log('Props', this.props);
	    console.log('prevState', prevState);
	    console.log('State', this.state);
	    
	    /*
	    if (prevProps.packs.allReady !== this.props.packs.allReady) { // => 比較更新前後的 state 屬性
	    	console.log("prevProps.packs.allReady !== this.props.packs.allReady")
	        this.handleUpload();
	    }
	    */
	    console.log('prevState.toSaveImg', prevState.toSaveImg);
	    console.log('this.state.toSaveImg', this.state.toSaveImg);
	    
	    if (prevState.toSaveImg !== this.state.toSaveImg) { // => 比較更新前後的 state 屬性
	    	console.log("prevState.toSaveImg !== this.state.toSaveImg")
	        this.handleUpload();
	    }

	
	    if(prevProps.packs.ModelDone !== this.props.packs.ModelDone){
        	this.setState({ isLoading: false }) 
        	this.setState({ isFinished: true }) 
      	}


	}

	resetPage = () => {

		this.setState({ isReset : true })
		/*
	    this.state.ReadyToUploadCount = 0;
	    this.state.isReadyToUpload = false;
	    this.state.render_id = 0;
	    this.state.toSaveImg = false;
	    this.state.isLoading = false;
	 	this.state.isFinished = false;
	    */
	    this.setState({ ReadyToUploadCount: 0 }) 
	    this.setState({ isReadyToUpload: false }) 
	    this.setState({ render_id: 0 }) 
	    this.setState({ toSaveImg: false }) 
	    this.setState({ isLoading: false }) 
	    this.setState({ isFinished: false })    
	}

	set_ReadyToUpload = () =>{
		this.state.ReadyToUploadCount++;
		if(this.state.ReadyToUploadCount > 0 && this.state.ReadyToUploadCount === this.props.packs.masklist.length)
	        this.setState({ isReadyToUpload : true })
	}

	set_render_id = (value) => {
	  this.setState({ render_id : value })
    } 

    push_back_img = (value) =>{
    	var limit = 5
    	if (this.state.ImgList.length >= limit){		//Max 5 item
    		//var removed = this.state.ImgList.splice(limit-1, this.state.ImgList.length-(limit-1));
    	}
		this.state.ImgList.unshift(value)
    }

    push_save_img = (value) =>{
    	var limit = 5
    	if (this.state.ImgList.length >= limit){		//Max 5 item
    		//var removed = this.state.ImgList.splice(limit-1, this.state.ImgList.length-(limit-1));
    	}
		this.state.ImgList.unshift(value)
    }


	dataURItoBlob (dataURI) {
	  let byteString = atob(dataURI.split(',')[1]);

	  // separate out the mime component
	  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	  let ab = new ArrayBuffer(byteString.length);
	  let ia = new Uint8Array(ab);
	  for (let i = 0; i < byteString.length; i++) {
	    ia[i] = byteString.charCodeAt(i);
	  }
	  let blob = new Blob([ab], {type: mimeString});
	  return blob;
	}

    getFileExtention (blobType) {
      // by default the extention is .png
      let extention = IMAGE_TYPES.PNG;

      if (blobType === 'image/jpeg') {
        extention = IMAGE_TYPES.JPG;
      }
      return extention;
    }

    handleReady() {

    	console.log("handleReady");
		const { packs, isReady } = this.props;

		isReady(packs);

		this.setState({ toSaveImg : true })
		this.setState({ isReadyToUpload : false })


    }

    handleUpload() {

    	const { packs, uploadImages, /*uploadList,*/ user } = this.props;

		console.log('handleUpload');
		console.log('packs: ', packs);
		console.log('imglist: ', packs.imglist);
		
        var formData = new FormData();
        for (const key of Object.keys(packs.imglist)) {

			console.log('key = ', key);

            const blob = this.dataURItoBlob(packs.imglist[key]);
            const filename_full = packs.masklist[key].split(".");//split("\\.(?=[^\\.]+$)");
            console.log('filename_full = ', filename_full);
            const filename = filename_full[0];
            const extention = this.getFileExtention(blob.type);
            const filename_ext = filename + '.' + extention;

            console.log('append file: ', key, ', filename: ', filename_ext)
    
            formData.append('file', blob, filename_ext);

        }
        
	    var options = {
	      day: '2-digit',    //(e.g., 1)
	      month: '2-digit',    //(e.g., Oct)
	      year: '2-digit',   //(e.g., 2019)
	      hour: '2-digit',   //(e.g., 02)
	      minute: '2-digit', //(e.g., 02)          
	      second: '2-digit',
	      hour12:false// 
	    };

	    var now = new Date().toLocaleString('en-us', options,  {timeZone: 'Asia/Taipei', hour12:false}); 

	    var uploadStamp = now.replace(/\//g,'');
	    uploadStamp = uploadStamp.replace(/:/g, '');
	    uploadStamp = uploadStamp.replace(/,\s/g, '-');
    	const folder = user.id + '_' + uploadStamp;
    	console.log("folder: ", folder);
    	this.setState({ folder : folder })
    	
    	const packs_toServer = packs;
    	delete packs_toServer.imglist;
    	console.log("packs_toServer: ", packs_toServer);
    	
    	formData.append('folder', folder);
    	formData.append('packs', JSON.stringify(packs_toServer));


        uploadImages(formData, packs);
        //uploadList(packs, folder);    	


    }
    handleDownload() {

    	console.log("handleDownload");
		const { downloadModel, packs } = this.props;
		const { folder } = this.state;

		this.setState({ isLoading : true })

		console.log('folder@handleDownload: ', folder);
		downloadModel(packs, folder);
	
    }

	render() {
		const { user, packs } = this.props;
		//this.state.PatternList = packs.masklist;
		console.log("packs.masklist " + packs.masklist);
		//console.log("this.state.PatternList " + this.state.PatternList);
		//const packs = products.map(product => (
        //  <ProductCard key={product.id} product={product} />
        //))}
        console.log("==========================================================");
        console.log("packs.ImgReady@page: ", packs.ImgReady);
        console.log("packs.isReady@page: ", packs.isReady);
        console.log("packs.isUpload@page: ", packs.isUpload);
 		console.log("packs.ImgReady.every(e => e == true)@page: ", packs.ImgReady.every(e => e === true));
 		console.log("packs.allReady@page: ", packs.allReady);
 		console.log("packs.@page render: ", packs);
 

/* 		
 		if(packs.ModelDone){
        	this.setState({ isLoading: false }) 
        	this.setState({ isFinished: true }) 
      	}	
*/
        var PatternList = []
        for(var i = 0;i < packs.masklist.length; i++){
        	PatternList.push({maskImage: "images-sewing/"+packs.masklist[i], id:i})
        }

		const sewingUploadElements = PatternList.map((pattern_data) => (
	      <SewingUploadApp patternList={PatternList} 
	      				imgList={this.state.ImgList} 
	      				id={pattern_data.id} 
	      				render_id={this.state.render_id} 
	      				onClickMask={this.set_render_id} 
	      				onLoadImg={this.push_back_img}
	      				isReset={this.state.isReset}
	      				onSetImg={this.set_ReadyToUpload}
	      				/>
	    ));




		if(this.state.isReset)
	        this.setState({ isReset : false })

	 

	    console.log("!!user.id: " + !!user.id);
		if (!!user.id) {
			return(

			  <div className="wrapper">
			    <section>
			      
			      <Tshirt_Item handle_Submit={this.resetPage}/>
			     
			      <div className=" show-product ui raised very padded text segment">
			      	  
			      	  {sewingUploadElements}
				  
				  </div> 
				
				  <div className="  show-product ui raised very padded text segment ">  		
				      <Button type="submit"  disabled={!this.state.isReadyToUpload}  onClick={() => this.handleReady()}>
				      	Save & Upload 
				      </Button>
				      
					  <Button type="submit" disabled={!this.state.toSaveImg || this.state.isFinished} loading={this.state.isLoading} onClick={() => this.handleDownload()}>
					   Download
	          		  </Button>
	          	  </div>

			      
			    </section>


			  </div>
			);
		}else{

			return(
			  <div className="wrapper">
			    <section>
			      <Login redirect={"/sewing"}/>
			      
			    </section>
			  </div>
			);


		}
	}
}


//export default SewingUploadPage;
export default connect(mapState, mapDispatch)(SewingUploadPage);