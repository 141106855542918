import React, { Component } from "react";
import { CategoryGrid } from "../../components";
import { connect } from "react-redux";
import { Container, Header, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { fetchCategoriesGender, fetchCategories, fetchCart, fetchHeaders, updateDataXAR, updateProject } from "../../store";

import './categories.css';


const mapState = state => ({
  project: state.project,
  user: state.user,
  categories: state.categories,
  cart: state.cart, 
  xar: state.xar
});

const mapDispatch = dispatch => ({

  setProject: (params) => dispatch(updateProject(params)),

  getCategoriesGender: () => dispatch(fetchCategoriesGender()),
  getCategories: () => dispatch(fetchCategories()),
  getCart: id => dispatch(fetchCart(id)),

  getHeaders: () => dispatch(fetchHeaders()),
  
  updateXAR: (xar) => dispatch(updateDataXAR(xar))

});




class CategoryCatalog extends Component {


  constructor(props) {
    super(props);

    const projectName = this.props.match.params.projectName.toLowerCase();
    const { project, setProject } = this.props;
    var params = project;   
    params.projectName = projectName


    this.state = {
      
      projectName: projectName      

    };
    console.log("params@CategoryCatalog: ", params)
    setProject(params);
    

    
  }




  componentDidMount() {
    
   
    
    const { getCart, user  } = this.props;

    if (user.id) getCart(user.id);

    

    //for men/women catogeries only.
    
  }

  render() {
    
    const { project, user, categories } = this.props;
    const { categoryGenderMode } = project;

    const genderId = Number(this.props.match.params.genderId);


    if(genderId!==""){
      var HierachyCategoryCatalog = 1
    }else{

      var HierachyCategoryCatalog = 0
    }


    console.log("genderId", genderId)
    console.log("project", project)

    return (
      <Container style={styles.container}>
        <Header as="h1">
          {user.isAdmin ? (
            <Button
              as={Link}
              to="/admin/category/add"
              basic
              color="green"
              style={styles.button}
            >
              Add A Category
            </Button>
          ) : (
            ""
          )}
        </Header>
        
        <CategoryGrid categoryGenderMode={categoryGenderMode} HierachyCategoryCatalog={HierachyCategoryCatalog} genderId={genderId} project={project} categories={categories}/>
      </Container>
    );
  }
}

const styles = {
  container: {
    
   
    
    marginRight: 30,
    marginBottom: 30,
    marginLeft: 30,

  },
  button: {
    marginLeft: 20
  }
};

export default connect(mapState, mapDispatch)(CategoryCatalog);
