import React from "react";
import { Card } from "semantic-ui-react";
import { CategoryCard } from "../../components";
import { connect } from "react-redux";


import './categories.css';

const mapState = state => ({
    categories: state.categories
});

//    <Card.Group itemsPerRow={6}>
const CategoryGrid = ({ categories, categoryGenderMode, HierachyCategoryCatalog, genderId, project, windowWidth }) => {

  
  //console.log("categories @CategoryGrid", categories)
  //console.log("project @CategoryGrid", project)
  //console.log("genderId @CategoryGrid", genderId)
  //console.log("categoryGenderMode, HierachyCategoryCatalog", categoryGenderMode, HierachyCategoryCatalog)
  /*
  const categories_render = HierachyCategoryCatalog ? categories.filter(o => o.genderID === -genderId)
                                              : categoryGenderMode ? categories.filter(o => o.genderID < 0 ) : categories.filter(o => o.genderID < 0 ) //categories 
  */
  var categories_render =""
  if(HierachyCategoryCatalog===0 && categoryGenderMode===0){
      //show all categories without Men/Women catgeory at first
      //console.log("HierachyCategoryCatalog===0 && categoryGenderMode===0  categories.filter(o => o.genderID > 0 )")
      categories_render = categories.filter(o => o.genderID > 0 )

  }else if(HierachyCategoryCatalog===0 && categoryGenderMode===1){
      //show gender Men/Women category at first
      //console.log("HierachyCategoryCatalog===0 && categoryGenderMode===1  categories.filter(o => o.genderID < 0 )")
      categories_render = categories.filter(o => o.genderID < 0)

  }else if(HierachyCategoryCatalog===0 && categoryGenderMode===2){
      //show gender Men/Women category at first
      //console.log("HierachyCategoryCatalog==0 && categoryGenderMode===2  categories.filter(o => o.genderID < 0 )")
      categories_render = categories.filter(o => o.genderID < 0)





  }else if(HierachyCategoryCatalog===1 && categoryGenderMode===1){
      //after gender and show gender's categories
      //console.log("HierachyCategoryCatalog==1 && categoryGenderMode===1  categories.filter(o => o.genderID === -genderId )")
      categories_render = categories.filter(o => o.genderID === -genderId )
  
  }else if(HierachyCategoryCatalog===1 && categoryGenderMode===2){
      //after gender and show gender's products
      //console.log("HierachyCategoryCatalog==1 && categoryGenderMode===2  categories_render==__ ")
      categories_render = ""


  }else{
      //after gender and show gender's products
      //console.log("categories_render=__")
      categories_render = ""
  }                                            


  //console.log("categories_render", categories_render)
  //console.log("windowWidth", windowWidth)

  const varItemsPerRow = windowWidth > 1900 ? 3 : 2;


  return (
      <Card.Group itemsPerRow={varItemsPerRow} className="categories-wrapper" style={styles.categoryGrid}>
          {

              categories_render.length > 0 ? (
              categories_render.map(category => (
                <CategoryCard key={category.id} categoryGenderMode={categoryGenderMode}  HierachyCategoryCatalog={HierachyCategoryCatalog} project={project} {...category}  />
              ))
              ) : (
                <div>No Categories</div>
              )
          }
          {/*
              <CategoryCard
                   key={-1}
                   id={0}
                   name="All Products"
                   imageUrl="/images/all-products.png" 
              />
          */}
      </Card.Group>
  );
};

const styles = {
  categoryGrid: {
    marginLeft: '-1.5em',
    marginRight: '-1.5em',
     
  }
 
};

export default connect(mapState)(CategoryGrid);
