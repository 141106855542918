import axios from 'axios'
import store from '../../store';

/**
 * ACTION TYPES
 */
import {ADD_SUIT, EDIT_SUIT} from './suit'
const GET_SUITS = 'GET_SUITS'
const GET_SEARCH_SUITS = 'GET_SEARCH_SUITS'

/**
 * INITIAL STATE

    name: "Vismile collection",
    suitID: "vs_f_002",
    suitClothesID: [],
    gender: "female",
    designerName: "Vismile",
    styleName: "",
    bodyShape: "",
    bodyHeight: "",
    description: "",              
    scenary: "", 
    imageUrl: "/images/robo-5.png",
    
 */
const initialSuits = [
  {
    id: 0,
    name: '',
    suitID: '',
    suitClothesID: [],
    gender: "female",
    designerName: "Vismile",
    styleName: "",
    bodyShape: "",
    bodyHeight: "",
    description: "",              
    scenary: "", 
    imageUrl: "/images/robo-5.png"
  }
]

/**
 * ACTION CREATORS
 */
const getSuits = suits => ({
  type: GET_SUITS,
  suits
})


export const findSuits = (suitIDs) => {
  return async dispatch => {
    
    const body = {
                  databaseName: store.getState().project.projectName,
                  suitIDs: suitIDs
                 }
    
    const {data} = await axios.post(`/api/suits/find/`, body)
    dispatch(getSuits(data.suit))
    console.log("findSuits data.suit: ", data.suit);
    
  }
}

export const getSearchSuits = suits => ({
  type: GET_SEARCH_SUITS,
  suits
})

/**
 * THUNK CREATORS
 */

export const fetchSuits = () => async dispatch => {
  const {data} = await axios.get('/api/suits')
  dispatch(getSuits(data))
}

/**
 * REDUCER
 */
export default function(state = initialSuits, action) {
  switch (action.type) {
    case ADD_SUIT:
      return [...state, action.suit]
    case EDIT_SUIT:
      return [...state].map(suit => {
        return suit.id === action.suit.id
          ? {...suit, suit: action.suit}
          : suit
      })
    case GET_SUITS:
      return action.suits
    case GET_SEARCH_SUITS:
      return action.suits
    default:
      return state
  }
}
