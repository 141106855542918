import axios from "axios";
import produce from 'immer';

/**
 * ACTION TYPES
 */
export const GET_PROJECT = 'GET_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const UPLOAD_PROJECT = 'UPLOAD_PROJECT'
export const FETCH_PROJECT = 'FETCH_PROJECT'

/**
 * INITIAL STATE
 */
const initialState = {  //when webpage loading at the beginning, not yet get response from server, requires initial values
                        projectName: "",
                        pageName: "",
                        userId: 0,
                        mirrorId: -1,
                        remoteUserId: -1,
                        mirrorSide: 0,
                        userStatus: 0,
                        ARfittigTimeOut: false,  //Mirror side
                        ARfittingMirrorStart: false,  //Mirror side
                        ARfittingRemoteStart: false, //Remote side
                        controlRemoteStart: false, //Remote side
                        controlMirrorStart: false, //Mirror side
                        onTakePhotoStart: false, //Remote side
                        onTakePhoto: false, //Remote side
                        onHandleTakePhoto: false, //Mirror side
                        photoName: '', //Remote side to Mirror side
                        photoDone: false, //Mirror side to Remote side
                        tiltCompensation: 4,//0:0 degree, 1: 7.5degree, 2: 15degree,  3: 22.5degree, 4: 30degree, 5: 37.5degree, 6. 45degree

                        wrapMap: [
                                  '',                    '',
                                  'horizonMap_7p5.png',  'verticalMap_7p5.png',
                                  'horizonMap_15.png',   'verticalMap_15.png',
                                  'horizonMap_22p5.png', 'verticalMap_22p5.png',
                                  'horizonMap_30.png',   'verticalMap_30.png',   
                                  'horizonMap_37p5.png', 'verticalMap_37p5.png',
                                  'horizonMap_45.png',   'verticalMap_45.png'
                                  ],

                        onDemoSite: false,
                        onGesture: false,
                        onButton: false,
                        onHeader: false,
                        onNavbar: false,
                        onFooter: false,
                        onCamera: false,                        
                        onQRcode: false,
                        onList:   false,
                        onSearch: false,
                        onAccount: false,                        
                        onMirror: false,
                        onGender: false,
                        onPotrait: false,


                        fittingMode: 0,
                        enableDebugLog: false,
                        
                        mirrorMode: 0,
                        RTCdelayTime: -1,
                        scalingFactor: 1.5,
                        
                        rtcServerMode: 0,
                        rtcServerId: 4,
                        rtcDetectFootZone: false,

                        largeHeaderMode : null,    
                        firstHeaderImg:  '', //'/images/1_Magic_Mirror.jpg',
                        secondHeaderImg: '', //'/images/2_Sales.jpg',
                        thirdHeaderImg:  '', //'/images/3_Female.jpg',
                        fourthHeaderImg: '', //'/images/4_Men.jpg',
                        
                        mirrorHomeImg: '',

                        firstFooterImg: '/images/vismile.png',
                        secondFooterImg: '/images/vismile.png',

                        categoryGenderMode: 1,
                        footerColorSel: 1,
                        btnBarPosSel: 1,
                        divBarPosSel: 1,
                        imgBarPosSel: 1,

                        camPosSel: 90, //0
                        camBtnPosSel: 1,
                        countdownPosSel: 1,

                        ARseconds: 0,
                        onARrecord: false,
       
                        enable_log: false,

                        projectDescription_01: '',
                        projectDescription_02: '',
                        projectDescription_03: '',
                        projectDescription_04: '',
                        projectDescription_05: '',
                        projectLabel_01: '',
                        projectLabel_02: '',
                        projectLabel_03: '',
                        projectLabel_04: '',
                        projectLabel_05: '',


                        ClientInfo: {

                                        isMobile:           false, 
                                        isMobileOnly:       false, 
                                        isTablet:           false,
                                        isBrowser:          false,
                                        isConsole:          false,
                                        isAndroid:          false,
                                        isWinPhone:         false,
                                        isIOS:              false,
                                        isChrome:           false,
                                        isFirefox:          false,
                                        isSafari:           false,
                                        isOpera:            false,
                                        isIE:               false,
                                        isEdge:             false,
                                        isYandex:           false,
                                        isChromium:         false,
                                        isMobileSafari:     false,
                                        osVersion:          '',
                                        osName:             '',
                                        fullBrowserVersion: '',
                                        browserName:        '',
                                        mobileVendor:       '',
                                        mobileModel:        '',
                                        engineName:         '',
                                        engineVersion:      '',
                                        getUA:              '',
                                        deviceType:         '',

                                        disableCardLink:    false,


                                    }

                  }

/**
 * ACTION CREATORS
 */

const getProject = ProjectData => ({type: GET_PROJECT, ProjectData});
const setProject = ProjectData => ({type: UPDATE_PROJECT, ProjectData});
const uploadProject = ProjectData => ({type: UPLOAD_PROJECT, ProjectData});
const getPhotoProject = ProjectData => ({type: FETCH_PROJECT, ProjectData});

/**
 * THUNK CREATORS
 */
export const fetchProject = (project) => {
  return async dispatch => {
    
    //console.log("project@before fetchProject", project)

    const body = {
                  databaseName: project.projectName,
                  userStatus: project.userStatus
                 }

    const {data} = await axios.post(`/api/project`, body)
   
    data.projectName = project.projectName;   
    data.ClientInfo  = project.ClientInfo;   
    
    console.log("data@fetchProject", data)

    dispatch(getProject(data))
     
  }
}

export const updateProject = (project) => {
  return async dispatch => {
    
    dispatch(setProject(project))
  }
}

export const uploadPhotoProject = (project, formData) => {
  return async dispatch => {

    

    console.log("formData@uploadPhotoProject : ", formData);
    
    const res =  await axios.post('/api/project/uploadPhoto', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                       }
                 );

    project.onHandleTakePhoto = false;

    dispatch(uploadProject(project));
    
  }
};

export const fetchPhotoProject = (project) => {
  return async dispatch => {
    try {  
      
      const body = {
                      databaseName: project.projectName,
                      userStatus: project.userStatus,
                      photoName: project.photoName
                   }
     
      const {data} = await axios.post(`/api/project/getPhoto`, body);
      
      
      
      console.log("end of fetchPhotoProject");
      
      dispatch(getPhotoProject(data));


   
      
    }catch (err) {
      console.error(err)
    }
  }
}

/**
 * REDUCER
 */
export default function(state = initialState, action) {
  switch (action.type) {

    case GET_PROJECT:
      /*return [...state, action.ProjectData]*/
      
      return produce(state, data => {
                                      data.onDemoSite            = action.ProjectData.onDemoSite         
                                      data.onGesture             = action.ProjectData.onGesture         
                                      data.onButton              = action.ProjectData.onButton          
                                      data.onHeader              = action.ProjectData.onHeader          
                                      data.onNavbar              = action.ProjectData.onNavbar          
                                      data.onFooter              = action.ProjectData.onFooter          
                                      data.onCamera              = action.ProjectData.onCamera          
                                      data.onQRcode              = action.ProjectData.onQRcode          
                                      data.onList                = action.ProjectData.onList            
                                      data.onSearch              = action.ProjectData.onSearch          
                                      data.onAccount             = action.ProjectData.onAccount         
                                      data.onMirror              = action.ProjectData.onMirror          
                                      data.onGender              = action.ProjectData.onGender      
                                      data.onPotrait             = action.ProjectData.onPotrait      

                                      data.mirrorMode            = action.ProjectData.mirrorMode          
                                      data.RTCdelayTime          = action.ProjectData.RTCdelayTime          
                                      data.scalingFactor         = action.ProjectData.scalingFactor          
                                      data.photoName             = action.ProjectData.photoName          
                                      data.tiltCompensation      = action.ProjectData.tiltCompensation          
                                      data.wrapMap               = action.ProjectData.wrapMap       
                                      data.canvasResolutionScale = action.ProjectData.canvasResolutionScale          

                                      data.rtcServerMode         = action.ProjectData.rtcServerMode
                                      data.rtcServerId           = action.ProjectData.rtcServerId
                                      data.rtcDetectFootZone     = action.ProjectData.rtcDetectFootZone
                                      
                                      data.fittingMode        = action.ProjectData.fittingMode  
                                      data.enableDebugLog     = action.ProjectData.enableDebugLog  

                                      data.largeHeaderMode    = action.ProjectData.largeHeaderMode   
                                      data.firstHeaderImg     = action.ProjectData.firstHeaderImg    
                                      data.secondHeaderImg    = action.ProjectData.secondHeaderImg   
                                      data.thirdHeaderImg     = action.ProjectData.thirdHeaderImg    
                                      data.fourthHeaderImg    = action.ProjectData.fourthHeaderImg   
                                      data.mirrorHomeImg      = action.ProjectData.mirrorHomeImg   
                                      data.firstFooterImg     = action.ProjectData.firstFooterImg    
                                      data.secondFooterImg    = action.ProjectData.secondFooterImg   
                                      data.categoryGenderMode = action.ProjectData.categoryGenderMode
                                      data.footerColorSel     = action.ProjectData.footerColorSel    
                                      data.btnBarPosSel       = action.ProjectData.btnBarPosSel      
                                      data.divBarPosSel       = action.ProjectData.divBarPosSel      
                                      data.imgBarPosSel       = action.ProjectData.imgBarPosSel      
                                      data.camPosSel          = action.ProjectData.camPosSel         
                                      data.camBtnPosSel       = action.ProjectData.camBtnPosSel      
                                      data.countdownPosSel    = action.ProjectData.countdownPosSel   
                                      data.enable_log         = action.ProjectData.enable_log        
                                      
                                      data.ARseconds          = action.ProjectData.ARseconds       
                                      data.onARrecord         = action.ProjectData.onARrecord     
                                        
                                      data.projectDescription_01 = action.ProjectData.projectDescription_01          
                                      data.projectDescription_02 = action.ProjectData.projectDescription_02                                                
                                      data.projectDescription_03 = action.ProjectData.projectDescription_03          
                                      data.projectDescription_04 = action.ProjectData.projectDescription_04          
                                      data.projectDescription_05 = action.ProjectData.projectDescription_05          
                                      
                                      data.projectLabel_01 = action.ProjectData.projectLabel_01          
                                      data.projectLabel_02 = action.ProjectData.projectLabel_02                                                
                                      data.projectLabel_03 = action.ProjectData.projectLabel_03          
                                      data.projectLabel_04 = action.ProjectData.projectLabel_04          
                                      data.projectLabel_05 = action.ProjectData.projectLabel_05          

                                    }
                    );     
                     
      //console.log("action", action)
      //return action.ProjectData
      //        //Object.keys(action.ProjectData).forEach(data => { console.log("data", data); state[data.id] = ProjectData });
    case UPDATE_PROJECT:
      return action.ProjectData

    case UPLOAD_PROJECT:
      return action.ProjectData

    case FETCH_PROJECT:
      return produce(state, data => {
                                      
                                      data.photoDone         = action.ProjectData.photoDone        
                                      
                                    }
                    );      

    default:
      return state
  }
}
/*

 export default produce((state = initialState, action) => {
  switch (action.type) {

    case GET_PROJECT:
                      console.log("action", action)
                      action.ProjectData.map(item => {
                                                       state[item.id] = item
                                                      )
    case UPDATE_PROJECT:
      return action.ProjectData

    default:
      return state
  }
}, {})
*/