import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { StripeProvider, Elements } from "react-stripe-elements";
import {
  Login,
  Signup,
  ProductCatalog,
  CategoryCatalog,
  //SingleProductPage,
  SingleSuitPage,
  Home,
  ProductSearch,
  CheckoutComplete,
  CartPage,
  Checkout,
  //EditorPage,
  DemoSite,
  ProjectSite,
  VismileSite,
  CrossBrowser,
  XARdemoShow,
} from "./components";
import AdminRoutes from "./Router/AdminRoutes";
import UserRoutes from "./Router/UserRoutes";
import { me, fetchHeaders, fetchProject, updateProject, updateWebRTC, registerWebrtc, updateDataXAR, doRTCAR, fetchMirror, getTicketWebrtc, updateTicketWebrtc } from "./store";

/*
import Products from './components/Items';
import CartProducts from './components/Cart';
import WomensProducts from './components/Women';
import MensProducts from './components/Men';
import Clothes from './components/Clothes';
import Accessories from './components/Accessories';
import ShowProduct from './components/ShowProduct';
*/
import { TakePhoto } from './components';
import { FileUpload } from './components';
import { SewingUploadPage } from './components';
//import { HandToCursorPage } from './components';


import { RTCfitting, RTCfittingWebgl, RemotePhone, RemotePhone_SendJson, RTCfittingWebglNoRTC } from './components';

import { Icon, Button, Menu } from "semantic-ui-react";

import axios from 'axios'
import webSocket from 'socket.io-client'

const mapState = state => {
  return {
    isLoggedIn: !!state.user.id,
    isAdmin: state.user.isAdmin,


    xar: state.xar,
    project: state.project,
    webrtc: state.webrtc
  };
};

const mapDispatch = dispatch => {
  return {
    loadInitialData() {dispatch(me());},
    
    setProject: (params) => dispatch(updateProject(params)),
    getProject: (params) => dispatch(fetchProject(params)),  
    getMirror: (mirror) => dispatch(fetchMirror(mirror)),  

    updateXAR: (xar) => dispatch(updateDataXAR(xar)),    
    requestRTCAR: (xar) => dispatch(doRTCAR(xar)),

    setWebRTC: (webrtc) => dispatch(updateWebRTC(webrtc)),
    regWebRTC: (webrtc) => dispatch(registerWebrtc(webrtc)),

    bookingWebrtc: (webrtc) => dispatch(getTicketWebrtc(webrtc)),    
    checkTicketWebrtc: (webrtc) => dispatch(updateTicketWebrtc(webrtc)),    
      
  };
};



class Routes extends Component {
  
  constructor(props) {
    super(props);


    //console.log("this.props.location.pathname", this.props.location.pathname)
    var tmp = this.props.location.pathname.split("/");
    //console.log("tmp", tmp, this)
    let projectName = tmp[1].toLowerCase();//this.props.match.params.projectName.toLowerCase();
    let userId = Number(tmp[2]);//Number(this.props.match.params.userId);
    let mirrorId = Number(tmp[3]);
    
    let pageName = tmp[2].toLowerCase();

    tmp = window.location.href.split("/");
    //console.log("tmp", tmp, this)

    const href = tmp[0]+"//"+tmp[2];

    const { project, getProject, setProject, getMirror } = this.props;

    //const projectName = this.props.match.params.projectName.toLowerCase();
    
    
    console.log("project@constructor routes", project)
    var params = project;   
    
    params.projectName = projectName
    params.pageName = pageName
    
    params.href = href
    
    if(userId){
        params.userId = userId
        if(userId<0){
          params.mirrorId = userId
        }else{
          params.remoteUserId = userId
          params.mirrorId = mirrorId;
        }
    }else{
        params.userId = 0
    }
    //
    

    //console.log("setProject@constructor router")
            
    setProject(params);

    getProject(params)
    //console.log("params@route: ", params)
    


    this.state = {

      ws : null,

      maxIdleSeconds: 10,
      maxTryOnSeconds: 30,
      ARfitting: false,
      time: {}, 
      seconds: 8, 
      
      ARseconds: 0,

      onTakePhoto: false,

      projectName: params.projectName,  
      pageName: params.pageName,
      userId: params.userId,    
      mirrorId: (params.userId<0)? params.userId : params.mirrorId,
      remoteUserId: (params.userId<0)? null : params.userId,

      linkAddr: "", //for trigger componentDidUpdate
    
      queryCnt: 0,

      //for webrtc connection
      webRTCstart: false,
      webcamStart: false,
      webrtcReady: false,

      onHandleTakePhotoToChild: false,
      largeHeaderMode: 2,  //not working, due to baselayout can not be dynamic change size
      largeHeaderModeOri: 2,//not working, due to baselayout can not be dynamic change size

      showUsageSteps: true,

      //webrtcPCstart: false

      //remote side
      getCanvasResolutionDone: false,
      
      //DemoSite
      myTurnNow: false,

      //
      ARfittingStop: false,


    };
    this.timer = 0;
    this.query = 0;

    this.ARtimer = 0;

    this.options = {

          day: '2-digit',    //(e.g., 1)
          month: '2-digit',    //(e.g., Oct)
          year: '2-digit',   //(e.g., 2019)
          hour: '2-digit',   //(e.g., 02)
          minute: '2-digit', //(e.g., 02)          
          second: '2-digit',
          hour12:false// 
      };


    //console.log("this.state@constructor routes", this.state)
    this.setState({seconds: this.state.maxIdleSeconds})
      

    if(userId<=0){
        console.log("I am Mirror")
        this.sendToServer('registerMirror', userId)
        //this.setState({})
        //getMirror(params)

    }else{
        console.log("I am Remote")
    }

     
    

   
  }

  componentWillUnmount() {
      console.log("componentWillUnmount@routes")
      this.disconnect();

      this.props.webrtc.webRTCstart = false;
      this.props.webrtc.webcamStart = false;
      this.props.setWebRTC(this.props.webrtc);
      
      window.removeEventListener('beforeunload', this.disconnect);
    
  }

  componentDidMount() {

    const { project } = this.props;
    console.log("this.props@routes", this.props)


    if(project.onMirror===false && this.state.userId===0){
        this.updateUserId(this.state.userId);
    }
    //console.log("project.onMirror", project.onMirror)
    this.props.loadInitialData();

    
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });

    //set values to state, for forcing re-render

      

  }


  componentDidUpdate(prevProps, prevState) {
    // 常見用法（別忘了比較 prop）：
    //console.log("**********************************************");
    //console.log("componentDidUpdate@routes");
    //console.log("this.props.project:@componentDidUpdate route", this.props);
    //console.log("prevProps.project@componentDidUpdate route:", prevProps);
    //console.log("this.state.userId:", this.state.userId);
    //console.log("prevState.linkAddr:", prevState.linkAddr);
    //console.log("this.state.linkAddr:", this.state.linkAddr);
    //console.log("this.props.project:", this.props.project);
    //console.log("this.props.project.MirrorRTCstart:", this.props.project.MirrorRTCstart);
    //console.log("this.state:@componentDidUpdate route", this.state);
    //console.log("prevState:@componentDidUpdate route", prevState);


    /*
    if(this.state.webrtcPCstart && this.props.webrtc.pc.connectionState !== prevProps.webrtc.pc.connectionState){
      console.log("*********************************************************************")
      console.log("*********************************************************************")
      console.log("*********************************************************************")
      console.log("webrtc.pc.connectionState", this.props.webrtc.pc.connectionState)
      console.log("*********************************************************************")
      console.log("*********************************************************************")

    }
    if(this.state.webrtcPCstart && this.props.webrtc.pc.iceConnectionState !== prevProps.webrtc.pc.iceConnectionState){

      console.log("*********************************************************************")
      console.log("*********************************************************************")
      console.log("webrtc.pc.iceConnectionState", this.props.webrtc.pc.iceConnectionState)
      console.log("*********************************************************************")
      console.log("*********************************************************************")
      
    }
  */

    const { project, setProject, webrtc, setWebRTC } = this.props;
    
    if (this.props.project.largeHeaderMode !== prevProps.project.largeHeaderMode) {
        ////not working, due to baselayout can not be dynamic change size
        this.setState({largeHeaderMode: project.largeHeaderMode})
        this.setState({largeHeaderModeOri: project.largeHeaderMode})

    }
  

    if (this.props.project.onMirror !== prevProps.project.onMirror) {



        //console.log("this.props.project.onMirror !== prevProps.project.onMirror")
        //for remote control client
        if(project.onMirror && this.state.userId>0 && this.state.mirrorId<=0){
            //inform server start
            this.sendToServer('userStatus', 1)  
            var tmp = this.props.project
            tmp.userStatus = 1;
            tmp.remoteUserId = this.state.userId;
            tmp.mirrorId = this.state.mirrorId;
            tmp.controlRemoteStart = true;
            //console.log("setProject@componentDidUpdate router userId!==0")
            this.props.setProject(tmp);
            //inform local route start
            this.setState({ARfitting: true})
            
            window.addEventListener('click', this.handleClick.bind(this));
            
            if(project.mirrorMode==0){
              window.addEventListener('scroll', this.handleScroll.bind(this));
              
              this.unlisten = this.props.history.listen((location, action) => {
                                                                                //console.log("testtesttesttesttesttest 3", this);
                                                                                //console.log("testtesttesttesttesttest 3", this.props.location.pathname);
                                                                                //console.log("testtesttesttesttesttest 3", window.location.href);
                                                                                let pathname = this.props.location.pathname
                                                                                if(this.props.location.pathname === "/"+this.props.project.projectName+"/"+this.state.userId){
                                                                                    pathname = "/"+this.props.project.projectName+"/"+this.state.userId+"/"+this.state.mirrorId
                                                                                }
                                                                                
                                                                                if(pathname.search(RemotePhone) !== -1){
                                                                                    //console.log("don't sendToServer to RemotePhone and no resetARstatus")
                                                                                }else{
                                                                                    //for click back when ARfitting
                                                                                    console.log('this.resetARstatus(this.props.project.userId', this.props.project.userId, ', ', pathname)
                                                                                    this.resetARstatus(this.props.project.userId)
                                                                                    this.sendToServer('pathname', pathname) 
                                                                                }

                                                                              });
            }

        }else if(project.onMirror && !(this.state.mirrorId<=0) ){

          //Invalid user
          setTimeout(function () { document.location.href = "https://vismile.com.tw" }, 1000);

        }




        if(project.onMirror===true && this.state.userId<=0){

            //generate userId
            if(this.state.userId===0){//
          
                let userId = Math.floor(Math.random() * 1000) + 1;
                //console.log("generate userId", userId)
                var tmp = this.props.project
                tmp.remoteUserId = userId;
                //console.log("setProject@componentDidUpdate router userId===0")
                this.props.setProject(tmp);
                //send userId to server for check the same user from phone
                this.updateUserId(userId);

            }else{


            }
            

            //creat socket and listen
            //console.log("window.location.origin@componentDidUpdate", window.location.origin)
            this.setState({ws : webSocket(window.location.origin)}, ()=>{
                                                                          //console.log("this.state.ws", this.state.ws)
                                                                          var _this = this;
                                                                          this.state.ws.emit('join',  {projectName: this.props.project.projectName,
                                                                                                       mirrorId: this.props.project.mirrorId})
                                                                          this.state.ws.on('userStatus', userStatus => {
                                                                                                                        console.log("userStatus@listen: ", userStatus)
                                                                                                                        let tmp = _this.props.project
                                                                                                                        tmp.userStatus = userStatus;
                                                                                                                        
                                                                                                                        if(userStatus===1){
                                                                                                                            //console.log("userStatus===1")
                                                                                                                            //remote client fisrt time refresh website
                                                                                                                            tmp.controlMirrorStart = true;
                                                                                                                            //-------  mode constantly show video
                                                                                                                            if(_this.props.project.mirrorMode == 1){
                                                                                                                                _this.setPath(`/${this.props.project.projectName}/RTCfittingARmirror`); 
                                                                                                                                
                                                                                                                                webrtc.webcamStart = true;
                                                                                                                                setWebRTC(webrtc);

                                                                                                                                ////not working, due to baselayout can not be dynamic change size
                                                                                                                                _this.setState({largeHeaderMode:0})
                                                                                                                                tmp.largeHeaderMode = 0;

                                                                                                                            }
                                                                                                                            //-------
                                                                                                                            if(_this.props.project.mirrorMode == 0){
                                                                                                                              _this.resetTimer(_this.state.maxIdleSeconds)
                                                                                                                            }
                                                                                                                        }else if(userStatus===3 || userStatus===0){
                                                                                                                            console.log("userStatus===3 || userStatus===0")
                                                                                                                            //server detect ARfitting out of user
                                                                                                                            if(_this.state.ARfitting){ //remove temporary

                                                                                                                                _this.resetARstatus(_this.props.project.userId, true)
                                                                                                                                if(_this.props.project.mirrorMode == 0){
                                                                                                                                  _this.resetTimer(_this.state.maxIdleSeconds)//resetTimer by Mirror itself due to change page by Mirror itself, not by remote client
                                                                                                                                }  
                                                                                                                                _this.sendToServer('ARfittingStart', 0)  //just inform server to update memory


                                                                                                                            }
                                                                                                                        }

                                                                                                                        console.log("setProject@componentDidUpdate router userStatus")
                                                                                                                        _this.props.setProject(tmp);
                                                                                                                        
                                                                                                                        })

                                                                          this.state.ws.on('scrollY', scrollY => {
                                                                                                                  //console.log("_this: ", _this)
                                                                                                                  //console.log("scrollY: ", scrollY)
                                                                                                                  if(_this.state.ARfitting){


                                                                                                                  }else{
                                                                                                                      _this.setScroll(scrollY);
                                                                                                                      //_this.setState({time: {}})
                                                                                                                      _this.resetTimer(_this.state.maxIdleSeconds)
                                                                                                                  }
                                                                                                                  })

                                                                          this.state.ws.on('pathname', pathname => {
                                                                                                                    //console.log("pathname@componentDidUpdate route ws.on: ", pathname)
                                                                                                                    _this.setPath(pathname);   
                                                                                                                    //_this.setState({time: {}}) 
                                                                                                                    //_this.resetTimer(_this.state.maxIdleSeconds)  
                                                                                                                   })
                                                                          this.state.ws.on('ARfittingStart', ARfittingStart => {
                                                                                                                    //console.log("ARfittingStart@componentDidUpdate route ws.on: ", ARfittingStart)
                                                                                                                    //_this.setPath(`/${this.props.project.projectName}/ARfitting`);   
                                                                                                                    //_this.setState({time: {}}) 
                                                                                                                    //_this.resetTimer(_this.state.maxIdleSeconds)  
                                                                                                                   })

                                                                          this.state.ws.on('TakePhoto', TakePhoto => {
                                                                                                                    //console.log("TakePhoto@componentDidUpdate route ws.on: ", TakePhoto)
                                                                                                                    _this.handleTakePhotoMirror(TakePhoto);   
                                                                                                                    
                                                                                                                   })

                                                                          

                                                                          if(this.props.project.ARfittingMirrorstart===0){
                                                                              this.state.ws.emit('ARfittingStop', { ARfittingStart: this.props.project.ARfittingMirrorstart,
                                                                                                                    projectName: this.props.project.projectName })    
              
                                                                          }


                                                                          //for mirror in computing server and Internet access
                                                                          this.state.ws.on('armirrorStatus', armirrorStatus => {
                                                                                                                    //console.log("armirrorStatus: ")
                                                                                                                    _this.handleARmirrorStatus(armirrorStatus);   
                                                                                                                    
                                                                                                                   })



                                                                        })
          
            

            



        }
    }

    //console.log("this.props.project.onMirror:", this.props.project.onMirror);
    //console.log("this.state.userId:", this.state.userId);
    //for remote control client
    //if(this.props.project.onMirror && this.state.userId!==0){
    if(this.state.userId>0){  
        //console.log("this.state.ARfitting:", this.state.ARfitting);
        //console.log("prevState.ARfitting:", prevState.ARfitting);
        
        if(this.props.project.ARfittingRemoteStart===true && this.state.queryCnt===0){

            //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
            //console.log("this.props.project.ARfittingRemoteStart", this.props.project.ARfittingRemoteStart)
            //console.log("prevState", prevState)
            
            if(this.props.project.mirrorMode == 1){

                this.sendToServer('ARfittingStart', 1)  
                this.setState({queryCnt: -1})

                //this.sendToServer('getARfittingStatus', "")//for fetch canvasResolution in remote side

            }else{
                //no querry @mirrorMode==1
                this.sendToServer('getARfittingStatus', "")
  
            }

            

        } 
        if(this.state.ARfitting===false && prevState.ARfitting===true){
          //not modified as the same with useId<-0, that ARfittingStop pass to child component and have go(-1) there
          console.log("this.props.history.go(-1)@route (this.state.ARfitting===false && prevState.ARfitting===true)")
          this.props.history.go(-1);

        }
        //console.log("this.props.project.onTakePhoto@componentDidUpdate route:", this.props.project.onTakePhoto);
        //console.log("this.state.onTakePhoto@componentDidUpdate route:", this.state.onTakePhoto);

        if(prevState.onTakePhoto===false && this.state.onTakePhoto===true ){
            //console.log("onTakePhoto count down to 0")

            this.sendToServer('TakePhoto', this.props.project.photoName)
            

            //need to find timing to unset onTakePhoto

            this.setState({onTakePhoto: false})//for remote side
            
            var tmp = this.props.project
            tmp.onTakePhoto = false;
            this.props.setProject(tmp);
        }
        //handle remote click stop operating,  userStatus ===0 
        //console.log("prevState.ARfitting@componentDidUpdate route:", prevState.ARfitting);
        //console.log("this.props.project.userStatus@componentDidUpdate route:", this.props.project.userStatus);

        if(prevState.ARfitting===true && this.props.project.userStatus===0){
            //not possible enterring when jump page which into construct()
            //console.log("prevState.ARfitting===true && this.props.project.userStatus===0");
            this.sendToServer('userStatus', 0)  
        }

    }
    //for Mirror side and DemoSite
    //console.log("this.state.userId@componentDidUpdate", this.state.userId)
    //if(this.props.project.onMirror && this.state.userId===0){
    if(this.state.userId<=0){  
        //for webrtc connection

        //console.log("in this.state.userId<=0 @componentDidUpdate route")
        //console.log("this.props.webrtc.webRTCstart @componentDidUpdate route", this.props.webrtc.webRTCstart)
        //console.log("this.state.webRTCstart @componentDidUpdate route", this.state.webRTCstart)

        //if(this.props.webrtc.webRTCstart===true && this.state.webRTCstart === false){
        if(prevState.webRTCstart===false && this.state.webRTCstart === true){
          //console.log("prevState.webRTCstart===false && this.state.webRTCstart === true")
          const {webrtc, setWebRTC} = this.props;

          //this.setState({webRTCstart: true});

          //this.trace("getConstraints clone:" + webrtc.localStream_clone.getTracks()[0].getConstraints().width);
          webrtc.RTCSessionDescription = window.RTCSessionDescription;
          webrtc.RTCIceCandidate = window.RTCIceCandidate;
          
          setWebRTC(webrtc);
          //console.log("connect()")
          this.connect();

        }
        if(this.props.webrtc.webcamStart===true && this.state.webcamStart === false){
            
            //console.log("this.props.webrtc.webcamStart===true && this.state.webcamStart === false")

            const {webrtc, setWebRTC} = this.props;

            this.setState({webcamStart: webrtc.webcamStart});
            this.startWebcam();
            //initial important state here(before webcam start)
            this.setState({ARseconds: project.ARseconds})
            this.setState({ARfittingStop: false})

        }
        if(this.props.webrtc.webRTCstart===false && this.state.webRTCstart === true){
            this.setState({webRTCstart: webrtc.webRTCstart});
            this.setState({webcamStart: webrtc.webcamStart});
            console.log("this.props.webrtc.webRTCstart===false && this.state.webRTCstart === true,  disconnect()")
            this.resetARstatus(this.props.project.userId)

            console.log('this.state.userId', this.state.userId)
            
            if(this.state.userId==0){
              console.log("this.state.userId==0 disconnect")
              this.disconnect();//disconnect only in DemoSite, Mirror always connect webrtc

              //not so sure
              this.setState({ARseconds: this.props.project.ARseconds})
              this.setState({ARfittingStop: true})
              
              document.getElementById("TimerCountUse").style.display = 'none'
              document.getElementById("buttonHomeUse").style.visibility = 'hidden'
             

            }
        }
        //for handle take photo
        if(prevState.onHandleTakePhotoToChild===false && this.state.onHandleTakePhotoToChild===true){
            //console.log(" this.setState({test: this.props.project.onHandleTakePhoto})@route")
            this.setState({onHandleTakePhotoToChild: false})

        }

        //send canvas resolution to server and then pass to client
        if(prevState.webrtcReady===false && this.state.webrtcReady===true){
            //console.log("prevState.webrtcReady===false && this.state.webrtcReady===true")
            this.sendToServer("canvasResolution", this.props.webrtc.canvasResolution)

        }
        //console.log("in this.state.userId<=0 End @componentDidUpdate route")

    }

    //for DemoSite
    if(prevState.ARseconds===1 && this.state.ARseconds===0){

      this.resetARstatus(this.props.project.userId)
      this.disconnect();//webcamStart and webRTCstart of props/state are set in diconnect()
      this.setState({ARseconds: this.props.project.ARseconds})
      //this.props.history.go(-1);
      //this.props.history.push(`/${this.props.project.projectName}/MetaFitting`)
      this.setState({ARfittingStop: true})

      console.log("prevState.ARseconds===1 && this.state.ARseconds===0 ARfittingStop", this.state.ARfittingStop)

    }


  }

  componentWillUnmount() {

      const { project } = this.props;

      //for remote side
      if(project.onMirror && this.state.userId>0){
          if(project.mirrorMode==0){
            
            window.removeEventListener('scroll', this.handleScroll.bind(this));
            window.removeEventListener('click', this.handleClick.bind(this));

            this.unlisten();
          }
      }
      //for mirror side
      if(project.onMirror && this.state.userId<=0){

          this.state.ws.disconnect()
          this.state.ws.close()
      }

      this.disconnect()

  }



  handleScroll(event) {
        let scrollTop    = event.srcElement.body.scrollTop
        let scrollHeight = event.srcElement.body.scrollHeight
        let currentScrollY = window.scrollY;
        //console.log("testtesttesttesttesttest 1", scrollTop, scrollHeight, currentScrollY)
        //console.log("testtesttesttesttesttest 1", this)
        
        this.sendToServer('scrollY', currentScrollY) 

        
  }
  handleClick(event) {//remote side
      //console.log("into handleClick()")    
      //console.log("this.props.project.ARfittingRemoteStart@handleClick", this.props.project)
      if(this.props.project.mirrorMode===1){

          if(this.props.project.ARfittingRemoteStart){
              
              if(this.props.project.onTakePhoto){
                    //console.log("onTakePhoto@handleClick")//no chance to happen
                    this.sendToServer('TakePhoto', this.props.project.photoName)
                    this.setState({onTakePhoto: true})//for remote side
                    //need to find timing to unset onTakePhoto

              }
          }

          if(this.props.project.userStatus===0){ //when remote client clicking signout, inform server and then mirror site to jump home page

            this.sendToServer('userStatus', 0)  
          }

      }else{
        
          if(this.props.project.controlRemoteStart){//ARfittingRemoteStart
              //console.log("************************************************************************")
              //console.log("ARfittingStart@handleClick")

              if(this.props.project.onTakePhoto){
                  //console.log("onTakePhoto@handleClick")//no chance to happen
                  this.sendToServer('TakePhoto', this.props.project.photoName)
                  this.setState({onTakePhoto: true})//for remote side
                  //need to find timing to unset onTakePhoto

              }else if(this.props.project.ARfittingRemoteStart){

                      this.sendToServer('ARfittingStart', 1)
                  
                      
                      this.setState({linkAddr : `/${this.props.project.projectName}/ARfitting`})
                      
                      this.setState({ARfitting: true})//for remote side
                  
              }else{

                  this.setState({linkAddr : this.props.location.pathname});//window.location.href  

              }
            

          }else if(this.props.project.ARfittingMirrorStart){

              //for click back when ARfitting
              console.log('@this.props.project.ARfittingMirrorStar ', this.props.project.ARfittingMirrorStar)
              this.resetARstatus(this.props.project.userId)

              this.setState({linkAddr : this.props.location.pathname});//window.location.href
          }    

            
       

        //console.log("testtesttesttesttesttest 2@handleClick", this.state.linkAddr)
        //console.log("testtesttesttesttesttest 2@handleClick", this.props.project)
        //console.log("pathname @ remote set", this.state.linkAddr)

        this.sendToServer('pathname', this.state.linkAddr)  
    }     
        
  }

  setScroll(scrollY){
      //console.log("@setScroll", document)
      document.documentElement.scrollTop = scrollY

  }

  setPath(pathname){
      console.log("this@setPath", this, pathname)
      if(pathname===`/${this.props.project.projectName}/ARfitting`){

          if(this.props.project.mirrorMode==0){
              this.resetTimer(this.state.maxTryOnSeconds)
          }
          this.setState({ARfitting: true})

      }else if(pathname===`/${this.props.project.projectName}/RTCfittingARmirror`){

          if(this.props.project.mirrorMode==1){
              this.setState({ARfitting: true})
          }
          


      }else{
          console.log('for click back when ARfitting')
          //for click back when ARfitting
          this.resetARstatus(this.props.project.userId)

          if(this.props.project.mirrorMode==0){
              this.resetTimer(this.state.maxIdleSeconds)
          }
      }
      //console.log("pathname@mirror go", pathname)
      //this.props.history.push(pathname)
      this.props.history.push({ pathname: pathname,
                                state: { linkAddr: this.props.location.pathname }
                             });
  }

  async sendToServer(name, payload){

      //console.log("sendToServer()@ route  name, payload, this.state.projectName", name, payload, this.state.projectName)
      let body = {
                    databaseName: this.state.projectName,
                    userId: this.state.userId,
                    mirrorId: this.state.mirrorId,
                    [name] : payload
                 }
      let data = null;
      if(name === 'registerMirror'){ 
        /*
        body = {
                  payload,
                  ...body
               }
        */
        const response = await axios.post("/api/project/registerMirror", body );
        data = response.data
        //console.log("response @registerMirror", data)

      }else if(name==='myPeerId'||name==="ARfittingStart"){ //if sent by mirror, must change userId as remoteUserId, also valid if sent by remote
        console.log("sendToServer@myPeerId", this.state.remoteUserId)

        body.userId = this.state.remoteUserId;
        const response = await axios.post("/api/project/remoteControl", body );
        data = response.data



      }else if(name==='getARfittingStatus'){
        //console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
        //console.log("send post getARfittingStatus")
        
        
        if(this.query===0){
            this.query = setInterval(this.queryARfittingStatus.bind(this), 5000);
            //console.log("setInterval", this.query)
        }else{

            //console.log("do nothing")
        }

        let data = "TBD"
      }else{

          //console.log("sendToServer@route", name)
          const response = await axios.post("/api/project/remoteControl", body );
          data = response.data

          //console.log("response data@sendToServer", data)
      }             

      
          
      if (data) {
          
          
          if(data=='InvalidMirror'){

            this.handleNotValidUser(0);

          }else if(data=='NotValidUser'){

            this.handleNotValidUser(1);

          }else if(data.ARfittingStart){

            var tmp = this.props.project
            tmp.ARfittigTimeOut = false;
            //console.log("setProject@sendToServer")                                                                                                                        
            this.props.setProject(tmp);
          
          }else if(data.remoteUserId){

            //console.log("data.remoteUserId", data.remoteUserId)
            var tmp = this.props.project
            tmp.remoteUserId = data.remoteUserId;
            console.log("setProject@data.remoteUserId")                                                                                                                                                    
            this.props.setProject(tmp);
            this.setState({remoteUserId: data.remoteUserId})
            

          }

      }      
      

  }

  async queryARfittingStatus(){

      
      const body = {
                      databaseName: this.state.projectName,
                      userId: this.state.userId,
                      mirrorId: this.state.mirrorId,
                      getARfittingStatus : this.state.queryCnt
                   }


      //console.log("queryARfittingStatus body queryCnt @route", body, this.state.queryCnt)
      const {data} = await axios.post("/api/project/getARfittingStatus", body );


      //console.log("data@queryARfittingStatus route", data)
      //console.log("data.ARfittingStart@queryARfittingStatus route", data.ARfittingStart)

      if(data.ARfittingStart===0 || this.state.queryCnt > 5){
          console.log("clearInterval", this.query)
          if(this.props.project.mirrorMode==1){


          }else{
            console.log('this.props.project.mirrorMode ', this.props.project.mirrorMode)
            this.resetARstatus(this.props.project.userId)
            
          }
          
          clearInterval(this.query)
          this.query = 0;
          

      }else if(data.ARfittingStart===1){

         this.setState({queryCnt: this.state.queryCnt+1})

         if(data.canvasResolution && data.canvasResolution[0]>0){
            
            this.props.webrtc.canvasResolution = data.canvasResolution;
            this.props.setWebRTC(this.props.webrtc)

            this.setState({getCanvasResolutionDone: true})
            //clearInterval(this.query)
            //this.query = 0;
         }

      }else if(data.ARfittingStart===3){
          //out of user
          console.log("data.ARfittingStart===3@queryARfittingStatus")
          console.log("clearInterval", this.query)
          this.resetARstatus(this.props.project.userId)
          
          clearInterval(this.query)
          this.query = 0;

          //should push history to home


      }else if(data=="NotValidUser"){
        //different userId now due to mirror update
          console.log("clearInterval !data.ARfittingStart", this.query)
          this.resetARstatus(this.props.project.userId)

          clearInterval(this.query)
          this.query = 0;
          
      }


  }

  async updateUserId(userId){

      //console.log("updateUserId", {userId: userId})
      const body = {
                      databaseName: this.state.projectName,
                      userId: userId
                   }
      /*             
      const {data} = await axios.post(`/api/project/updateUserId`, body);
      if (data) {
          console.log("response @updateUserId", data)
          if(data=='NotValidUser'){

            this.handleNotValidUser();
          }
      }
      */
  }


  secondsToTime(secs){

      let hours = Math.floor(secs / (60 * 60));

      let divisor_for_minutes = secs % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);

      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);

      let obj = {
        "h": hours,
        "m": minutes,
        "s": seconds
      };
      return obj;

  } 

  startTimer() {

      //console.log("startTimer", this.timer, this.state.seconds)
      if (this.timer == 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown.bind(this), 1000);
      }

  }

  resetTimer(maxSeconds) {
      //console.log("resetTimer maxSeconds", maxSeconds)
      clearInterval(this.timer);
      this.timer = 0;
      //console.log("timer", this.timer);
      this.setState({time: {}})
      this.setState({seconds: maxSeconds})
      this.startTimer();

  }

  countDown() { //mirror side
      // Remove one second, set state so a re-render happens.
      //console.log("this@countDown", this)
      let seconds = this.state.seconds - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });
      //console.log("time@countDown", this.state.time)
      //console.log("time@countDown", this.state.seconds)
      
      // Check if we're at zero.
      if (seconds == 0) { 
        console.log("time to 0")
        clearInterval(this.timer);

        if(this.state.ARfitting){

           
            console.log('this.state.ARfitting ', this.state.ARfitting)
            //this.resetTimer(this.state.maxIdleSeconds)
            this.resetARstatus(this.props.project.userId, true)
            this.resetTimer(this.state.maxIdleSeconds)//resetTimer by Mirror itself due to change page by Mirror itself, not by remote client

            this.sendToServer('ARfittingStart', 0)  


        }else{

            var tmp = this.props.project
            tmp.userStatus = 0;

            ////not working, due to baselayout can not be dynamic change size
            this.setState({largeHeaderMode: this.state.largeHeaderModeOri})
            tmp.largeHeaderMode = this.state.largeHeaderModeOri

            //console.log("setProject@countDown 1")                                                                                                                                        
            this.props.setProject(tmp);
            this.sendToServer('userStatus', 0)  

            //generate userId
            let userId = Math.floor(Math.random() * 1000) + 1;
            console.log("generate userId", userId)
            var tmp = this.props.project
            tmp.remoteUserId = userId;
            //console.log("setProject@countDown 2")                                                                                                                                                    
            this.props.setProject(tmp);
            //send userId to server for check the same user from phone
            this.updateUserId(userId);
            //go to the home page
            this.disconnect();
            //reset
            this.setState({showUsageSteps:true});
            this.props.history.push("/"+this.props.project.projectName+'/'+this.props.project.mirrorId)

        }


      }
  }

  handleNotValidUser(isRemote){

    //jump to home and userStatus =0
    var tmp = this.props.project
    tmp.userStatus = 0;
    //console.log("setProject@handleNotValidUser")                                                                                                                                                                
    this.props.setProject(tmp)
    //console.log("history address", this.props.location.pathname, "/"+this.props.project.projectName)
    
    if(isRemote){

      setTimeout(function () { document.location.href = "https://vismile.com.tw" }, 1000);

    }else{

      setTimeout(function () { document.location.href = "https://vismile.com.tw" }, 1000);
      //this.props.history.push("/"+this.props.project.projectName+"/"+this.props.project.mirrorId)
    }
    /*
    if(this.props.location.pathname !== "/"+this.props.project.projectName)
    {
        this.props.history.push("/"+this.props.project.projectName)
    }
    */
  }


  resetARstatus(userId, ARfittigTimeOut=false){

      console.log("resetARstatus@route", userId)

      if(userId<=0){
          console.log("setProject@resetARstatus userId<=0") 

          //mirror side
          var tmp = this.props.project
          tmp.ARfittigTimeOut = ARfittigTimeOut;
          tmp.ARfittingMirrorStart = false;
          tmp.controlMirrorStart = false;

          tmp.photoName = '';
          tmp.onHandleTakePhoto = false;

          if(this.props.project.mirrorMode==1){
            tmp.webRTCstart = false;
            tmp.webcamStart = false;

            this.disconnect();

            //re-registerMirror to generate remoteUserId
            //TBD
          }
          
          ////not working, due to baselayout can not be dynamic change size
          this.setState({largeHeaderMode: this.state.largeHeaderModeOri})          
          tmp.largeHeaderMode = this.state.largeHeaderModeOri

          this.props.setProject(tmp);

          this.setState({ARfitting: false})//move to after setProject for trigger render again
          this.setState({onTakePhoto: false})
          this.setState({showUsageSteps: true})

          //console.log("setProject@resetARstatus")                                                                                                                                        
          

      }else{
          //remote side
          var tmp = this.props.project
          tmp.ARfittingRemoteStart = false;
          tmp.controlRemoteStart = false;
          //console.log("setProject@resetARstatus userId!==0")                                                                                                                                                                

          tmp.onTakePhotoStart = false;
          tmp.onTakePhoto = false;
          tmp.photoName = '';
          tmp.photoDone = false;

          this.props.setProject(tmp);

          this.setState({ARfitting: false})//for remote side
          this.setState({queryCnt: 0})//for remote side query ARstatus

          this.sendToServer('ARfittingStart', 0) //only control by remote
      }




  }

  handleTakePhotoMirror(TakePhoto){

      //mirror side
      var tmp = this.props.project
      tmp.onHandleTakePhoto = true;
      tmp.photoName = TakePhoto;
      //console.log("setProject@handleTakePhotoMirror route")
      this.props.setProject(tmp);

      this.setState({onHandleTakePhotoToChild: true})

    

  }
  handleARmirrorStatus(armirrorStatus){

    //console.log("***************** armirrir write file ****************************************", armirrorStatus)
    //var now = new Date().toLocaleString('en-us', this.options,  {timeZone: 'Asia/Taipei', hour12:false}); 
    //var log_filename = now.replace(/\//g,'');
    //log_filename = log_filename.replace(/:/g, '');
    //log_filename = log_filename.replace(/,\s/g, '-');
    //log_filename = log_filename + '.json'
    
    const filename = "ARfitting_" + this.props.xar.peerID+"_"+this.props.xar.suitID + '.json'

    var str = JSON.stringify(armirrorStatus);
    var str_bin = this.encode( str );

    var blob = new Blob( [ str_bin ], {
        type: 'application/octet-stream'
    });

    window.URL = window.webkitURL || window.URL;

    let anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = window.URL.createObjectURL(blob);      

    let mouseEvent = document.createEvent('MouseEvents');
    mouseEvent.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
    anchor.dispatchEvent(mouseEvent);    

    //json.done
    this.handleARmirrorJsonDone(filename)


  }

  handleARmirrorJsonDone(filename){

    //console.log("handleARmirrorJsonDone")
    var str = "test"
    var str_bin = this.encode( str );

    var blob = new Blob( [ str_bin ], {
        type: 'application/octet-stream'
    });

    window.URL = window.webkitURL || window.URL;

    let anchor = document.createElement('a');
    anchor.download = filename + ".done";
    anchor.href = window.URL.createObjectURL(blob);      

    let mouseEvent = document.createEvent('MouseEvents');
    mouseEvent.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
    anchor.dispatchEvent(mouseEvent);    



  }

  encode( s ) {
    var out = [];
    for ( var i = 0; i < s.length; i++ ) {
      out[i] = s.charCodeAt(i);
    }
    return new Uint8Array( out );
  }

//---------------------------------------------------------------------------------------------------------------
// webrtc section
  async createPeerConnection(peer_id) {
        try {
            //console.log("@createPeerConnection this: ", this);
            
            const { webrtc, setWebRTC, regWebRTC } = this.props;
            webrtc.pc = new RTCPeerConnection( webrtc.pcConfig, webrtc.pcOptions)
            //this.setState({webrtcPCstart: true})


             //console.log("createPeerConnection @then ", this);


             webrtc.localIce = []

             webrtc.pc.onicecandidate = (function(event) {
                 if (event.candidate) {
                     var candidate = {
                                         sdpMLineIndex: event.candidate.sdpMLineIndex,
                                         sdpMid: event.candidate.sdpMid,
                                         candidate: event.candidate.candidate
                                     };
                     webrtc.localIce.push(candidate);
                     //this.sendToPeer(JSON.stringify(candidate));
                 } else {
                     this.sendToPeer(JSON.stringify(webrtc.localIce));
                     //console.log("End of candidates.");
                 }
             }).bind(this);
             webrtc.pc.onconnecting   = this.onSessionConnecting;
             webrtc.pc.onopen         = this.onSessionOpened;
             webrtc.pc.ontrack        = this.onRemoteStreamAdded.bind(this);
             webrtc.pc.onremovestream = this.onRemoteStreamRemoved;
             webrtc.pc.onclose        = this.onSessionClosed;
             webrtc.pc.oniceconnectionstatechange = this.onSessionStateChange.bind(this);
             webrtc.pc.onconnectionstatechange = this.onConnectionStateChange.bind(this);

             webrtc.pc.onsignalingstatechange = this.onSignalingStateChange.bind(this);

             //console.log("inside setState of pc: ", webrtc.pc)


             //console.log("Created RTCPeerConnnection with config: " + JSON.stringify(webrtc.pcConfig));
             webrtc.localStream_clone.getTracks().forEach(track => {
             //webrtc.localStream.getTracks().forEach(track => {
                                                                     console.log("track", track, webrtc.pc, track.getSettings())
                                                                     this.trace("track" + track.getSettings().width)
                                                                     webrtc.pc.addTrack(track, webrtc.localStream_clone) //webrtc.localStream_clone
                                                                     
                                                                 });

             //console.log('Added local stream to pc: ', webrtc.pc);
             //console.log('this: ', this);


             
             //ios works but the original source is /4  
             //if(!project.ClientInfo.isFirefox){
              
             /*if(webrtc.canvasResolution[0]/webrtc.canvasResolution[1] >= 1.7 ){//16/9
                 const [sender] = webrtc.pc.getSenders();
                 var width = sender.track.getSettings().width/4;
                  console.log("16/9 A ", sender.track.getSettings())
                 sender.track.applyConstraints({
                    width: {ideal: 320},
                    height: {ideal: 240},
                    advanced: [ {aspectRatio : 1.3333} ]
                  }
                ).then(() => {
                  const [sender] = webrtc.pc.getSenders();
                  console.log("applyConstraints then ", webrtc.localStream_clone.getTracks()[0] == webrtc.localStream.getTracks()[0])
                });
                  
             }else{
                 const [sender] = webrtc.pc.getSenders();
                 var width = sender.track.getSettings().width/2;
                  console.log("! 16/9", sender.track.getSettings())
                 sender.track.applyConstraints({
                    width: {min: 270, ideal: sender.track.getSettings().width/4},
                    height: {min: 270, ideal: sender.track.getSettings().height/4},
                    advanced: [ {aspectRatio : 1.7777} ]
                  }
                );
             }*/
             
             //---------------------------------------------


             webrtc.pc.ondatachannel = (event) => {
                                               //console.log("OnDataChannel @", event);
                                               webrtc.dataChannel = event.channel
                                               webrtc.dataChannel.onerror = (error) => { console.log("Data Channel Error:", error); };

                                               webrtc.dataChannel.onmessage = (event) => { 
                                                                                           console.log("Got Data Channel Message 1:", event.data); 
                                                                                           this.trace("Got Data Channel Message:", event.data);
                                                                                            if(event.data[0] == 'R'){
                                                                                              this.props.webrtc.videoOrientation = parseInt(event.data.substring(1));
                                                                                            }else if(event.data == 'loading'){ 
                                                                                              this.props.webrtc.loading = true;
                                                                                            }else{ //ARmirror working
                                                                                              this.props.webrtc.groupDelay = parseFloat(event.data)*1000; //s to ms

                                                                                              console.log("Got Data Channel Message 2:", event.data); 
                                                                                              this.props.webrtc.remoteComing = true;
                                                                                              this.props.setWebRTC(this.props.webrtc);
                                                                                              
                                                                                              if(this.state.showUsageSteps){
                                                                                                 const UsageSteps = document.getElementById('UsageSteps');
                                                                                                 UsageSteps.style.visibility = 'hidden';  
                                                                                                 //console.log("UsageSteps", UsageSteps)
                                                                                                 this.setState({showUsageSteps: false})
                                                                                              }

                                                                                             
                                                                                            }
                                                                                         };

                                               webrtc.dataChannel.onopen = () => { 
                                                                                    console.log("The Data Channel is Open, flag as start ARfitting"); 
                                                                                    webrtc.dataChannelDone = 1;
                                                                                    regWebRTC(webrtc);
                                                                                 };

                                               webrtc.dataChannel.onclose = () => { 
                                                                                    //console.log("The Data Channel is Closed"); 
                                                                                  };

             };



                                         


                                              


        setWebRTC(webrtc);    


        //console.log("@createPeerConnection end")
      
      
      
      
      //end of add local data
        } 
        catch (e) {
            console.log("Failed to create PeerConnection with ", e.message);
        }
    }
        


    onRemoteStreamAdded(event) {
      //console.log("onRemoteStreamAdded this: ", this)
      const { webrtc, setWebRTC } = this.props;
      //------
      // for access timestamp in framebuffer 
      /*
      const videoTrack = event.stream.clone().getVideoTracks()[0];

      const trackProcessor = new MediaStreamTrackProcessor({ track: videoTrack });
      const trackGenerator = new MediaStreamTrackGenerator({ kind: 'video' });

      
      const transformer = new TransformStream({
          async transform(videoFrame, controller) {
            
            videoFrame.close();
            
          },
      });

      trackProcessor.readable.pipeThrough(transformer).pipeTo(trackGenerator.writable);
      */
      //------     

   

      //webrtc.remoteComing = true;
      //webrtc.remoteVideoStream= event.stream;
      webrtc.remoteVideoStream= event.streams[0];
     
      setWebRTC(webrtc);   

    }

    sld_success_cb() {
    }

    sld_failure_cb() {
      console.log("setLocalDescription failed");
    }

    aic_success_cb() {

      console.log("aic_success_cb", this)
      
      var tmp = this.props.webrtc;
      tmp.webrtcReady = true;
      this.props.setWebRTC(tmp);

      this.setState({webrtcReady: true})

      //this.trace("getConstraints clone:" + this.props.webrtc.localStream_clone.getTracks()[0].getConstraints().width);
      //this.trace("getConstraints :" + this.props.webrtc.localStream.getTracks()[0].getConstraints().width );
         
    }

    aic_failure_cb() {
      console.log("addIceCandidate failed");
    }

    handleSessionDescription(sessionDescription){

      //console.log("this: ", this);
      //console.log("Create answer:", sessionDescription);
      
      const { webrtc, setWebRTC } = this.props;
      
      //test for high resolution, change SDP content, not work for every browser
      var arr = sessionDescription.sdp.split('\r\n');
      arr.forEach((str, i) => {
          if (/^a=fmtp:\d*/.test(str)) {
            arr[i] = str + ';x-google-max-bitrate=10000;x-google-min-bitrate=10000;x-google-start-bitrate=10000';
          } else if (/^a=mid:(1|video)/.test(str)) {
            arr[i] += '\r\nb=AS:10000';
          }
      });
      sessionDescription.sdp = arr.join('\r\n')
      
      //-------------
      
      webrtc.pc.setLocalDescription(sessionDescription, this.sld_success_cb, this.sld_failure_cb);
      var data = JSON.stringify(sessionDescription);
      this.sendToPeer(data);

      setWebRTC(webrtc)
      console.log("@handleSessionDescription peer_id: ", webrtc.peer_id)

    }
    
    handlePeerMessage(peer_id, data) {

      const { webrtc, setWebRTC } = this.props;
      console.log("@handlePeerMessage this: ", this)
      //console.log("@handlePeerMessage peer_id: ", webrtc.peer_id)
      

        var str = "Message from '" + webrtc.otherPeers[webrtc.peer_id] + ":" + data;
        //this.trace(str);
        
    

    
    
        var dataJson = JSON.parse(data);
        console.log("@handlePeerMessage received ", dataJson);
        if (data.search("offer") != -1) {  //connect command

            console.log("@handlePeerMessage createPeerConnection: offer pc: ", webrtc.pc)
            this.createPeerConnection(webrtc.peer_id)//, () => {

            //console.log("@handlePeerMessage after  createPeerConnection this: ", this)
                                  
            webrtc.pc.setRemoteDescription(new webrtc.RTCSessionDescription(dataJson), this.onRemoteSdpSucces, this.onRemoteSdpError)//, ()=>{

            //console.log("new session: pc", webrtc.pc)

            webrtc.pc.createAnswer(this.handleSessionDescription.bind(this),
                                       function(error) { // error
                                                          console.log("Create answer error:", error);
                                                        }/*, 
                                       webrtc.mediaConstraints*/); 

                                                                    
            webrtc.iceQueue = []

            //console.log("webrtc.pc.signalingState@ offer != -1", webrtc.pc.signalingState)                                                                           
            //console.log("@handlePeerMessage in createPeerConnection end")

    
        }
        else {
            //console.log("Adding ICE candiate ", dataJson, webrtc.pc.signalingState);
            var candidate = new webrtc.RTCIceCandidate({sdpMLineIndex: dataJson.sdpMLineIndex, candidate: dataJson.candidate});
            webrtc.iceQueue.push(candidate)
            if(webrtc.pc.signalingState == "stable"){
              webrtc.iceQueue.forEach( pastIce => {
                webrtc.pc.addIceCandidate(pastIce, this.aic_success_cb.bind(this), this.aic_failure_cb);
                console.log("Already Add ICE candiate ", pastIce);
              })
              webrtc.iceQueue = []
            }else{
              console.log("Don't add ice candidate ", webrtc.pc.signalingState);
            }
        }

        setWebRTC(webrtc)   

    }    
    
    trace(txt) {
        
        if(this.props.project.enableDebugLog){
            var elem = document.getElementById("debugShow");
            elem.innerHTML += txt + "<br>";
        }
    }
    
    handleServerNotification(data) {
        const { webrtc, setWebRTC } = this.props;

        //this.trace("Server notification: " + data);
        var parsed = data.split(',');
        if (parseInt(parsed[2]) != 0){
            webrtc.otherPeers[parseInt(parsed[1])] = parsed[0];
        }
        setWebRTC(webrtc);
    }
    
    parseIntHeader(r, name) {
        var val = r.getResponseHeader(name);
        return val != null && val.length ? parseInt(val) : -1;
    }
    
    hangingGetCallback() {
        try {
          const { webrtc, project, setWebRTC, setProject } = this.props;
          console.log("@hangingGetCallback webrtc.hangingGet: ", webrtc.hangingGet)

            if (webrtc.hangingGet.readyState != 4){
                console.log("webrtc.hangingGet.readyState != 4", webrtc.hangingGet.readyState)
                return;
            }


            if (webrtc.hangingGet.status != 200) {
                console.log("webrtc.hangingGet.status != 200")
                this.trace("server error: " + webrtc.hangingGet.statusText);
                this.disconnect();
                //go to start page
                this.changeClothes();
                project.userStatus = 0;
                setProject(project);
                this.sendToServer('userStatus', 0)  

            } else {

                webrtc.peer_id = this.parseIntHeader(webrtc.hangingGet, "Pragma");
                
                console.log("Message from:", webrtc.peer_id, ':', webrtc.hangingGet.responseText);
                if (webrtc.peer_id == webrtc.myId) {
                  this.handleServerNotification(webrtc.hangingGet.responseText);
                } else {
                  //this.trace("handlePeerMessage: " + webrtc.hangingGet.responseText);
                  this.handlePeerMessage(webrtc.peer_id, webrtc.hangingGet.responseText);
                }
            }

            if (webrtc.hangingGet) {
                console.log("if(webrtc.hangingGet)");
                webrtc.hangingGet.abort();
                webrtc.hangingGet = null;                                   
            }



            if (webrtc.myId != -1){
                window.setTimeout(this.startHangingGet(), 0);
            }

            //update webrtc
            setWebRTC(webrtc)
                
      } catch (e) {
          this.trace("Hanging get error: " + e);
      }
    }
    
    startHangingGet() {
        try {

            const { webrtc, setWebRTC } = this.props;
            //this.trace("startHangingGet");
            console.log("startHangingGet");
            webrtc.hangingGet = new XMLHttpRequest();

            webrtc.hangingGet.onreadystatechange = this.hangingGetCallback.bind(this);
            webrtc.hangingGet.ontimeout = this.onHangingGetTimeout.bind(this);
            console.log("startHangingGet myId: ", webrtc.server + "/wait?peer_id=" + webrtc.myId)
            webrtc.hangingGet.open("GET", webrtc.server + "/wait?peer_id=" + webrtc.myId, true);
            webrtc.hangingGet.send();  


                                                     
              
        
            
           setWebRTC(webrtc);

        } catch (e) {
            console.log("@startHangingGet error" + e)
            this.trace("@startHangingGet error" + e);
        }
    }
    
    onHangingGetTimeout() {

      try {
        const { webrtc, setWebRTC } = this.props;

        console.log("hanging get timeout. issuing again.")
        //this.trace("hanging get timeout. issuing again.");
        webrtc.hangingGet.abort();
        webrtc.hangingGet = null;

        if (webrtc.myId != -1){
            console.log("webrtc.myId != -1 @onHangingGetTimeout ")
            window.setTimeout(this.startHangingGet(), 0);
        }
        
        setWebRTC(webrtc);

      } catch (e) {
            console.log("@onHangingGetTimeout error" + e)
            this.trace("@onHangingGetTimeout error" + e);
      }    
    }
    
    signInCallback() {
        try {
            const { xar, project, webrtc, setWebRTC, updateXAR, requestRTCAR } = this.props;

            //console.log("@signInCallback webrtc.request: ", webrtc.request)
          
            if (webrtc.request.readyState == 4) {
                if (webrtc.request.status == 200) {


                    

                    var peers = webrtc.request.responseText.split("\n");
                    console.log("peers", peers);

                    webrtc.myId = parseInt(peers[0].split(',')[1])
                    console.log("webrtc.myId", webrtc.myId);

                    if(project.onMirror===true){
                        this.XARclothesProducts();
                        xar.peerID = webrtc.myId;
                        //console.log("peerID ", xar.peerID)
                        updateXAR(xar);
                    }else{
                        xar.peerID = webrtc.myId;
                        //console.log("peerID ", xar.peerID)
                        updateXAR(xar);
                    }                                                  
                    
                    this.sendToServer('myPeerId', webrtc.myId)

                    //this.trace("My id: " + webrtc.myId);
                    for (var i = 1; i < peers.length; ++i) {
                        if (peers[i].length > 0) {
                            //this.trace("Peer " + i + ": " + peers[i]);
                            var parsed = peers[i].split(',');
                            webrtc.otherPeers[parseInt(parsed[1])] = parsed[0];
                        }
                    }
                    //console.log("@signInCallback startHangingGet request", webrtc.request)
                    //if(project.mirrorMode===0){

                        if(project.onMirror && project.userId<=0){
                            //Mirror side, remote side has sent json file. mirror side has no ideas which clothes clicked
                            
                            var tmp = xar;
                            tmp.peerID = webrtc.myId;
                            
                            tmp.camOrientation = project.camPosSel;
                            tmp.remoteUserId = project.remoteUserId;
                            tmp.mirrorId = project.mirrorId;
                            tmp.tiltCompensation = project.tiltCompensation;
                            tmp.canvasResolution = [webrtc.canvasResolution[0] * project.canvasResolutionScale, webrtc.canvasResolution[1] * project.canvasResolutionScale];//const streamSetting = mediaStream.getTracks()[0].getSettings()
                            
                            //console.log("tmp", tmp)
                            requestRTCAR(tmp);  

                        }else if(project.onMirror && project.userId>0){
                            //not ARmorror mode, that is, not remote side or mirror side, either,
                            //such as phone mode, and need send request after rtc connectting
                            var tmp = xar;
                            tmp.peerID = webrtc.myId;
                            
                            tmp.camOrientation = project.camPosSel;
                            tmp.remoteUserId = project.remoteUserId;
                            tmp.mirrorId = project.mirrorId;
                            tmp.tiltCompensation = project.tiltCompensation;
                            tmp.canvasResolution = [webrtc.canvasResolution[0] * project.canvasResolutionScale, webrtc.canvasResolution[1] * project.canvasResolutionScale];
                            //console.log("tmp", tmp)
                            requestRTCAR(tmp);  
                        }else if(project.onMirror===false){
                            var tmp = xar;

                            tmp.peerID = webrtc.myId;

                            tmp.camOrientation = project.camPosSel;
                            tmp.remoteUserId = project.remoteUserId;
                            tmp.mirrorId = project.mirrorId;
                            tmp.tiltCompensation = project.tiltCompensation;
                            tmp.canvasResolution = [webrtc.canvasResolution[0] * project.canvasResolutionScale, webrtc.canvasResolution[1] * project.canvasResolutionScale];
                            //console.log("tmp", tmp, webrtc, webrtc.canvasResolution, webrtc.webRTCstart)
                            requestRTCAR(tmp);  //send again(original in singleSuitPage, due to peerId) 
                        }
                    //}

                    this.startHangingGet();
                    webrtc.request = null;
                    
                }
            }

            setWebRTC(webrtc);

        } catch (e) {
            this.trace("signInCallback error: " + e);
            this.trace("signInCallback error: " + e.description);
        }
    }
    
    signIn() {
      try {

          //console.log("@signIn")
          const { webrtc, setWebRTC } = this.props;

          webrtc.request = new XMLHttpRequest();
          
          //console.log("request@signin", webrtc.request);
          webrtc.request.onreadystatechange = this.signInCallback.bind(this);
          webrtc.request.open("GET", webrtc.server + "/sign_in?" + webrtc.localName, true);
          webrtc.request.send();

          setWebRTC(webrtc)
                                                    
          

      } catch (e) {
          this.trace("@signIn error: " + e);
      }
    }

    dummy() {
    }
    
    sendToPeer(data) {
      try {
          const { webrtc, setWebRTC } = this.props;
          console.log("@sendToPeer: ", webrtc.peer_id," Send ", data);
          if (webrtc.myId == -1) {
              alert("Not connected");
              return;
          }
          if (webrtc.peer_id == webrtc.myId) {
              alert("Can't send a message to oneself :)");
              return;
          }
          var r = new XMLHttpRequest();
          r.onreadystatechange = this.dummy
          r.open("POST", webrtc.server + "/message?peer_id=" + webrtc.myId + "&to=" + webrtc.peer_id, true);
          r.setRequestHeader("Content-Type", "text/plain");
          r.send(data);
      } catch (e) {
          this.trace("send to peer error: " + e);
      }
    }
    
    connect() {

          const { webrtc, setWebRTC } = this.props;    

          webrtc.localName = this.props.project.enableDebugLog ? document.getElementById("localInput").value.toLowerCase() : 'Vismile';

          webrtc.server    = this.props.project.enableDebugLog ? document.getElementById("serverInput").value.toLowerCase() : 'https://aichure.com/ARfitting';
                         
         

         if (webrtc.localName.length == 0) {
             
             alert("I need a name please.");
             if(this.props.project.enableDebugLog){
                document.getElementById("localInput").focus();
             }

         } else {
           console.log("localName, server", webrtc.localName, webrtc.server);
           this.signIn();
         }

         setWebRTC(webrtc)                           
                          

                        


        

      
    }
    
    async disconnect() {

        //console.log('disconnect');
        //console.log("this.props@disconnect: ", this.props);
        //console.log("this.state@disconnect: ", this.state);
        const { project, webrtc, setWebRTC } = this.props;     



        //unregister mirrorId -------------------------------
        //TBD
        // the same mirrorId, which already registered in server, need to regenerate remoteUserId
        const body = {
                        databaseName:  project.projectName,
                        mirrorId: -1,//this.state.userId,
                        registration: false                            
                     }
        //this.sendToServer('registerMirror', userId)                  
        //await axios.post("/api/project/registerMirror", body );
        await axios.post("/api/project", body );//original, not clear what it for
        //---------------------------------------------------    

        if(webrtc.dataChannel && webrtc.dataChannel.readyState==='open'){

          webrtc.dataChannel.send("disconnect")


        }

        if (webrtc.myId != -1) {
            
            //console.log('disconnect webrtc.myId');
            webrtc.request = new XMLHttpRequest()

            webrtc.request.open("GET", webrtc.server + "/sign_out?peer_id=" + webrtc.myId, false);
            webrtc.request.send();
            webrtc.request = null;
            webrtc.myId = -1;

                                                    
             
        }

        if (webrtc.request) {
            //console.log('disconnect request');
            webrtc.request.abort();
            webrtc.request = null;

        }
        
        if (webrtc.hangingGet) {
            //console.log('disconnect hangingGet');
            webrtc.hangingGet.onreadystatechange = null;
            webrtc.hangingGet.abort();
            webrtc.hangingGet = null;

        }

        if(webrtc.pc){

            //console.log('disconnect webrtc.pc');
            webrtc.pc.close();  
        }

      
        //turnoff webcam
        if(webrtc.stream){

          webrtc.stream.getTracks().forEach(
                                              track => {
                                                        if(track){
                                                          track.enabled = false;
                                                          track.stop();
                                                        }
                                           });

        }
        if(webrtc.localStream){

          webrtc.localStream.getTracks().forEach(track => {
                                                            if(track){
                                                              track.enabled = false;
                                                              track.stop();
                                                            }
                                                          });

        }
        if(webrtc.localStream_clone){

          webrtc.localStream_clone.getTracks().forEach(track => {
                                                                  if(track){
                                                                    track.enabled = false;
                                                                    track.stop();
                                                                  } 
                                                                });

        }

        if(webrtc.webcamDelayVideo){

          webrtc.webcamDelayVideo.srcObject = null;
          
        }        
        
        webrtc.webRTCstart  = false;
        webrtc.webcamStart  = false;
        webrtc.webrtcReady  = false;
        webrtc.remoteComing = false;

        //DemoSite
        clearInterval(webrtc.timer)
        this.setState({myTurnNow: false})

        webrtc.dataChannelDone = false;
        webrtc.booking = false;
        webrtc.ticket.id = -1;
        webrtc.ticket.timeStamp = -1;
        
        webrtc.timer = null;

        setWebRTC(webrtc)
 

    }
    
    
    
    send() {
        const { webrtc, setWebRTC } = this.props;   
        var text = document.getElementById("message").value;
        webrtc.peer_id = parseInt(document.getElementById("peer_id").value);
        
        if (!text.length || webrtc.peer_id == 0) {
            alert("No text supplied or invalid peer id");
        } else {
            this.sendToPeer(webrtc.peer_id, text);
        }
        setWebRTC(webrtc)
    }
    
    toggleMe(obj) {
        var id = obj.id.replace("toggle", "msg");
        var t = document.getElementById(id);
        if (obj.innerText == "+") {
            obj.innerText = "-";
            t.style.display = "block";
        } else {
            obj.innerText = "+";
            t.style.display = "none";
        }
    }
    
    onSessionConnecting(message) {
        //console.log("Session connecting.");
    }

    onSessionStateChange(){

        //console.log("Session state change.", this.props.webrtc.webrtcReady);
        //console.log("iceConnectionState ", this.props.webrtc.pc.iceConnectionState);

        if(this.props.webrtc.pc.iceConnectionState=="connected"){
            /*
            console.log("this.props.webrtc.pc.iceConnectionState==connected")
            var tmp = this.props.webrtc;
            tmp.webrtcReady = true;
            this.props.setWebRTC(tmp);

            this.setState({webrtcReady: true})
            */
        }

    }

    onSignalingStateChange(){ //dealing with situation which lots of ice candidates done but not stabe, after then stable trigger

      const { webrtc } = this.props;
      if(webrtc.pc.signalingState == "stable"){
        webrtc.iceQueue.forEach( pastIce => {
                                              webrtc.pc.addIceCandidate(pastIce, this.aic_success_cb.bind(this), this.aic_failure_cb);
                                              //console.log("Already Add ICE candiate ", pastIce);
                                })
        webrtc.iceQueue = []
      }

    }

    onConnectionStateChange(){
        //console.log("connectionState Change ", this.props.webrtc.pc.connectionState);
    }

    onSessionOpened(message) {
        //console.log("Session opened.");
    }
    onSessionClosed(message) {
        //console.log("Session closed.");
        //this.disconnect();
    }    
    onRemoteStreamRemoved(event) {
        //console.log("Remote stream removed.");
    }
    
    onRemoteSdpError(event) {
        //console.error('onRemoteSdpError', event.name, event.message);
    }
    
    onRemoteSdpSucces() {
        //console.log('onRemoteSdpSucces');
    } 
  
    changeClothes(){

        //if(!this.props.project.onMirror){
        //    const linkAddr = this.props.location.state.linkAddr;
        //}
        //this.disconnect()
        //this.props.history.push(linkAddr);
        //this.props.history.go(-2);

        //console.log("changeClothes")
        if(this.props.project.mirrorMode===1){
            const linkAddr = this.props.location.state.linkAddr;
            this.props.history.go(-1);
        
        }else{
            const linkAddr = this.props.location.state.linkAddr;
            //this.props.history.push(linkAddr);
            this.props.history.go(-2);
        }

    }
    

  onChangeClientName(e) {

    const { webrtc, setWebRTC } = this.props;   
    const ClientName = e.target.value;

    webrtc.localName = ClientName;
    setWebRTC(webrtc)
  }

  onChangeServerAddr(e) {
    const { webrtc, setWebRTC } = this.props;   
    const ServerAddr = e.target.value;

    webrtc.server = ServerAddr;
    setWebRTC(webrtc)
  }

  XARclothesProducts() {
    //console.log("============================= clothlist ===============================")
    
    //if mirror mode, mirror side has no product informoation, need get it from webserver,
    const { xar, /*suit,*/ product } = this.props;
    //console.log("this.props @XARclothesProducts: ", this.props)
    //console.log("suit @XARclothesProducts: ", suit)
    //console.log("suit.suit @XARclothesProducts: ", suit.suit)

    var suit_suit_suitID = "Vismile_female_set_013";
    var suit_suit_gender = 'male';

    const video_filename = xar.picID + '_' + suit_suit_suitID;//suit.suit.suitID;
    

    const clothlist = [];//suit.suit.suitClothesID;
    const shoes = "vsdemo_female_highheels001";//suit.suit.shoes;

    //console.log("clothlist: ", clothlist)

    xar.clothlist = clothlist;
    xar.shoes = shoes;
    xar.suitID = suit_suit_suitID;//suit.suit.suitID;
    xar.clothesID = "asia2020_f_001_dress_long_red";//product.product.internal_name;
    xar.video_filename = video_filename;
    if(suit_suit_gender/*suit.suit.gender*/ === 'male'){
      xar.human.gender = 1;  
    }else
    {
      xar.human.gender = 0;
    }
    

  }
  
// webcam 
  async startWebcam(){

        const { project,  webrtc, setWebRTC, xar } = this.props;

        var inputWidthLarge  = 640;//xar.image_size[0]
        var inputHeightLarge = 480;//xar.image_size[1]

        var inputWidthSmall  = 320;//xar.image_size[0]
        var inputHeightSmall = 240;//xar.image_size[1]

        //console.log("startWebcam @ ", xar.image_size);

        if(project.ClientInfo.isFirefox){

            if(xar.image_size[0]===1920){

              inputWidthLarge  = 960;
              inputHeightLarge = 720;

              inputWidthSmall  = 320;
              inputHeightSmall = 240;

            }
        }else{

            if(xar.image_size[0]===1920){
              /*
              if (window.innerHeight > window.innerWidth){

                inputWidthLarge  = 1080;//1920;
                inputHeightLarge = 1920;//1080;
   
                inputWidthSmall  = 270; //480;
                inputHeightSmall = 480; //270;

              }else{
              */
                inputWidthLarge  = 1920;//1080;//1920;
                inputHeightLarge = 1080;//1920;//1080;
   
                inputWidthSmall  = 480; //270; //480;
                inputHeightSmall = 270; //480; //270;

              //}

            }

        }
        /*
        if(inputAspectRatio===16.0/9.0){//sould be 1920x1080 1440x720
            console.log("inputAspectRatio 16/9")
            inputWidth  = 640
            inputHeight = 360

        }else{ //should be 640x480
            console.log("inputAspectRatio 4/3")
            inputWidth  = 320
            inputHeight = 240

        }
        */

/*
      //const constraints = { video: { width: 1920, height: 1080, facingMode: 'user' } };
      if(this.props.webrtc.canvasResolution[0]/this.props.webrtc.canvasResolution[1]>=1.7){

          var constraints = { audio: false, video: { width: 1920, height: 1080,  facingMode: "environment", frameRate: { ideal: 20, max: 22 } }  };
      
      }else{

          var constraints = { audio: false, video: { width: 640, height: 480,  facingMode: "environment", frameRate: { ideal: 20, max: 22 } }  };

      }//this.stream = await navigator.mediaDevices.getUserMedia( constraints );
      

      
      //for firefox, rtc /4 would reduce original resolution
      if(project.ClientInfo.isFirefox){

          var _this = this
          navigator.mediaDevices.getUserMedia(constraints)
              .then(function(mediaStream) {
                console.log("getUserMedia @isFirefox", mediaStream)
                //this.stream = mediaStream;
                //console.log("_this", _this) 
                //console.log("_this.webcamDelayVideo", _this.webcamDelayVideo) 
                _this.webcamDelayVideo.srcObject = mediaStream;
                
                _this.webcamDelayVideo.onloadedmetadata = function(e) {
                                                                      console.log("this, this.webcamDelayVideo", this, this.webcamDelayVideo)
                                                                      this.play();
                                                                     };
                //this.webcamDelayVideo.play();

              })
              .catch(function(err) { console.log(err.name + ": " + err.message); }); // always check for errors at the end.
      }else{

          //testing work on ios safari  
          this.webcamDelayVideo.srcObject = this.props.webrtc.localStream;     
          this.webcamDelayVideo.play();

      }
*/
        
        var constraintsLarge = { audio: false, video: { width: inputWidthLarge, height: inputHeightLarge, facingMode: "environment", frameRate: { ideal: 20, max: 22 } }  };
        var constraintsSmall = { audio: false, video: { width: inputWidthSmall, height: inputHeightSmall, facingMode: "environment", frameRate: { ideal: 20, max: 22 } }  };
          
        if ( navigator.mediaDevices && navigator.mediaDevices.getUserMedia ) {

          

          //const constraints = { video: { width: 1920, height: 1080, facingMode: 'environment' } };
          //var constraints = { audio: false, video: { width: 1920, height: 1080,  facingMode: "environment", frameRate: { ideal: 20, max: 22 } }  };
          //var constraints = { audio: false, video: { width: { exact: inputWidth }, height: { exact: inputHeight },  facingMode: "environment", frameRate: { ideal: 20, max: 22 } }  };
          //var constraints = { audio: false, video: { width: inputWidth, height: inputHeight, aspectRatio: inputAspectRatio,  facingMode: "environment", frameRate: { ideal: 20, max: 22 } }  };
          //var constraints = { audio: false, video: true  };
          //var constraints = { audio: false, video: { width: {min: 320, max: 1920}, height: {min: 240, max: 1080},   facingMode: "environment" }  };
          //const stream = await navigator.mediaDevices.getUserMedia( constraints );
          //webrtc.localStream = stream;
          //webrtc.localStream_clone = stream.clone();
          
          //console.log("constraints @startWebcam", constraintsLarge)
          var _this = this;
          navigator.mediaDevices.getUserMedia(constraintsLarge)
          .then(function(mediaStream) {
                                        webrtc.localStream = mediaStream;
                                        webrtc.localStream_clone = mediaStream.clone();

                                        
                                        //var scralingFactor = 
                                        const streamSetting = mediaStream.getTracks()[0].getSettings()
                                        //console.log("streamSetting", streamSetting)

                                        let streamConstrains = mediaStream.getTracks()[0].getConstraints()
                                        streamConstrains.width = 480;//inputWidthSmall;
                                        streamConstrains.height = 270;//inputHeightSmall;


                                        webrtc.localStream_clone.getTracks()[0].applyConstraints(
                                          { width: {ideal: inputWidthSmall},
                                            height: {ideal: inputHeightSmall},
                                            advanced: [ {aspectRatio : 1.7777} ] }
                                            //streamConstrains
                                        ).then(() => {
                                            
                                                        const settingShouldNotChange = webrtc.localStream.getTracks()[0].getSettings();
                                                        webrtc.canvasResolution = [ inputWidthLarge, inputHeightLarge ]
                                                        webrtc.canvasResolutionScale = project.canvasResolutionScale;
                                                        
                                                        if(settingShouldNotChange.width != streamSetting.width || settingShouldNotChange.width != streamSetting.width){

                                                            //console.log("Get UserMedia Twice.")
                                                            navigator.mediaDevices.getUserMedia(constraintsLarge)
                                                              .then(function(mediaStream) {
                                                                  webrtc.localStream = mediaStream;
                                                              });
                                                              
                                                              //if(this.props.project.userStatus===0){
                                                              //    this.disconnect();
                                                              //}else{
                                                                  webrtc.webRTCstart = true;
                                                                  _this.setState({webRTCstart: webrtc.webRTCstart});
                                                                  setWebRTC(webrtc);
                                                              //}

                                                        }else{
                                                              //console.log("Get UserMedia Once.")

                                                              //if(this.props.project.userStatus===0){
                                                              //    this.disconnect();
                                                              //}else{
                                                                  webrtc.webRTCstart = true;
                                                                  _this.setState({webRTCstart: webrtc.webRTCstart});
                                                                  setWebRTC(webrtc);
                                                              //}

                                                        }

                                                        //testing
                                                        //console.log("getSetting clone: ", webrtc.localStream_clone.getTracks()[0].getSettings() )

                                        }).catch((e)=>{console.log("applyConstraints err", e)});
          })
          .catch(function(err) { console.log("getUserMedia error", err.name + ": " + err.message); }); // always check for errors at the end.
                  
          
                                                           
          //webrtc.webcamDelayVideo.srcObject = this.stream;
          //webrtc.webcamDelayVideo.play();
          
          

        } else {

          console.error( 'MediaDevices interface not available.' );

        }

        

  }




  getTakePhotoFromChild(TakePhotoFromChild){
    
        //console.log("getTakePhotoFromChild@route", TakePhotoFromChild)
        //console.log("this@route", this)
        this.setState({onTakePhoto: TakePhotoFromChild})
       

    }

  //for ARfitting DemoSite  
  onARclothesShows(){

     
  
      //console.log("onARclothesShows", this.ARtimer)
      document.getElementById("TimerCountUse").style.display = 'unset'
      document.getElementById("buttonHomeUse").style.visibility = 'visible'

      if (this.ARtimer == 0) {
        this.ARtimer = setInterval(this.ARcountDown.bind(this), 1000);
        //console.log("start ARtimer", this.ARtimer)
        
      }

  

  }

  ARcountDown() { 
      // Remove one second, set state so a re-render happens.
      //console.log("this@countDown", this)

      
      //document.getElementById("TimerCountUse").innerHTML = this.state.ARseconds.toString()
      document.getElementById("TimerCountUse").style.display = 'unset'
      document.getElementById("buttonHomeUse").style.visibility = 'visible'

      let seconds = this.state.ARseconds - 1;
      let timerStop = this.state.ARfittingStop;
      this.setState({
        ARseconds: seconds,
      });
      //console.log("time@countDown", this.state.ARseconds)
      
      document.getElementById("TimerCountUse").innerHTML = seconds.toString()

      // Check if we're at zero.
      if (seconds == 0 || timerStop) { 
        //console.log("time to 0")

        clearInterval(this.ARtimer);
        this.ARtimer = 0;

        document.getElementById("TimerCountUse").style.display = 'none'
        document.getElementById("buttonHomeUse").style.visibility = 'hidden'

      }
  }

  OnClickHome(){

    this.setState({ARfittingStop: true})
    this.props.history.push(`/${this.props.project.projectName}/${this.props.project.pageName}`)


  }

  checkTicket(){

    const { webrtc, checkTicketWebrtc, setWebRTC } = this.props;

    checkTicketWebrtc(webrtc).then( () => {


                                            //console.log("webrtc@checkTicketWebrtc.then()", webrtc.ticket.id)
                                            if(webrtc.ticket.id === 0){

                                              //clearInterval(this.timer); only clear after finishing ARfitting
                                              //console.log("start ARfitting")
                                              //webrtc.ticket.id = -1;
                                              //setWebRTC(webrtc)
                                              this.setState({myTurnNow: true})
                                              //this.ARfittingSubmit(this.state.suit)

                                            }else if(webrtc.ticket.id === -1){

                                              //console.log("ARfitting stops")
                                              this.setState({myTurnNow: false})
                                              //this.props.history.go(-1);
                                              clearInterval(webrtc.timer)

                                            }

                                  })


  }

  registerWebrtc() {

    const { webrtc, bookingWebrtc, setWebRTC } = this.props;

    

    webrtc.booking = true;
    bookingWebrtc(webrtc).then( () => {
                                          console.log("webrtc@bookingWebrtc.then()", webrtc.ticket.id)
                                          //if(webrtc.ticket.id !== 0){

                                            //in case of ths condition that user close the page before webrtc connection
                                            const setTimer = setInterval(this.checkTicket.bind(this), 1000);
                                            webrtc.timer = setTimer
                                            setWebRTC(webrtc)
                                            
                                          

                                          //}else{
                                            //console.log("start ARfitting")
                                            //this.ARfittingSubmit(suit)
                                          //}

                                          if(webrtc.ticket.id === 0){
                                            //console.log("start ARfitting")
                                            //webrtc.ticket.id = -1;
                                            //setWebRTC(webrtc)
                                            this.setState({myTurnNow: true})
                                            //this.ARfittingSubmit(suit)
                                          }


                              });




  }


//---------------------------------------------------------------------------------------------------------------

  render() {

    const { isLoggedIn, isAdmin } = this.props;

    //console.log("isLoggedIn, isAdmin", isLoggedIn, isAdmin)

    return (

      <div style={{ width : "100%",
                    position: "relative", //due to header position: fixed
                    minHeight: this.props.project.onPotrait ? '92vh' : "",
                    top:   this.props.project.largeHeaderMode===2 && this.props.project.userId<0 ? "38vh" //due to variant header size
                         : this.props.project.largeHeaderMode===1 && this.props.project.userId<0 ? "180px"
                         : this.props.project.largeHeaderMode===0 && this.props.project.userId<0 ? "" //"8vh"
                         : "" ,
                    paddingTop: this.props.project.onDemoSite ? "70px" : ""    //due to fixed Navbar 70px
                 }} >



          <div>
              <Switch>


                {isAdmin    && <AdminRoutes />}
                {isLoggedIn && <UserRoutes  />}
                {/*<Route path="/" component={Home} />*/}

                {/* Routes placed here are available to all visitors */}


                <Route exact path="/:projectName/fdd" render={  (props) => <ProjectSite {...props} parentCallback = {this.registerWebrtc.bind(this)}  myTurnNow={this.state.myTurnNow} /> } />
                
                <Route path="/:projectName/MetaFitting" render={  (props) => <DemoSite {...props} parentCallback = {this.registerWebrtc.bind(this)}  myTurnNow={this.state.myTurnNow} /> } />
                <Route path="/:projectName/AI-virtual-fitting" render={  (props) => <VismileSite {...props}  /> } />
                <Route path="/:projectName/XARdemoShow" render={  (props) => <XARdemoShow {...props}  /> } />
                <Route path="/:projectName/CrossBrowser" render={  (props) => <CrossBrowser {...props}  /> } />
                
                <Route path="/:projectName/sewing/login" render={(props) => <Login {...props} redirect={"/sewing"} />} />
                <Route path="/:projectName/login" render={(props) => <Login redirect={`/${this.props.project.projectName}/admin/account/manage`} />} />

                <Route path="/:projectName/signup" component={Signup} />
                
                {/*<Route path="/:projectName/manage" component={EditorPage} />*/}

                <Route path="/:projectName/RTCfittingARmirror" render={  () => <RTCfittingWebglNoRTC webrtcReady={this.state.webrtcReady}   onHandleTakePhotoToChild={this.state.onHandleTakePhotoToChild} parentCallback = {this.onARclothesShows.bind(this)}/> } />
                <Route path="/:projectName/RemotePhone" render={  () => <RemotePhone parentCallback = {this.getTakePhotoFromChild.bind(this)}/> } />
                <Route path="/camera" component={TakePhoto} />
                <Route path="/fileupload" component={FileUpload} />
                <Route path="/sewing" component={SewingUploadPage} />
                {/*<Route path="/handtocursor" component={HandToCursorPage} />*/}
               
                <Route path="/:projectName/ARfitting" render={  () => <RTCfittingWebglNoRTC parentCallback = {this.onARclothesShows.bind(this)}  ARfittingStop={this.state.ARfittingStop} /> } />
                {/*<Route path="/:projectName/ARfitting" component={RTCfittingWebgl} />*/}

                
                <Route exact path="/:projectName/:userId/:mirrorId" component={Home} />
                <Route exact path="/:projectName/:userId" render={  (props) => <Home {...props} parentCallback = {this.registerWebrtc.bind(this)}  /> } />
                <Route exact path="/:projectName" component={Home} />
                

                <Route exact path="/:projectName/cart" component={CartPage} />
                {/*<Route path="/:projectName/products/product/:id" component={SingleProductPage} />*/}
                <Route path="/:projectName/products/search/:searchText" component={ProductSearch} />
                
                <Route path="/:projectName/suits/suit/:id/:isSuit" component={SingleSuitPage} />
                

                <Route path="/:projectName/catalog/:categoryId/products" component={ProductCatalog}/>
                
                <Route path="/:projectName/catalog/:categoryId/gender/:genderId/products" component={ProductCatalog}/>

                <Route path="/:projectName/gender/:genderId/categories" component={CategoryCatalog}/>
                
                <Route path="/:projectName/categories" component={CategoryCatalog}/>
                
                <Route path="/:projectName/cart/checkout/complete" component={CheckoutComplete} />
                <Route path="/:projectName/cart/checkout"
                                                          render={routeProps => (
                                                            <StripeProvider apiKey="pk_test_UniUJcxzZgf0zmgciwrViyLC">
                                                              <Elements>
                                                                <Checkout {...routeProps} />
                                                              </Elements>
                                                            </StripeProvider>
                                                          )}
                />
                

                
                
              </Switch>

          </div>
          
          
          {
            this.props.project.enableDebugLog ? (
                                                  <div>
                                                      <div><input type="text" id="serverInput" defaultValue="https://aichure.com/ARfitting" onChange={this.onChangeServerAddr.bind(this)} /></div>
                                                      <div><input type="text" id="localInput"  defaultValue="Vismile"                       onChange={this.onChangeClientName.bind(this)} /></div>
                                                      <div> <pre id="debugShow"></pre> </div>
                                                  </div>    
                                            ) : (<div></div>)
          }    

         { 
            this.props.project.onDemoSite ? (
                                              <div id="TimerCountUse" style={ styles.countdownUse }></div>  
                                        ) : (<div id="TimerCountUse"  style={{visibility: 'hidden' }} ></div>)
         }                                        

         { 
            this.props.project.onDemoSite ? (
                                              <Button icon  id="buttonHomeUse" onClick={() => this.OnClickHome()} style={ {...styles.buttonHome, visibility: 'hidden'} }>
                                                <Icon style={ styles.button }  name={"home"} color='#f2711c' circular='true' size="massive"/>
                                              </Button>  
                                        ) : (<Button icon  id="buttonHomeUse"  style={{visibility: 'hidden' }} ></Button>)
         }                                        

      </div>
    );
  }
}

const styles = {

    display_region:{

        width: '100%'
    },

    countdownUse: {

      position: 'fixed',
      left: '3%',
      top: '10vh',
      'z-index': 999,
      'font-size': '15vw',
      color: 'rgb(255, 76, 143)',
      width: '50%'
     
    },

    buttonHome: {
      position: 'fixed',
      left: '-13%',
      top: '22vh',
      'z-index': 999,
      color: 'rgb(255, 76, 143)',
      width: '50%',

      backgroundColor: '#00000000'

    },

    transparent: {
                
    },

    button: {
                   color: "rgb(255, 76, 143)"
    },


};

export default withRouter(connect(mapState, mapDispatch)(Routes));

Routes.propTypes = {
  loadInitialData: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};
