//Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
//import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import { Icon, Button, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

import {
  
	updateProject,
  updateDataXAR

} from "../../../../store";
//Internals

const mapState = state => ({

  xar: state.xar,
  project: state.project
});

const mapDispatch = dispatch => {
  return {
   
    setProject : (params) => dispatch(updateProject(params)),
    updateXAR: (xar) => dispatch(updateDataXAR(xar)),
    
  };
};


class ButtonBar extends Component{
	constructor() {
	    super();
	    
	    
	    this.state = {

	    	btnBarPosRight : 1,

		    scrollBarPos : 0,
		    isClient : typeof window === 'object',
		    windowSize : [],
		    height : 0,
		    width : 0, 

		    men : '',
		    women : '', 

		    projectName : '',
		    pageName : '',
		};
	}
	
	componentDidMount() {

		const { project } = this.props;
	    this.setState({ projectName : project.projectName });
	    this.setState({ pageName : project.pageName });
	    this.setState({ btnBarPosRight : project.btnBarPosSel });

	    const size = this.useWindowSize();

    	if (!this.isClient) {

	      	return false
	      	
	   
	    	
	    }

	    this.setState({ height : size.height });

	    window.addEventListener('resize', this.handleResize);
	    return () => window.removeEventListener('resize', this.handleResize);
	} 
	

	useWindowSize = () => {

		this.setState({ windowSize : this.getSize() });

    	return this.windowSize;

	}

	getSize = () => {

		return {

			width: this.isClient ? window.innerWidth : undefined,

			height: this.isClient ? window.innerHeight : undefined
		};		
	}
	//if not full screen, 100 should be as % of window height
	
	handleResize = () => {

		this.setState({ windowSize : this.getSize() });

	}


	resetPos = () => {


		
	}

	OnClickHome(){

		const { xar, updateXAR } = this.props;
		xar.isShoot = false;
		updateXAR(xar);
    

	}

	OnClickSignOut(){
		console.log("OnClickSignOut")
		const { project, setProject } = this.props;
		project.userStatus = 0;
		setProject(project);
    
    
    setTimeout(function () { document.location.href = "https://vismile.com.tw" }, 1000);

	}

	componentDidUpdate(prevProps, prevState) {

		if(this.props.categories !== prevProps.categories){

			console.log('this.props', this.props);
			const Men = this.props.categories.find(o => o.name === 'Men');
			const Women = this.props.categories.find(o => o.name === 'Women');
			console.log('men@ButtonBar@DidUpdate', Men);
			console.log('wemen@ButtonBar@DidUpdate', Women);
			this.setState({ men : Men });
			this.setState({ women : Women });
			//-------------------------------------
		}


	}
			

	render() {
			console.log("this.state: @buttonBar", this.state)


    	return (
	        <div style = { this.state.btnBarPosRight===2 ? style_bar_rightPhone 
	        	                                           : this.state.btnBarPosRight===1 ? style_bar_right 
	        	                                           : style_bar_left } >
	    	{/*
	    	<ul style={style_ul}>
	    		<li style={{margin: '10'}}><Icon   name={"arrow circle up"} color='orange'   size="massive" onClick={() => scroll.scrollMore(100)} type="Icon"></Icon></li>
	    		<li> </li>
	    		<li> <br></br>  </li>
	    		<li style={{margin: '10'}}><Icon  name={"arrow circle down"} color='orange'   size="massive" onClick={() => scroll.scrollMore(-100)} type="Icon"></Icon></li>
	    	</ul>
			
			
			<ul style={style_ul_left}>
	    		<li style={{margin: '10'}}><Icon   name={"home"} color='orange'   size="massive" to={`/catalog/${header.id}/products/`} type="Icon"></Icon></li>
	    	</ul>
			*/}
			<ul style={style_ul_right}>
	    		<li style={{margin: '10'}}> 
	    				<Menu.Item as={ Link } to={`/`+ this.state.projectName + `/` + this.state.pageName}  > 
	    						<Button icon onClick={() => this.OnClickHome()} style={style_transparent}>
	    								{this.props.project.onMirror? <Icon style={style_button}  name={"home"} color='#f2711c' circular='true' size="large"/>
																									: <Icon style={style_button}  name={"home"} color='#f2711c' circular='true' size="massive"/>}		    								
	    						</Button>
	    				</Menu.Item>
	    				<Menu.Item > 
	    						<Button icon onClick={() => this.OnClickSignOut()} style={style_transparent}>
	    								{this.props.project.onMirror? <Icon style={style_button}  name={"log out"} color='#f2711c' circular='true' size="large"/>
																									: ''}		    								
	    						</Button>
	    				</Menu.Item>
	    		</li>

	    	

	    		
	    		{/*
	    		<li style={{margin: '10'}}> <Menu.Item as={ Link } to={`/catalog/${this.state.men.id}/products/`}  > <Icon style={style_button} name={"female"} color='#' circular='true'  size="huge" type="Icon"></Icon></Menu.Item></li>
	    		<li style={{margin: '10'}}> <Menu.Item as={ Link } to={`/catalog/${this.state.women.id}/products/`} > <Icon style={style_button} name={"male"} color='#f2711c' circular='true'  size="huge"  type="Icon"></Icon></Menu.Item></li>
	    		*/}
	    </ul>
			

	    	</div>
	  		
    );
  }


}


		const style_transparent = {
					      backgroundColor: '#00000000'
				      };

    	const style_bar_right = {
					      position: 'fixed',
					      top: '550px', /*top: '550px', for massive in ASrock project*/ /*top: '170px', for huge in Havas project*/
					      left: '76.5%', /*left: '76.5%',for massive in ASrock project*/  /*left: '88%',for huge in Havas project*/							      					      
					      "z-index": '5'
				      };
    	const style_bar_rightPhone = {
					      position: 'fixed',
					      top: '550px', /*top: '550px', for massive in ASrock project*/ /*top: '170px', for huge in Havas project*/
					      left: '80.5%', /*left: '76.5%',for massive in ASrock project*/  /*left: '88%',for huge in Havas project*/							      					      
					      "z-index": '5'
				      };
			const style_bar_left = {
					      position: 'fixed',
					      top: '550px', /*top: '550px', for massive in ASrock project*/ /*top: '170px', for huge in Havas project*/
					      left: '2.5%', /*left: '76.5%',for massive in ASrock project*/  /*left: '88%',for huge in Havas project*/							      					      
					      "z-index": '5'
				      };

		const style_ul_right = {
							     listStyleType: 'none', /* Remove bullets */
		  						 padding: '0', /* Remove padding */
		  					 	 margin: '0' /* Remove margins */
						      };
		const style_button = {
							     color: "rgb(255, 76, 143)"
						      };




/*
	      	<button onClick={this.onUpClick} type="button">Up</button>
	      	<button onClick={this.onDnClick} type="button">Down</button>
*/
    

export default connect(mapState, mapDispatch)(ButtonBar);
