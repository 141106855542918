/*

UPDATE_XAR => clothlist/gender

UPDATE_XAR => isShoot

UPLOAD_XAR

REQ_XAR

UPDATE_XAR => isUpload.photoUpload

FETCH_XAR

*/

import React, { Component } from "react";
import {
  fetchProduct,
  
  
  postCart,
  putCart,
  postGuestCart,
  putGuestCart,


  doXAR,
  updateDataXAR,
  fetchXAR

} from "../../store";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Image,
  Button,
  Grid,
  Header,
  Label,
  Icon,
  Message, 
  Card,
  Container
} from "semantic-ui-react";
import { RecommendPage } from "../../components";


/*camera related*/
import { FACING_MODES } from "../../lib";


const mapState = state => ({
  product: state.product,
  category: state.category,
  recommended: state.recommended,

  user: state.user,
  cart: state.cart,



  xar: state.xar

});

const mapDispatch = dispatch => {
  return {
    getProduct: id => dispatch(fetchProduct(id)),

    //getProducts: id => dispatch(fetchProducts(id)),    

    addToCart: input => dispatch(postCart(input)),
    editProductQuantity: data => dispatch(putCart(data)),
    addToGuestCart: product => dispatch(postGuestCart(product)),
    editGuestQuantity: productId => dispatch(putGuestCart(productId)),

    requestXAR: (xar) => dispatch(doXAR(xar)),
    updateXAR: (xar) => dispatch(updateDataXAR(xar)),    
    getXAR: (xar) => dispatch(fetchXAR(xar))

  };
};

class SingleProductPage extends Component {
  constructor() {
    super();
    /*camera*/    
    
    this.state = {

      /*camera*/
      dataUri: '',
      idealFacingMode: '',
      isMaxResolution: false,
      DataUri: '',
      isFullscreen: false,

      message: "",
      inCart: false,
      inventoryReq: 0,
      isGuest: true, 

      sendXARreq: false, 
      XARdone: false,
      isWaiting: false
     
     


    };
  }

  componentDidMount() {
    const { getProduct, user, cart } = this.props;//getProducts,  
    const id = +this.props.match.params.id;

    getProduct(id).then(()=>{
       this.props.xar.isUpdate = false;
       console.log('afet getProduct @componentDidMount', this.props.xar);
    });

    //camera
    //this.cameraPhoto = new CameraPhoto(this.videoRef.current);
    console.log("FACING_MODES.USER: " + FACING_MODES.USER);


    // if user is logged in, set isGuest to false
    if (user.id) {
      this.setState({ isGuest: false });
    }

    if (cart) {
      cart.forEach(elem => {
        if (elem.productId === id) {
          this.setState({ inCart: true, inventoryReq: elem.inventoryReq });
        }
      });
    }

    


  }
/*
  shouldComponentUpdate(nextProps, nextState){

    console.log("shouldComponentUpdate");
    console.log("this.props.xar:", this.props.xar);
    console.log("nextProps.xar:", nextProps.xar);

    if (this.props.xar !== nextProps.xar) {
    
      if( this.props.xar.isShoot && this.props.xar.isRequest && !this.state.XARdone){
      
        console.log("XARsubmit in shouldComponentUpdate");
        this.XARsubmit(this.props.product.id, this.props.user.id);
        
      }

    }
    
  }
*/

  componentDidUpdate(prevProps, prevState) {
    // 常見用法（別忘了比較 prop）：
    console.log("**********************************************");
    console.log("componentDidUpdate@SingleProductPage");
    console.log("this.props.xar:", this.props.xar);
    console.log("prevProps.xar:", prevProps.xar);

    const { updateXAR, getXAR, xar } = this.props;
    
    if (this.props.xar.isUpload !== prevProps.xar.isUpload) {
    /*
      if( this.props.xar.isShoot && this.props.xar.isRequest && !this.state.XARdone){
      
        console.log("XARsubmit in componentDidUpdate");
        this.XARsubmit(this.props.product.id, this.props.user.id);
        
      }*/
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log("xar.isUpload @componentDidUpdate:", xar.isUpload);
      console.log("this.state.XARdone @componentDidUpdate:", this.state.XARdone);
    
      if(xar.isUpload.photoUpload && xar.isUpload.clothlistUpload && !this.state.XARdone){
         console.log("!!!!!!!!!!!!!! xar photo & clothlist both upload!!!!!!!!!!!!!!!!!!!!!!");
         console.log("Go get the photo: start getXAR(xar) in componentDidUpdate");
         getXAR(xar).then(()=>{
             this.setState({ XARdone: true }); 
             this.setState({ isWaiting: false});
             console.log("set XARdone done! ", this.state.XARdone);
             

         });

        
      }else{
        console.log("Not go get the photo: maybe due to XARdone  in componentDidUpdate", this.state.XARdone);
         

      }

    }

    if(this.state.XARdone !== prevState.XARdone){
      //check whether get photo or timeout error
      if(!xar.isUpdate && this.state.XARdone){ //something connection error
        console.log("!!!!!!!!!!!!!! getXAR got error and redo!!!!!!!!!!!!!!!!!!!!!!");
        this.setState({ XARdone: false }); 
        this.setState({ isWaiting: true});
        getXAR(xar).then(()=>{
             this.setState({ XARdone: true }); 
             this.setState({ isWaiting: false});
             console.log("set XARdone done! ", this.state.XARdone);
             

         });

      }

    }

  }


  addToCartSubmit(productId, userId) {
    const {
      addToCart,
      editProductQuantity,
      product,
      addToGuestCart,
      editGuestQuantity
    } = this.props;

    let { inCart, inventoryReq, isGuest } = this.state;
    const quantity = inventoryReq + 1;

    // if the product inventory and the required inventory are the same
    if (product.inventory === inventoryReq) {
      this.setState({ message: "out-of-stock" });
    }

    // if the product isn't in the cart && it's a guest
    if (!inCart && isGuest) {
      addToGuestCart(product);
      this.setState({ inCart: true, message: "updated", inventoryReq: 1 });
    }

    // if product is inCart and the user is a guest
    if (inCart && isGuest) {
      editGuestQuantity({ productId, inventoryReq: quantity });
      this.setState({ message: "updated", inventoryReq: quantity });
    }

    // if the product isn't in the cart && user is logged in
    if (!inCart && !isGuest) {
      addToCart({ productId, userId: userId });
      this.setState({ inCart: true, message: "updated", inventoryReq: 1 });
    }

    // if the product is in the cart && user is logged in
    if (inCart && !isGuest) {
      editProductQuantity({ quantity, productId, userId });
      this.setState({ message: "updated", inventoryReq: quantity });
    }
  }

  XARclothesProducts() {
    console.log("============================= clothlist ===============================")
    
    const { xar, product } = this.props;
    console.log("this.props @XARclothesProducts: ", this.props)
    console.log("product.product.suit @XARclothesProducts: ", product.product.suit)

    console.log("product.product.internal_name: ", product.product.internal_name)
    console.log("product.suit: ", product.suit)
    console.log("product.suit.products: ", product.suit)

    const clothlist = [];
    //clothlist.push(product.product.internal_name);
    let videoQuality = 'HQ';
    let extraOutputFileFormat = 'jpg';//none
    let video_filename = xar.picID + '_' + product.suit.sitID;
    
    console.log("suit@XARclothesProducts: ", product.suit);

    product.suit.forEach(product => {
        console.log("product.suit.products.internal_name: ", product.internal_name)
        clothlist.push(product.internal_name);        
      });

    console.log("clothlist: ", clothlist)

    xar.clothlist = clothlist;
    xar.suitID = product.product.suitID;
    xar.videoQuality = videoQuality;
    xar.extraOutputFileFormat = extraOutputFileFormat;
    xar.video_filename = video_filename;


    
    if(product.product.gender === 'male'){
      xar.human.gender = 1;  
    }else
    {
      xar.human.gender = 0;
    }
    

  }


  XARsubmit(productId, userId) {
    const {
      
      xar,
      updateXAR,
      requestXAR

    } = this.props;

    this.setState({ sendXARreq: true}, ()=>{
                                            console.log("sendXARreq@XARsubmit: " + this.state.sendXARreq);
                                            });
        
    this.XARclothesProducts();

    xar.isRequest = true;
    xar.isUpdate = false;

    updateXAR(xar);
    
    
    //if no photo, do take photo
    //if already has photo, do xar json request
    
    //

    const linkAddr = '/products/product/' + productId

    if(xar.isShoot){
      requestXAR(xar);      
      console.log("  with old pic");
      this.setState({ isWaiting: true});
    }else{
      console.log("XARsubmit don't send and go to camera page");
      this.props.history.push({ pathname: '/camera',
                                state: { linkAddr: linkAddr }
                              });
    }
    

    
      
   
  
    

  
  }

  renderAddToCart() {
    const { product, user } = this.props;
    const { inventory, name } = product;
    const { message, inventoryReq } = this.state;

    // if product is out of stock, render out of stock message
    if (inventory === 0) {
      return (
        <Message negative>
          Sorry, this product is currently out of stock.
        </Message>
      );
    } else if (
      message === "out-of-stock" ||
      inventoryReq === product.inventory
    ) {
      return (
        <Message negative>
          You have all {name}s currently available in your cart.
        </Message>
      );
      // if product is in stock, render add to cart button
    } else if (inventory > 0 && message !== "out-of-stock") {
      return (
        <div>
          <Button as="div" labelPosition="right">
            <Button
              color="red"
              onClick={() => this.addToCartSubmit(product.id, user.id)}
            >
              <Icon name="shop" />Add to Cart
            </Button>
            <Label as="a" basic color="red" pointing="left">
              Only {inventory} left!
            </Label>
          </Button>
          {message === "updated" ? (
            <Message compact positive>
              There are {inventoryReq} {name}s in your cart!
            </Message>
          ) : (
            ""
          )}
        </div>
      );
    }
  }
  
  /*camera functions*/
  renderButtons() {
      return (
         <div>
          <button onClick={ (e) => {
            
            this.setState({ IdealFacingMode: FACING_MODES.USER }, () => {
              console.log(this.state.IdealFacingMode, 'IdealFacingMode');
            });
            this.setState({ IsMaxResolution: false });

            console.log("IdealFacingMode:" + this.state.IdealFacingMode);
            console.log("IsMaxResolution:" + this.state.IsMaxResolution);


          }}> FACING_MODES.USER </button>

          <button onClick={ (e) => {
            this.setState({ IdealFacingMode: FACING_MODES.ENVIRONMENT }, () => {
              console.log(this.state.IdealFacingMode, 'IdealFacingMode');
            });
            this.setState({ IsMaxResolution: true });
            
            console.log("IdealFacingMode:" + this.state.IdealFacingMode);
            console.log("IsMaxResolution:" + this.state.IsMaxResolution);


          }}> FACING_MODES.ENVIRONMENT & MaxResolution</button>

          

        </div>
      );
  }

  handleTakePhoto (dataUri) {
    // Do stuff with the photo
    console.log('handleTakePhoto');

    
    console.log('dataUri:' + dataUri);
    console.log('DataUri:' + this.state.DataUri);

    this.setState({ DataUri: dataUri }, () => {
      console.log(this.state.DataUri, 'DataUri');
    });
    


  }
  handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo
    console.log('handleTakePhotoAnimationDone');

  }

  handleCameraError (error) {
    console.log('handleCameraError', error);
  }

  handleCameraStart (stream) {
    console.log('handleCameraStart');
  }

  handleCameraStop () {
    console.log('handleCameraStop');
  }

  showTakePhoto () {
    console.log("showTakePhoto");    
  }

  render() {
    const { product, user, xar } = this.props;
    
    const id = Number(this.props.match.params.id);
    let product_ready = product;
    let renderProduct = product_ready.product;
    let renderRecommended = product_ready.recommended;

    if (product && id !== 0) {
      product_ready = product;
      renderProduct = product_ready.product;
      renderRecommended = product_ready.recommended;
      
    }
    console.log("sendXARreq@render", this.state.sendXARreq);
    console.log("XARdone@render", this.state.XARdone);
    console.log("xar@render", xar);

    if (!this.state.sendXARreq && !this.state.XARdone) {
    
      if( xar.isShoot && xar.isRequest ){
      
        console.log("?????????????????????????????????????????????????");
        console.log("XARsubmit in render@this.props: ", this.props);
        
        
        
        //updateXAR(xar);
        this.XARsubmit(this.props.product.id, this.props.user.id);
        
      }

    }


        
    console.log("this.props@render: ", this.props);
    //console.log("product@render: ", product);
    

    //console.log("renderProduct@render: ", product["product"]);
    //console.log("renderRecommended@render: ", product["recommended"]);
    
    
    //console.log("renderProduct@render: ", renderProduct);
    //console.log("renderRecommended@render: ", renderRecommended);
    

    if (renderProduct) {
      

      //console.log("renderProduct.id: ", renderProduct.id);
      
      
    }
    console.log("xar@render: ", xar);
    console.log("isUpload@render : ", xar.isUpload);
    /*
    if(xar.isUpload.photoUpload && xar.isUpload.clothlistUpload){
       console.log("start getXAR(xar) in render ");
       getXAR(xar);
    }
*/
    if ((renderRecommended && renderProduct) ) {
      
      //console.log("renderProduct.id: ", renderProduct.id);
      //console.log("renderRecommended.id: ", renderRecommended.id);

 
      
     
    //<Icon name="universal access" style={styles.xar} size="big"/> {"Dressing Room"}
      

    //if(renderRecommended && renderProduct){
      //console.log("product.product.imageUrl: ", product.product.imageUrl);
      //console.log("renderRecommended.id: ", renderRecommended.id);
      console.log("picID@render: ", xar.picID);
      console.log("isUpdate@after getXAR: ", xar.isUpdate);
      //var update_flag = xar.isUpdate;
      //xar.isUpdate = false;

      return (
       
       
        <div className="show-product">

         

            { this.state.XARdone ? (
                            
                            <div className="product-wrapper ui raised very padded text segment" style={styles.showXAR}>
            
                              <Card centered style={styles.maincard}>
                                <Card.Content header={renderProduct.name} textAlign="center" />
                                <Card.Content >
                                  <Image src={xar.XARimg[0]["img"]} style={{
                                                                        height: "96%",
                                                                        
                                                                      }} />
                                  <Card.Description style={{ color: "white" }}>
                                    {product.product.description}!
                                  </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                  <Icon name="money bill alternate outline" />
                                  Money Icon
                                  <Button color="green" inverted floated="right">
                                    Submit
                                  </Button>
                                </Card.Content>
                              </Card>

                                <Image 
                                  src='' 
                                  label={{ as: 'a', color: 'red', corner: 'right', icon: 'heart' }} 
                                  fluid 
                                />



                            </div> 

                          )
                          :(
                            <div className="product-wrapper">


                                <div className="ui raised very padded text segment " style={styles.div} >
                                  <Grid divided="vertically">



                                    <Grid.Row mobile={1} tablet={2} computer={2}>

                                      <Grid.Column width={10} style={styles.grid}>
                                        <Image src={product.product.imageUrl} style={styles.imageGrid} />
                                      </Grid.Column>

                                      <Grid.Column width={6} >

                                        <Grid divided="vertically">
                                          <Grid.Row >

                                            <Grid.Column width={8}>
                                              <Header as="h2">{renderProduct.name}</Header>
                                              <Label.Group>
                                                <Container text as="h3"  >
                                                  {"$" + product.product.price}
                                                </Container>
                                                
                                                
                                                
                                                {user.isAdmin ? (
                                                  <Button
                                                    as={Link}
                                                    to={`/admin/products/edit/${renderProduct.id}`}
                                                    style={styles.button}
                                                    size="small"
                                                  >
                                                    <Icon name="edit" /> Edit
                                                  </Button>
                                                ) : (
                                                  ""
                                                )}
                                              </Label.Group>
                                              

                                            </Grid.Column>  

                                            <Grid.Column width={8}>
                                              <Button onClick={() => this.XARsubmit(renderProduct.id, user.id)} size="large" circular>
                                                  <Image src='/try-fitting-room.gif' size="large" />                                                
                                              </Button>
                                            </Grid.Column>    
                                            

                                          </Grid.Row>    
                                        </Grid>    
                                        <text as="h3" >
                                                  {product.product.color}
                                        </text>
                                        <text as="h3"  >
                                                  {product.product.description}
                                        </text>  
                                       
                                        {this.renderAddToCart()}      

                                      </Grid.Column>

                                      


                                    </Grid.Row>
                                  </Grid>
                                </div>

                                <div className="ui raised very padded text segment " style={styles.div}>
                                  <RecommendPage recommends={product.recommended.products} title='Recommendations'/>
                                  {/*<ReviewPage productId={id} />*/}
                                </div>

                              </div>     
                          )
            }



 
            
          

          {/*
          <div className="  "
               style={styles.div}> 
          <SimilarGrid products={product.recommended.products} category={product.product.categories}className="ui raised very padded text container segment "/> 
          </div>
          */}


        </div>
      );

    }else{ return (<div>loading</div>);}

  }
}

const styles = {
  div: {
    marginTop: 40,
    width:'72vw',
    zIndex: 3,
    minWidth: '380px'
  },
  grid: {
    display: 'table',
    justifyContent: 'center',
    minWidth: '330px'
  },
  
  divBar: {
    marginTop: 40,
    width: '23vw',
    right: '10%',
    position: 'absolute',
    zIndex: 2
  },

  button: {
    marginLeft: 5
  },
  xar: {

    color: 'rgb(255, 112, 112)',
    margin: '0 0 0 0'

  },
 maincard: {

    height: '1920px',
    width: '1080px'
   

  }, 
  imageGrid: {

    height: '100%',
    width: '100%'
   

  }, 
  showXAR: {
    marginTop: 10,
    width:'96vw'
  },
  card: {
    height: 'unset',
    minWidth: '290px'
  },
  cardContent: {
    minHeight: 'unset',
  }

};

export default connect(mapState, mapDispatch)(SingleProductPage);
