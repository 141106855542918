import React from "react";
import { Route, Switch } from "react-router-dom";
import { Home, UserOrderHistory, AccountPage, EditAccount } from "../components";

const UserRoutes = () => {
  return (

    
    <Switch>
      {console.log("UserRoutes")}

      {/* Routes placed here are only available after logging in */}
      <Route path="/:projectName/account/:id/info/edit" component={EditAccount} />
      <Route path="/:projectName/account/info" component={AccountPage} />
      <Route path="/:projectName/account/orderhistory" component={UserOrderHistory} />
      <Route path="/:projectName" component={Home} />
    </Switch>
  );
};
export default UserRoutes;
