import React, { Component } from 'react';

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import Message from './Message';
import Resizer from 'react-image-file-resizer';
 

import {
  Card, 
  Grid, 
  Segment, 
  Divider, 
  Header, 
  Button,
  Form
} from "semantic-ui-react";
import { Image as ImageUI } from "semantic-ui-react"; 

import {
  
  uploadPhotoXAR,
  updateDataXAR,
  selectSampleXAR,

  updateProject

} from "../../store";


import "./fileupload.css"


const mapState = state => ({
  
  suit : state.suit,
  xar  : state.xar,
  project: state.project

});

const mapDispatch = dispatch => {
  return {

    sendPhotoXAR    : (xar, formData) => dispatch(uploadPhotoXAR(xar, formData)),
    sendShootXAR    : (xar) => dispatch(updateDataXAR(xar)),
    pickSampleXAR    : (xar) => dispatch(selectSampleXAR(xar)), // can not use UseXXXX because 'use' is checking keywords of lints
    

    setProject      : (params) => dispatch(updateProject(params))
  };
};

class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {

      
                    userID: '',
                    sourceType: 'aichure-takephoto',
                    file: '',
                    filename: '',
                    oriFilename: 'Choose your photo',//'Choose file & Upload',
                    DataUri: '',
                    uploadedFile: '',
                    message: '',
                    isShoot: false,

                    portait: true,
                    isImageRotate: true,

                    sampleName: { 
                                  male   : [ "test43", "test32" ],
                                  female : [ "test05", "test24" ] 
                                }, 

                    sampleSize: {
                                  //male
                                  test43 : [2051, 3000],
                                  test32 : [2000, 3000],
                                  //female  
                                  test05 : [926, 1200],
                                  test24 : [722, 1080],

                                }            

                  }
    console.log("constructor@FileUpload")     
    window.scrollTo(0, 0)         
  }

  fileInputRef = React.createRef();

  handleImageResize(file){



  }

  onChange = e => {
  
      
      this.setState( {file: e.target.files[0]}, ()=>{
            console.log(" e.target : ",  e.target);
            console.log("file@onChange: ", this.state.file);

            this.setState( {oriFilename: this.state.file.name} );                                                    
            /*
            const img = new Image()
            img.onload = () =>   {
                console.log("@img.onload", this.width, this.height);
                console.log("@img.onload", img);

                var isPortrait = (img.height>img.width);
                var resize_width = isPortrait ? (img.width>1080) ? 1080 
                                                                 : img.width
                                              : (img.width>1920) ? 1920 
                                                                 : img.width                        

                var resize_height = isPortrait ? (img.height>1920) ? 1920 
                                                                   : img.height
                                               : (img.height>1080) ? 1080 
                                                                   : img.height                        
                console.log("resize_width", resize_width);
                console.log("resize_height", resize_height);


                Resizer.imageFileResizer(
                                                this.state.file,
                                                resize_width,
                                                resize_height,
                                                'JPEG',
                                                100,
                                                0,
                                                uri => {
                                                        this.setState( {file: uri} );      
                                                        console.log("uri@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ", uri)
                                                        console.log("uri@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ", this.state.file)
                                                        
                                                        },
                                                'blob'
                                            );
            };*/
            /*
            var reader = new FileReader();

            reader.onload = function(event) {
              console.log("@reader.onload");

              img.src = event.target.result;
            };

            reader.readAsDataURL(this.state.file);
            */
                                 
            this.handleFileUpload(this.state.isImageRotate, this.props.xar.id , this.state.sourceType);
       } );    
      
      this.setState( {isShoot: true});                     
      
  };


  handleFileUpload(isImageRotate, userID, sourceType) {


    console.log('handleFileUpload');
    console.log("this.props@handleFileUpload: ", this.props);
    console.log("this.state@handleFileUpload: ", this.state);


    
  

                   

    const { xar, sendShootXAR, project, setProject } = this.props;

    const linkAddr = this.props.location.state.linkAddr;
    console.log("this.props@FileUpload render: ",  this.props);
      
    

    const filename = this.getFileName(this.state.file.type, userID, sourceType, project.projectName);
    const filename_ext = filename[0] + '.' + filename[1];
    this.setState( {filename: filename_ext});
    this.onSubmit(this.state.file, filename_ext);
            
    var img = new Image();

    var _URL = window.URL || window.webkitURL;
    var objectUrl = _URL.createObjectURL(this.state.file);

    img.onload = () => {

      console.log("this @img.onload FileUpload", this)
      console.log("img @img.onload FileUpload", img)
      console.log("objectUrl @img.onload FileUpload", objectUrl)
      
      console.log("this.width @img.onload FileUpload", img.width, " ", img.height)

      

      xar.isShoot = this.state.isShoot;
      //xar.XARimg[0].img = '/' + filename_ext;
      xar.picID = filename[0];
      xar.picExt = filename[1];
      xar.uploadImgSize[0] = img.width;
      xar.uploadImgSize[1] = img.height;
      
      //xar.photoURI = dataUri;
      //send XAR photo
      sendShootXAR(xar);
      console.log('xar@handleFileUpload: ', xar);
      
      _URL.revokeObjectURL(objectUrl);

      //----
      var params = project;

      //params.onButton = true;      
      setProject(params);
      //Jenan testing
      //this.props.history.push(`/fitting/product/${product.product.id}`);
      //this.props.history.push(linkAddr);
         
      this.props.history.push({ pathname: linkAddr,
                                state: { linkAddr: linkAddr }
                             });
      //this.props.history.goBack()
    }

    img.src = objectUrl;


  }


  onSubmit(file, filename) {


      const { sendPhotoXAR, xar } = this.props;



      console.log("======================================================================");


      const formData = new FormData();
      console.log("file@onSubmit ",  file);
      console.log("filename@onSubmit ", filename)
      formData.append('file', file, filename);
      //formData.append('xar', xar);

      

        xar.picID = filename;
        console.log("xar @onSubmit : ", xar);
        sendPhotoXAR(xar, formData);


    }

  getFileName (fileType, userID, sourceType, projectName) {

    console.log('@getFileName')
    const prefix = (userID&&sourceType) ? userID+sourceType : projectName+'-fileupload';


    var options = {
      day: '2-digit',    //(e.g., 1)
      month: '2-digit',    //(e.g., Oct)
      year: '2-digit',   //(e.g., 2019)
      hour: '2-digit',   //(e.g., 02)
      minute: '2-digit', //(e.g., 02)          
      second: '2-digit',
      hour12:false// 
    };


    var now = new Date().toLocaleString('en-us', options,  {timeZone: 'Asia/Taipei', hour12:false}); 

    var photoStamp = now.replace(/\//g,'');
    photoStamp = photoStamp.replace(/:/g, '');
    photoStamp = photoStamp.replace(/,\s/g, '-');
    //const photoStamp = date.replace(/([^\d]*)(\d*)([^\w]*)/, '-');

    const extention = this.getFileExtention(fileType);

    console.log("filename@getFileName: ", prefix+"-"+photoStamp);
    //return `${prefix}-${photoStamp}.${extention}`;
    return [ prefix+"-"+photoStamp, extention];
    
  }

  getFileExtention (fileType) {
    // by default the extention is .png
    const types = ['image/png', 'image/jpeg', 'image/gif'];

    console.log('@getFileExtention')

    if (types.every(type => fileType !== type)) {
      
      this.setState( {message: `'${fileType}' is not a supported format`});
      return
    }

    let extention = 'png';

    if (fileType === 'image/jpeg') {
      extention = 'jpg';
    }
    return extention;
  }

  onSelect(filename) {


      const { xar, pickSampleXAR, sendShootXAR, project, setProject } = this.props;

      console.log("xar, this @onSelect : ", xar, this);
      
      const linkAddr = this.props.location.state.linkAddr;
      
            
            


      console.log("======================================================================");



        xar.picID = filename;
        console.log("filename", filename)
        console.log("this.state.sampleSize[filename]", this.state.sampleSize[filename])
        

        xar.uploadImgSize[0] = this.state.sampleSize[filename][0];
        xar.uploadImgSize[1] = this.state.sampleSize[filename][1];
        
        this.setState( {isShoot: true});        
        xar.isShoot = true;
        sendShootXAR(xar);

        var params = project;
        //params.onButton = true;      
        setProject(params);


        pickSampleXAR(xar);
        

        //this.props.history.push(linkAddr);
        this.props.history.push({ pathname: linkAddr,
                                  state: { linkAddr: linkAddr }
                               });


  }

  render(){

    const { suit } = this.props;

    const renderSampleName =  this.state.sampleName[suit.suit.gender]
    console.log("renderSampleName", renderSampleName)

    return (

      <div className="  ui raised very padded text segment container" style={styles.div}>
          

        <Segment placeholder>
          <Grid columns={2}  /*stackable textAlign='center'*/> 
           

            <Grid.Row verticalAlign='middle'>
              <Grid.Column>
                <Header>
                 
                    Choose your photo or pick one photo belowed.
                  
                </Header>

               
              </Grid.Column>

              <Grid.Column>
                  <div  style={styles.loginForm}>
                      { this.state.message ? <Message msg={ this.state.message } /> : null }
                      <Form>
                          <div className="custom-file mb-4" style={{paddingBottom:'10px'}}>
                              <Button
                                    style={styles.button}
                                    content={this.state.oriFilename}
                                    labelPosition="left"
                                    icon="file"
                                    onClick={() => this.fileInputRef.current.click()}
                                    className='btnIcon' htmlFor='customFile' /*custom-file-label */
                                    color='black'
                              />

                              <input
                                  ref={this.fileInputRef}
                                  type="file"
                                  className="custom-file-input"
                                  hidden
                                  id="customFile"
                                  onChange={this.onChange}
                              />
                              {/*
                              <label className='custom-file-label' htmlFor='customFile'>
                                {this.state.filename}
                              </label>*/}
                          </div>

                           {/*
                          
                            <Button 
                                  onClick={() => this.handleFileUpload(this.state.isImageRotate, this.props.xar.id , this.state.sourceType)}
                                  labelPosition="left"
                                  icon='upload'
                                  style={styles.button}
                                  content='Upload'    
                                  color='black'  
                            />
                            */}
                         

                      </Form>
                      {/* uploadedFile ? <div className="row mt-5">
                                         <div className="col-md-6 m-auto"></div>
                                         <h3 classNAme="text-center">{ uploadedFile.fileName }</h3>
                                         <img style={{ width: '100%' }} src={uploadedFile.filePath} alt="" />
                                       </div> 
                                     : null 
                      */}
                  </div>  
              </Grid.Column>
            </Grid.Row>

            <Divider horizontal>Or</Divider>
            <Grid.Row verticalAlign='middle'>
                <Card.Group itemsPerRow={2} centered className="products-wrapper">
                    

                    <Card centered style={styles.sampleCard} onClick={() => this.onSelect(renderSampleName[0])}>                                                   
                        <Card.Header  >
                            <Link to={``} style={styles.photoDescription}>{"Please choose full-body photo"}</Link>                      
                        </Card.Header>

                        <ImageUI 
                               src={"images/test/"+renderSampleName[0]+".jpg"} 
                               style={styles.sampleImg} 
                                
                        />
                                                      
                        <Card.Description style={{ color: "black" }}>
                            {/*suit_ready.description*/}
                        </Card.Description>                       
                      
                        

                    </Card>


                    <Card centered style={styles.sampleCard} onClick={() => this.onSelect(renderSampleName[1])}>                                                   
                        <Card.Header >
                            <Link to={``} style={styles.photoDescription}>{"please choose standing pose photo"}</Link>                      
                        </Card.Header>

                        <ImageUI 
                                src={"images/test/"+renderSampleName[1]+".jpg"} 
                                style={styles.sampleImg} 
                                
                        />
                                                      
                        <Card.Description style={{ color: "black" }}>
                            {/*suit_ready.description*/}
                        </Card.Description>                       
                      
                        

                    </Card>


                </Card.Group>
            </Grid.Row>  


          </Grid>
        </Segment>



          
          


              

              




         
      </div>
    );
  };


}

const styles = {
  container: {
    margin: 30
  },
  button: {
    
    width: '100%',
    height: '100%',
    alignContent: 'center',
    display: 'block ruby',

    paddingLeft: '33px !important',
    paddingRight: '10% !important',

    textAlign: 'initial',
    

  },
  icon: {
    
    alignContent: 'center',
    display: 'grid',
    width:'36px',
    height:'36px',
    padding: '21px'

  },
  sampleCard: {
    height: 'unset',
    color: '#e44299'
  },   
  loginForm:{

    justifyContent: 'center',
    display: 'flex'

  },
  div:{

    marginLeft: 'auto',
    marginRight: 'auto',

    padding: '1.5em',
    borderTop: '4px solid black',
    borderTopWidth: '4px',
    borderTopStyle: 'solid',
    borderTopColor: 'black'    

  },
  sampleImg: {
    transform: 'scale(1.0)'
  },
  
  photoDescription:{
    marginLeft: '5%',
    color: '#e44299'
  }
};


export default connect(mapState, mapDispatch)(FileUpload);
