export default `

    uniform vec3 keyColor;
    uniform float similarity;
    uniform float smoothness;
    varying vec2 vUv;
    uniform sampler2D map;
    uniform float spill;

// From https://github.com/libretro/glsl-shaders/blob/master/nnedi3/shaders/rgb-to-yuv.glsl
vec2 RGBtoUV(vec3 rgb) {
  return vec2(
    rgb.r * -0.169 + rgb.g * -0.331 + rgb.b *  0.5    + 0.5,
    rgb.r *  0.5   + rgb.g * -0.419 + rgb.b * -0.081  + 0.5
  );
}

vec3 KeyColorCorr(vec3 rgb){
  rgb.r = (rgb.r > 0.5) ? 1.0 : 0.0;
  rgb.g = (rgb.g > 0.5) ? 1.0 : 0.0;
  rgb.b = (rgb.b > 0.5) ? 1.0 : 0.0;
  return rgb;
}

vec4 ProcessChromaKey() {

  vec4 rgba = texture2D(map, vUv);

  rgba.a = 1.0;

  return rgba;

  vec4 sample_Texture = texture2D(map, vec2(0, 1));

  vec3 sample_KeyColor = KeyColorCorr(sample_Texture.rgb);  
  
  if( all( equal( sample_Texture.rgb, vec3(0,0,0) ) ) ){
    sample_KeyColor = keyColor;
  }
  
  float chromaDist = distance(RGBtoUV(rgba.rgb), RGBtoUV(sample_KeyColor.rgb));
  //float chromaDist = distance(rgba.rgb, sample_KeyColor.rgb);

  float baseMask = chromaDist - similarity;
  float fullMask = pow(clamp(baseMask / smoothness, 0., 1.), 1.5);
  rgba.a = min(fullMask, rgba.a);

  float spillVal = pow(clamp(baseMask / spill, 0., 1.), 1.5);
  float desat = clamp(rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722, 0., 1.);
  rgba.rgb = mix(vec3(desat, desat, desat), rgba.rgb, spillVal);

  
  if(vUv.x < 0.2){
      rgba = texture2D(map, vUv);
  }
  return rgba;
}

void main() {
  gl_FragColor = ProcessChromaKey();
}

/*void main( )  {
    
        vec4 videoColor = texture2D(map, vUv);

        float Y1 = 0.299 * keyColor.r + 0.587 * keyColor.g + 0.114 * keyColor.b;
        float Cr1 = keyColor.r - Y1;
        float Cb1 = keyColor.b - Y1;
        
        float Y2 = 0.299 * videoColor.r + 0.587 * videoColor.g + 0.114 * videoColor.b;
        float Cr2 = videoColor.r - Y2; 
        float Cb2 = videoColor.b - Y2; 
        
        float blend = smoothstep(similarity, similarity + smoothness, distance(vec2(Cr2, Cb2), vec2(Cr1, Cb1)));
        gl_FragColor = vec4(videoColor.rgb, videoColor.a * blend); 
        
}*/
`
