import React, { Component } from 'react';
import { connect } from "react-redux";
import { Input } from 'semantic-ui-react'


import { drawArbitraryQuadImage, FILL_METHOD } from 'canvas-arbitrary-quads';
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo';


import {
  Button,
  Form
} from "semantic-ui-react";

 
import { 
          //updateParams, 
          updateProject,
          uploadPhotoProject,
 
          updateDataXAR,
          doRTCAR 
       
       } from "../../store";

import * as THREE from 'three'  

import fragmentShader from './fragmentShader'
import vertexShader from './vertexShader'

import vdoFragmentShader from './vdoFragmentShader'
import vdoVertexShader from './vdoVertexShader'

//import RTCMesh from "../../lib-rtc";
require('react-rtc-real/assets/index.css');



const mapState = state => ({
  
   //baselayout: state.baselayout
   suit : state.suit,
   xar  : state.xar,
   product: state.product,
  
   project: state.project,
   webrtc: state.webrtc

});

const mapDispatch = dispatch => {
  return {

    //setParams: (params) => dispatch(updateParams(params))
    setProject : (params) => dispatch(updateProject(params)),
    sendPhotoProject: (params, formData) => dispatch(uploadPhotoProject(params, formData)),
    
    updateXAR: (xar) => dispatch(updateDataXAR(xar)),    
    requestRTCAR: (xar) => dispatch(doRTCAR(xar))

  };
};

class RTCfittingWebgl extends Component {
  constructor(props) {

  	console.log("RTCfittingWebgl =============================================")

    super(props);

    this.state = {

      request : null,
      hangingGet : null,
      localName : '',
      server : '',
      myId : -1,
      otherPeers : {},
      messageCounter : 0,
      pc : '',    
      pcConfig : {"iceServers": [{"url": "stun:stun.l.google.com:19302"}]},
      pcOptions : {
                  optional: [
                                {DtlsSrtpKeyAgreement: true}
                            ]
                  },
      mediaConstraints : {'mandatory': {
                                'OfferToReceiveAudio': true,
                                'OfferToReceiveVideo': true 
                          }},
     
      RTCPeerConnection : '',
      RTCSessionDescription : '',
      RTCIceCandidate : '',
      //getUserMedia : '',
      //URL : '',
      peer_id: '',



      ARfittigTimeOut: false,
      onHandleTakePhoto: false,

      /*camera*/
      dataUri: '',
      idealFacingMode: FACING_MODES.USER,
      isMaxResolution: false,
      DataUri: '',
      isFullscreen: false,
      imageNumber: 0,
      modifyDataUri:'',
      camPosSel: 0,  
      camBtnPosSel: 0,
      countdownPosSel: 1,
      isImageRotate: 1,//0 horizontal, 1, +90, -1:-90
      isShoot: false,
      isCountDown: false,
      timerCount: 0,
      timerRunning: false,
      sourceType: 'aichureMirror-takephoto',
      isCameraOn: false, 

      scalingFactor: 1.25

      
    };

    this.localStream = null;
    this.localStream_clone = null;
    this.remoteComing = false;

    //this.remoteVideoCanvas = React.createRef();


    //this.cameraPhoto = null;
    //this.videoRef = React.createRef();
    this.manipulateCanva = React.createRef();   
    this.videoSnapShot = [];

    this.canvasArray = [];
    this.drawCnt = 0;
    this.sizeCanvasArray = 30;

    for (var i = 0; i < this.sizeCanvasArray; ++i) {

        this.canvasArray[i] = new THREE.WebGLRenderTarget(1920, 1080);
        
    }

    this.mergeRef = React.createRef();
    this.mergeCnt = -21;
    /*
    this.merge = time => {
      //
      //console.log("merge", this.mergeCnt, this.mergeCnt%this.sizeCanvasArray);
      if(this.mergeCnt >= 0){
        const canvas = this.canvasArray[this.mergeCnt%this.sizeCanvasArray];
        const ctx = this.manipulateCanva.current.getContext('2d');
        ctx.globalCompositeOperation = "copy"
        ctx.drawImage(this.remoteVideoCanvas.current, 0, 0, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
        const frame = ctx.getImageData(0, 0, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
        const length = frame.data.length;

        for (let i = 0; i < length; i += 4) {
          const red = frame.data[i + 0];
          const green = frame.data[i + 1];
          const blue = frame.data[i + 2];
          if (green > 175 && red < 100 && blue < 100) {
            frame.data[i + 3] = 0;
          }
        }

        ctx.putImageData(frame, 0, 0);
        ctx.globalCompositeOperation = "destination-over"
        ctx.drawImage(canvas, 0, 0, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
      }

      this.mergeRef.current = requestAnimationFrame(this.merge);
    }
    */
    this.ArbitraryQuadSrc = [];
    this.ArbitraryQuadDst = [];

    

    //window.onbeforeunload = this.disconnect();


    // create a video texture
    //const videoElement = document.getElementById('remoteVideo');


    //------------------------ webgl ------------------------------
    console.log("vertexShader", vertexShader)
    console.log("fragmentShader", vertexShader)
    /*
    this.renderer = null;
  	this.camera = null;
  	this.scene = null;
  	this.plane = null;
  	this.webcamVideo = null;
  	this.webcamTexture = null;
  	*/
    //--------------------------------------------------------------

  }
  
  doSomethingBeforeUnload = () => {
    console.log("RTCfittingWebgl close")
        this.disconnect();
  }
  
  setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.doSomethingBeforeUnload();
        });
  };

  componentWillUnmount() {
    document.getElementsByName('topHeader')[0].style.display = null;
    this.disconnect();
  }

  componentDidMount() {

      //add local stream
    
      const { xar, project, setProject } = this.props;

      var params = project;

      params.onButton = false;
      setProject(params);

      console.log("project@RTCfitting", project)
      
      this.setState({ camPosSel: params.camPosSel });
      this.setState({ camBtnPosSel: params.camBtnPosSel });
      this.setState({ countdownPosSel: params.countdownPosSel });
     
      this.setState({ARfittigTimeOut: false})

      this.setState({ RTCSessionDescription : (window.mozRTCSessionDescription || window.RTCSessionDescription) });
      this.setState({ RTCIceCandidate : (window.mozRTCIceCandidate || window.RTCIceCandidate) });
      

      //end of add local stream
  

      //this.cameraPhoto = new CameraPhoto(this.videoRef.current);




      

    //-------------------------------
	this.startWebGL();
  
  }

  async startWebGL() {
  	//this.camera = new THREE.PerspectiveCamera(45, 16/9, 0.001, 1000)
    //this.camera.position.set(0, 0, 1)

    const isRotate = this.props.project.camPosSel;
    
    var remoteVideoWidth = (isRotate==90 || isRotate==-90 || isRotate==270) ? 1080 : 1920;
    var remoteVideoHeight = (isRotate==90 || isRotate==-90 || isRotate==270) ? 1920 : 1080;
    
    this.camera = new THREE.OrthographicCamera( 0, remoteVideoWidth, remoteVideoHeight, 0, 0, 1 );
    this.camera.zoom = 1;

    /*=============delay==================*/

    this.delayCamera = new THREE.OrthographicCamera( 0, 1920, 1080, 0, 0, 1 );
    this.delayCamera.zoom = 1;

    this.delayScene = new THREE.Scene()
    const geometryDelayVideo = new THREE.PlaneGeometry( 1920, 1080 );
    this.webcamDelayVideo = document.createElement('video')
    this.localVideoTexture = new THREE.VideoTexture(this.webcamDelayVideo)
    this.localVideoTexture.minFilter = THREE.LinearFilter
    this.localVideoTexture.maxFilter = THREE.LinearFilter

    const planeDelayPivot = new THREE.Object3D();
    this.delayScene.add(planeDelayPivot);
    
    this.vdoDelayShaderMaterial = new THREE.ShaderMaterial({
          transparent: true,
          uniforms: {
            map: { value: this.localVideoTexture },
            keyColor: { value: [0.0, 1.0, 0.0] },
            similarity: { value: 0.0 },
            smoothness: { value: 0.0 },
            spill: { value : 0.0 }
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentShader
    })
    this.vdoDelayShaderMaterial.side = THREE.DoubleSide;
    this.delayMesh = new THREE.Mesh( geometryDelayVideo, this.vdoDelayShaderMaterial );
    planeDelayPivot.add(this.delayMesh);
    
    this.delayMesh.position.set(0, 0, 0);
    planeDelayPivot.position.set(1920/2, 1080/2, 0);

    /*=============merge==================*/
    
	  this.scene = new THREE.Scene();

    const scalingFactor = this.state.scalingFactor;
    console.log("Camera @ ", this.camera);
    
    //test
    const x = 0, y = 0;

    const geometryVideo = new THREE.PlaneGeometry( 1920, 1080 );

    var positionAttrVideo = geometryVideo.attributes.position;

    var transformDelX = 0;
    var transformDelY = 0;


    if(isRotate===90){

      transformDelX = 120;//116;
      transformDelY = 92;//93;

      positionAttrVideo.setXYZ( 0, -540*scalingFactor, -960, 0 );
      positionAttrVideo.setXYZ( 1, -540*scalingFactor,  960 + 1920 * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 2,  540*scalingFactor, -960, 0 );
      positionAttrVideo.setXYZ( 3,  540*scalingFactor,  960 + 1920 * (scalingFactor-1.0), 0 );


    }else if(isRotate===270 || isRotate===-90){

      transformDelX = 120;//116;
      transformDelY = 92;//93;

      positionAttrVideo.setXYZ( 0,  540*scalingFactor,  960 + 1920 * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 1,  540*scalingFactor, -960, 0 );
      positionAttrVideo.setXYZ( 2, -540*scalingFactor,  960 + 1920 * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 3, -540*scalingFactor, -960, 0 );


    }else if(isRotate===180){
     transformDelX = 80;//77;
     transformDelY = 52;//53;

      positionAttrVideo.setXYZ( 0, -960*scalingFactor, -540, 0 );
      positionAttrVideo.setXYZ( 1,  960*scalingFactor, -540, 0 );
      positionAttrVideo.setXYZ( 2, -960*scalingFactor,  540 + 1080 * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 3,  960*scalingFactor,  540 + 1080 * (scalingFactor-1.0), 0 );
    }else{

     transformDelX = 80;//77;
     transformDelY = 52;//53;
      positionAttrVideo.setXYZ( 0, -960*scalingFactor,  540 + 1080 * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 1,  960*scalingFactor,  540 + 1080 * (scalingFactor-1.0), 0 );
      positionAttrVideo.setXYZ( 2, -960*scalingFactor, -540, 0 );
      positionAttrVideo.setXYZ( 3,  960*scalingFactor, -540, 0 );

       /*positionAttrVideo.setXYZ( 0, -960 +transformDelX,  540-transformDelY, 0 );
       positionAttrVideo.setXYZ( 1, 960-transformDelX, 540-transformDelY, 0 );*/
    }


    console.log("geometryTest @", positionAttrVideo);

    //----------------------

    //this.webcamVideoRef = React.createRef();   
    //this.webcamVideo = document.createElement('video')
    //this.webcamVideo = document.getElementById( 'webcamVideo' );

    this.remoteVideo = document.createElement('video')
    
    const planePivot = new THREE.Object3D();
    this.scene.add(planePivot);
    

    //const geometry = new THREE.PlaneGeometry( 1920, 1080 );
    //console.log("geometry @", geometry.attributes.uv);
  	
    this.vdoShaderMaterial = new THREE.ShaderMaterial({
      transparent: true,
      uniforms: {
        map: { value: this.localVideoTexture },
        keyColor: { value: [0.0, 1.0, 0.0] },
        videoWidth: { value: remoteVideoWidth*1.0 },
        videoHeight: { value: remoteVideoHeight*1.0 },
        transformDelX: { value: transformDelX*1.0 },
        transformDelY: { value: transformDelY*1.0 },
        rotateAngle: { value: isRotate*1.0 }
      },
      vertexShader: vdoVertexShader,
      fragmentShader: vdoFragmentShader
    })
    this.vdoShaderMaterial.side = THREE.DoubleSide;
    this.mesh = new THREE.Mesh( geometryVideo, this.vdoShaderMaterial );

    //const material = new THREE.MeshBasicMaterial( { map: texture, side: THREE.DoubleSide } );
    //const mesh = new THREE.Mesh( geometryVideo, material );
  	
    //mesh.lookAt( this.camera.position );
    
    planePivot.add(this.mesh);
    
    this.mesh.position.set(0, 0, 0);
    planePivot.position.set(remoteVideoWidth/2, remoteVideoHeight/2, 0);
    //this.scene.add( mesh );

   

    var webcamCanvas = document.getElementById("webcamCanvas")
    webcamCanvas.width = remoteVideoWidth
    webcamCanvas.height = remoteVideoHeight
    this.renderer = new THREE.WebGLRenderer( { canvas: webcamCanvas, antialias: true } );
  	//this.renderer.setPixelRatio( window.devicePixelRatio );
  	this.renderer.setSize( remoteVideoWidth, remoteVideoHeight, false );
  	

  	if ( navigator.mediaDevices && navigator.mediaDevices.getUserMedia ) {

  		const constraints = { video: { width: 1920, height: 1080, facingMode: 'user' } };
  		
  		this.stream = await navigator.mediaDevices.getUserMedia( constraints );

  		//this.webcamVideo.srcObject = this.stream;
  		//this.webcamVideo.play();

      this.manipulateCanva.current.width = 1920;
      this.manipulateCanva.current.height = 1080;

      //this.webcamDelayVideo.srcObject = this.manipulateCanva.current.captureStream(30);
      this.webcamDelayVideo.srcObject = this.stream;
      this.webcamDelayVideo.play();
      
      this.localStream = this.stream;
      this.localStream_clone = this.stream.clone();
      
      console.log("stream@startWebGL", this.stream)
      console.log("localStream@startWebGL", this.localStream)
      console.log("localStream_clone@startWebGL", this.localStream_clone)

  	} else {

  		console.error( 'MediaDevices interface not available.' );

  	}

    this.remoteTexture = new THREE.VideoTexture(this.remoteVideo)
    this.remoteTexture.minFilter = THREE.LinearFilter
    this.remoteTexture.maxFilter = THREE.LinearFilter

      
    if(true){//this.remoteComing){  

        this.shaderMaterial = new THREE.ShaderMaterial({
          transparent: true,
          uniforms: {
            map: { value: this.remoteTexture },
            keyColor: { value: [0.0, 1.0, 0.0] },
            similarity: { value: 0.4 },
            smoothness: { value: 0.1 },
            spill: { value : 0.1 }
          },
          vertexShader: vertexShader,
          fragmentShader: fragmentShader
        })

    }

    const geometryRemote = new THREE.PlaneGeometry( remoteVideoWidth*this.state.scalingFactor, remoteVideoHeight*this.state.scalingFactor );
    //var positionAttrRemote = geometryVideo.attributes.position;
    
    var positionAttrRemote = geometryRemote.attributes.position;

    if(isRotate===90 || isRotate===-90 || isRotate === 270){

      positionAttrRemote.setXYZ( 0, -540*scalingFactor,  960 + 1920 * (scalingFactor-1.0), 0 );
      positionAttrRemote.setXYZ( 1,  540*scalingFactor,  960 + 1920 * (scalingFactor-1.0), 0 );
      positionAttrRemote.setXYZ( 2, -540*scalingFactor, -960, 0 );
      positionAttrRemote.setXYZ( 3,  540*scalingFactor, -960, 0 );

    }else{

      positionAttrRemote.setXYZ( 0, -960*scalingFactor,  540 + 1080 * (scalingFactor-1.0), 0 );
      positionAttrRemote.setXYZ( 1,  960*scalingFactor,  540 + 1080 * (scalingFactor-1.0), 0 );
      positionAttrRemote.setXYZ( 2, -960*scalingFactor, -540, 0 );
      positionAttrRemote.setXYZ( 3,  960*scalingFactor, -540, 0 );
    }

    //positionAttrRemote.setXYZ( 0, -1200, 600, 0 );
    //positionAttrRemote.setXYZ( 1, 1200, 600, 0 );

    
    this.shaderMaterial.side = THREE.DoubleSide;
    const meshRemote = new THREE.Mesh( geometryRemote, this.shaderMaterial );
    
    const planePivotRemote = new THREE.Object3D();
    this.scene.add(planePivotRemote);
    
    planePivotRemote.add(meshRemote);
    
    meshRemote.position.set(0, 0, 0);
    planePivotRemote.position.set(remoteVideoWidth/2, remoteVideoHeight/2, 0);
    //this.plane = new THREE.Mesh(new THREE.PlaneGeometry(1920, 1080), this.shaderMaterial)
    //this.plane.lookAt( this.camera.position );
    //this.scene.add(this.plane)
     
  	this.animate = time => {

        if(document.getElementById("webcamCanvas") !== null){

          //if (resizeRendererToDisplaySize(this.renderer)) {
          //  this.camera.right = canvas.width;
          //  this.camera.bottom = canvas.height;
          //  this.camera.updateProjectionMatrix();
          //}
        	

          requestAnimationFrame(this.animate)

          //this.renderer.render( this.scene, this.camera );
          
          if(this.mergeCnt >= 0){
            this.mesh.material.uniforms.map.value = this.canvasArray[this.mergeCnt%this.sizeCanvasArray].texture;
            this.renderer.setRenderTarget(null);
            this.renderer.render( this.scene, this.camera );

          }

          if(this.state.onHandleTakePhoto){

            this.manipulateCanva.current.getContext('2d').drawImage(document.getElementById("webcamCanvas"), 0, 0, this.manipulateCanva.current.width, this.manipulateCanva.current.height);
            this.handleTakePhoto();
          }
          
          this.renderer.setRenderTarget(this.canvasArray[this.drawCnt%this.sizeCanvasArray]);
          this.renderer.render( this.delayScene, this.delayCamera);

          
          this.drawCnt = this.drawCnt+1;
          this.mergeCnt = this.mergeCnt+1;
        }
          
    }

    
  
    this.animate()
  	
    //this.connect();
  }

  resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  /**
   * Creates a plane to display webcam stream
   */
  createWebCamPlane (width = 5, height = 4){
    const geometry = new THREE.PlaneGeometry(width, height)
    return new THREE.Mesh(geometry, this.shaderMaterial)
  }


  /*
  webcamAnimate () {
    if (this.webcamVideo.readyState === this.webcamVideo.HAVE_ENOUGH_DATA) {
      this.canvasCtx.drawImage(this.webcamCanvas, 0, 0, 1920, 1080)
      this.webcamTexture.needsUpdate = true
      
    }
  }
  */
  componentDidUpdate(prevProps, prevState) {

      console.log("Props@RTCfitting componentDidUpdate", this.props.project.ARfittigTimeOut)
      console.log("prevProps@RTCfitting componentDidUpdate", prevProps.project.ARfittigTimeOut)

      if (this.props.project.ARfittigTimeOut===true && this.state.ARfittigTimeOut===false) {

          this.setState({ARfittigTimeOut: this.props.project.ARfittigTimeOut})

          
          var tmp = this.props.project
          tmp.ARfittigTimeOut = false;
          this.props.setProject(tmp);
               

          this.changeClothes();

      }

      if (this.props.project.onHandleTakePhoto===true && this.state.onHandleTakePhoto===false) {

          this.setState({onHandleTakePhoto: this.props.project.onHandleTakePhoto})
          

      }

      if (this.props.project.onHandleTakePhoto===false && this.state.onHandleTakePhoto===true){

          this.setState({onHandleTakePhoto: this.props.project.onHandleTakePhoto})
      }


  }



  XARclothesProducts() {
    console.log("============================= clothlist ===============================")
    
    //if mirror mode, mirror side has no product informoation, need get it from webserver,
    const { xar, suit, product } = this.props;
    console.log("this.props @XARclothesProducts: ", this.props)
    console.log("suit @XARclothesProducts: ", suit)
    console.log("suit.suit @XARclothesProducts: ", suit.suit)


    

    const video_filename = xar.picID + '_' + suit.suit.suitID;
    

    const clothlist = suit.suit.suitClothesID;
    const shoes = suit.suit.shoes;

    console.log("clothlist: ", clothlist)

    xar.clothlist = clothlist;
    xar.shoes = shoes;
    xar.suitID = suit.suit.suitID;
    xar.clothesID = "asia2020_f_001_dress_long_red";//product.product.internal_name;
    xar.video_filename = video_filename;
    if(suit.suit.gender === 'male'){
      xar.human.gender = 1;  
    }else
    {
      xar.human.gender = 0;
    }
    

  }



  async createPeerConnection(peer_id) {
        try {
            console.log("@createPeerConnection this: ", this);
            
            this.setState({ pc : (new RTCPeerConnection(this.state.pcConfig, this.state.pcOptions)) }, ()=> {

                                    console.log("createPeerConnection this: ", this);

                                    this.state.pc.onicecandidate = (function(event) {
                                        if (event.candidate) {
                                            var candidate = {
                                                sdpMLineIndex: event.candidate.sdpMLineIndex,
                                                sdpMid: event.candidate.sdpMid,
                                                candidate: event.candidate.candidate
                                            };
                                            this.sendToPeer(JSON.stringify(candidate));
                                        } else {
                                          console.log("End of candidates.");
                                        }
                                    }).bind(this);
                                    this.state.pc.onconnecting = this.onSessionConnecting;
                                    this.state.pc.onopen = this.onSessionOpened;
                                    this.state.pc.onaddstream = this.onRemoteStreamAdded.bind(this);
                                    this.state.pc.onremovestream = this.onRemoteStreamRemoved;
                                    console.log("inside setState of pc: ", this.state.pc)


                                    console.log("Created RTCPeerConnnection with config: " + JSON.stringify(this.state.pcConfig));
                                    this.localStream_clone.getTracks().forEach(track => {
                                                                                            this.state.pc.addTrack(track, this.localStream)
                                                                                            
                                                                                        });
                                    console.log('Added local stream to pc: ', this.state.pc);
                                    console.log('this: ', this);


                                    
                                    //ios works but the original source is /4  
                                    const [sender] = this.state.pc.getSenders();
                                    var height = sender.track.getSettings().height/4;
                                    sender.track.applyConstraints({height});
                                    //---------------------------------------------

                                    this.state.pc.ondatachannel = (event) => {
                                      const dataChannel = event.channel
                                      dataChannel.onerror = (error) => { console.log("Data Channel Error:", error); };

                                      dataChannel.onmessage = (event) => { console.log("Got Data Channel Message:", event.data); };

                                      dataChannel.onopen = () => { /*dataChannel.send("Hello World!");*/ };

                                      dataChannel.onclose = () => { console.log("The Data Channel is Closed"); };

                                    };

                                    // Send a simple text message when we click the button
                                    //const message = 'addTrack localStream done';
                                    //dataChannel.send(message);
                                   

                                    /* Append new messages to the box of incoming messages
                                    dataChannel.addEventListener('message', event => {
                                        const message = event.data;
                                        console.log("incoming message", message)
                                    });*/

                                  }
            );




        console.log("@createPeerConnection end")
      
      
      
      
      //end of add local data
        } 
        catch (e) {
            console.log("Failed to create PeerConnection with ", e.message);
        }
    }
        


    onRemoteStreamAdded(event) {
      console.log("onRemoteStreamAdded this: ", this)
      //this.remoteVideoCanvas.current.srcObject  = event.stream;

      //------
      /*const videoTrack = event.stream.clone().getVideoTracks()[0];

      const trackProcessor = new MediaStreamTrackProcessor({ track: videoTrack });
      const trackGenerator = new MediaStreamTrackGenerator({ kind: 'video' });

      //console.log("videoTrack", videoTrack)
      //console.log("trackProcessor", trackProcessor)
      //console.log("trackGenerator", trackGenerator)

      const transformer = new TransformStream({
          async transform(videoFrame, controller) {
            //console.log("videoFrame", videoFrame)
            //const barcodes = await detectBarcodes(videoFrame);
            //const newFrame = highlightBarcodes(videoFrame, barcodes);
            //console.log("frame timestamp", videoFrame.timestamp)
            videoFrame.close();
            //controller.enqueue(newFrame);
          },
      });

      trackProcessor.readable.pipeThrough(transformer).pipeTo(trackGenerator.writable);
*/
      //------     

      //this.remoteVideoCanvas.current.play();

      this.remoteComing = true;
      this.remoteVideo.srcObject = event.stream;
      this.remoteVideo.play();
  

    }

    sld_success_cb() {
    }

    sld_failure_cb() {
      console.log("setLocalDescription failed");
    }

    aic_success_cb() {
    }

    aic_failure_cb() {
      console.log("addIceCandidate failed");
    }

    handleSessionDescription(sessionDescription){

      console.log("this: ", this);
      
      console.log("Create answer:", sessionDescription);
      //test for high resolution
      var arr = sessionDescription.sdp.split('\r\n');
      arr.forEach((str, i) => {
          if (/^a=fmtp:\d*/.test(str)) {
            arr[i] = str + ';x-google-max-bitrate=10000;x-google-min-bitrate=10000;x-google-start-bitrate=10000';
          } else if (/^a=mid:(1|video)/.test(str)) {
            arr[i] += '\r\nb=AS:10000';
          }
      });
      //sessionDescription.sdp = arr.join('\r\n')
      
      //-------------
      
      this.state.pc.setLocalDescription(sessionDescription, this.sld_success_cb, this.sld_failure_cb);
      var data = JSON.stringify(sessionDescription);
      this.sendToPeer(data);


      console.log("@handleSessionDescription peer_id: ", this.state.peer_id)

    }
    
    handlePeerMessage(peer_id, data) {

      console.log("@handlePeerMessage this: ", this)
      console.log("@handlePeerMessage peer_id: ", this.state.peer_id)
      this.setState({ messageCounter : (this.state.messageCounter++) });

        
        var str = "Message from '" + this.state.otherPeers[this.state.peer_id] + ":" + data;
        this.trace(str);
        
    

    
    
        var dataJson = JSON.parse(data);
        console.log("@handlePeerMessage received ", dataJson);
        if (data.search("offer") != -1) {  //connect command

            console.log("@handlePeerMessage createPeerConnection: offer pc: ", this.state.pc)
            this.createPeerConnection(this.state.peer_id)//, () => {

            console.log("@handlePeerMessage after  createPeerConnection this: ", this)
                                  
            this.state.pc.setRemoteDescription(new this.state.RTCSessionDescription(dataJson), this.onRemoteSdpSucces, this.onRemoteSdpError)//, ()=>{

            console.log("new session: pc", this.state.pc)

            this.state.pc.createAnswer(this.handleSessionDescription.bind(this),
                                       function(error) { // error
                                                          console.log("Create answer error:", error);
                                                        }, 
                                       this.state.mediaConstraints); 

                                                                    


                                                                           
            console.log("@handlePeerMessage in createPeerConnection end")

    
        }
        else {
            console.log("Adding ICE candiate ", dataJson);
            var candidate = new this.state.RTCIceCandidate({sdpMLineIndex: dataJson.sdpMLineIndex, candidate: dataJson.candidate});
            this.state.pc.addIceCandidate(candidate, this.aic_success_cb, this.aic_failure_cb);
        }
    }    
    
    trace(txt) {
        var elem = document.getElementById("debug");
        elem.innerHTML += txt + "<br>";
    }
    
    handleServerNotification(data) {
        this.trace("Server notification: " + data);
        var parsed = data.split(',');
        if (parseInt(parsed[2]) != 0)
            this.state.otherPeers[parseInt(parsed[1])] = parsed[0];
    }
    
    parseIntHeader(r, name) {
        var val = r.getResponseHeader(name);
        return val != null && val.length ? parseInt(val) : -1;
    }
    
    hangingGetCallback() {
        try {

          console.log("@hangingGetCallback this.state.hangingGet: ", this.state.hangingGet)

            if (this.state.hangingGet.readyState != 4){
                console.log("this.state.hangingGet.readyState != 4", this.state.hangingGet.readyState)
                return;
            }


            if (this.state.hangingGet.status != 200) {
                console.log("this.state.hangingGet.status != 200")
                this.trace("server error: " + this.state.hangingGet.statusText);
                this.disconnect();

            } else {

                this.setState({ peer_id : this.parseIntHeader(this.state.hangingGet, "Pragma") });
                
                console.log("Message from:", this.state.peer_id, ':', this.state.hangingGet.responseText);
                if (this.state.peer_id == this.state.myId) {
                  this.handleServerNotification(this.state.hangingGet.responseText);
                } else {
                  this.trace("handlePeerMessage: " + this.state.hangingGet.responseText);
                  this.handlePeerMessage(this.state.peer_id, this.state.hangingGet.responseText);
                }
            }

            if (this.state.hangingGet) {
                console.log("if(this.state.hangingGet)");
                this.state.hangingGet.abort();
                this.setState({ hangingGet : null });
                     
              
            }

            if (this.state.myId != -1)
                window.setTimeout(this.startHangingGet(), 0);
      } catch (e) {
          this.trace("Hanging get error: " + e);
      }
    }
    
    startHangingGet() {
        try {
            this.trace("startHangingGet");
            this.setState({ hangingGet : (new XMLHttpRequest()) }, ()=> {
                                    this.state.hangingGet.onreadystatechange = this.hangingGetCallback.bind(this);
                                    this.state.hangingGet.ontimeout = this.onHangingGetTimeout.bind(this);
                                    console.log("startHangingGet myId: ", this.state.server + "/wait?peer_id=" + this.state.myId)
                                    this.state.hangingGet.open("GET", this.state.server + "/wait?peer_id=" + this.state.myId, true);
                                    this.state.hangingGet.send();  

            });
            
           
        } catch (e) {
            this.trace("@startHangingGet error" + e);
        }
    }
    
    onHangingGetTimeout() {

      try {
        this.trace("hanging get timeout. issuing again.");
        this.state.hangingGet.abort();
        this.setState({ hangingGet : null });

        if (this.state.myId != -1)
            window.setTimeout(this.startHangingGet(), 0);

      } catch (e) {
            this.trace("@onHangingGetTimeout error" + e);
      }    
    }
    
    signInCallback() {
        try {
            const { xar, updateXAR, requestRTCAR, project } = this.props;

            console.log("@signInCallback this.state.request: ", this.state.request)
          
            if (this.state.request.readyState == 4) {
                if (this.state.request.status == 200) {


                    

                    var peers = this.state.request.responseText.split("\n");
                    console.log("peers", peers);

                    this.setState({ myId : (parseInt(peers[0].split(',')[1])) }, ()=>{ 
                                                                                         this.XARclothesProducts();
                                                                                         xar.peerID = this.state.myId;
                                                                                         console.log("peerID ", xar.peerID)
                                                                                         updateXAR(xar);
                                                                                     });
                    
                    this.trace("My id: " + this.state.myId);
                    for (var i = 1; i < peers.length; ++i) {
                        if (peers[i].length > 0) {
                            this.trace("Peer " + i + ": " + peers[i]);
                            var parsed = peers[i].split(',');
                            this.state.otherPeers[parseInt(parsed[1])] = parsed[0];
                        }
                    }
                    console.log("@signInCallback startHangingGet request", this.state.request)

                    if(project.onMirror && project.userId===0){
                        //Mirror side, remote side has sent json file. mirror side has no ides which clothes clicked
                        
                        var tmp = xar;
                        tmp.camOrientation = project.camPosSel;
                        requestRTCAR(tmp);  

                    }else{
                        //not ARmorror mode, that is, not remote side or mirror side, either,
                        //such as phone mode, and need send request after rtc connectting
                        var tmp = xar;
                        tmp.camOrientation = project.camPosSel;
                        requestRTCAR(tmp);  
                    }


                    this.startHangingGet();
                    this.setState({ request : null });
                    
                }
            }
        } catch (e) {
            this.trace("signInCallback error: " + e);
            this.trace("signInCallback error: " + e.description);
        }
    }
    
    signIn() {
      try {

          console.log("@signIn")
          

          this.setState({ request : (new XMLHttpRequest()) }, () => {
                          console.log("request@signin", this.state.request);
                          this.state.request.onreadystatechange = this.signInCallback.bind(this);
                          this.state.request.open("GET", this.state.server + "/sign_in?" + this.state.localName, true);
                          this.state.request.send();

          });
       
      } catch (e) {
          this.trace("@signIn error: " + e);
      }
    }

    dummy() {
    }
    
    sendToPeer(data) {
      try {
          console.log("@sendToPeer: ", this.state.peer_id," Send ", data);
          if (this.state.myId == -1) {
              alert("Not connected");
              return;
          }
          if (this.state.peer_id == this.state.myId) {
              alert("Can't send a message to oneself :)");
              return;
          }
          var r = new XMLHttpRequest();
          r.onreadystatechange = this.dummy
          r.open("POST", this.state.server + "/message?peer_id=" + this.state.myId + "&to=" + this.state.peer_id, true);
          r.setRequestHeader("Content-Type", "text/plain");
          r.send(data);
      } catch (e) {
          this.trace("send to peer error: " + e);
      }
    }
    
    connect() {


                          this.setState({ localName : document.getElementById("local").value.toLowerCase(),
                                          server    : document.getElementById("server").value.toLowerCase()
                                        },
                                          () => {

                                                  if (this.state.localName.length == 0) {
                                                    alert("I need a name please.");
                                                    document.getElementById("local").focus();
                                                  } else {
                                                    //document.getElementById("connect").disabled = true;
                                                    //document.getElementById("disconnect").disabled = false;
                                                    console.log("localName, server", this.state.localName, this.state.server);
                                                    this.signIn();
                                                  }

                                                  
                                                }

                                       );


                      // } );
        

      
    }
    
    disconnect() {

        console.log('disconnect');
        console.log("this.props@disconnect: ", this.props);
        console.log("this.state@disconnect: ", this.state);
        

        if (this.state.myId != -1) {
            
            this.setState({ request : (new XMLHttpRequest()) }, ()=>{

                                  this.state.request.open("GET", this.state.server + "/sign_out?peer_id=" + this.state.myId, false);
                                  this.state.request.send();
                                  this.setState({ request : null });
                                  this.setState({ myId : -1 });

                          });
            
             
        }

        if (this.state.request) {
            this.state.request.abort();
            this.setState({ request : null });

        }
        
        if (this.state.hangingGet) {
            this.state.hangingGet.onreadystatechange = null;
            this.state.hangingGet.abort();
            this.setState({ hangingGet : null });

        }
      
        //turnoff webcam
        this.stream.getTracks().forEach(
                                        track => {track.enabled = false;
                                        track.stop();
                                       });
        this.localStream.getTracks().forEach(track => {
                                                       track.enabled = false;
                                                       track.stop();
                                                      });
        this.localStream_clone.getTracks().forEach(track => {
                                                              track.enabled = false;
                                                              track.stop();
                                                             });

        
        this.webcamDelayVideo.srcObject = null;
        
  

        //document.getElementById("connect").disabled = false;
        //document.getElementById("disconnect").disabled = true;

        /*
        if(this.remoteVideoCanvas.current != null){
          this.remoteComing = false;
          this.remoteVideoCanvas.current.pause();
          cancelAnimationFrame(this.mergeRef.current);
        }
        */

    }
    
    
    
    send() {
        var text = document.getElementById("message").value;
        this.setState({ peer_id : parseInt(document.getElementById("peer_id").value) });
        
        if (!text.length || this.state.peer_id == 0) {
            alert("No text supplied or invalid peer id");
        } else {
            this.sendToPeer(this.state.peer_id, text);
        }
    }
    
    toggleMe(obj) {
        var id = obj.id.replace("toggle", "msg");
        var t = document.getElementById(id);
        if (obj.innerText == "+") {
            obj.innerText = "-";
            t.style.display = "block";
        } else {
            obj.innerText = "+";
            t.style.display = "none";
        }
    }
    
    onSessionConnecting(message) {
        console.log("Session connecting.");
    }
    
    onSessionOpened(message) {
        console.log("Session opened.");
    }
    
    onRemoteStreamRemoved(event) {
        console.log("Remote stream removed.");
    }
    
    onRemoteSdpError(event) {
        console.error('onRemoteSdpError', event.name, event.message);
    }
    
    onRemoteSdpSucces() {
        console.log('onRemoteSdpSucces');
    } 
  
    changeClothes(){

        if(!this.props.project.onMirror){
            const linkAddr = this.props.location.state.linkAddr;
        }
        this.disconnect()
        //this.props.history.push(linkAddr);
        this.props.history.go(-2);
    }
    




// <RTCMesh URL='wss://localhost:8888' />
  onChangeClientName(e) {
    const ClientName = e.target.value;

    this.setState({
      localName: ClientName
    });
  }

  onChangeServerAddr(e) {
    const ServerAddr = e.target.value;

    this.setState({
      server: ServerAddr
    });
  }


  handleTakePhoto(){

      var data = this.manipulateCanva.current.toDataURL();
      
      

      const blob = this.dataURItoBlob(data);
      //const filename = this.getFileName(blob.type,  this.props.project.projectName);
      //const filename_ext = filename[0] + '.' + filename[1];
      
      this.setState( {filename: this.props.project.photoName});

      
      this.onSubmit(blob, this.props.project.photoName);


  }
  dataURItoBlob (dataURI) {
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ab], {type: mimeString});
    return blob;
  }  


  onSubmit(blob, filename) {


    const { sendPhotoProject, project } = this.props;

    console.log("======================================================================");


    const formData = new FormData();
    console.log("blob@onSubmit ",  blob);
    console.log("filename@onSubmit ", filename)
    formData.append('file', blob, filename);

    try {

      
      sendPhotoProject(project, formData);

      //this.setState({onHandleTakePhoto: false})

    } catch(err) {
      console.log("err in sendPhotoProject")

    }
  }

  render(){
  	

    return (

      

      <div id="container" style={styles.container}>

        {/*
        <video
           ref={this.videoRef}
           autoPlay={true}
           muted="muted"
           playsInline
           style={styles.video}
        /> 
        */}   

        <canvas hidden id="ManipulatingCanvas" ref={this.manipulateCanva}></canvas>
       

        {/* <video id="remoteVideo" ref={this.remoteVideoCanvas} autoPlay={true} muted="muted" playsInline style={styles.video}></video> */}        


        {/*<video
       	   id="webcamVideo"
           autoPlay={true}
           muted="muted"
           playsInline
           style={styles.video}
        />*/}

        <canvas id="webcamCanvas" style={styles.canvas}></canvas>
       


      

        <div><input type="text" id="server" defaultValue="https://aichure.com/ARfitting" onChange={this.onChangeServerAddr.bind(this)} /></div>
        <div><input type="text" id="local"  defaultValue="myclient"                    onChange={this.onChangeClientName.bind(this)} /></div>

 
          
          <div>
          {/*
            <Button
              id="camera"
              type="button"
              onClick={this.start.bind(this)}
              style={styles.button}
            >
              Camera
            </Button>
          */}

            <Button
              id="connect"
              type="button"
              onClick={this.connect.bind(this)}
              style={styles.button}
            >
              Connect
            </Button>

            <Button
              id="disconnect"
              type="button"
              onClick={this.disconnect.bind(this)}
              style={styles.button}
            >
              Disconnect
            </Button>
            
            <Button
              id="changeClothes"
              type="button"
              onClick={this.changeClothes.bind(this)}
              style={styles.button}
            >
              change clothes
            </Button>

          </div>  

          
          <div>
            <pre id="debug"></pre>
            
            
          </div>
      </div>


    );
  };


}

const styles = {
  
  container: {
    
    height: 'fit-content'

  },
  
  button: {
    width:'auto',
    //alignContent: 'center',
    //display: 'block ruby'

  },
  icon: {
    
    alignContent: 'center',
    display: 'grid',
    width:'36px',
    height:'36px',
    padding: '21px'

  },
  video:{

    width: '100vw',
    top: '-10%',
    position: 'relative',

  },
  canvas:{

      //width: '100%',
      //height: '100%',
      //display: 'block'
      //clipPath: 'inset(0px 500px 0px 0px)',
      //width: '200%'
  },
  div:{

    marginLeft: 'auto',
    marginRight: 'auto'

  }
};


export default connect(mapState, mapDispatch)(RTCfittingWebgl);
