import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Grid, Icon } from "semantic-ui-react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "../../lib";
import { ImagePreview } from '../../components';


import {
  
  uploadPhotoXAR,
  updateDataXAR,

  updateProject
  //updateParams

} from "../../store";

import { drawArbitraryQuadImage, FILL_METHOD } from 'canvas-arbitrary-quads';


//import './reset.css';


const mapState = state => ({
  xar: state.xar,
  project: state.project
  //baselayout: state.baselayout
});

const mapDispatch = dispatch => {
  return {

    sendPhotoXAR: (xar, formData) => dispatch(uploadPhotoXAR(xar, formData)),
    sendShootXAR: (xar) => dispatch(updateDataXAR(xar)),

    setProject: (params) => dispatch(updateProject(params))
    //setParams: (params) => dispatch(updateParams(params))

  };
};

class TakePhoto extends Component {
  constructor(props) {
    super(props);
    /*file upload   
    const [file, setFile] = useState('');
    const [filename, setFilename] = useState('Choose File');
    const [uploadedFile, setUploadedFile] = useState({});
    const [message, setMessage] = useState('');
    const [uploadPercentage, setUploadPercentage] = useState(0);
*/ 
    this.state = {

      /*camera*/
      //dataUri: '',
      idealFacingMode: '',
      isMaxResolution: false,
      DataUri: '',
      isFullscreen: true,
      imageNumber: 0,
      modifyDataUri:'',
      camPosSel: 0,  
      camBtnPosSel: 0,
      countdownPosSel: 1,

      userID: '',
      sourceType: 'aichure-takephoto',
      file: '',
      filename: 'Choose File',
      uploadedFile: '',
      message: '',
      uploadPercentage: 0,

      countNum: 3000,
      portait: true,
      isImageRotate: true,

      isShoot: false,

      isCountDown: false,
      timerCount: 0,
      timerRunning: false


    };
  }

  componentDidMount() {

      const { project, setProject } = this.props;

      var params = project;

      params.onButton = false;
      setProject(params);

      console.log("project@TakePhoto", project)
      
      this.setState({ camPosSel: params.camPosSel });
      this.setState({ camBtnPosSel: params.camBtnPosSel });
      this.setState({ countdownPosSel: params.countdownPosSel });

      
  }

  componentDidUpdate() {


  }

  /*camera functions*/

  handleTimerStart(startTime) {
    
      this.setState(
        {isCountDown: true,
         timerCount : startTime,
         timerRunning: true
       }
      );
        
      this.timer = setInterval(() => {
        
        const newCount = this.state.timerCount - 1;
        this.setState(
          {timerCount: newCount >= 0 ? newCount : 0}
        );

        if(newCount <= 0){
          this.handleTimerStop();
          this.handleTimerReset();
          this.setState(
            {isCountDown: false}
          );
        };
        console.log("newCount: ", newCount);

        
      }, 1000);
      console.log("this.timer: ", this.timer);
  }
    
  handleTimerStop() {
  
      if(this.timer) {
        clearInterval(this.timer);
        this.setState(
          {timerRunning:false}
        );
      }

      console.log("handleTimerStop timerRunning: ", this.state.timerRunning);

  }
    
  handleTimerReset() {
      this.setState(
        {timerCount: 0}
      );
      console.log("handleTimerReset timerCount: ", this.state.timerCount);
  }
    
  handleTimerCountdown(seconds) {
  
      this.setState({
        timerCount: seconds,
        timerRunning: true
      })
      console.log("handleTimerCountdown timerRunning: ", this.state.timerRunning);
  }
  
  renderTimer(time) {
  
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
  
    let time_display =  minutes + ':' + seconds;

    console.log("time_display: ", time_display);

    return (
    
      
        <div className="show-product ui raised very padded text container">
          <h1>{time_display}</h1>
        </div>
    );

    
  }

  renderButtons() {
      return (
         
        <div className="show-product ui raised very padded text container">
        
          <button onClick={ (e) => {
            
            this.setState({ IdealFacingMode: FACING_MODES.USER }, () => {
              console.log(this.state.IdealFacingMode, 'IdealFacingMode');
            });
            this.setState({ IsMaxResolution: false });

            console.log("IdealFacingMode:" + this.state.IdealFacingMode);
            console.log("IsMaxResolution:" + this.state.IsMaxResolution);


          }}> FACING_MODES.USER </button>

          <button onClick={ (e) => {
            this.setState({ IdealFacingMode: FACING_MODES.ENVIRONMENT }, () => {
              console.log(this.state.IdealFacingMode, 'IdealFacingMode');
            });
            this.setState({ IsMaxResolution: true });
            
            console.log("IdealFacingMode:" + this.state.IdealFacingMode);
            console.log("IsMaxResolution:" + this.state.IsMaxResolution);


          }}> FACING_MODES.ENVIRONMENT & MaxResolution</button>

          

        </div>
      );
  }

  rotateImage (imageBase64, rotation, camPosSel, cb) {
            
    var img = new Image();
    img.onload = () => {
        var canvas = document.createElement("canvas");
        const maxDim = Math.max(img.height, img.width);
        canvas.width = img.height;
        canvas.height = img.width;
        var ctx = canvas.getContext("2d");
        
        if(camPosSel==90){
          ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
          ctx.rotate(rotation * (Math.PI / 180));
          ctx.drawImage(img, -1*maxDim / 2, -1*maxDim / 2);
          
        }else if(camPosSel==-90 || camPosSel==270){

          ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
          ctx.rotate(rotation * (-Math.PI / 180));
          ctx.drawImage(img, -1*maxDim / 2, - maxDim /16);

        }else if(camPosSel==0){

          ctx.drawImage(img, 0, 0);
        }

        const srcPoints = [
          { x: canvas.width*0.0, y: canvas.height*0.0 },//TL
          { x: canvas.width*0.0, y: canvas.height*1.0 },//BL
          { x: canvas.width*1.0, y: canvas.height*1.0 },//BR
          { x: canvas.width*1.0, y: canvas.height*0.0 } //TR
        ];
         
        const angle = 37.5;   
        const z_value = maxDim*2.13; // 4096.0/1920.0  
        const deform_height = canvas.height*Math.cos(Math.PI*angle/180) * z_value/(z_value-canvas.height*Math.sin(Math.PI*angle/180));
        const lambda = canvas.height/deform_height;
        //cutoff
        const cut_height = Math.floor(canvas.height*0.12);
        const dstPoints = [
          { x: canvas.width*(0.5) - canvas.width*0.5*lambda, 
            y: 0 - cut_height },
          { x: canvas.width*(0.5) + lambda*canvas.width*(-0.5)*z_value/(z_value-canvas.height*Math.sin(Math.PI*angle/180)), 
            y: canvas.height - cut_height },// + 3*cut_height
          { x: canvas.width*(0.5) + lambda*canvas.width*(0.5)*z_value/(z_value-canvas.height*Math.sin(Math.PI*angle/180)), 
            y: canvas.height - cut_height },// + 3*cut_height
          { x: canvas.width*(0.5) + canvas.width*0.5*lambda , 
            y: 0 - cut_height },
        ];
        console.log("drawArbitraryQuadImage2", srcPoints, dstPoints, z_value)
        ctx.resetTransform();
        drawArbitraryQuadImage(ctx, canvas, srcPoints, dstPoints, FILL_METHOD.BILINEAR);

        var clip_canvas = document.createElement("canvas");
        clip_canvas.width = canvas.width;
        clip_canvas.height = canvas.height-cut_height;
        clip_canvas.getContext("2d").drawImage(canvas, 0, 0);

        console.log("TakePhoto clip_canvas resolution : ", clip_canvas.width, clip_canvas.height)

        //cb(clip_canvas.toDataURL("image/jpeg", 1))//send clip size
        cb(canvas.toDataURL("image/jpeg", 1))//send 1920x1080 size
        
 
    };
    img.src = imageBase64;
  }


  handleTakePhoto (dataUri, isImageRotate, userID, sourceType) {
    
    // Do stuff with the photo
    console.log('handleTakePhoto');

    /*
    this.rotateImage( dataUri, -90, (url) => { 
      //this.setState( {DataUri: url});
      this.setState({DataUri: dataUri}, () => {
        console.log('DataUri@handleTakePhoto : ', this.state.DataUri);
      });
      //this.downloadImageFile(this.state.DataUri, this.state.imageNumber, this.state.userID, this.state.sourceType);
      this.setState({ imageNumber: this.state.imageNumber+1 }, () => {
        console.log('imageNumber @ downloadImageFile', this.state.imageNumber);
      });
    } );
    */
    //console.log('dataUri:' + dataUri);
    //console.log('DataUri:' + this.state.DataUri);


    this.setState({DataUri: dataUri}, () => {
        console.log('DataUri@handleTakePhoto : ', this.state.DataUri);
      });
    

    
    this.setState({isShoot: true}, () => {
        console.log('isShoot@handleTakePhoto : ', this.state.isShoot);
      });

  }
  
  handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo
    console.log('handleTakePhotoAnimationDone');
    //upload

  }

  handleCameraError (error) {
    console.log('handleCameraError', error);
  }

  handleCameraStart (stream) {
    console.log('handleCameraStart');
  }

  handleCameraStop () {
    console.log('handleCameraStop');
  }




  dataURItoBlob (dataURI) {
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ab], {type: mimeString});
    return blob;
  }

  padWithZeroNumber (number, width) {
    number = number + '';
    return number.length >= width
      ? number
      : new Array(width - number.length + 1).join('0') + number;
  }

  getFileExtention (blobType) {
    // by default the extention is .png
    let extention = IMAGE_TYPES.PNG;

    if (blobType === 'image/jpeg') {
      extention = IMAGE_TYPES.JPG;
    }
    return extention;
  }

  getFileName (imageNumber, blobType, userID, sourceType, projectName) {
    const prefix = (userID&&sourceType) ? userID+sourceType : projectName+'-takephoto';
    //const photoNumber = this.padWithZeroNumber(imageNumber, 4);
    var options = {
      day: '2-digit',    //(e.g., 1)
      month: '2-digit',    //(e.g., Oct)
      year: '2-digit',   //(e.g., 2019)
      hour: '2-digit',   //(e.g., 02)
      minute: '2-digit', //(e.g., 02)          
      second: '2-digit',
      hour12:false// 
    };


    var now = new Date().toLocaleString('en-us', options,  {timeZone: 'Asia/Taipei', hour12:false}); 

    var photoStamp = now.replace(/\//g,'');
    photoStamp = photoStamp.replace(/:/g, '');
    photoStamp = photoStamp.replace(/,\s/g, '-');
    //const photoStamp = date.replace(/([^\d]*)(\d*)([^\w]*)/, '-');

    const extention = this.getFileExtention(blobType);

    console.log("filename@getFileName: ", prefix+"-"+photoStamp);
    //return `${prefix}-${photoStamp}.${extention}`;
    return [ prefix+"-"+photoStamp, extention];
    
  }

  handleReShoot(){


    this.setState({DataUri: ""}, () => {
        console.log("DataUri@handleReShoot : " + this.state.DataUri);
      });

    this.setState({isShoot: false}, () => {
        console.log('isShoot@handleReShoot : ', this.state.isShoot);
      });

  }
  getImageDimensions(dataUri) {

      return new Promise (function (resolved, rejected) {
                              var i = new Image()
                              i.onload = function(){
                                resolved({w: i.width, h: i.height})
                              };
                              i.src = dataUri
                          })
  }

  handleUsePhoto(dataUri, isImageRotate, imageNumber, userID, sourceType){ 
    
    const { xar, sendShootXAR, project, setProject } = this.props;
    const linkAddr = this.props.location.state.linkAddr;
   
    console.log('handleUsePhoto', dataUri);
    console.log("this.props@handleUsePhoto: ", this.props);
    console.log("this.state@handleUsePhoto: ", this.state);

    let dimensions = this.getImageDimensions(dataUri)









     dimensions.then((dimensions) => {
                                        console.log("dimensions@handleUsePhoto: ", dimensions)
                                        console.log("dimensions.h@handleUsePhoto: ", dimensions.h)
                                        console.log("dimensions.w@handleUsePhoto: ", dimensions.w)
                                        


                                        //if(project.projectName == "asia" && (dimensions.h > 1080 || dimensions.w > 1920) ){
                                        //  console.log("dimensions.h > 1080 || dimension.w > 1920")
                                          

                                        //}else{

                                          //----------
                                            this.rotateImage( dataUri, -90, this.state.camPosSel, (url) => { 


                                                  const blob = this.dataURItoBlob(url);
                                                  const filename = this.getFileName(imageNumber, blob.type, userID, sourceType, project.projectName);
                                                  const filename_ext = filename[0] + '.' + filename[1];
                                                  this.setState( {filename: filename_ext});

                                                  this.setState( {DataUri: url});
                                                  this.onSubmit(blob, filename_ext);
                                                  ////this.downloadImageFile(this.state.DataUri, filename);
                                                  this.downloadImageFileFomBlob(blob, filename_ext);//removed temporary for havas
                                                  
                                                  this.setState( {isImageRotate: false});
                                                  this.setState({ imageNumber: this.state.imageNumber+1 }, () => {
                                                    console.log('imageNumber @ downloadImageFile', this.state.imageNumber);
                                                  });

                                                  xar.isShoot = this.state.isShoot;
                                                  xar.XARimg[0].img = '/' + filename_ext;
                                                  xar.picID = filename[0];
                                                  xar.picExt = filename[1];
                                                  xar.photoURI = dataUri;
                                                  
                                                  
                                                } );   

                                            //-----------------------
    
    

                                        //}

                                    });

    
   
      
    sendShootXAR(xar);
    console.log('xar@handleUsePhoto: ', xar);

    
    console.log('linkAddr@handleUsePhoto: ', linkAddr);


      

    var params = project;

    params.onButton = true;      
    setProject(params);


    //Jenan testing
    //this.props.history.push(`/fitting/product/${product.product.id}`);
    this.props.history.push(linkAddr);
    
    //this.props.history.goBack()
  }

  onSubmit(blob, filename) {
  //onSubmit(blob, filename) {

    const { sendPhotoXAR, xar } = this.props;

    console.log("======================================================================");


    const formData = new FormData();
    console.log("blob@onSubmit ",  blob);
    console.log("filename@onSubmit ", filename)
    formData.append('file', blob, filename);

    try {

      xar.picID = filename;
      console.log("xar @onSubmit : ", xar);
      sendPhotoXAR(xar, formData);

      //const { fileName, filePath } = res.data;
      
      //console.log("res: " + res )
      //this.setState({ UploadedFile: { fileName, filePath } });
      //this.setState({ Message: 'File uploaded' });
      
    } catch(err) {
      console.log("err in sendPhotoXAR")
      /*
      if(err.response.status === 500) {
        this.setState({ Message: 'There was a problem witht he server' });
      } else {
        this.setState({ Message: err.response.data.msg });
      }
      */
    }
  }


  downloadImageFileFomBlob (blob, filename) {
    window.URL = window.webkitURL || window.URL;

    let anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = window.URL.createObjectURL(blob);

    

    let mouseEvent = document.createEvent('MouseEvents');
    mouseEvent.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
    anchor.dispatchEvent(mouseEvent);

    
    this.setState({ filename: anchor.download }, () => {
              console.log("filename@downloadImageFileFomBlob", this.state.filename);
              //this.onSubmit(blob, filename);
            });
    
    

  }

  downloadImageFile (dataUri, filename) {

    let blob = this.dataURItoBlob(dataUri);
    
    this.downloadImageFileFomBlob(blob, filename);

  }



  
  render() {
    const { xar } = this.props;
    const linkAddr = this.props.location.state.linkAddr;
    console.log("this.props@TakePhoto render: ",  this.props);
    console.log("linkAddr@render: ",  linkAddr);
    console.log("time@render :",  this.state.timerCount);
    console.log("xar@render :",  xar);
    const isImageRotate = this.state.isImageRotate;



                         
    console.log("this.state.timerRunning: ", this.state.timerRunning);


    return (
      
        <div>

                       

          { this.renderButtons() }
          {
          (this.state.isShoot)
            ? 
              
              <div style={{perspective: '2048px', perspectiveOrigin: '540px'}}>
                <ImagePreview dataUri={this.state.DataUri}
                  isFullscreen={this.state.isFullscreen}
                  isImageRotate={isImageRotate}
                  camPosSel={this.state.camPosSel}
                />
                
                

                <Grid columns='one' divided style={ this.state.camBtnPosSel ? styles.bar_right : styles.bar_left}>
                  <Grid.Column>
                    <Button
                      onClick={() => this.handleReShoot()}
                      style={styles.button}
                      size="huge" 
                      circular
                    >
                      <Icon  name=" camera " style={styles.xar}  size="huge"/> 
                    </Button>
                  </Grid.Column>
                  <Grid.Column>  
                    <Button onClick={() => this.handleUsePhoto(this.state.DataUri, isImageRotate, xar.id , this.state.sourceType)}
                      style={styles.button}
                      size="huge" 
                      circular
                    >
                      <Icon  name=" check" style={styles.xar}  size="huge"/> 
                    </Button>


                  </Grid.Column>    
                </Grid> 
              </div>
              
            :
           
            <Camera
              onButtonClick = { () => {this.handleTimerStart(0);} }
              onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri, isImageRotate, xar.id, this.state.sourceType); } }
              onTakePhotoAnimationDone = { (dataUri) => { this.handleTakePhotoAnimationDone(dataUri); } }
              onCameraError = { (error) => { this.props.history.push({ pathname: '/fileupload',
                                                                       state: { linkAddr: linkAddr }
                                                                     }); /*this.handleCameraError(error);*/ 
                                             console.log("error: ", error)
                                           } 
                              }
              idealFacingMode = {this.state.idealFacingMode}
              idealResolution = {{width: 1920, height: 1080}}//{{width: 3840, height: 2160}}
              imageType = {IMAGE_TYPES.JPG}
              imageCompression = {0.97}
              isMaxResolution = {this.state.isMaxResolution}
              isImageMirror = {false}
              isImageRotate = {this.state.isImageRotate}
              camPosSel = {this.state.camPosSel}
              camBtnPosSel = {this.state.camBtnPosSel}
              countdownPosSel = {this.state.countdownPosSel}
              isSilentMode = {false}
              isDisplayStartCameraError = {true}
              isFullscreen = {this.state.isFullscreen}
              sizeFactor = {1}
              onCameraStart = { (stream) => { this.handleCameraStart(stream); } }
              onCameraStop = { () => { this.handleCameraStop(); } }
              
            />
                           
       

          }  
        </div>

    ); 
   

    

  }




}

const styles = {

    bar_right: {
        position: 'fixed',
        top: '285px',
        right: '35.5%',
        zindex: '1',
        boxShadow: 'unset'
    }, 

    bar_left: {
        position: 'fixed',
        top: '285px',
        left: '2%',
        zindex: '1',
        boxShadow: 'unset'
    }, 
    
    container: {
       margin: 30
    },

    button: {
        width:'150px',
        height:'150px'
    }, 

    xar: {

      color: 'rgb(255, 112, 112)',
      margin: '0 0 0 0'

    }
};

export default connect(mapState, mapDispatch)(TakePhoto);


