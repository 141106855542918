import axios from 'axios'

//import fileDownload from 'js-file-download'
/**
 * ACTION TYPES
 */
export const GET_PACKS = 'GET_PACKS'
export const ADD_PACKS = 'ADD_PACKS'
export const EDIT_PACKS = 'EDIT_PACKS'
export const UPLOAD_PACKS = 'UPLOAD_PACKS'
export const UPLOAD_SEWING = 'UPLOAD_SEWING'
export const UPDATE_PACKS = 'UPDATE_PACKS'
export const UPLOAD_LIST = 'UPLOAD_LIST'
export const READY_PACKS = 'READY_PACKS'
export const DOWNLOAD_MODEL = 'DOWNLOAD_MODEL'
export const RESET_PACKS = 'RESET_PACKS'


/**
 * INITIAL STATE
 */
const singlePacks = {
  //dataId: 0,
  gender: 0,
  sleeve_type: '',
  shape: '',
  slit_side: false,
  sleeve_length: '',
  clothlist: [],
  masklist: [],
  imglist: [],
  hem: false,
  SewSetting: '',
  collar: false, 

  ImgReady: [], 
  isReady: false, 
  isUpload: false, 

  allReady: false, 

  modelPath: '', 
  ModelDone: false
}
     
const FileDownload = require('js-file-download');
  
/**
 * ACTION CREATORS
 */
const getPacks = packs => ({type: GET_PACKS, packs})
const addPacks = packs => ({type: ADD_PACKS, packs})
const editPacks = packs => ({type: EDIT_PACKS, packs})
const uploadPacks = packs => ({type: UPLOAD_PACKS, packs})
const uploadSewing = packs => ({type: UPLOAD_SEWING, packs})
const updatePacks = packs => ({type: UPDATE_PACKS, packs})
const uploadList = packs => ({type: UPLOAD_LIST, packs})
const readyPcaks = packs => ({type: READY_PACKS, packs})
const downloadModel = packs => ({type: DOWNLOAD_MODEL, packs})
const resetPacks = packs => ({type: RESET_PACKS, packs})

/**
 * THUNK CREATORS
 */

export const defaultPacks = (packs_props) => {
  return async dispatch => {
    try {
      dispatch(resetPacks(singlePacks))
      
    }catch (err) {
      console.error(err)
    }
  }
}

export const findPacks = (packs_props) => {
  return async dispatch => {
    try {
      console.log("packs_props: ", packs_props);
      console.log("sleeve_type: ", packs_props.sleeve_type);
      console.log("sleeve_length: ", packs_props.sleeve_length);
      console.log("shape: ", packs_props.shape);
      console.log("gender: ", packs_props.gender);
      console.log("collar: ", packs_props.collar);
      console.log("hem: ", packs_props.hem);
      console.log("slit_side: ", packs_props.slit_side);
      let method = "Tshirt"
      //const {data} = await axios.post(`/api/sewing/${method}`, {sleeve_type, sleeve_length, shape, gender, collar, hem, slit_side}) server route not yet ready
      const {data} = await axios.post(`/api/sewing`, packs_props)
      console.log("data: ", data);
      console.log("singlePacks: ", singlePacks);

      const packs = {
        //dataId: 0,
        gender: data.gender,
        sleeve_type: data.sleeve_type,
        shape: data.shape,
        slit_side: data.slit_side,
        sleeve_length: data.sleeve_length,
        clothlist: data.clothlist,
        masklist: data.masklist,
        imglist: [],
        hem: data.hem,
        SewSetting: data.SewSetting,
        collar: data.collar, 

        ImgReady: [], 
        isReady: false, 
        isUpload: false, 

        allReady: false, 

        modelPath: '', 
        ModelDone: false
      }


      
      for(var i = 0;i < packs.masklist.length; i++){
          packs.ImgReady[i] = false;
          packs.imglist[i] = '';
             
      }

      console.log("packs@findPacks: ", packs);
      dispatch(getPacks(packs))
      
    }catch (err) {
      console.error(err)
    }
  }
}

export const uploadMaterials = (formData, packs) => {
  return async dispatch => {
    try {

      let method = "Tshirt"



      //const {data} = await axios.post(`/api/sewing/${method}`, {sleeve_type, sleeve_length, shape, gender, collar, hem, slit_side}) server route not yet ready
      const {data} = await axios.post(`/api/sewing/uploadImg`, formData)
      console.log("data: ", data);
      packs.isUpload =  data.isUpload;
      dispatch(uploadPacks(packs))
      
    }catch (err) {
      console.error(err)
    }
  }
}




export const uploadTexList = (packs, folder) => {
  return async dispatch => {

 

    console.log("packs: ", packs);
    const {data} = await axios.post(`/api/sewing/uploadLst`, {packs, folder} );
    
    dispatch(uploadList(packs))

    console.log("request return: ", data);
    
  }
}

export const updateMaterials = (packs) => {
  return async dispatch => {

 
    packs.allReady =  packs.ImgReady.every(e => e === true)
    dispatch(updatePacks(packs))


  }
}

export const readyToUpload = (packs) => {
  return async dispatch => {

    console.log("packs: ", packs);
    packs.isReady = true;

    dispatch(readyPcaks(packs))


  }
}


export const fetchPacks = packs => {
  return async dispatch => {
    const {data} = await axios.get(`/api/sewing/test`)
    dispatch(getPacks(data))
    console.log("data: ", data);
    
  }
}


export const fetchModel = (packs, folder) => {
  return async dispatch => {

    const data = await axios.get(`/api/sewing/downloadModel/${folder}`, {responseType: 'arraybuffer'})
    console.log("data: ", data);
    console.log("data.headers: ", data.headers);

    const headers = data.headers;
    console.log("headers.date: ", headers.date);
    console.log("headers[content-disposition]: ", headers["content-disposition"]);

    var filename = '';
    const headers_disposition = headers["content-disposition"];

    if (headers_disposition && headers_disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(headers_disposition);
        console.log("filenameRegex: ", filenameRegex)
        console.log("matches: ", matches)
        if (matches != null && matches[1]) { 
          filename = matches[1].replace(/['"]/g, '');
        }
    }

    console.log("filename: ", filename);
    
    FileDownload(data.data, filename, "application/octet-stream");


    dispatch(downloadModel(packs))
    
  }
}


/**
 * REDUCER
 */
export default function(state = singlePacks, action) {
  switch (action.type) {
    case GET_PACKS:
      return action.packs
    case ADD_PACKS:
      return action.packs
    case EDIT_PACKS:
      return action.packs
    case UPLOAD_PACKS:
      return action.packs
    case UPLOAD_SEWING:
      return action.packs
    case UPDATE_PACKS:
      return action.packs
    case UPLOAD_LIST:
      return action.packs      
    case READY_PACKS:
      return action.packs 
    case DOWNLOAD_MODEL:
      return Object.assign({}, state, {
                            ModelDone: true
                          })
    case RESET_PACKS:
      return action.packs     
    default:
      return state
  }
}
