import React, { Component } from "react";
import { CategoryGrid } from "../components";
import { connect } from "react-redux";
import { Container, Header, Button, Card, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { fetchCategoriesGender, fetchCategories, fetchCart, fetchHeaders, /*updateParams,*/ updateProject, updateDataXAR } from "../store";
import QRCodeSVG from "qrcode.react";



const mapState = state => ({
  user: state.user,
  categories: state.categories,
  cart: state.cart, 
  //baselayout: state.baselayout,
  project: state.project,
  xar: state.xar
});

const mapDispatch = dispatch => ({
  //getCategoriesGender: () => dispatch(fetchCategoriesGender()),
  getCategories: () => dispatch(fetchCategories()),
  getCart: id => dispatch(fetchCart(id)),

  getHeaders: () => dispatch(fetchHeaders()),
  //setProject: (params) => dispatch(updateProject(params)),

  updateXAR: (xar) => dispatch(updateDataXAR(xar))

});




class Home extends Component {


  constructor(props) {
    super(props);

    //const projectName = this.props.match.params.projectName.toLowerCase();
    //const userId = Number(this.props.match.params.userId);

    const { getCategories, getCart, user, project, /*setProject,*/ xar, updateXAR  } = this.props;
    /*
    var params = project;   
    
    params.projectName = projectName
    if(userId){
        params.userId = userId
    }


    this.state = {

      
      projectName: projectName,      
      windowWidth: window.innerWidth
    };
    console.log("params@home: ", params)
    setProject(params);
    */
    this.state = {
        windowWidth: window.innerWidth,
        userId: project.userId,
        userStatus: -1,
        remoteUserId: 0,

       

    };
    getCategories();


    

    
  }



  componentDidUpdate(prevProps, prevState) {
    // 常見用法（別忘了比較 prop）：
    console.log("**********************************************");
    console.log("componentDidUpdate@home");
    //console.log("this.props.project.:", this.props.project);
    //console.log("prevProps.project.:", prevProps.project);
    //console.log("this.state.userStatus:", this.state.userStatus);
    //console.log("prevState.userStatus:", prevState.userStatus);

    const { project } = this.props;
    
    //not working code => routes .history solve workaround
    if (this.props.project.remoteUserId !== this.state.remoteUserId) {
        this.setState({ remoteUserId: project.remoteUserId });
    }
    if (this.props.project.userStatus !== this.state.userStatus) {
        this.setState({ userStatus: project.userStatus });
    }
  }

  handleResize = (e) => {
  
      this.setState({ windowWidth: window.innerWidth });
  
  }



  componentWillUnmount() {
   
      window.addEventListener("resize", this.handleResize);
   
   } 

  componentDidMount() {
    
    window.addEventListener("resize", this.handleResize);


    
    const { getCategories, getCart, user, project, xar, updateXAR  } = this.props;
    
    //console.log("**********************************************");
    //console.log("componentDidMount@home");
    //console.log("this.props.project.userStatus:", this.props.project.userStatus);
    
    this.setState({ userStatus: project.userStatus });

    this.props.parentCallback();
   
    if (user.id) getCart(user.id);

    
    //getCategories();  
  
    //for men/women catogeries only.
    updateXAR(xar);
    
    


  }

  render() {
    const { user, project } = this.props;
    const { categoryGenderMode, userStatus, onMirror } = project;

    if(categoryGenderMode===0){
      var HierachyCategoryCatalog = 0
    }else{

      var HierachyCategoryCatalog = 0
    }


    const { windowWidth } = this.state; 

    //console.log("userStatus@home render", userStatus, this.state.userStatus)


    return (
      <Container style={ (onMirror&&project.userId<0) ? styles.containerMirror : styles.container} >
        <Header as="h1">
          {user.isAdmin ? (
            <Button
              as={Link}
              to="/admin/category/add"
              basic
              color="green"
              style={styles.button}
            >
              Add A Category
            </Button>
          ) : (
            ""
          )}
        </Header>
        {onMirror   ? 
                      this.state.userStatus===1 ? (<CategoryGrid categoryGenderMode={project.categoryGenderMode} HierachyCategoryCatalog={HierachyCategoryCatalog} project={project} windowWidth={windowWidth}/>)
                                                : (this.state.userId<=0) ? ( 
                                                                            <>
                                                                              <div  style= {styles.imageMirror}>  
                                                                                <Image src={project.mirrorHomeImg} />
                                                                              </div>

                                                                              <div style={styles.mainDiv}>
                                                                                <Header style={styles.instructions}> 掃描 QRcode</Header>
                                                                                <Header style={styles.instructions}> 啟動手機遙控</Header>
                                                                                <div >
                                                                                      <QRCodeSVG size={500} style={styles.qrcode} value={"https://aichure.com/"+project.projectName+"/"+this.props.project.remoteUserId+"/"+this.props.project.mirrorId} />
                                                                                </div>
                                                                               </div>
                                                                            </>
                                                                          )
                                                                        : ""
                    : (<CategoryGrid categoryGenderMode={project.categoryGenderMode} HierachyCategoryCatalog={HierachyCategoryCatalog} project={project} windowWidth={windowWidth}/>)            
        }
        
      </Container>
    );
  }
}

const styles = {
  container: {
    marginRight: 30,
    marginBottom: 30,
    marginLeft: 30,

    backgroundColor: '#f2f2f2',
    borderTop: '4px solid #000000',

  },
  containerMirror: {
    marginRight: 30,
    marginBottom: 30,
    marginLeft: 30,
    minHeight: '51vh',
    paddingTop: '30px',
    backgroundColor: '#FFFFFF',
    //borderTop: '4px solid #000000',
  },
  button: {
    marginLeft: 20
  },
  mainDiv: {
   
    padding: '10% 20%',
    backgroundColor: '#f2f2f2'
  },
  instructions: {
    fontSize: '8vw',// 'calc(15px + 0.390625vw)', //'5.5rem',
    textAlign: 'center',
  },
  qrcode: {
    width: '100%',
    height: '100%'
  },

  imageMirror: {
    //height: "38vh",
    width: "100%",
    marginBottom: "3vh"
                                                                                         

  },  

};

export default connect(mapState, mapDispatch)(Home);
