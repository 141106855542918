import axios from 'axios'
import history from '../../history'
import {emptyUserCart, setUserCartOnSignup} from '../../store'
import store from '../../store';

/**
 * ACTION TYPES
 */
const GET_USER = 'GET_USER'
const REMOVE_USER = 'REMOVE_USER'
const UPDATE_USER = 'UPDATE_USER'

/**
 * INITIAL STATE
 */
const defaultUser = {}

/**
 * ACTION CREATORS
 */
const getUser = user => ({type: GET_USER, user})
const removeUser = () => ({type: REMOVE_USER})
const updateUser = updatedUser => ({type: UPDATE_USER, updatedUser})

/**
 * THUNK CREATORS
 */
export const me = () => async dispatch => {
  try {
    const res = await axios.get('api/auth/me')
    dispatch(getUser(res.data || defaultUser))
  } catch (err) {
    console.error(err)
  }
}

export const auth = (email, password, method, cart, redirect) => async dispatch => {
  let res
  try {

    const body = {
                  databaseName: store.getState().project.projectName,
                  email       : email,
                  password    : password,
                 }

    console.log("method", method)
    res = await axios.post(`/api/auth/${method}`, body)
  } catch (authError) {
    return dispatch(getUser({error: authError}))
  }

  if (method === 'signup') {
    try {
      const {data} = await axios.post(`api/auth/signup/cart`, {
        user: res.data,
        cart: cart
      })

      dispatch(setUserCartOnSignup(data))
    } catch (err) {
      console.error(err)
    }
  }

  try {
    dispatch(getUser(res.data))
    history.push(redirect) /*here Jenan TBD : parameter original: history.push('/home') */
  } catch (dispatchOrHistoryErr) {
    console.error(dispatchOrHistoryErr)
  }
}

export const logout = () => async dispatch => {
  try {
    await axios.post('api/auth/logout')
    dispatch(removeUser())
    dispatch(emptyUserCart())
    history.push('/login')
  } catch (err) {
    console.error(err)
  }
}

export const updateUserToServer = data => async dispatch => {
  try {
    await axios.put(`/api/users/${data.id}`, data)
    dispatch(updateUser(data))
  } catch (err) {
    console.error(err)
  }
}

/**
 * REDUCER
 */
export default function(state = defaultUser, action) {
  switch (action.type) {
    case GET_USER:
      return action.user
    case REMOVE_USER:
      return defaultUser
    case UPDATE_USER:
      return action.updatedUser
    default:
      return state
  }
}
